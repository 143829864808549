var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import FormInputText from "../FormInputText";
import { getFieldTitle, getFieldInfo } from "../../../../redux/form/form.utils";
import isNaN from "lodash/isNaN";
import { formatPhoneWhileTyping, formatPhoneWhileTypingWithoutSpaces, isValidPhone, } from "../../../../utils/phone/phone.utils";
import { getLocalizedText } from "../../../../utils/i18n/i18n.utils";
import { isValidEmail } from "../../../../utils/email.utils";
var FormInputPhoneOrEmailJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormInputPhoneOrEmailJsonFormSchema, _super);
    function FormInputPhoneOrEmailJsonFormSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        // Helpers
        _this.isPossiblePhone = function (input) {
            if (input.length === 0) {
                return false;
            }
            var firstCharacter = input.substring(0, 1);
            // First character is '+'
            if (firstCharacter === "+") {
                return true;
            }
            // First character is number
            if (!isNaN(parseInt(firstCharacter))) {
                return true;
            }
            return false;
        };
        return _this;
    }
    // Render
    FormInputPhoneOrEmailJsonFormSchema.prototype.render = function () {
        var _this = this;
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        var placeholder = customUiSchema.placeholder, suffixText = customUiSchema.suffixText, isDisabled = customUiSchema.isDisabled;
        var disabled = isDisabled
            ? isDisabled(formContext.getAllFormData())
            : undefined;
        var handleChangeValue = function (input) {
            var formattedInput = undefined;
            if (input.length > 0) {
                if (_this.isPossiblePhone(input)) {
                    formattedInput = formatPhoneWhileTypingWithoutSpaces(input);
                }
                else {
                    formattedInput = input;
                }
            }
            onChange(formattedInput);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, formattedInput);
                }
            }, 0);
        };
        var formDataFormatted = formData || "";
        if (this.isPossiblePhone(formDataFormatted)) {
            formDataFormatted = formatPhoneWhileTyping(formData);
        }
        return (_jsx(FormInputText, __assign({}, customUiSchema, { onPressExtraInfo: this.handlePressExtraInfo, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), placeholder: placeholder, suffixText: suffixText, value: formDataFormatted, onChangeValue: handleChangeValue, error: rawErrors[0], disabled: disabled })));
    };
    // Validation
    FormInputPhoneOrEmailJsonFormSchema.validate = function (formData, errors, schema, uiSchema) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:phone_or_email") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            if (isValidPhone(data) || isValidEmail(data)) {
                return;
            }
            fieldError.addError(getLocalizedText("system.form.error.invalid_phone_or_email"));
        });
    };
    return FormInputPhoneOrEmailJsonFormSchema;
}(React.PureComponent));
export default FormInputPhoneOrEmailJsonFormSchema;
