var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { transparentize } from "polished";
import { StyledListWrapper } from "../ResourceList";
export var StyledDropdownMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: ", ";\n  position: relative;\n  z-index: 99999;\n  margin: ", " 0 ", ";\n  box-shadow:\n    0 0 0 1px\n      ", ",\n    0 2px 16px\n      ", ";\n  border-radius: ", ";\n  ", " {\n    padding: ", " 0;\n  }\n  ", " + ", " {\n    ", "\n  }\n"], ["\n  max-width: ", ";\n  position: relative;\n  z-index: 99999;\n  margin: ", " 0 ", ";\n  box-shadow:\n    0 0 0 1px\n      ", ",\n    0 2px 16px\n      ", ";\n  border-radius: ", ";\n  ", " {\n    padding: ", " 0;\n  }\n  ", " + ", " {\n    ", "\n  }\n"])), "calc(100vw - ".concat(parseInt("".concat(spacing()), 10) * 2, "px)"), spacing("tight"), spacing("tight"), function (props) { return transparentize(0.9, props.theme.colors.neutral100); }, function (props) { return transparentize(0.9, props.theme.colors.neutral100); }, borderRadius(), StyledListWrapper, spacing("tight"), StyledListWrapper, StyledListWrapper, function (_a) {
    var theme = _a.theme;
    return "border-top: 1px solid ".concat(theme.getColor("gray", 200), ";");
});
export var StyledDropdownMenuWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  overflow-x: auto;\n  background-color: ", ";\n  border-radius: ", ";\n  max-height: 440px;\n"], ["\n  position: relative;\n  overflow-x: auto;\n  background-color: ", ";\n  border-radius: ", ";\n  max-height: 440px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, borderRadius());
export var DropdownTrigger = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  cursor: pointer;\n  &:hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary");
});
var templateObject_1, templateObject_2, templateObject_3;
