import { jsx as _jsx } from "react/jsx-runtime";
import VisuallyHidden from "../../VisuallyHidden";
import * as S from "../SharedForm.styled";
export var FieldTemplate = function (_a) {
    var children = _a.children, hidden = _a.hidden;
    if (hidden) {
        return _jsx(VisuallyHidden, { children: children });
    }
    return _jsx(S.Field, { children: children });
};
