var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DropdownMenu, Loading, TextField } from "../../components";
import { ELoadingSize } from "../Loading/Loading.types";
import { ResourceList, ResourceListItem, renderResourceListAction, } from "../ResourceList";
var AutocompleteField = function (_a) {
    var loadOptions = _a.loadOptions, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, onInputChange = _a.onInputChange, optionRender = _a.optionRender, id = _a.id, name = _a.name, label = _a.label, createOptions = _a.createOptions, initialOptions = _a.initialOptions, inputRender = _a.inputRender, _b = _a.initialValue, initialValue = _b === void 0 ? "" : _b, _c = _a.clearAfterChange, clearAfterChange = _c === void 0 ? false : _c, renderId = _a.renderId, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, props = __rest(_a, ["loadOptions", "onChange", "onFocus", "onBlur", "onInputChange", "optionRender", "id", "name", "label", "createOptions", "initialOptions", "inputRender", "initialValue", "clearAfterChange", "renderId", "isLoading"]);
    var _e = React.useState(), value = _e[0], setValue = _e[1];
    var _f = React.useState(), options = _f[0], setOptions = _f[1];
    var _g = React.useState(false), showDropdown = _g[0], setShowDropdown = _g[1];
    var hasFocus = React.useRef(false);
    React.useEffect(function () {
        setValue(initialValue);
    }, [initialValue, renderId]);
    var handleLoadOptions = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var loadedOptions, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadOptions(value)];
                case 1:
                    loadedOptions = _a.sent();
                    if (hasFocus.current) {
                        if (loadedOptions && loadedOptions.length > 0) {
                            setOptions(loadedOptions);
                            setShowDropdown(true);
                        }
                        else {
                            setOptions([]);
                            setShowDropdown(!!createOptions && value !== "");
                        }
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.warn(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (item) {
        if (clearAfterChange) {
            setValue("");
        }
        setShowDropdown(false);
        setOptions([]);
        onChange(item);
    };
    var handleChange = function (valueOrEvent) {
        var isEventValue = typeof valueOrEvent === "object" && valueOrEvent.target;
        var newValue = isEventValue ? valueOrEvent.target.value : valueOrEvent;
        setValue(newValue);
        if (onInputChange) {
            onInputChange(valueOrEvent);
        }
        handleLoadOptions(newValue);
    };
    var handleFocus = function (e) {
        var _a, _b, _c, _d, _e;
        (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
        (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
        (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
        (_e = (_d = e.nativeEvent) === null || _d === void 0 ? void 0 : _d.stopImmediatePropagation) === null || _e === void 0 ? void 0 : _e.call(_d);
        hasFocus.current = true;
        if (initialOptions && (!options || options.length === 0)) {
            setShowDropdown(true);
        }
        else {
            options && options.length > 0 && setShowDropdown(true);
        }
        // !!createOptions && setShowDropdown(true)
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    var handleBlur = function (event) {
        hasFocus.current = false;
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        // Small delay to take click on dropdown item in account
        setTimeout(function () {
            setShowDropdown(false);
        }, 250);
    };
    var renderList = function (option) {
        var _a;
        var handleOptionClick = function () {
            handleClick(option.data);
        };
        return (_jsx(ResourceListItem, __assign({ mediaSize: "medium", media: option.media, item: option, boxProps: { p: (_a = option.paddingOverride) !== null && _a !== void 0 ? _a : 2 }, onClick: handleOptionClick }, { children: option.content })));
    };
    return (_jsx(DropdownMenu, { passDownClickEvents: false, children: _jsx("div", { children: inputRender ? (
            // @ts-ignore
            React.createElement(inputRender, __assign({ value: value, autoComplete: "off", onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange, name: name, label: label }, props))) : (_jsx(TextField, __assign({ id: id, label: label, value: value, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, autoComplete: "off", name: name, prefix: isLoading ? _jsx(Loading, { size: ELoadingSize.Medium }) : null }, props))) }), extraData: { options: options, createOptions: createOptions }, renderContent: function (closeDropdownMenu) {
            var showOptions = options && options.length > 0;
            return (_jsxs(_Fragment, { children: [showOptions && (_jsx(ResourceList, { items: options, renderItem: optionRender || renderList, hasSeparator: false })), !showOptions && initialOptions && initialOptions.length > 0 && (_jsx(ResourceList, { items: initialOptions, renderItem: optionRender || renderList, hasSeparator: false })), !!createOptions && (_jsx(ResourceList, { items: createOptions, renderItem: renderResourceListAction, hasSeparator: false }))] }));
        }, tooltipShown: showDropdown, triggerProps: {
            trigger: "none",
            placement: "bottom-start",
            modifiers: {
                setWidth: {
                    enabled: true,
                    order: 840,
                    fn: function (data) {
                        data.offsets.popper.left = data.offsets.reference.left;
                        data.offsets.popper.right = data.offsets.reference.right;
                        data.offsets.popper.width = data.styles.width = Math.round(data.offsets.reference.width);
                        return data;
                    },
                },
            },
        } }));
};
export default AutocompleteField;
