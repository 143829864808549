var UNIT = 4;
var SPACING_DATA = {
    none: 0,
    extraTight: "".concat(UNIT, "px"),
    tight: "".concat(UNIT * 2, "px"),
    base: "".concat(UNIT * 4, "px"),
    loose: "".concat(UNIT * 6, "px"),
    extraLoose: "".concat(UNIT * 8, "px"),
    superLoose: "".concat(UNIT * 16, "px"),
};
export var spacing = function (value) {
    if (value === void 0) { value = "base"; }
    return SPACING_DATA[value];
};
export var baseUnit = UNIT;
