var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getFieldTitle } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { useSelector } from "react-redux";
import Banner from "../../Banner";
export var InfoField = function (_a) {
    var uiSchema = _a.uiSchema, formContext = _a.formContext, formData = _a.formData;
    var _b = uiSchema, icon = _b.icon, subtitle = _b.subtitle, getSubtitle = _b.getSubtitle, actionTitle = _b.actionTitle, type = _b.type, onPressAction = _b.onPressAction;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    var customSubtitle = useSelector(function (state) {
        return subtitle ||
            (!!getSubtitle &&
                getSubtitle(state, formContext.getAllFormData(), formData));
    });
    return (_jsxs(Banner, __assign({ title: title, icon: icon, hasDismiss: false, variation: type }, { children: [!!customSubtitle && (_jsx("span", __assign({ style: { whiteSpace: "pre-line" } }, { children: customSubtitle }))), actionTitle && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsx(ButtonBasic, { title: actionTitle, onPress: onPressAction })] }))] })));
};
