var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FastField as FormikField, getIn } from "formik";
import TextField from "../../../TextField";
export var Field = function (_a) {
    var name = _a.name, type = _a.type, _b = _a.onChange, onChange = _b === void 0 ? function () {
        return;
    } : _b, rest = __rest(_a, ["name", "type", "onChange"]);
    var render = function (_a) {
        var _b = _a.field, onFormikChange = _b.onChange, field = __rest(_b, ["onChange"]), _c = _a.form, touched = _c.touched, errors = _c.errors;
        var error = getIn(errors, name);
        var touch = getIn(touched, name);
        var fieldAttr = __assign(__assign({}, field), { onChange: function (e) {
                onFormikChange(e);
                onChange(e);
            } });
        return (_jsx(TextField, __assign({ label: name, id: name, error: touch && error && error }, fieldAttr, rest, { type: type || "text" })));
    };
    return _jsx(FormikField, { name: name, render: render });
};
