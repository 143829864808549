import remove from "./remove";
export var DEFAULT_ACTIONS = {
    remove: remove,
};
export default function execute(_a, schema, formData, extraActions) {
    var type = _a.type, params = _a.params;
    if (extraActions === void 0) { extraActions = {}; }
    var action = extraActions[type]
        ? extraActions[type]
        : DEFAULT_ACTIONS[type];
    action === null || action === void 0 ? void 0 : action(params, schema, formData);
}
