var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { IMaskInput } from "react-imask";
import MaskedInput from "react-text-mask";
import Labelled from "../Labelled";
import { setCaretPosition } from "../SharedForm/utils";
import { StyledBackdrop, StyledFloatIcon, StyledInput, StyledPrefix, StyledSuffix, StyledTextField, } from "./TextField.styled";
var TextField = /** @class */ (function (_super) {
    __extends(TextField, _super);
    function TextField(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            focus: false,
        };
        _this.inputRef = _this.props.innerRef
            ? _this.props.innerRef
            : React.createRef();
        return _this;
    }
    TextField.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.value, value = _c === void 0 ? "" : _c, placeholder = _a.placeholder, size = _a.size, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.readOnly, readOnly = _e === void 0 ? false : _e, _f = _a.autoFocus, autoFocus = _f === void 0 ? false : _f, _g = _a.autoComplete, autoComplete = _g === void 0 ? "" : _g, name = _a.name, error = _a.error, _h = _a.errorMessageHidden, errorMessageHidden = _h === void 0 ? false : _h, _j = _a.multiline, multiline = _j === void 0 ? false : _j, _k = _a.elementType, elementType = _k === void 0 ? "input" : _k, _l = _a.type, type = _l === void 0 ? "text" : _l, label = _a.label, _m = _a.labelHidden, labelHidden = _m === void 0 ? false : _m, helpText = _a.helpText, optional = _a.optional, prefix = _a.prefix, suffix = _a.suffix, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, onKeyDown = _a.onKeyDown, onMouseUp = _a.onMouseUp, min = _a.min, max = _a.max, minLength = _a.minLength, maxLength = _a.maxLength, pattern = _a.pattern, floatIcon = _a.floatIcon, floatIconClick = _a.floatIconClick, iMaskProps = _a.iMaskProps, mask = _a.mask;
        var isTextarea = elementType === "textarea" || multiline;
        var prefixMarkup = prefix ? (_jsx(StyledPrefix, __assign({ id: "".concat(id, "Prefix") }, { children: prefix }))) : null;
        var suffixMarkup = suffix ? (_jsx(StyledSuffix, __assign({ id: "".concat(id, "Suffix") }, { children: suffix }))) : null;
        var onFloatClick = function () {
            if (_this.inputRef.current) {
                _this.inputRef.current.focus();
            }
            if (floatIconClick) {
                floatIconClick();
            }
        };
        var floatMarkup = floatIcon ? (_jsx(StyledFloatIcon, __assign({ onClick: onFloatClick, id: "".concat(id, "Float") }, { children: floatIcon }))) : null;
        var inputProps = {
            ref: this.inputRef,
            name: name,
            id: id,
            disabled: disabled,
            readOnly: readOnly,
            autoFocus: autoFocus,
            autoComplete: autoComplete,
            value: value,
            placeholder: placeholder,
            onFocus: onFocus,
            onBlur: onBlur,
            onKeyDown: onKeyDown,
            onChange: onChange,
            min: min,
            max: max,
            minLength: minLength,
            maxLength: maxLength,
            pattern: pattern,
            type: type,
            onMouseUp: onMouseUp,
        };
        var maskedInput = function (ref, props) {
            var setCaretPositionToEndOfString = function (_a) {
                var target = _a.target;
                setTimeout(function () {
                    setCaretPosition(target, target.value.indexOf("_"));
                }, 1);
            };
            var handleChange = function (e) {
                props.onChange(e);
                if (onChange) {
                    onChange(e);
                }
            };
            // inputProps.onChange = handleChange
            return (_jsx(StyledInput, __assign({ ref: function (input) { return ref(input); }, as: "input" }, props, { onChange: handleChange, onFocus: setCaretPositionToEndOfString, id: inputProps.id, name: inputProps.id })));
        };
        var handleIMaskChange = function (value) {
            if (onChange) {
                onChange({ target: { value: value } });
            }
        };
        var renderInputMarkup = function () {
            switch (true) {
                case !!mask:
                    var _a = _this.props, guide = _a.guide, placeholderChar = _a.placeholderChar, keepCharPositions = _a.keepCharPositions, pipe = _a.pipe, showMask = _a.showMask;
                    return (_jsx(MaskedInput, { value: value, 
                        // @ts-ignore
                        mask: mask, guide: guide, placeholderChar: placeholderChar, keepCharPositions: keepCharPositions, pipe: pipe, showMask: showMask, render: maskedInput }));
                case !!iMaskProps:
                    return (_jsx(IMaskInput, __assign({}, iMaskProps, { value: value, onAccept: handleIMaskChange })));
                // return <MaskedStyledInput {...iMaskProps} key="mask" onAccept={handleIMaskChange} value={value} {...inputProps} />
                default:
                    return (_jsx(StyledInput, __assign({ as: isTextarea ? "textarea" : "input", size: size, isTextarea: isTextarea }, inputProps)));
            }
        };
        var renderTextField = function () {
            return (_jsx(_Fragment, { children: _jsxs(StyledTextField, __assign({ hasError: !!error }, { children: [prefixMarkup, renderInputMarkup(), suffixMarkup, floatMarkup, _jsx(StyledBackdrop, {})] })) }));
        };
        return label ? (_jsx(Labelled, __assign({ label: label, error: errorMessageHidden ? undefined : error, labelHidden: labelHidden, helpText: helpText, optional: optional, onPressExtraInfo: this.props.onPressExtraInfo }, { children: renderTextField() }))) : (_jsx(_Fragment, { children: renderTextField() }));
    };
    return TextField;
}(React.PureComponent));
export default TextField;
