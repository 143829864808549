var _a;
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import { differenceInCalendarDays, format as dateFormat, isBefore, isToday, subDays, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { enGB, enUS, fr, nl } from "date-fns/locale";
import * as t from "../services/translationService";
export var dateLocales = (_a = {},
    _a[ELocale.NL] = nl,
    _a[ELocale.FR] = fr,
    _a[ELocale.EN] = enGB,
    _a.en_US = enUS,
    _a.en_GB = enGB,
    _a);
export var format = function (
// TODO: Date should not be undefined
date, formatStr) {
    if (formatStr === void 0) { formatStr = "dd/MM/yyyy"; }
    return date === undefined
        ? ""
        : dateFormat(date, formatStr, {
            locale: dateLocales[getLocale() || nl],
        });
};
export var diffForHumans = function (date, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.from, from = _c === void 0 ? new Date() : _c, _d = _b.to, to = _d === void 0 ? subDays(new Date(), 2) : _d, formatStr = _b.formatStr, todayAsTime = _b.todayAsTime, firstWeekAsString = _b.firstWeekAsString;
    var diff = differenceInCalendarDays(date, from);
    switch (true) {
        case todayAsTime && isToday(date):
            return format(date, "HH:mm");
        case diff === 0:
            return t.system("today");
        case diff === -1:
            return t.system("yesterday");
        case isBefore(to, date):
            var daysAgo = 0 - diff;
            if (firstWeekAsString && daysAgo < 7) {
                return format(date, "EEEE");
            }
            return t.daysAgo(daysAgo);
        default:
            return format(date, formatStr);
    }
};
export var removeTimezoneOffset = function (date) {
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
};
