var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "../../components";
import DisplayText from "../DisplayText";
import { StyledNoResults, StyledNoResultsContent } from "./NoResults.styled";
var NoResults = function (_a) {
    var children = _a.children, heading = _a.heading, icon = _a.icon, _b = _a.headingSize, headingSize = _b === void 0 ? "small" : _b, _c = _a.maxWidth, maxWidth = _c === void 0 ? 400 : _c, _d = _a.iconColor, iconColor = _d === void 0 ? "gray" : _d;
    return (_jsxs(StyledNoResults, __assign({ maxWidth: maxWidth }, { children: [icon && _jsx(Icon, { color: iconColor, source: icon, size: "extraLarge" }), heading && (_jsx(DisplayText, __assign({ size: headingSize, space: headingSize === "small" ? "tight" : "base" }, { children: heading }))), children && _jsx(StyledNoResultsContent, { children: children })] })));
};
export default NoResults;
