var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import Engine from "json-rules-engine-simplified";
import toPath from "lodash/toPath";
import getFromPath from "ramda/es/path";
import React from "react";
import { DescriptionList, DescriptionListItem, DisplayText, Grid, Icon, } from "..";
import * as t from "../../services/translationService";
import Button from "../Button";
import ContactList from "../ContactList";
import * as S from "./JsonSchemaRender.styled";
import rulesRunner from "./utils/rulesRunner";
var isHidden = function (schema) {
    return schema.ui &&
        (schema.ui["ui:widget"] === "hidden" ||
            (schema.ui["ui:summary"] && schema.ui["ui:summary"].hidden === true));
};
var applyRules = function (schema, formData) { return __awaiter(void 0, void 0, void 0, function () {
    var runRules, newSchema, unknownError_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!schema.rules) return [3 /*break*/, 5];
                runRules = rulesRunner(schema, schema.rules, Engine);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, runRules(formData)];
            case 2:
                newSchema = (_a.sent()).schema;
                return [2 /*return*/, newSchema];
            case 3:
                unknownError_1 = _a.sent();
                error = unknownError_1;
                console.warn(error);
                return [3 /*break*/, 4];
            case 4: return [3 /*break*/, 6];
            case 5: return [2 /*return*/, Promise.resolve(schema)];
            case 6: return [2 /*return*/];
        }
    });
}); };
var displayTextSize = ["medium", "small"];
var Steps = function (_a) {
    var schema = _a.schema, path = _a.path, level = _a.level, formData = _a.formData, currentStep = _a.currentStep, goToStep = _a.goToStep;
    return schema.steps.map(function (step, stepIndex) {
        var hasSteps = !!step.steps;
        var showEdit = (!hasSteps && level === 0) || level === 1;
        var thisStep = [];
        if (level === 0) {
            thisStep = [stepIndex];
        }
        if (level === 1) {
            thisStep = __spreadArray(__spreadArray([], currentStep, true), [stepIndex], false);
        }
        var handleEditClick = function () {
            goToStep === null || goToStep === void 0 ? void 0 : goToStep(thisStep);
        };
        if (isHidden(step)) {
            return false;
        }
        return step.type === "summary" ? null : (_jsxs(S.Step, { children: [_jsxs(Grid, __assign({ justifyContent: "space-between", mb: 2 }, { children: [_jsx(Grid.Item, __assign({ flexGrow: 1 }, { children: _jsx(DisplayText, __assign({ size: displayTextSize[level] }, { children: step.title })) })), _jsx(Grid.Item, __assign({ flexGrow: 1 }, { children: goToStep && showEdit && (_jsx(Button, __assign({ onClick: handleEditClick, appearance: "plain" }, { children: _jsx(Icon, { source: "edit" }) }))) }))] })), _jsx(Schema, { schema: step, path: path, level: level + 1, formData: formData, goToStep: goToStep, currentStep: thisStep })] }, stepIndex));
    });
};
var renderField = function (_a) {
    var schema = _a.schema, path = _a.path, rest = __rest(_a, ["schema", "path"]);
    var type = Array.isArray(schema.type) ? schema.type[0] : schema.type;
    var renderArray = function (schema, path) {
        var _a;
        var pathArray = toPath(path);
        var values = getFromPath(pathArray, rest.formData) || [];
        if ((_a = schema.ui) === null || _a === void 0 ? void 0 : _a["ui:field"]) {
            switch (schema.ui["ui:field"]) {
                case "contact":
                    return _jsx(ContactList, { contacts: values }, path);
            }
        }
        return values.map(function (_, index) {
            var item = renderField(__assign({ schema: schema.items, path: "".concat(path, "[").concat(index, "]") }, rest));
            return _jsx(S.ArrayItem, { children: item }, index);
        });
    };
    var renderPrimitive = function (schema, path) {
        var title = schema.title, type = schema.type, format = schema.format, _a = schema.ui, ui = _a === void 0 ? [] : _a;
        var pathArray = toPath(path);
        var value = getFromPath(pathArray, rest.formData);
        var enums = schema.enum; // enum is reserved keyword
        if (enums) {
            var enumIndex = enums.indexOf(value);
            value = schema.enumNames[enumIndex];
        }
        if (isHidden(schema)) {
            return false;
        }
        switch (format) {
            case "date":
                return (_jsx(DescriptionListItem, { term: title, description: value ? value : t.system("empty_value") }, path));
        }
        switch (type) {
            case "boolean":
                var displayValue = value ? t.system("yes") : t.system("no");
                if (value === undefined) {
                    displayValue = t.system("empty_value");
                }
                return (_jsx(DescriptionListItem, { term: title, description: displayValue }, path));
        }
        switch (ui["ui:widget"]) {
            case "hidden":
                return false;
            case "currency":
                return (_jsx(DescriptionListItem, { term: title, description: value ? formatCurrency(value) : t.system("empty_value") }, path));
        }
        return (_jsx(DescriptionListItem, { term: title, description: value ? value : t.system("empty_value") }, path));
    };
    switch (type) {
        case "object":
            if (isHidden(schema)) {
                return null;
            }
            return _jsx(Schema, __assign({ schema: schema, path: path }, rest), path);
        case "array":
            return renderArray(schema, path);
        default:
            return renderPrimitive(schema, path);
    }
};
// @ts-ignore
var Schema = function (_a) {
    var oldSchema = _a.schema, path = _a.path, _b = _a.level, level = _b === void 0 ? 0 : _b, formData = _a.formData, _c = _a.currentStep, currentStep = _c === void 0 ? [] : _c, rest = __rest(_a, ["schema", "path", "level", "formData", "currentStep"]);
    var _d = React.useState(), schema = _d[0], setSchema = _d[1];
    React.useEffect(function () {
        applyRules(oldSchema, formData).then(function (parsedSchema) {
            setSchema(parsedSchema);
        });
    }, [formData, oldSchema]);
    if (oldSchema.type === "steps") {
        return (_jsx(Steps, __assign({ schema: oldSchema, currentStep: currentStep, path: path, level: level, formData: formData }, rest)));
    }
    if (!schema) {
        return null;
    }
    var props = schema.properties || {};
    var fields = Object.keys(props).reduce(function (render, propName) {
        var field = renderField(__assign({ schema: props[propName], path: path ? "".concat(path, ".").concat(propName) : propName, formData: formData }, rest));
        return __spreadArray(__spreadArray([], render, true), [field], false);
    }, []);
    return Array.isArray(fields[0]) ? (fields[0]) : (_jsx(DescriptionList, __assign({ termWidth: 320 }, { children: fields })));
};
var JsonSchemaRender = function (_a) {
    var props = __rest(_a, []);
    return (_jsxs("div", { children: [_jsx(DisplayText, { children: t.system("summary") }), _jsx(Schema, __assign({ path: "" }, props))] }));
};
export default JsonSchemaRender;
