var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Platform, Text } from "react-native";
export var htmlTextAsReactText = function (input) {
    var resultString = input.replace(/<br\/>/g, "\n").replace(/<br>/g, "\n");
    var boldResultString = input.match(/<s*b[^>]*>(.*?)<s*\/s*b>/g) || [];
    boldResultString.forEach(function (element) {
        resultString = resultString.replace(element, "::x::" + element + "::x::");
    });
    return (_jsx(Text, { children: resultString.split("::x::").map(function (text, index) {
            var isBold = text.includes("<b>");
            var cleanText = text.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, "");
            if (isBold) {
                return (_jsx(Text, __assign({ style: { fontWeight: "900" } }, { children: cleanText }), index));
            }
            return _jsx(Text, { children: cleanText }, index);
        }) }));
};
var element = undefined;
if (element) {
    element = document.createElement("div");
}
export var decodeHTMLEntities = function (input) {
    var result = input;
    if (Platform.OS !== "web") {
        return result;
    }
    input = input.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    input = input.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    if (element) {
        element.innerHTML = input;
        if (element.textContent) {
            result = element.textContent;
        }
        element.textContent = "";
    }
    return result;
};
export var capitalize = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var isPossibleTranslationKey = function (string) {
    // No white spaces, any characters between a-z or A-Z, any digit, must contain a dot
    return /^\S+[A-Za-z\d]*\.\S*$/.test(string);
};
