// cspell:disable
import merge from "lodash/merge";
import { Platform } from "react-native";
var rentioTheme = {
    name: "rentio-base",
    colors: {
        background: "white",
        neutral1: "black",
        neutral2: "white",
        grey: "rgb(209, 209, 215)",
        grey2: "rgb(207, 208, 208)",
        global1: "rgb(33, 47, 46)",
        global2: "#7F8281",
        global3: "rgb(229, 229, 229)",
        global4: "rgb(245, 245, 245)",
        global5: "rgb(26, 179, 148)",
        global6: "rgb(13, 139, 105)",
        global7: "rgb(43, 51, 49)",
        info: "#F1FAFC",
        infoText: "#2D9CDB",
        success: "#F1F9F6",
        successText: "#4AB89A",
        warning: "#E69100",
        warningText: "#E69100",
        error: "#FEF2F1",
        errorText: "#EF3D59",
    },
    fonts: Platform.select({
        web: {
            primary: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            financial: '"SFMono-Medium", "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Consolas, Courier, monospace',
        },
        ios: {
            primary: "System",
            financial: "Courier",
        },
        android: {
            primary: "System",
            financial: "monospace",
        },
        default: {
            primary: "",
            financial: "",
        },
    }),
    textStyles: {},
};
export var rentioThemeNative = merge({}, rentioTheme, {
    name: "rentio-base-native",
    fonts: Platform.OS === "ios"
        ? {
            primary: "System",
            financial: "Courier",
        }
        : {
            primary: "System",
            financial: "monospace",
        },
});
export default rentioTheme;
