var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import verifyPhoneSchemas from "@rentiohq/shared-frontend/dist/forms/verifyPhone";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/verifyPhone/schema.verifyPhone.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useSelector } from "react-redux";
import { DisplayText, Link } from "../../components";
import { MultiStepForm } from "../../components/SharedForm/MultiStepForm";
import * as t from "../../services/translationService";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
var VerifyPhone = function () {
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    // State
    var _a = React.useState(true), preventRedirect = _a[0], setPreventRedirect = _a[1];
    // Redux
    var accessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!user || user.unverifiedPhone) {
            return;
        }
        navigateAfterSuccess();
    }, [accessToken, navigateAfterSuccess, preventRedirect, user]);
    // Render
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authVerificationTitle() }), _jsx(MultiStepForm, { formId: "verify-phone", 
                                    // @ts-ignore state match
                                    isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionResultSelector: performingSubmitActionResultSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(), submitActionCreator: submitActionCreator(), schemas: verifyPhoneSchemas({}), asModal: false, withAside: false, onSuccess: function () {
                                        setPreventRedirect(false);
                                    }, submitLabel: getLocalizedText("auth.verification.submit.label"), fullWidthSubmitButton: true }), _jsx(StyledAuthActions, { children: _jsx(Link, __assign({ to: "/auth/signin" }, { children: t.authToLogin() })) })] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
export default VerifyPhone;
