var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import MarkdownWrapper from "../components/MarkdownWrapper";
export var t = function (id, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.values, values = _c === void 0 ? {} : _c, n = _b.n, locale = _b.locale;
    return getLocalizedText(id, values, n, locale);
};
export var tm = function (id, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.values, values = _c === void 0 ? {} : _c, n = _b.n, locale = _b.locale, markdownProps = _b.markdownProps;
    return (_jsx(MarkdownWrapper, __assign({}, markdownProps, { source: getLocalizedText(id, values, n, locale) })));
};
