var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { spacing } from "@rentiohq/web-theme";
import { DisplayText } from "../../../../components";
export var Fieldset = styled.fieldset(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-inline-size: initial; /* <pre> fix */\n  margin: 0;\n  padding: 0;\n  border: none;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  > button {\n    &:not(:first-child) {\n      margin-top: ", ";\n    }\n  }\n"], ["\n  min-inline-size: initial; /* <pre> fix */\n  margin: 0;\n  padding: 0;\n  border: none;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  > button {\n    &:not(:first-child) {\n      margin-top: ", ";\n    }\n  }\n"])), function (_a) {
    var divider = _a.divider, theme = _a.theme;
    return divider
        ? "\n    border-bottom: 1px solid ".concat(theme.getColor("gray", 200), ";\n    margin-bottom: ").concat(spacing(), ";\n    padding-bottom: ").concat(spacing("extraLoose"), ";\n  ")
        : null;
}, spacing("extraLoose"), spacing(), spacing("extraLoose"));
export var Legend = styled(function (props) { return _jsx(DisplayText, __assign({}, props)); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &&& {\n    margin-bottom: ", ";\n  }\n"], ["\n  &&& {\n    margin-bottom: ", ";\n  }\n"])), spacing("tight"));
var templateObject_1, templateObject_2;
