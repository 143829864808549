var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Flex } from "@rebass/grid";
import { defaultTheme } from "@rentiohq/web-theme";
import { HalfCircleSpinner, HollowDotsSpinner } from "react-epic-spinners";
import { ESpacings } from "../Grid";
import * as S from "./Loading.styled";
import { ELoadingSize } from "./Loading.types";
var Loading = function (_a) {
    var _b = _a.asDots, asDots = _b === void 0 ? false : _b, _c = _a.color, color = _c === void 0 ? defaultTheme.colors.neutral60 : _c, size = _a.size;
    var loadingSize = size || asDots ? ELoadingSize.Small : ELoadingSize.Large;
    return asDots ? (_jsx(Flex, __assign({ py: ESpacings.base, justifyContent: "center" }, { children: _jsx(HollowDotsSpinner, { size: loadingSize, color: color }) }))) : (_jsx(S.Loading, __assign({ size: loadingSize }, { children: _jsx(HalfCircleSpinner, { size: loadingSize, color: defaultTheme.colors.neutral60 }) })));
};
export default Loading;
