var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { rem, transparentize } from "polished";
import { DropdownTrigger } from "../../../DropdownMenu/DropdownMenu.styled";
import Link from "../../../Link/Link";
import { SIDEBAR_WIDTH } from "../../Frame.styled";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Header = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  box-sizing: border-box;\n  display: flex;\n  flex-shrink: 0;\n  justify-content: space-between;\n  height: ", ";\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  align-items: center;\n  box-sizing: border-box;\n  display: flex;\n  flex-shrink: 0;\n  justify-content: space-between;\n  height: ", ";\n  position: relative;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem(theme.header.height, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.global6;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.green10;
});
export var Logo = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  width: ", "px;\n  height: ", ";\n  color: ", ";\n  border-right: 1px solid ", ";\n  fill: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  display: block;\n  width: ", "px;\n  height: ", ";\n  color: ", ";\n  border-right: 1px solid ", ";\n  fill: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &:hover {\n    background-color: ", ";\n  }\n"])), SIDEBAR_WIDTH, function (_a) {
    var theme = _a.theme;
    return rem(theme.header.height, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 0);
}, transparentize(0.6, "white"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var theme = _a.theme;
    return transparentize(0.2, theme.colors.global5);
});
export var ContentLeft = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-grow: 1;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-grow: 1;\n  align-items: center;\n"])));
// @ts-ignore
export var ContentRight = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-shrink: 0;\n  align-items: center;\n  ", " {\n    color: currentColor;\n    &:hover {\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  flex-shrink: 0;\n  align-items: center;\n  ", " {\n    color: currentColor;\n    &:hover {\n      color: ", ";\n    }\n  }\n"])), DropdownTrigger, function (_a) {
    var theme = _a.theme;
    return transparentize(0.6, theme.colors.global5);
});
export var SearchFormWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-shrink: 0;\n  margin-right: ", ";\n  margin-left: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  width: 65%;\n  min-width: ", ";\n"], ["\n  flex-shrink: 0;\n  margin-right: ", ";\n  margin-left: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  width: 65%;\n  min-width: ", ";\n"])), spacing("base"), spacing("base"), function (props) { return rem(240, props.theme.baseFontSize); });
export var SearchForm = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var SearchFormContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  height: ", ";\n  color: ", ";\n  ", "\n"], ["\n  position: relative;\n  height: ", ";\n  color: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return rem(32, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.green10;
}, function (props) {
    if (props.hasFocus) {
        return "\n        color: ".concat(props.theme.colors.neutral60, "\n      ");
    }
});
export var SearchFormIcon = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  ", ";\n"], ["\n  position: absolute;\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "\n  left: ".concat(rem(8, theme.baseFontSize), ";\n  top: ").concat(rem(4, theme.baseFontSize), ";\n  ");
});
export var SearchFormField = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: inherit;\n  padding-left: ", ";\n  width: 100%;\n  &:focus {\n    color: ", ";\n    border-color: ", ";\n    background-color: ", ";\n    outline: none;\n  }\n  &::placeholder {\n    color: currentColor;\n  }\n  &:focus::placeholder {\n    color: ", ";\n  }\n"], ["\n  height: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  font-size: inherit;\n  padding-left: ", ";\n  width: 100%;\n  &:focus {\n    color: ", ";\n    border-color: ", ";\n    background-color: ", ";\n    outline: none;\n  }\n  &::placeholder {\n    color: currentColor;\n  }\n  &:focus::placeholder {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return rem(32, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.green60;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.green40;
}, borderRadius(), function (_a) {
    var theme = _a.theme;
    return theme.colors.green10;
}, function (_a) {
    var theme = _a.theme;
    return rem(40, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral90;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.green80;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral0;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral60;
});
export var Profile = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  padding: 0 ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-left: 1px solid ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  padding: 0 ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-left: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem(300, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return rem(theme.header.height, theme.baseFontSize);
}, spacing(), transparentize(0.6, "white"));
export var UserInfo = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  flex-grow: 1;\n  padding: 0 ", ";\n  min-width: 0;\n  & > div {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n"], ["\n  flex-grow: 1;\n  padding: 0 ", ";\n  min-width: 0;\n  & > div {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n"])), spacing());
export var DropdownWrap = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  min-width: ", ";\n"], ["\n  min-width: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem(300, theme.baseFontSize);
});
export var UserProfile = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", ";\n  min-width: ", ";\n  border-bottom: 1px solid ", ";\n  margin-bottom: -", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", ";\n  min-width: ", ";\n  border-bottom: 1px solid ", ";\n  margin-bottom: -", ";\n"])), spacing(), function (_a) {
    var theme = _a.theme;
    return rem(300, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral30;
}, spacing("tight"));
export var UserProfilePicture = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  margin-bottom: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  margin-bottom: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem(80, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return rem(80, theme.baseFontSize);
}, spacing());
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
