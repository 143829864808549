import * as authActions from "../../redux/auth/auth.actions";
export var isPerformingSubmitActionSelector = function () { return function (state) {
    return state.auth.isSigningIn || state.auth.isFetchingUser;
}; };
export var performingSubmitActionResultSelector = function () { return function (state) {
    return state.auth.user;
}; };
export var performingSubmitActionErrorSelector = function () { return function (state) {
    return state.auth.signInError || state.auth.fetchUserError;
}; };
export var submitActionCreator = function () { return function (formData) {
    return authActions.signInAutomatically.actions.start(formData);
}; };
