var _a;
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authHooks from "../auth/auth.hooks";
import { hooksGenerator } from "../generic/all.hooks";
import { getAllEmployees as getAllEmployeesAction } from "./broker.actions";
import CONFIG from "./broker.config";
import { getAllEmployees, getEmployeesError, isFetchingEmployees, } from "./broker.selectors";
export var useDetail = (_a = hooksGenerator({
    entityName: CONFIG.ENTITY_NAME,
    resource: CONFIG.RESOURCE,
    getBaseState: function (state) { return state[CONFIG.REDUCER_KEY]; },
}), _a.useDetail), usePaged = _a.usePaged;
export var useBrokerFeature = function (feature) { var _a; return ((_a = authHooks.useSelf().broker) === null || _a === void 0 ? void 0 : _a.features.includes(feature)) || false; };
export var useGetBrokerEmployees = function () {
    var dispatch = useDispatch();
    var employees = useSelector(getAllEmployees);
    var isFetching = useSelector(isFetchingEmployees);
    var fetchError = useSelector(getEmployeesError);
    var broker = authHooks.useSelf().broker;
    var fetchEmployees = useCallback(function (shouldRefetch) {
        if (shouldRefetch === void 0) { shouldRefetch = false; }
        if (!broker || isFetching)
            return;
        if (!employees || shouldRefetch) {
            dispatch(getAllEmployeesAction.actions.start({ id: broker.id }));
        }
    }, [broker]);
    var refetch = useCallback(function () { return fetchEmployees(true); }, []);
    useEffect(fetchEmployees, [fetchEmployees]);
    return {
        employees: employees || [],
        isFetching: isFetching,
        fetchError: fetchError,
        refetch: refetch,
    };
};
