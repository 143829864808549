import { getDay, getMonth } from "@rentiohq/shared/dist/utils/date-fns.utils";
export var isChristmasPeriod = function () {
    var now = new Date();
    var isDecember = getMonth(now) === 11;
    var isJanuary = getMonth(now) === 0;
    var day = getDay(now);
    if (isDecember && day > 6) {
        return true;
    }
    if (isJanuary && day < 6) {
        return true;
    }
    return false;
};
