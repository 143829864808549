var VIEW_BOX_REGEX = /viewBox="([^"]*)"/;
var SVG_REGEX = /(<svg[^>]*>|<\/svg>)/g;
var FILL_REGEX = /fill="[^"]*"/g;
export function assetLoader(source) {
    var finalSource = source.replace(FILL_REGEX, function (fill) {
        // @ts-ignore
        return fill.includes("#FFF") ? 'fill="currentColor"' : "";
    });
    // Issue with ESLint recognizing this as needing an object destructure
    // @ts-ignore
    var viewBox = VIEW_BOX_REGEX.exec(finalSource)[1];
    return {
        viewBox: viewBox,
        body: finalSource.replace(SVG_REGEX, ""),
    };
}
