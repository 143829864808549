import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { formatBytes } from "@rentiohq/shared/dist/utils/number.utils";
import { toast } from "../managers/Toast";
export var removeExtension = function (filename) {
    return filename.replace(/\.[^/.]+$/, "");
};
export var downloadToFile = function (params) {
    var content = params.content, filename = params.filename, contentType = params.contentType;
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
};
export var showDropErrors = function (params) {
    var fileRejections = params.fileRejections, _a = params.maxFileSize, maxFileSize = _a === void 0 ? CONFIG.UPLOAD_MAX_FILE_SIZE : _a;
    fileRejections.forEach(function (_a) {
        var errors = _a.errors, file = _a.file;
        errors.forEach(function (error) {
            switch (error.code) {
                case "file-too-large":
                    toast({
                        heading: file.name,
                        variation: "error",
                        content: getLocalizedText("files.error.size_limit_exceeded", {
                            size: formatBytes(maxFileSize),
                        }),
                    });
                    break;
                default:
                    break;
            }
        });
    });
};
