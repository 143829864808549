import hash from "object-hash";
export var getPagedId = function (params) {
    var entityName = params.entityName, order = params.order, filter = params.filter;
    var resultComponents = [entityName];
    if (order) {
        resultComponents.push(hash(order));
    }
    if (filter) {
        resultComponents.push(hash(filter));
    }
    return resultComponents.join("-");
};
