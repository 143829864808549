import { ERentioFormField, ERentioFormFormat, } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { EIdCardOptions } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.1.broker";
import { CONTACT_AGE_RANGE_MAX, CONTACT_AGE_RANGE_MIN, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.constants";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMappedLanguages, getMappedNationalities, } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { ts } from "../../../../services";
import { EField } from "./schema.editContact.types";
export var getSchema = function (initialValues, _a) {
    var contact = _a.contact;
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Firstname, EField.Lastname],
            properties: (_a = {},
                _a[EField.Firstname] = {
                    default: getValue(EField.Firstname, initialValues, defaultValues),
                },
                _a[EField.Lastname] = {
                    default: getValue(EField.Lastname, initialValues, defaultValues),
                },
                _a[EField.Email] = {
                    default: getValue(EField.Email, initialValues, defaultValues),
                },
                _a[EField.Phone] = {
                    default: getValue(EField.Phone, initialValues, defaultValues),
                },
                _a[EField.Language] = {
                    default: getValue(EField.Language, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedLanguages(),
                },
                _a[EField.Address] = {
                    default: getValue(EField.Address, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return !contact.isCompany; },
                },
                _a[EField.DateOfBirth] = {
                    default: getValue(EField.DateOfBirth, initialValues, defaultValues),
                },
                _a[EField.PlaceOfBirth] = {
                    default: getValue(EField.PlaceOfBirth, initialValues, defaultValues),
                },
                _a[EField.Nationality] = {
                    default: getValue(EField.Nationality, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedNationalities(),
                },
                _a[EField.IdType] = {
                    default: getValue(EField.IdType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        EIdCardOptions.BelgianIdCardNumber,
                        EIdCardOptions.PassportNumber,
                    ].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.national_register_number.".concat(id)),
                    }); }),
                    showIf: function (formData) { return formData[EField.Nationality] !== "BE"; },
                },
                _a[EField.NationalRegisterNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] === "BE" ||
                            (formData[EField.Nationality] !== "BE" &&
                                formData[EField.IdType] === EIdCardOptions.BelgianIdCardNumber);
                    },
                    format: ERentioFormFormat.NationalRegisterNumber,
                    default: getValue(EField.NationalRegisterNumber, initialValues, defaultValues),
                },
                _a[EField.ForeignIdentityNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    default: getValue(EField.ForeignIdentityNumber, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": ts.contactEditContact(),
            "rentio:title": "contact_edit"
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: ts.system("address"),
            placeholder: getLocalizedText("system.address"),
            optional: true,
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("firstname"),
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("lastname"),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: ts.system("email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: ts.system("phone"),
        },
        _a[EField.Language] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: ts.system("language"),
            placeholder: getLocalizedText("system.language"),
        },
        _a[EField.Company] = {
            "ui:field": ERentioFormField.Text,
            title: ts.contactCompanyNameLabel(),
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("system.date_of_birth"),
            formatSummary: function (formData) { return formData && formatDate(formData); },
            optional: true,
            minDate: CONTACT_AGE_RANGE_MIN,
            maxDate: CONTACT_AGE_RANGE_MAX,
        },
        _a[EField.PlaceOfBirth] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.place_of_birth"),
            lookupTypes: ["(cities)"],
            optional: true,
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.nationality"),
            optional: true,
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("system.identification"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
            optional: true,
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number.foreigner"),
            optional: true,
        },
        _a);
};
