var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import DropdownMenu from "../DropdownMenu";
import Stack from "../Stack";
import Button from "./Button";
export var renderActions = function (actions, options) {
    var _a = Object.assign({
        uniqueId: 0,
        dropdownPlacement: "bottom-end",
    }, options), overrides = _a.overrides, uniqueId = _a.uniqueId, distribution = _a.distribution, alignment = _a.alignment, dropdownPlacement = _a.dropdownPlacement;
    if (isEmpty(actions)) {
        return null;
    }
    if (Array.isArray(actions)) {
        return (_jsx(Stack, __assign({ distribution: distribution, alignment: alignment }, { children: actions.map(function (action, index) {
                return (_jsx(React.Fragment, { children: action.dropdown ? (_jsx(DropdownMenu, { children: buttonFrom(action, __assign(__assign({}, overrides), { iconAfterName: "chevronDown" }), uniqueId), actions: action.dropdown, triggerProps: {
                            placement: dropdownPlacement,
                        } })) : (_jsx(_Fragment, { children: buttonFrom(action, overrides, uniqueId) })) }, index));
            }) })));
    }
    else {
        var action = actions;
        return buttonFrom(action, overrides);
    }
};
export var buttonFrom = function (_a, overrides, key) {
    var content = _a.content, onClick = _a.onClick, action = __rest(_a, ["content", "onClick"]);
    return (_jsx(Button, __assign({ onClick: onClick }, action, overrides, { children: content }), key));
};
