var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as agreementActions from "./agreement.actions";
export var REDUCER_KEY_AGREEMENT = "agreement";
var getDefaultState = function () { return ({
    agreements: {},
    agreementsById: {},
    agreementsByQuoteId: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(agreementActions.getAgreement.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId;
    return (__assign(__assign({}, state), { agreementsById: __assign(__assign({}, state.agreementsById), (_b = {}, _b[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(agreementActions.getAgreement.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId, agreement = _a.agreement;
    return (__assign(__assign({}, state), { agreements: __assign(__assign({}, state.agreements), (_b = {}, _b[agreementId] = agreement, _b)), agreementsById: __assign(__assign({}, state.agreementsById), (_c = {}, _c[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isFetching: false, fetchError: undefined }), _c)), agreementsByQuoteId: __assign(__assign({}, state.agreementsByQuoteId), (_d = {}, _d[quoteId] = __assign(__assign({}, state.agreementsByQuoteId[quoteId]), { activeAgreementId: agreementId, isCreating: false, createError: undefined }), _d)) }));
});
reducer.on(agreementActions.getAgreement.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId, error = _a.error;
    return (__assign(__assign({}, state), { agreementsById: __assign(__assign({}, state.agreementsById), (_b = {}, _b[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isFetching: false, fetchError: error }), _b)) }));
});
reducer.on(agreementActions.createAgreement.types.START, function (state, _a) {
    var _b;
    var quoteId = _a.quoteId;
    return (__assign(__assign({}, state), { agreementsByQuoteId: __assign(__assign({}, state.agreementsByQuoteId), (_b = {}, _b[quoteId] = __assign(__assign({}, state.agreementsByQuoteId[quoteId]), { isCreating: true, createError: undefined }), _b)) }));
});
reducer.on(agreementActions.createAgreement.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var quoteId = _a.quoteId, agreement = _a.agreement;
    return (__assign(__assign({}, state), { agreements: __assign(__assign({}, state.agreements), (_b = {}, _b[agreement.id] = agreement, _b)), agreementsByQuoteId: __assign(__assign({}, state.agreementsByQuoteId), (_c = {}, _c[quoteId] = __assign(__assign({}, state.agreementsByQuoteId[quoteId]), { activeAgreementId: agreement.id, isCreating: false, createError: undefined }), _c)) }));
});
reducer.on(agreementActions.createAgreement.types.FAILURE, function (state, _a) {
    var _b;
    var quoteId = _a.quoteId, error = _a.error;
    return (__assign(__assign({}, state), { agreementsByQuoteId: __assign(__assign({}, state.agreementsByQuoteId), (_b = {}, _b[quoteId] = __assign(__assign({}, state.agreementsByQuoteId[quoteId]), { isCreating: false, createError: error }), _b)) }));
});
reducer.on(agreementActions.mailAgreement.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId;
    return (__assign(__assign({}, state), { agreementsById: __assign(__assign({}, state.agreementsById), (_b = {}, _b[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isMailing: true, mailError: undefined }), _b)) }));
});
reducer.on(agreementActions.mailAgreement.types.SUCCESS, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId;
    return (__assign(__assign({}, state), { agreementsById: __assign(__assign({}, state.agreementsById), (_b = {}, _b[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isMailing: false, mailError: undefined }), _b)) }));
});
reducer.on(agreementActions.mailAgreement.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, agreementId = _a.agreementId, error = _a.error;
    return (__assign(__assign({}, state), { agreementsById: __assign(__assign({}, state.agreementsById), (_b = {}, _b[agreementId] = __assign(__assign({}, state.agreementsById[agreementId]), { leadId: leadId, quoteId: quoteId, isMailing: false, mailError: error }), _b)) }));
});
export default reducer;
