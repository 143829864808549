var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { useMemo } from "react";
import { Icon, Tags, TextField, TextStyle } from "../../components";
import Button from "../Button";
import Card from "../Card";
import DropdownMenu from "../DropdownMenu";
import { ESpacings } from "../Grid";
import Stack from "../Stack";
import * as S from "./Filters.styled";
import * as filterUtils from "./Filters.util";
export var Filters = function (props) {
    var helpText = props.helpText, appliedFiltersRaw = props.appliedFilters, queryPlaceholder = props.queryPlaceholder, queryValue = props.queryValue, onQueryChange = props.onQueryChange, onQueryBlur = props.onQueryBlur, onQueryFocus = props.onQueryFocus, onQueryClear = props.onQueryClear, focused = props.focused, filters = props.filters, filterConfigs = props.filterConfigs;
    var appliedFilters = __spreadArray([], (appliedFiltersRaw || []), true);
    if (filterConfigs) {
        var mappedFilterConfigs_1 = [];
        filterConfigs.forEach(function (fc) {
            fc.filters
                .map(function (filter) {
                var _a;
                return (__assign(__assign({}, filter), { values: ((_a = filter.shownValuesInQuickFilter) === null || _a === void 0 ? void 0 : _a.call(filter, filter.values)) || filter.values }));
            })
                .forEach(function (filter) {
                var _a, _b, _c;
                // eslint-disable-next-line no-nested-ternary
                var currentFilterValues = filter.values
                    ? Array.isArray(filter.values)
                        ? filter.values
                        : [filter.values]
                    : [];
                var shouldShow = (_b = (_a = filter.showInAppliedFilters) === null || _a === void 0 ? void 0 : _a.call(filter, filter.values)) !== null && _b !== void 0 ? _b : true;
                var asBlock = (_c = filter.showAsOneAppliedFilterBlock) !== null && _c !== void 0 ? _c : false;
                var currentConfigAppliedFilters = [];
                if (shouldShow && currentFilterValues.length > 0) {
                    if (asBlock) {
                        currentConfigAppliedFilters.push({
                            key: "combined-".concat(fc.groupKey),
                            label: (filter.appliedFiltersPrefix || "") +
                                currentFilterValues
                                    .map(function (cfv) { return filter.translate(cfv); })
                                    .sort()
                                    .join(", "),
                            groupKey: fc.groupKey,
                            onRemove: function () {
                                if (filter.onChange)
                                    filter.onChange([]);
                            },
                        });
                    }
                    else {
                        currentConfigAppliedFilters.push.apply(currentConfigAppliedFilters, currentFilterValues.map(function (current) {
                            var label = (filter.appliedFiltersPrefix || "") +
                                filter.translate(current);
                            return {
                                key: current,
                                label: label,
                                groupKey: fc.groupKey,
                                onRemove: filter.onRemove,
                            };
                        }));
                    }
                }
                mappedFilterConfigs_1.push.apply(mappedFilterConfigs_1, currentConfigAppliedFilters);
            });
        });
        appliedFilters.push.apply(appliedFilters, mappedFilterConfigs_1);
    }
    var _filters = useMemo(function () {
        return (filters !== null && filters !== void 0 ? filters : (filterConfigs ? filterUtils.getFilters(filterConfigs) : []));
    }, [filters, filterConfigs]);
    var handleQueryChange = function (_a) {
        var target = _a.target;
        onQueryChange === null || onQueryChange === void 0 ? void 0 : onQueryChange(target.value);
    };
    var transformFilters = function () {
        if (!_filters) {
            return null;
        }
        var allFilters = _filters.map(function (filter) {
            var key = filter.key, label = filter.label, content = filter.content, renderContent = filter.renderContent, disabled = filter.disabled, _a = filter.asDropdown, asDropdown = _a === void 0 ? true : _a;
            if (!asDropdown) {
                return _jsx(Stack.Item, { children: content });
            }
            return (_jsx(Stack.Item, { children: disabled ? (_jsx(Button, __assign({ appearance: "outline", color: "gray", iconAfterName: "chevronDown", isDisabled: true }, { children: label }))) : (_jsx(DropdownMenu, { children: _jsx("div", { children: _jsx(Button, __assign({ appearance: "outline", color: "gray", iconAfterName: "chevronDown" }, { children: label })) }), extraData: { content: content, renderContent: renderContent }, renderContent: function (closeDropdownMenu) { return (_jsxs(Card, __assign({ space: ESpacings.base }, { children: [content, (renderContent === null || renderContent === void 0 ? void 0 : renderContent(closeDropdownMenu)) || null] }))); }, triggerProps: {
                        placement: "bottom-start",
                    }, shouldCloseOnClick: false })) }, key));
        });
        return allFilters;
    };
    var filtersControlMarkup = queryValue !== undefined ? (_jsxs(Stack, { children: [_jsx(Stack.Item, __assign({ hasFill: true }, { children: _jsx(TextField, { name: "search-query", placeholder: queryPlaceholder, onChange: handleQueryChange, onBlur: onQueryBlur, onFocus: onQueryFocus, value: queryValue, autoFocus: focused, label: queryPlaceholder, labelHidden: true, prefix: _jsx(Icon, { source: "search" }), floatIcon: queryValue ? _jsx(Icon, { source: "removeCircle" }) : undefined, floatIconClick: onQueryClear, autoComplete: "off" }) })), transformFilters()] })) : (_jsx(Stack, { children: transformFilters() }));
    var tagsMarkup = appliedFilters.length > 0 ? (_jsx(S.TagsContainer, { children: _jsx(Tags, { items: appliedFilters }) })) : null;
    var helpTextMarkup = helpText ? (_jsx(Box, __assign({ mt: ESpacings.tight }, { children: _jsx(TextStyle, __assign({ variation: "subdued" }, { children: helpText })) }))) : null;
    return (_jsxs("div", { children: [filtersControlMarkup, tagsMarkup, helpTextMarkup] }));
};
