var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { connect } from "react-redux";
var withShowErrorsProvider = function (Component, formId, stepIndex) {
    var mapStateToProps = function (state) {
        var formState = state.form[formId];
        var showErrors = formState ? formState.showErrors : [];
        return {
            showErrors: showErrors.includes(stepIndex),
        };
    };
    return connect(mapStateToProps)(/** @class */ (function (_super) {
        __extends(WithShowErrorsProvider, _super);
        function WithShowErrorsProvider() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            // Render
            _this.render = function () {
                var _a = _this.props, _b = _a.showErrors, showErrors = _b === void 0 ? false : _b, rawErrors = _a.rawErrors, rest = __rest(_a, ["showErrors", "rawErrors"]);
                return (_jsx(Component, __assign({ rawErrors: showErrors ? rawErrors : undefined }, rest)));
            };
            return _this;
        }
        return WithShowErrorsProvider;
    }(React.Component)));
};
export default withShowErrorsProvider;
