var _a, _b, _c, _d, _e;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./document.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["DocumentCategories"] = "DOCUMENT_CATEGORIES";
    EActionCustom["UploadDocument"] = "UPLOAD_DOCUMENT";
    EActionCustom["ArchiveDocument"] = "ARCHIVE_DOCUMENT";
    EActionCustom["UnarchiveDocument"] = "UNARCHIVE_DOCUMENT";
})(EActionCustom || (EActionCustom = {}));
var generateDocumentCategoriesActions = function () { return ({
    documentCategoriesStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Start,
    }),
    documentCategoriesSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Success,
    }),
    documentCategoriesFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Fail,
    }),
}); };
var generateUploadDocumentActions = function () { return ({
    uploadDocumentStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Start,
    }),
    uploadDocumentSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Success,
    }),
    uploadDocumentFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Fail,
    }),
}); };
var generateArchiveDocumentActions = function () { return ({
    archiveDocumentStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ArchiveDocument,
        state: EActionState.Start,
    }),
    archiveDocumentSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ArchiveDocument,
        state: EActionState.Success,
    }),
    archiveDocumentFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ArchiveDocument,
        state: EActionState.Fail,
    }),
}); };
var generateUnarchiveDocumentActions = function () { return ({
    unarchiveDocumentStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UnarchiveDocument,
        state: EActionState.Start,
    }),
    unarchiveDocumentSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UnarchiveDocument,
        state: EActionState.Success,
    }),
    unarchiveDocumentFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UnarchiveDocument,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getAllWhereStart = _a.getAllWhereStart, getAllWhereFailed = _a.getAllWhereFailed, getAllWhereSucceeded = _a.getAllWhereSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, updateMembersFailed = _a.updateMembersFailed, updateMembersStart = _a.updateMembersStart, updateMembersSucceeded = _a.updateMembersSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var documentCategoriesStart = (_b = generateDocumentCategoriesActions(), _b.documentCategoriesStart), documentCategoriesSucceeded = _b.documentCategoriesSucceeded, documentCategoriesFailed = _b.documentCategoriesFailed;
export var uploadDocumentStart = (_c = generateUploadDocumentActions(), _c.uploadDocumentStart), uploadDocumentSucceeded = _c.uploadDocumentSucceeded, uploadDocumentFailed = _c.uploadDocumentFailed;
export var archiveDocumentStart = (_d = generateArchiveDocumentActions(), _d.archiveDocumentStart), archiveDocumentSucceeded = _d.archiveDocumentSucceeded, archiveDocumentFailed = _d.archiveDocumentFailed;
export var unarchiveDocumentStart = (_e = generateUnarchiveDocumentActions(), _e.unarchiveDocumentStart), unarchiveDocumentSucceeded = _e.unarchiveDocumentSucceeded, unarchiveDocumentFailed = _e.unarchiveDocumentFailed;
