var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import IBAN from "iban";
function parseStructure(structure) {
    // split in blocks of 3 chars
    var mask = structure.match(/(.{3})/g).map(function (block) {
        // parse each structure block (1-char + 2-digits)
        var format;
        var pattern = block.slice(0, 1);
        var repeats = parseInt(block.slice(1), 10);
        switch (pattern) {
            case "A":
                format = "0-9A-Za-z";
                break;
            case "B":
                format = "0-9A-Za-z";
                break;
            case "C":
                format = "A-Za-z";
                break;
            case "F":
                format = "0-9";
                break;
            case "L":
                format = "a-zA-Z";
                break;
            case "U":
                format = "a-zA-Z";
                break;
            case "W":
                format = "0-9a-zA-Z";
                break;
            default:
                break;
        }
        return __spreadArray([], Array(repeats).fill(new RegExp("[" + format + "]")), true);
    });
    var splittedMask = [];
    [].concat.apply([], mask).forEach(function (item, index) {
        if (index % 4 === 0) {
            splittedMask.push(" ");
        }
        splittedMask.push(item);
    });
    return splittedMask;
}
export var ibanMask = function (value) {
    var mask = [/[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/];
    var countryCode = value ? value.substring(0, 2) : "BE";
    // @ts-ignore
    var countrySpecifications = IBAN.countries[countryCode];
    if (!countrySpecifications) {
        return mask;
    }
    var countryMask = parseStructure(countrySpecifications.structure);
    return __spreadArray(__spreadArray([], mask, true), countryMask, true);
};
export var ibanPipe = function (value) {
    return value.toUpperCase();
};
export { IBAN };
