var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
var appearanceToColor = {
    default: "primary",
    info: "blue",
    warning: "yellow",
    error: "red",
    success: "green",
};
var getAppearanceColors = function (theme, appearance, isBold) {
    switch (appearance) {
        case "success":
        case "info":
        case "warning":
        case "error":
            return isBold
                ? "\n          color: ".concat(theme.getColor(appearanceToColor[appearance], 0), ";\n          background: ").concat(theme.getColor(appearanceToColor[appearance]), ";\n      ")
                : "\n          color: ".concat(theme.getColor(appearanceToColor[appearance], 1000), ";\n          background: ").concat(theme.getColor(appearanceToColor[appearance], 0), ";\n      ");
        default:
            return isBold
                ? "\n          color: ".concat(theme.colors.neutral0, ";\n          background: ").concat(theme.colors.neutral80, ";\n        ")
                : " \n          color: ".concat(theme.colors.neutral90, ";\n          background: ").concat(theme.colors.neutral20, ";\n        ");
    }
};
// TODO: check this max height on the lozenge for small screens and long text
export var Wrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  padding: ", ";\n  border-radius: ", ";\n  line-height: 1;\n  max-height: ", ";\n  white-space: nowrap;\n  ", "\n  ", "\n"], ["\n  display: inline-block;\n  padding: ", ";\n  border-radius: ", ";\n  line-height: 1;\n  max-height: ", ";\n  white-space: nowrap;\n  ", "\n  ", "\n"])), spacing("extraTight"), borderRadius("small"), spacing("loose"), function (_a) {
    var customBackgroundColor = _a.customBackgroundColor, customTextColor = _a.customTextColor, _b = _a.appearance, appearance = _b === void 0 ? "default" : _b, _c = _a.isBold, isBold = _c === void 0 ? false : _c, theme = _a.theme;
    var styles = "";
    if (customBackgroundColor) {
        styles = "background: ".concat(customBackgroundColor, ";");
    }
    if (customTextColor)
        styles = "".concat(styles, " color: ").concat(customTextColor, ";");
    if (!customTextColor && !customBackgroundColor) {
        return getAppearanceColors(theme, appearance, isBold);
    }
    return styles;
}, function (_a) {
    var isUpperCase = _a.isUpperCase, theme = _a.theme;
    return "\n    ".concat(isUpperCase
        ? "\n          font-size: ".concat(rem("11px", theme.baseFontSize), ";\n          text-transform: uppercase;\n        ")
        : "\n          font-size: ".concat(rem("14px", theme.baseFontSize), ";\n          text-transform: none;\n        "), "\n  ");
});
var templateObject_1;
