var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { hideVisually, rem } from "polished";
import { StyledIcon } from "../../../Icon/Icon.styled";
export var Option = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  cursor: pointer;\n  input {\n    margin-top: 6px; /* To align the checkbox with the text (DIRTY) */\n    margin-right: ", ";\n  }\n"], ["\n  display: flex;\n  cursor: pointer;\n  input {\n    margin-top: 6px; /* To align the checkbox with the text (DIRTY) */\n    margin-right: ", ";\n  }\n"])), spacing("tight"));
export var OptionContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var OptionInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"], ["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme, checked = _a.checked;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), ";\n    height: ").concat(rem(24, theme.baseFontSize), ";\n  ");
}, hideVisually(), StyledIcon);
var templateObject_1, templateObject_2, templateObject_3;
