import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { endOfDay, endOfMonth, isAfter, isBefore, startOfDay, startOfMonth, startOfWeek, subMonths, subYears, } from "date-fns";
import { capitalize } from "lodash";
import { EmployeeRoles } from "../Employees";
import { OptionList } from "../OptionList";
import { MonthRangeFilter } from "./FilterComponents/MonthRangeFilter";
import * as S from "./Filters.styled";
import { EDateQuickFilters, EFilterType, } from "./Filters.types";
var defaultSortFunction = function (a, b) {
    if (a > b)
        return 1;
    else if (a < b)
        return -1;
    else
        return 0;
};
var getMonthRangeFilter = function (_a) {
    var values = _a.values, onChange = _a.onChange, minStartDate = _a.minStartDate, maxStartDate = _a.maxStartDate, minEndDate = _a.minEndDate, maxEndDate = _a.maxEndDate;
    return (_jsx(MonthRangeFilter, { minStartDate: minStartDate, maxStartDate: maxStartDate, minEndDate: minEndDate, maxEndDate: maxEndDate, range: values, onChange: function (range) {
            onChange === null || onChange === void 0 ? void 0 : onChange(range);
        } }));
};
var getSelectComponent = function (_a, multiple) {
    var options = _a.options, values = _a.values, _b = _a.sort, sort = _b === void 0 ? false : _b, filterKey = _a.filterKey, sortFunction = _a.sortFunction, onChange = _a.onChange, translate = _a.translate;
    var sortedOptions = options !== null && options !== void 0 ? options : [];
    var sortFn = sortFunction !== null && sortFunction !== void 0 ? sortFunction : defaultSortFunction;
    if (sort) {
        sortedOptions = options ? options.sort(sortFn) : [];
    }
    return (_jsx(OptionList, { id: filterKey, value: values, variant: "simple", multiple: multiple, options: sortedOptions.map(function (option) { return ({
            label: translate(option),
            value: option,
            id: option,
        }); }), onChange: function (event) {
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
        } }));
};
var getEmployeeSelectComponent = function (_a) {
    var options = _a.options, values = _a.values, onChange = _a.onChange, extra = _a.extra;
    return (_jsx(EmployeeRoles, { members: options || [], companyName: extra === null || extra === void 0 ? void 0 : extra.companyName, selectedMemberIds: values, onChange: function (event) {
            onChange === null || onChange === void 0 ? void 0 : onChange(event);
        } }));
};
export var getFilters = function (filterConfigs) {
    return filterConfigs
        .filter(function (f) { return !f.isHidden; })
        .reduce(function (allFilters, currentGroup) {
        var _a;
        var filtersToRender = currentGroup.filters.map(function (filter) {
            var component = null;
            switch (filter.type) {
                case EFilterType.MultiSelect:
                    component = getSelectComponent(filter, true);
                    break;
                case EFilterType.SingleSelect:
                    component = getSelectComponent(filter, false);
                    break;
                case EFilterType.MonthRange:
                    component = getMonthRangeFilter(filter);
                    break;
                case EFilterType.EmployeeSelect:
                    component = getEmployeeSelectComponent(filter);
                    break;
                default:
                    component = null;
                    break;
            }
            if (filter.label) {
                return (_jsxs(_Fragment, { children: [_jsx("h2", { children: capitalize(filter.label) }), _jsx(S.FilterGroup, { children: component })] }));
            }
            return component;
        });
        allFilters.push({
            key: currentGroup.groupKey,
            label: currentGroup.label,
            disabled: currentGroup.disabled,
            asDropdown: (_a = currentGroup.asDropdown) !== null && _a !== void 0 ? _a : true,
            content: filtersToRender,
        });
        return allFilters;
    }, []);
};
var getMostRecentDate = function (first, second) {
    if (isAfter(first, second))
        return first;
    else if (isBefore(first, second))
        return second;
    return first;
};
var thisOrMinDate = function (current, minDate) {
    return minDate ? getMostRecentDate(current, minDate) : current;
};
export var getRangeForQuickFilter = function (quickFilter, key, minDate) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var now = new Date();
    var range = undefined;
    switch (quickFilter) {
        case EDateQuickFilters.Today:
            range = [
                (_a = {},
                    _a[key] = { gte: thisOrMinDate(startOfDay(now), minDate) },
                    _a),
                (_b = {}, _b[key] = { lte: thisOrMinDate(endOfDay(now), minDate) }, _b),
            ];
            break;
        case EDateQuickFilters.ThisWeek:
            range = [
                (_c = {},
                    _c[key] = {
                        gte: thisOrMinDate(startOfWeek(now), minDate),
                    },
                    _c),
                (_d = {},
                    _d[key] = {
                        lte: thisOrMinDate(endOfDay(now), minDate),
                    },
                    _d),
            ];
            break;
        case EDateQuickFilters.ThisMonth:
            range = [
                (_e = {},
                    _e[key] = {
                        gte: thisOrMinDate(startOfMonth(now), minDate),
                    },
                    _e),
                (_f = {},
                    _f[key] = {
                        lte: thisOrMinDate(endOfDay(now), minDate),
                    },
                    _f),
            ];
            break;
        case EDateQuickFilters.PreviousMonth:
            var nowLastMonth = subMonths(now, 1);
            range = [
                (_g = {},
                    _g[key] = {
                        gte: thisOrMinDate(startOfMonth(nowLastMonth), minDate),
                    },
                    _g),
                (_h = {},
                    _h[key] = {
                        lte: thisOrMinDate(endOfMonth(nowLastMonth), minDate),
                    },
                    _h),
            ];
            break;
        case EDateQuickFilters.LastYear:
            var oneYearAgo = subYears(now, 1);
            range = [
                (_j = {},
                    _j[key] = {
                        gte: thisOrMinDate(startOfDay(oneYearAgo), minDate),
                    },
                    _j),
                (_k = {},
                    _k[key] = {
                        lte: thisOrMinDate(endOfDay(now), minDate),
                    },
                    _k),
            ];
            break;
        default:
            range = undefined;
    }
    return range;
};
export var translateDateRange = function (range, format, translationKey) {
    if (format === void 0) { format = "MM/yyyy"; }
    if (range && typeof range === "object") {
        var startMonthText = range.start
            ? formatDate(range.start, format)
            : "...";
        var endMonthText = range.end ? formatDate(range.end, format) : "...";
        var formattedRange = "".concat(startMonthText, " - ").concat(endMonthText);
        if (translationKey) {
            return "".concat(getLocalizedText(translationKey), ": ").concat(formattedRange);
        }
        return formattedRange;
    }
    return "";
};
