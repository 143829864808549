var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import hash from "object-hash";
export var getQueryHash = function (query, customPath) {
    var toBeHashed = query;
    if (query && isPagedQuery(query)) {
        // Ignore page
        var page = query.page, rest = __rest(query, ["page"]);
        toBeHashed = rest;
    }
    return hash({ toBeHashed: toBeHashed, customPath: customPath });
};
var isPagedQuery = function (query) {
    return Object.keys(query).includes("page");
};
