var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as React from "react";
import { StyledError, StyledHelpText } from "../../../../Form/Form.styled";
import Labelled from "../../../../Labelled";
import { BaseInput } from "../../../components/BaseInput";
var currencyGroupingCharacter = ".";
var currencyDecimalCharacter = ",";
var formatCurrencyToNumber = function (currency) {
    return typeof currency === "string"
        ? parseFloat(currency
            .replace(currencyGroupingCharacter, "")
            .replace(currencyDecimalCharacter, "."))
        : currency;
};
var formatCurrencyToString = function (currency) {
    return typeof currency === "number"
        ? currency.toString().replace(".", currencyDecimalCharacter)
        : currency;
};
export var NumberInput = function (_a) {
    var name = _a.name, schema = _a.schema, uiSchema = _a.uiSchema, idSchema = _a.idSchema, formData = _a.formData, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, _c = _a.rawErrors, rawErrors = _c === void 0 ? [] : _c, required = _a.required, onChange = _a.onChange, disabled = _a.disabled, rest = __rest(_a, ["name", "schema", "uiSchema", "idSchema", "formData", "formContext", "rawErrors", "required", "onChange", "disabled"]);
    var id = idSchema.$id;
    var title = getFieldTitle(uiSchema, formContext);
    var error = rawErrors[0];
    var _d = uiSchema, placeholder = _d.placeholder, getPlaceholder = _d.getPlaceholder, suffixText = _d.suffixText, _e = _d.isFloat, isFloat = _e === void 0 ? false : _e, _f = _d.decimals, decimals = _f === void 0 ? 2 : _f, readOnly = _d.readOnly, isDisabled = _d.isDisabled, optional = _d.optional, _g = _d.autoFocus, autoFocus = _g === void 0 ? false : _g, getInfo = _d.getInfo;
    var info = getInfo
        ? getInfo(formContext.getAllFormData())
        : getFieldInfo(uiSchema, formContext);
    var _h = React.useState(), value = _h[0], setValue = _h[1];
    var uiDisabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    // @ts-ignore TODO: Typescript upgrade
    var parse = React.useCallback(function (value) {
        var stringValue = "".concat(value);
        if (isFloat) {
            var noDots = stringValue.replace(".", ",");
            // Cap the amount of decimals
            if (noDots.includes(",")) {
                var parts = noDots.split(",");
                if (parts.length > 1) {
                    var decimalPart = parts.pop();
                    if (decimalPart && decimalPart.length > decimals) {
                        decimalPart = decimalPart.substring(0, decimals);
                        noDots = "".concat(parts.join(), ",").concat(decimalPart);
                    }
                }
            }
            var float = noDots.replace(/[^0-9,]/g, "");
            return float;
        }
        else {
            var parsedInt = stringValue.replace(/[^0-9]/g, "");
            var absInt = parsedInt === "" ? "" : parseInt(parsedInt);
            return stringValue === "" ? stringValue : String(absInt);
        }
    });
    React.useEffect(function () {
        if (formData === undefined || formData === null) {
            return;
        }
        var formDataString = formatCurrencyToString(formData);
        var parsedFormData = parse(formDataString);
        var updatedFormData = parsedFormData.length > 0 ? parsedFormData : undefined;
        setValue(updatedFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);
    var handleChange = function (input) {
        var parsedInput = parse(input);
        var updatedValue = parsedInput.length > 0 ? parsedInput : undefined;
        var formattedInput = parsedInput.length > 0 ? formatCurrencyToNumber(parsedInput) : undefined;
        setValue(updatedValue);
        onChange(formattedInput);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, formattedInput);
            }, 0);
        }
    };
    var customPlaceholder = !!getPlaceholder
        ? getPlaceholder(formContext.getFormData())
        : placeholder;
    var inputMarkup = (_jsxs(_Fragment, { children: [_jsx(BaseInput, __assign({}, rest, { name: name, id: "".concat(id, "-").concat(name), value: value, placeholder: customPlaceholder, suffix: suffixText, onChange: handleChange, error: error, 
                // @ts-ignore
                disabled: uiDisabled || disabled, readOnly: readOnly, autoFocus: autoFocus })), info && _jsx(StyledHelpText, { children: info })] }));
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, __assign({ label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo }, { children: inputMarkup }))) : (_jsxs(_Fragment, { children: [inputMarkup, error && _jsx(StyledError, __assign({ id: "".concat(id, "Error") }, { children: error }))] })) }));
};
