var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { iconSize } from "@rentiohq/web-theme";
import { isChristmasPeriod } from "./Logo.utils";
export var Logo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var size = _a.size;
    return size &&
        "\n    height: ".concat(iconSize(size, function (x) { return (isChristmasPeriod() ? x * 1.75 : x); }), ";\n    width: ").concat(iconSize(size, function (x) { return (isChristmasPeriod() ? x * 1.75 : x); }), ";\n  ");
}, function (_a) {
    var color = _a.color, theme = _a.theme;
    return color &&
        "\n    color: ".concat(theme.getColor(color, 800), ";\n    svg, svg * {\n      fill: currentColor;\n    }\n  ");
});
var templateObject_1;
