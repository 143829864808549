import { useSelector } from "react-redux";
import { REDUCER_KEY_BROKER } from "../broker/broker.reducer";
import * as brokerSelectors2 from "../broker/broker.selectors";
import { REDUCER_KEY_AUTH } from "./auth.reducer";
import * as authSelectors from "./auth.selectors";
export var useSelf = function () {
    var user = useSelector(authSelectors.getUser);
    var broker = useSelector(function (state) {
        return brokerSelectors2.detail.detail(state, user === null || user === void 0 ? void 0 : user.brokerId);
    });
    var isBroker = !!(user === null || user === void 0 ? void 0 : user.brokerId);
    return {
        user: user,
        broker: broker,
        isBroker: isBroker,
    };
};
