import { createAction } from "redux-act";
import { createRoutine } from "../utils/routine.utils";
export var signIn = createRoutine("AUTH_SIGN_IN");
export var signInAutomatically = createRoutine("AUTH_SIGN_IN_AUTOMATICALLY");
export var setAccessTokensWithoutSideEffects = createAction("SET_ACCESS_TOKENS_WITHOUT_SIDE_EFFECTS");
export var refreshSuccess = createAction("AUTH_REFRESH_SUCCESS");
export var signUp = createRoutine("AUTH_SIGN_UP");
export var signUpVerifyPhone = createRoutine("AUTH_SIGN_UP_VERIFY_PHONE");
export var signUpVerifiedEmail = createAction("AUTH_SIGN_UP_VERIFY_EMAIL");
export var signUpResendVerificationEmail = createAction("AUTH_SIGN_UP_RESEND_VERIFICATION_EMAIL");
export var getUser = createRoutine("AUTH_USER_GET");
export var updateUser = createRoutine("AUTH_USER_UPDATE");
