var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ACTIVITY_SUBTYPES } from "@rentiohq/shared/dist/types/activity.types";
import { ERentDepositStatus, } from "@rentiohq/shared/dist/types/rentDeposit.types";
import isEmpty from "ramda/es/isEmpty";
import isNil from "ramda/es/isNil";
import mergeDeepLeft from "ramda/es/mergeDeepLeft";
import { ERentDepositPaymentStatus, ERentDepositSignatureStatus, ERentDepositStageSimple, } from "../types";
import * as utils from "../utils";
export var LIMIT = 20;
export var DEFAULT_FILTER = {
    filter: {
        where: {},
        include: [
            "property",
            "rentDepositContractIn",
            "rentDepositContractOut",
            "paymentOrder",
            "paymentOrderOutTenant",
            "paymentOrderOutRenter",
        ],
        order: "rentContractStartedAt ASC",
        limit: LIMIT,
    },
};
export var getAdvancedStages = function (status) {
    switch (status) {
        case ERentDepositStageSimple.In:
            return [
                ERentDepositStatus.New,
                ERentDepositStatus.OpenToSign,
                ERentDepositStatus.OpenSigned,
                ERentDepositStatus.PayingIn,
                ERentDepositStatus.PayingInPartiallySigned,
                ERentDepositStatus.PaidInPartiallySigned,
            ];
        case ERentDepositStageSimple.Established:
            return [ERentDepositStatus.Established];
        case ERentDepositStageSimple.Out:
            return [
                ERentDepositStatus.ReleaseNew,
                ERentDepositStatus.WaitingForIBAN,
                ERentDepositStatus.ReleaseToSign,
                ERentDepositStatus.ReleaseSigned,
                ERentDepositStatus.ManualVerification,
                ERentDepositStatus.ManualVerificationJudicial,
                ERentDepositStatus.WaitingForValidations,
                ERentDepositStatus.PayingOut,
            ];
        default:
            return [status];
    }
};
export var getStatus = function (status) {
    switch (status) {
        case ERentDepositPaymentStatus.NotPaid:
            return [
                ERentDepositStatus.PayingIn,
                ERentDepositStatus.PayingOut,
                ERentDepositStatus.ManualVerification,
                ERentDepositStatus.ManualVerificationJudicial,
            ];
        case ERentDepositPaymentStatus.Paid:
            return [
                ERentDepositStatus.Established,
                ERentDepositStatus.PaidInPartiallySigned,
            ];
        case ERentDepositSignatureStatus.NotSigned:
            return [
                ERentDepositStatus.New,
                ERentDepositStatus.OpenToSign,
                ERentDepositStatus.PayingInPartiallySigned,
                ERentDepositStatus.PaidInPartiallySigned,
                ERentDepositStatus.ReleaseNew,
                ERentDepositStatus.WaitingForIBAN,
                ERentDepositStatus.ReleaseToSign,
                ERentDepositStatus.WaitingForValidations,
            ];
        case ERentDepositSignatureStatus.Signed:
            return [
                ERentDepositStatus.OpenSigned,
                ERentDepositStatus.ReleaseSigned,
                ERentDepositStatus.PayingIn,
                ERentDepositStatus.PayingOut,
                ERentDepositStatus.Established,
                ERentDepositStatus.ManualVerification,
                ERentDepositStatus.ManualVerificationJudicial,
            ];
        default:
            return [];
    }
};
export var getPaymentStatus = function (status) {
    switch (status === null || status === void 0 ? void 0 : status.toUpperCase()) {
        case ERentDepositStatus.PaidInPartiallySigned:
        case ERentDepositStatus.Established:
        case ERentDepositStatus.Released:
            return ERentDepositPaymentStatus.Paid;
        default:
            return ERentDepositPaymentStatus.NotPaid;
    }
};
export var getSignatureStatus = function (status) {
    switch (status === null || status === void 0 ? void 0 : status.toUpperCase()) {
        case ERentDepositStatus.PayingIn:
        case ERentDepositStatus.Established:
        case ERentDepositStatus.PayingOut:
        case ERentDepositStatus.ReleaseSigned:
        case ERentDepositStatus.Released:
            return ERentDepositSignatureStatus.Signed;
        default:
            return ERentDepositSignatureStatus.NotSigned;
    }
};
export var getFilterQuery = function (values, defaultFilter) {
    var newFilter = defaultFilter;
    var stageStatuses = [];
    var signatureStatuses = [];
    var paymentStatuses = [];
    if (values.stage && values.stage.length > 0) {
        values.stage.forEach(function (simpleStage) {
            stageStatuses = __spreadArray(__spreadArray([], stageStatuses, true), getAdvancedStages(simpleStage), true);
        });
    }
    if (values.signatureStatus && values.signatureStatus.length > 0) {
        values.signatureStatus.forEach(function (status) {
            signatureStatuses = __spreadArray(__spreadArray([], signatureStatuses, true), getStatus(status), true);
        });
    }
    if (values.paymentStatus && values.paymentStatus.length > 0) {
        values.paymentStatus.forEach(function (status) {
            paymentStatuses = __spreadArray(__spreadArray([], paymentStatuses, true), getStatus(status), true);
        });
    }
    var statuses = utils.array.intersectionIfNotEmpty(stageStatuses, signatureStatuses, paymentStatuses);
    if (statuses.length > 0) {
        newFilter = mergeDeepLeft({ filter: { where: { status: { inq: statuses } } } }, newFilter);
    }
    if (values.orderField && values.orderMethod) {
        newFilter = mergeDeepLeft({ filter: { order: "".concat(values.orderField, " ").concat(values.orderMethod) } }, newFilter);
    }
    if (values.query) {
        newFilter = mergeDeepLeft({ filter: { search: values.query } }, newFilter);
    }
    if (values.page) {
        newFilter = mergeDeepLeft({ filter: { page: values.page || 1 } }, newFilter);
    }
    return newFilter;
};
export var getSignatureStatusVariation = function (status) {
    switch (status) {
        case ERentDepositSignatureStatus.NotSigned:
            return "error";
        case ERentDepositSignatureStatus.JudicialRelease:
            return "warning";
        default:
            return "success";
    }
};
export var getPaymentStatusVariation = function (status) {
    switch (status) {
        case ERentDepositPaymentStatus.NotPaid:
            return "error";
        default:
            return "success";
    }
};
export var getStatusVariation = function (status) {
    switch (status) {
        case ERentDepositStatus.Established:
        case ERentDepositStatus.Released:
            return "success";
        default:
            return "error";
    }
};
export var getSimpleStage = function (status) {
    switch (status) {
        case ERentDepositStatus.New:
        case ERentDepositStatus.PayingIn:
        case ERentDepositStatus.OpenSigned:
        case ERentDepositStatus.OpenToSign:
        case ERentDepositStatus.PayingInPartiallySigned:
        case ERentDepositStatus.PaidInPartiallySigned:
            return ERentDepositStageSimple.In;
        case ERentDepositStatus.Established:
            return ERentDepositStageSimple.Established;
        case ERentDepositStatus.ReleaseNew:
        case ERentDepositStatus.WaitingForIBAN:
        case ERentDepositStatus.ReleaseToSign:
        case ERentDepositStatus.ReleaseSigned:
        case ERentDepositStatus.PayingOut:
            return ERentDepositStageSimple.Out;
    }
};
export var getHasActiveFilter = function (filter) {
    var hasQuery = !isEmpty(filter.query);
    var hasStage = !isEmpty(filter.stage) && !isNil(filter.stage);
    var hasSignatureStatus = !isEmpty(filter.signatureStatus) && !isNil(filter.signatureStatus);
    var hasPaymentStatus = !isEmpty(filter.paymentStatus) && !isNil(filter.paymentStatus);
    var hasFilter = [hasQuery, hasStage, hasSignatureStatus, hasPaymentStatus];
    return hasFilter.filter(Boolean).length > 0;
};
export var getRentDepositActivityFilter = function (rentDeposit) {
    var rentDepositId = rentDeposit.id, paymentOrder = rentDeposit.paymentOrder, rentDepositContractIn = rentDeposit.rentDepositContractIn, rentDepositContractOut = rentDeposit.rentDepositContractOut;
    return {
        where: {
            or: [
                { rentDepositId: rentDepositId },
                paymentOrder && {
                    paymentOrderId: paymentOrder.id,
                    subtype: {
                        inq: [
                            ACTIVITY_SUBTYPES.PAYMENT.ORDER_CREATED,
                            ACTIVITY_SUBTYPES.PAYMENT.ORDER_REMOVED,
                            ACTIVITY_SUBTYPES.PAYMENT.ORDER_RECURRING_MANDATE_REMOVED,
                        ],
                    },
                },
                (paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.paymentRequests) && {
                    paymentRequestId: {
                        inq: paymentOrder.paymentRequests.map(function (paymentRequest) { return paymentRequest.id; }),
                    },
                },
                !!rentDepositContractIn && {
                    contractId: rentDepositContractIn.id,
                    subtype: {
                        inq: [
                            ACTIVITY_SUBTYPES.CONTRACT.CREATED,
                            ACTIVITY_SUBTYPES.CONTRACT.SIGNED,
                        ],
                    },
                },
                !!(rentDepositContractIn === null || rentDepositContractIn === void 0 ? void 0 : rentDepositContractIn.documentPackages) && {
                    documentPackageId: {
                        inq: rentDepositContractIn.documentPackages.map(function (documentPackage) { return documentPackage.id; }),
                    },
                },
                !!rentDepositContractOut && {
                    contractId: rentDepositContractOut.id,
                    subtype: {
                        inq: [
                            ACTIVITY_SUBTYPES.CONTRACT.CREATED,
                            ACTIVITY_SUBTYPES.CONTRACT.SIGNED,
                        ],
                    },
                },
                !!(rentDepositContractOut === null || rentDepositContractOut === void 0 ? void 0 : rentDepositContractOut.documentPackages) && {
                    documentPackageId: {
                        inq: rentDepositContractOut.documentPackages.map(function (documentPackage) { return documentPackage.id; }),
                    },
                },
            ].filter(Boolean),
        },
    };
};
export var hasPaymentRequests = function (paymentOrder) {
    if (!(paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.paymentRequests)) {
        return false;
    }
    if (paymentOrder.paymentRequests.length === 0) {
        return false;
    }
    return true;
};
