var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatNumber } from "@rentiohq/shared/dist/utils/number.utils";
import { round } from "lodash";
import sortBy from "lodash/sortBy";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
import { EColor } from "../../DashboardStats.types";
import { formatFrom } from "../../DashboardStats.utils";
var StatChart = function (props) {
    var stats = props.stats, chart = props.chart, statInterval = props.statInterval;
    if (stats.data.length === 0) {
        return null;
    }
    var data = sortBy(stats.data, "from");
    var mappedData = data.map(function (item) {
        var mappedItem = {};
        chart.lines.forEach(function (line) {
            var value = item.total[line.key];
            var valueIsNumber = value !== null && !isNaN(value);
            if (valueIsNumber) {
                mappedItem[line.label] = round(value, 2);
            }
            else {
                mappedItem[line.label] = value;
            }
        });
        mappedItem.date = formatFrom(item.from, statInterval);
        return mappedItem;
    });
    var leftLine = chart.lines.find(function (line) { return !line.axis || (line === null || line === void 0 ? void 0 : line.axis) === "left"; });
    var rightLine = chart.lines.find(function (line) { return (line === null || line === void 0 ? void 0 : line.axis) === "right"; });
    var formatTooltipValueBase = function (value) {
        if (typeof value === "number") {
            return formatNumber(value, false);
        }
        return value;
    };
    var formatTooltipValue = function (value) {
        if (Array.isArray(value)) {
            return value.map(formatTooltipValueBase);
        }
        return formatTooltipValueBase(value);
    };
    return (_jsx(ResponsiveContainer, __assign({ height: 300, width: "99%" }, { children: _jsxs(LineChart, __assign({ data: mappedData }, { children: [_jsx(XAxis, { dataKey: "date" }), leftLine && (_jsx(YAxis, { dataKey: leftLine.label, yAxisId: "left", orientation: "left" })), rightLine && (_jsx(YAxis, { dataKey: rightLine.label, yAxisId: "right", orientation: "right" })), _jsx(CartesianGrid, { stroke: "#eee" }), chart.lines.map(function (line) { return (_jsx(Line, { type: "monotone", dataKey: line.label, yAxisId: line.axis || "left", stroke: line.color || EColor.DEFAULT }, line.key)); }), _jsx(Tooltip, { formatter: formatTooltipValue })] })) })));
};
export default StatChart;
