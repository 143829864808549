var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { useDropzone } from "react-dropzone";
import Dropzone from "../../components/Dropzone";
import { ts } from "../../services";
import { showDropErrors } from "../../utils/file";
var UploadField = function (_a) {
    var id = _a.id, name = _a.name, onChange = _a.onChange, _b = _a.asDropzone, asDropzone = _b === void 0 ? true : _b, _c = _a.multiple, multiple = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.maxFileSize, maxFileSize = _e === void 0 ? CONFIG.UPLOAD_MAX_FILE_SIZE : _e, _f = _a.accept, accept = _f === void 0 ? "*" : _f, required = _a.required, error = _a.error;
    var handleDrop = function (acceptedFiles, fileRejections, event) {
        showDropErrors({ acceptedFiles: acceptedFiles, fileRejections: fileRejections, event: event, maxFileSize: maxFileSize });
        if (acceptedFiles.length === 0) {
            return;
        }
        onChange(acceptedFiles);
    };
    var handleChange = function (_a) {
        var acceptedFiles = _a.target.files;
        onChange(acceptedFiles);
    };
    var dropzoneProps = {
        onDrop: handleDrop,
        multiple: multiple,
        maxSize: maxFileSize,
        disabled: disabled,
    };
    // To accept all, just leave the 'accept' prop out.
    if (accept && accept !== "*")
        dropzoneProps.accept = accept;
    var _g = useDropzone(dropzoneProps), getRootProps = _g.getRootProps, getInputProps = _g.getInputProps, isDragActive = _g.isDragActive;
    if (asDropzone)
        return (_jsx(_Fragment, { children: _jsxs(Dropzone.Dropzone, __assign({}, getRootProps(), { isDraggingOver: isDragActive, hasError: !!error }, { children: [_jsx("input", __assign({ id: id, name: typeof name === "string" ? name : undefined }, getInputProps())), _jsx(Dropzone.DropzonePlaceholder, { children: isDragActive ? (_jsx("p", { children: ts.fileDropZoneDrop() })) : (_jsx("p", { children: ts.fileDropZonePlaceholder() })) })] })) }));
    return (_jsx("input", { id: id, name: typeof name === "string" ? name : undefined, required: required, type: "file", onChange: handleChange, defaultValue: "", multiple: multiple, accept: accept, disabled: disabled }));
};
export default UploadField;
