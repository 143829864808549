var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../utils/hooks.utils";
import { generateActions } from "./generator.actions";
import { generateSelectors } from "./generator.selectors";
var generateUseDetail = function (params) {
    var _a = generateSelectors(params), getDetail = _a.getDetail, isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError;
    var getDetailStart = generateActions(params).getDetailStart;
    var useDetail = function (params) {
        var id = params.id, customPath = params.customPath, extraQueryParams = params.extraQueryParams, _a = params.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a;
        // Redux
        var dispatch = useDispatch();
        var detail = useSelector(function (state) {
            return getDetail(state, id, customPath);
        });
        var isFetching = useSelector(function (state) {
            return isFetchingDetail(state, id, customPath);
        });
        var fetchError = useSelector(function (state) {
            return fetchDetailError(state, id, customPath);
        });
        // Data
        var startFetching = function () {
            if ((!id && !customPath) || isFetching) {
                return;
            }
            if (!shouldRefetch && !!detail) {
                return;
            }
            dispatch(getDetailStart.getAction({
                id: id,
                customPath: customPath,
                extraQueryParams: extraQueryParams,
            }));
        };
        var refetch = function () {
            if ((!id && !customPath) || isFetching) {
                return;
            }
            dispatch(getDetailStart.getAction({
                id: id,
                customPath: customPath,
                extraQueryParams: extraQueryParams,
            }));
        };
        // Lifecycle
        useEffect(startFetching, [id, customPath, extraQueryParams]);
        return {
            detail: detail,
            isFetching: isFetching,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return useDetail;
};
var generateUseDetailLazy = function (params) {
    var _a = generateSelectors(params), getDetailSelector = _a.getDetail, isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError;
    var getDetailStart = generateActions(params).getDetailStart;
    // TODO: Look at ESLint ignores & fix component structure
    var getDetailLazy = function (params) {
        var customPath = params.customPath;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var idRef = useRef(undefined);
        // Redux
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var dispatch = useDispatch();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var detail = useSelector(function (state) {
            return idRef.current
                ? getDetailSelector(state, idRef.current, customPath)
                : undefined;
        });
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var isFetching = useSelector(function (state) {
            return idRef.current
                ? isFetchingDetail(state, idRef.current, customPath)
                : false;
        });
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var fetchError = useSelector(function (state) {
            return idRef.current
                ? fetchDetailError(state, idRef.current, customPath)
                : undefined;
        });
        // Data
        var fetch = function (id) {
            if (idRef.current === id && detail)
                return;
            idRef.current = id;
            if ((!id && !customPath) || isFetching) {
                return;
            }
            dispatch(getDetailStart.getAction({
                id: id,
                customPath: customPath,
            }));
        };
        return {
            getDetail: fetch,
            detail: detail,
            isFetching: isFetching,
            fetchError: fetchError,
        };
    };
    return getDetailLazy;
};
var generateUsePaged = function (params) {
    var getPaged = generateSelectors(params).getPaged;
    var getPagedStart = generateActions(params).getPagedStart;
    var usePaged = function (params) {
        var query = params.query, customPath = params.customPath, skipLegacyCount = params.skipLegacyCount;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var paged = useSelector(function (state) {
            return getPaged(state, query, customPath);
        });
        var _a = paged || {
            isFetching: false,
        }, totalItems = _a.totalItems, totalPages = _a.totalPages, items = _a.items, isFetching = _a.isFetching, fetchError = _a.fetchError;
        // Data
        var refetch = function () {
            if (!query || isFetching) {
                return;
            }
            dispatch(getPagedStart.getAction({
                query: query,
                customPath: customPath,
                skipLegacyCount: skipLegacyCount,
            }));
        };
        // Lifecycle
        useEffect(function () {
            if (!query) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getPagedStart.getAction({
                query: query,
                customPath: customPath,
                skipLegacyCount: skipLegacyCount,
            }));
        }, [query, customPath]);
        var hasData = (items || []).length > 0;
        return {
            totalItems: totalItems,
            totalPages: totalPages,
            items: items,
            isFetching: isFetching,
            hasData: hasData,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return usePaged;
};
var generateUseGetAll = function (params) {
    var getAllWhere = generateSelectors(params).getAllWhere;
    var getAllWhereStart = generateActions(params).getAllWhereStart;
    var useGetAll = function (params) {
        var query = params.query, customPath = params.customPath;
        var prevQuery = usePrevious(query);
        // Redux
        var dispatch = useDispatch();
        var all = useSelector(function (state) {
            return getAllWhere(state, query, customPath);
        });
        var _a = all || {
            isFetching: false,
        }, items = _a.items, isFetching = _a.isFetching, fetchError = _a.fetchError;
        var refetch = function () {
            if ((!query && !customPath) || isFetching) {
                return;
            }
            dispatch(getAllWhereStart.getAction({
                query: query,
                customPath: customPath,
            }));
        };
        // Lifecycle
        useEffect(function () {
            if ((!query && !customPath) || isFetching) {
                return;
            }
            if (query && isEqual(query, prevQuery)) {
                return;
            }
            dispatch(getAllWhereStart.getAction({
                query: query,
                customPath: customPath,
            }));
        }, [query, customPath]);
        var hasData = (items || []).length > 0;
        return {
            items: items,
            isFetching: isFetching,
            hasData: hasData,
            fetchError: fetchError,
            refetch: refetch,
        };
    };
    return useGetAll;
};
var generateUseInfiniteLoad = function (params) {
    var getInfiniteLoad = generateSelectors(params).getInfiniteLoad;
    var getInfiniteLoadStart = generateActions(params).getInfiniteLoadStart;
    var useInfiniteLoad = function (params) {
        var query = params.query, customPath = params.customPath;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var infiniteLoad = useSelector(function (state) {
            return getInfiniteLoad(state, query, customPath);
        });
        var _a = infiniteLoad || {
            isFetching: false,
        }, totalItems = _a.totalItems, totalPages = _a.totalPages, items = _a.items, isFetching = _a.isFetching;
        // State
        var _b = useState(), fetchError = _b[0], setFetchError = _b[1];
        // Data
        var loadMoreFn = useCallback(function (_a) {
            var _b = _a.shouldRefresh, shouldRefresh = _b === void 0 ? false : _b;
            if (isFetching) {
                return;
            }
            if (!query) {
                return;
            }
            setFetchError(undefined);
            dispatch(getInfiniteLoadStart.getAction({
                query: query,
                customPath: customPath,
                refresh: shouldRefresh,
                onFailure: function (error) { return setFetchError(error); },
            }));
        }, [query, getInfiniteLoadStart, dispatch, isFetching]);
        var refreshFn = useCallback(function () { return loadMoreFn({ shouldRefresh: true }); }, [loadMoreFn]);
        // Lifecycle
        useEffect(function () {
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            loadMoreFn({ shouldRefresh: false });
        }, [loadMoreFn, query, prevQuery, customPath, prevCustomPath]);
        var hasData = (items || []).length > 0;
        return {
            totalItems: totalItems,
            totalPages: totalPages,
            items: items,
            isFetching: isFetching,
            hasData: hasData,
            fetchError: fetchError,
            loadMore: function () { return loadMoreFn({ shouldRefresh: false }); },
            refresh: refreshFn,
        };
    };
    return useInfiniteLoad;
};
var generateUseCount = function (params) {
    var _a = generateSelectors(params), getCount = _a.getCount, isFetchingCount = _a.isFetchingCount, fetchCountError = _a.fetchCountError;
    var getCountStart = generateActions(params).getCountStart;
    var useCount = function (params) {
        var query = params.query, customPath = params.customPath, _a = params.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a;
        var prevQuery = usePrevious(query);
        var prevCustomPath = usePrevious(customPath);
        // Redux
        var dispatch = useDispatch();
        var count = useSelector(function (state) {
            return getCount(state, query, customPath);
        });
        var isFetching = useSelector(function (state) {
            return isFetchingCount(state, query, customPath);
        });
        var fetchError = useSelector(function (state) {
            return fetchCountError(state, query, customPath);
        });
        // Data
        var startFetching = useCallback(function () {
            if (!query) {
                return;
            }
            if (isFetching) {
                return;
            }
            if (!shouldRefetch && count !== undefined) {
                return;
            }
            // TODO: Prevent deep equal check
            if (isEqual(query, prevQuery) && isEqual(customPath, prevCustomPath)) {
                return;
            }
            dispatch(getCountStart.getAction({
                query: query,
                customPath: customPath,
            }));
        }, [dispatch, isFetching, query, customPath, prevCustomPath, prevQuery]);
        // Lifecycle
        useEffect(startFetching, [startFetching]);
        return {
            count: count,
            isFetching: isFetching,
            fetchError: fetchError,
            // startFetching,
        };
    };
    return useCount;
};
var generateUseUpdate = function (params) {
    var _a = generateSelectors(params), getDetail = _a.getDetail, isUpdating = _a.isUpdating, updateError = _a.updateError;
    var _b = generateActions(params), updateStart = _b.updateStart, updateMembersStart = _b.updateMembersStart, updateEmployeesStart = _b.updateEmployeesStart;
    var useUpdate = function (params) {
        var id = params.id;
        var _a = useState(id), _id = _a[0], setId = _a[1];
        // Redux
        var dispatch = useDispatch();
        var detail = useSelector(function (state) {
            return _id ? getDetail(state, _id) : undefined;
        });
        var pending = useSelector(function (state) {
            return _id ? isUpdating(state, _id) : false;
        });
        var error = useSelector(function (state) {
            return _id ? updateError(state, _id) : undefined;
        });
        // Data
        var update = function (params, id) {
            var ID = id !== null && id !== void 0 ? id : _id;
            if (!ID)
                return;
            if (ID)
                setId(ID);
            if (!ID) {
                return;
            }
            dispatch(updateStart.getAction(__assign({ id: ID }, params)));
        };
        var updateMembers = function (params, id) {
            var ID = id !== null && id !== void 0 ? id : _id;
            if (!ID)
                return;
            if (ID)
                setId(ID);
            if (!ID) {
                return;
            }
            dispatch(updateMembersStart.getAction(__assign({ id: ID }, params)));
        };
        var updateEmployees = function (params, id) {
            var ID = id !== null && id !== void 0 ? id : _id;
            if (!ID)
                return;
            if (ID)
                setId(ID);
            if (!ID) {
                return;
            }
            dispatch(updateEmployeesStart.getAction(__assign({ id: ID }, params)));
        };
        return {
            update: update,
            updateMembers: updateMembers,
            updateEmployees: updateEmployees,
            detail: detail,
            pending: pending,
            error: error,
        };
    };
    return useUpdate;
};
var generateUseRemove = function (params) {
    var _a = generateSelectors(params), isRemovingSelector = _a.isRemoving, removeError = _a.removeError;
    var removeStart = generateActions(params).removeStart;
    var useRemove = function (params) {
        var id = params.id;
        // Redux
        var dispatch = useDispatch();
        var isRemoving = useSelector(function (state) {
            return isRemovingSelector(state, id);
        });
        var error = useSelector(function (state) { return removeError(state, id); });
        var remove = function (_a) {
            var onSuccess = _a.onSuccess;
            if (!id)
                return;
            dispatch(removeStart.getAction({
                id: id,
                onSuccess: function () {
                    if (onSuccess)
                        onSuccess();
                },
            }));
        };
        return {
            remove: remove,
            isRemoving: isRemoving,
            error: error,
        };
    };
    return useRemove;
};
export var generateHooks = function (params) {
    var useDetail = generateUseDetail(params);
    var useDetailLazy = generateUseDetailLazy(params);
    var usePaged = generateUsePaged(params);
    var useUpdate = generateUseUpdate(params);
    var useCount = generateUseCount(params);
    var useInfiniteLoad = generateUseInfiniteLoad(params);
    var useRemove = generateUseRemove(params);
    var useGetAll = generateUseGetAll(params);
    return {
        useDetail: useDetail,
        useDetailLazy: useDetailLazy,
        usePaged: usePaged,
        useInfiniteLoad: useInfiniteLoad,
        useGetAll: useGetAll,
        useUpdate: useUpdate,
        useCount: useCount,
        useRemove: useRemove,
    };
};
