var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import Button from "../Button";
export var Tag = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  max-width: 100%;\n  align-items: center;\n  min-height: ", ";\n  padding-left: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  color: ", ";\n  ", "\n"], ["\n  display: inline-flex;\n  max-width: 100%;\n  align-items: center;\n  min-height: ", ";\n  padding-left: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  color: ", ";\n  ", "\n"])), function (props) { return rem("28px", props.theme.baseFontSize); }, spacing("tight"), function (props) { return props.theme.colors.neutral20; }, borderRadius(), function (props) { return props.theme.colors.neutral90; }, function (_a) {
    var hasCloseButton = _a.hasCloseButton;
    return hasCloseButton ? null : "padding-right: ".concat(spacing("tight"), ";");
});
export var Text = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1 1 0%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 1.2;\n"], ["\n  flex: 1 1 0%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  line-height: 1.2;\n"])));
export var Close = styled(function (props) { return _jsx(Button, __assign({}, props)); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  padding: 0;\n  margin-left: ", ";\n  border-radius: 0 ", " ", " 0;\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  padding: 0;\n  margin-left: ", ";\n  border-radius: 0 ", " ", " 0;\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return rem("28px", props.theme.baseFontSize); }, function (props) { return rem("28px", props.theme.baseFontSize); }, spacing("extraTight"), borderRadius(), borderRadius(), function (props) { return props.theme.colors.neutral20; }, function (props) { return props.theme.colors.neutral40; }, function (props) { return props.theme.colors.neutral80; });
var templateObject_1, templateObject_2, templateObject_3;
