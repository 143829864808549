var hasRole = function (roles, role) { return roles.includes(role); };
// Check if current user has role on object
export var iHaveRole = function (entity, role) { return hasRole(entity.roles, role); };
export var iHaveOneOfRoles = function (entity, roles) { return roles.some(function (role) { return hasRole(entity.roles, role); }); };
export var memberHasRole = function (member, role) {
    return hasRole(member.roles, role);
};
export var memberHasOneOfRoles = function (member, roles) { return roles.some(function (role) { return hasRole(member.roles, role); }); };
export var getMembersWithRole = function (members, role) { return members.filter(function (member) { return memberHasRole(member, role); }); };
export var getMembersWithOneOfRoles = function (members, roles) { return members.filter(function (member) { return memberHasOneOfRoles(member, roles); }); };
