import { jsx as _jsx } from "react/jsx-runtime";
import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { EField, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as React from "react";
import * as ts from "../../services/translationService";
import { i18n } from "../../utils";
import { MultiStepForm } from "../SharedForm";
var AddContactModal = function (props) {
    var fields = props.fields, type = props.type, onClose = props.onClose, onSubmit = props.onSubmit, contact = props.contact, baseAccountId = props.baseAccountId, prefilledAddress = props.prefilledAddress, initialType = props.initialType;
    // Form
    var formId = React.useRef(generateFormId());
    var contactSchemas = addContactSchemas({
        isCompany: type === "company",
        fields: fields,
        // @ts-ignore
        contact: contact,
        initialType: initialType,
        showSendInvite: true,
        prefilledAddress: prefilledAddress,
        baseAccountId: baseAccountId,
    });
    // Redux
    var broker = authHooks.useSelf().broker;
    // Event handlers
    var handleSuccess = function (contact) {
        if (!contact) {
            return;
        }
        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(contact);
        onClose();
    };
    // Render
    return (_jsx(MultiStepForm, { formId: "add-contact-".concat(formId.current), schemas: contactSchemas, asModal: true, withAside: false, onSuccess: handleSuccess, modalProps: { onClose: onClose }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(undefined), performingSubmitActionResultSelector: performingSubmitActionResultSelector(undefined), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(undefined), submitActionCreator: submitActionCreator(undefined, baseAccountId, prefilledAddress), submitLabel: ts.system("save"), getPrecommitConfirm: function (params) {
            var formData = params.formData, onProceed = params.onProceed;
            var email = formData[EField.Email];
            var phone = formData[EField.Phone];
            if (email !== (broker === null || broker === void 0 ? void 0 : broker.dummyEmail) && phone !== (broker === null || broker === void 0 ? void 0 : broker.dummyPhone)) {
                return;
            }
            var dummyInfo = [];
            if (email === (broker === null || broker === void 0 ? void 0 : broker.dummyEmail)) {
                dummyInfo.push(broker === null || broker === void 0 ? void 0 : broker.dummyEmail);
            }
            if (phone === (broker === null || broker === void 0 ? void 0 : broker.dummyPhone)) {
                dummyInfo.push(broker === null || broker === void 0 ? void 0 : broker.dummyPhone);
            }
            return {
                type: "warning",
                title: getLocalizedText("modal.contact.dummy_data.title"),
                info: i18n.tm("modal.contact.dummy_data.info", {
                    values: {
                        dummyInfo: dummyInfo.join(" ".concat(getLocalizedText("system.and"), " ").toLowerCase()),
                    },
                    markdownProps: { listAsChecklist: true, checklistCharacter: "✗" },
                }),
                primaryActions: [
                    {
                        title: getLocalizedText("system.edit"),
                    },
                ],
                secondaryAction: {
                    title: getLocalizedText("modal.contact.dummy_data.cta.create"),
                    onPress: function () {
                        onProceed();
                    },
                },
            };
        } }));
};
export default AddContactModal;
