var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createForm, storePartialFormData, } from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { REDUCER_KEY_FORM, } from "@rentiohq/shared-frontend/dist/redux/form/form.reducer";
import { getFormData, makeGetSchemas, mergedPartialFormDataSelector, } from "@rentiohq/shared-frontend/dist/redux/form/form.selectors";
import { connect } from "react-redux";
import { FormAside } from "./FormAside";
var mapStateToProps = function (state, _a) {
    var formId = _a.formId, props = __rest(_a, ["formId"]);
    var getSchemas = makeGetSchemas();
    return __assign(__assign({}, props), { schemas: getSchemas(state, formId), formData: getFormData(state, formId), mergedPartialFormData: mergedPartialFormDataSelector(state, formId) });
};
var mapDispatchToProps = {
    createForm: createForm,
    storePartialFormData: storePartialFormData,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FormAside);
