var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import Loading from "../../../Loading";
import { ContactSelect } from "./components/ContactSelect/ContactSelect";
export var ContactSelectField = function (_a) {
    var uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, _c = _a.formData, formData = _c === void 0 ? [] : _c, _d = _a.formContext, formContext = _d === void 0 ? {} : _d, required = _a.required, onChange = _a.onChange;
    var _e = brokerHooks.useGetBrokerEmployees(), employees = _e.employees, isFetchingEmployees = _e.isFetching;
    var broker = authHooks.useSelf().broker;
    var customUiSchema = uiSchema;
    var isMultiSelect = customUiSchema.isMultiSelect, isDisabled = customUiSchema.isDisabled, requiredContactFieldsSchema = customUiSchema.requiredContactFieldsSchema, _f = customUiSchema.employeeAndBrokerCanBeSelectedAtTheSameTime, employeeAndBrokerCanBeSelectedAtTheSameTime = _f === void 0 ? true : _f, getRequiredContactFieldsSchema = customUiSchema.getRequiredContactFieldsSchema, getRequiredCompanyFieldsSchema = customUiSchema.getRequiredCompanyFieldsSchema;
    var disabled = useMemo(function () {
        return isDisabled ? isDisabled(formContext.getAllFormData()) : undefined;
    }, [isDisabled]);
    // Event handlers
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    var handleContactSelectChange = function (_a) {
        var addIds = _a.addIds, removeIds = _a.removeIds;
        var newAccountIds = __spreadArray([], formData, true);
        var existingAccountIds = __spreadArray([], formData, true);
        if (addIds !== undefined) {
            var idsToAdd_1 = Array.isArray(addIds) ? addIds : [addIds];
            if (!isMultiSelect) {
                updateContactSelectFormData(idsToAdd_1);
                return;
            }
            var newIdsIncludesEmployee = employees.some(function (employee) {
                return idsToAdd_1.includes(employee.id);
            });
            newAccountIds = uniq(__spreadArray(__spreadArray([], formData, true), idsToAdd_1, true));
            // Remove broker (if in list) if employee is added
            // Don't add the broker if an employee is in the list
            if (broker &&
                newIdsIncludesEmployee &&
                existingAccountIds.includes(broker.accountId) &&
                !employeeAndBrokerCanBeSelectedAtTheSameTime) {
                newAccountIds = newAccountIds.filter(function (id) { return id !== broker.accountId; });
            }
        }
        if (removeIds !== undefined) {
            var idsToRemove_1 = Array.isArray(removeIds) ? removeIds : [removeIds];
            newAccountIds = newAccountIds.filter(function (currentAccountId) { return !idsToRemove_1.includes(currentAccountId); });
        }
        updateContactSelectFormData(newAccountIds);
    };
    var handleSelectMoreContacts = function (accountId) {
        handleContactSelectChange({ addIds: accountId });
        if (formContext.onSelectMore) {
            formContext.onSelectMore(name, uniq(__spreadArray(__spreadArray([], formData, true), [accountId], false).filter(function (id) { return id !== 0; })));
        }
    };
    // Update
    var updateContactSelectFormData = function (accountIds) {
        var accountIdsFormatted = accountIds.length > 0 ? accountIds : undefined;
        onChange(accountIdsFormatted);
        if (formContext.onFieldChange) {
            formContext.onFieldChange(name, accountIdsFormatted);
        }
    };
    if (isFetchingEmployees)
        return _jsx(Loading, {});
    return (_jsx(ContactSelect, __assign({}, customUiSchema, { getRequiredContactFieldsSchema: getRequiredContactFieldsSchema, getRequiredCompanyFieldsSchema: getRequiredCompanyFieldsSchema, requiredContactFieldsSchema: requiredContactFieldsSchema, formData: formContext.getAllFormData(), selectedAccountIds: formData, onPressExtraInfo: handlePressExtraInfo, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), isMultiSelect: isMultiSelect, error: rawErrors[0], disabled: disabled, onContactSelectChange: handleContactSelectChange, onSelectMoreContacts: handleSelectMoreContacts, required: required })));
};
