var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import { onMultiple } from "@rentiohq/shared-frontend/dist/redux/utils/reducer.utils";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import * as systemActions from "./system.actions";
import { EPreferencePersistScope } from "./system.types";
export var REDUCER_KEY_SYSTEM_LOCAL = "systemLocal";
var getDefaultState = function () { return ({
    preferences: {},
    preferencesLocalStorage: {},
    lastModified: undefined,
    isFetchingIndexDate: false,
    fetchingIndexDateError: undefined,
    search: {},
}); };
var reducer = createReducer({}, getDefaultState());
onMultiple(reducer, [
    "LOG_OUT",
    authActions.signInAutomatically.types.START,
    authActions.setAccessTokensWithoutSideEffects.getType(),
], function (state) { return (__assign(__assign({}, getDefaultState()), { lastModified: state.lastModified, preferencesLocalStorage: {
        RENTIO_INTERNAL: state.preferencesLocalStorage.RENTIO_INTERNAL,
    } })); });
reducer.on(systemActions.getRemotePreferences.types.SUCCESS, function (state, _a) {
    var preferences = _a.preferences;
    return (__assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), preferences) }));
});
reducer.on(systemActions.setPreferences, function (state, _a) {
    var persistScope = _a.persistScope, preferences = _a.preferences;
    return (__assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), (persistScope !== EPreferencePersistScope.LocalStorage
            ? preferences
            : {})), preferencesLocalStorage: __assign(__assign({}, state.preferencesLocalStorage), (persistScope === EPreferencePersistScope.LocalStorage
            ? preferences
            : {})) }));
});
reducer.on(systemActions.getIndexLastModified.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingIndexDate: true })); });
reducer.on(systemActions.getIndexLastModified.types.SUCCESS, function (state, _a) {
    var headers = _a.headers;
    return (__assign(__assign({}, state), { lastModified: headers["last-modified"], isFetchingIndexDate: false, fetchingIndexDateError: undefined }));
});
reducer.on(systemActions.getIndexLastModified.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingIndexDate: false, fetchingIndexDateError: error }));
});
reducer.on(systemActions.search.types.START, function (state, _a) {
    var _b;
    var identifier = _a.identifier;
    return merge({}, state, {
        search: (_b = {},
            _b[identifier] = {
                isFetching: true,
                fetchError: undefined,
            },
            _b),
    });
});
reducer.on(systemActions.search.types.SUCCESS, function (state, _a) {
    var _b;
    var identifier = _a.identifier, result = _a.result;
    return merge({}, state, {
        search: (_b = {},
            _b[identifier] = {
                result: result,
                isFetching: false,
                fetchError: undefined,
            },
            _b),
    });
});
reducer.on(systemActions.search.types.FAILURE, function (state, _a) {
    var _b;
    var identifier = _a.identifier, error = _a.error;
    return merge({}, state, {
        search: (_b = {},
            _b[identifier] = {
                isFetching: false,
                fetchError: error,
            },
            _b),
    });
});
export default reducer;
