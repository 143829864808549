var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: ", ";\n  ul {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    margin: 0 -", ";\n  }\n  li {\n    margin: 0 ", ";\n    a {\n      color: ", ";\n    }\n    &.is-active {\n      a {\n        color: ", ";\n        background: ", ";\n      }\n    }\n    &.is-disabled {\n      a {\n        color: ", ";\n        cursor: not-allowed;\n      }\n    }\n  }\n  a {\n    cursor: pointer;\n    box-sizing: content-box;\n    display: block;\n    min-width: 1em;\n    padding: ", "px;\n    line-height: 1em;\n    text-align: center;\n    &:hover {\n      color: ", ";\n    }\n    &:focus {\n      outline: none;\n    }\n  }\n"], ["\n  margin-top: ", ";\n  ul {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    margin: 0 -", ";\n  }\n  li {\n    margin: 0 ", ";\n    a {\n      color: ", ";\n    }\n    &.is-active {\n      a {\n        color: ", ";\n        background: ", ";\n      }\n    }\n    &.is-disabled {\n      a {\n        color: ", ";\n        cursor: not-allowed;\n      }\n    }\n  }\n  a {\n    cursor: pointer;\n    box-sizing: content-box;\n    display: block;\n    min-width: 1em;\n    padding: ", "px;\n    line-height: 1em;\n    text-align: center;\n    &:hover {\n      color: ", ";\n    }\n    &:focus {\n      outline: none;\n    }\n  }\n"])), spacing(), spacing("tight"), spacing("extraTight"), function (_a) {
    var theme = _a.theme;
    return theme.colors.global5;
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.global5;
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
}, function (props) { return props.theme.baseUnit * 3; }, function (_a) {
    var theme = _a.theme;
    return theme.colors.global5;
});
var templateObject_1;
