import { jsx as _jsx } from "react/jsx-runtime";
import { ASSETS } from "@rentiohq/web-assets";
import React from "react";
import * as S from "./Logo.styled";
import { isChristmasPeriod } from "./Logo.utils";
var Logo = function (props) {
    var color = props.color, size = props.size, _a = props.type, type = _a === void 0 ? "full" : _a, className = props.className;
    if (type === "full") {
        return (_jsx(S.Logo, { color: color, className: className, dangerouslySetInnerHTML: { __html: ASSETS.logoRentioFull } }));
    }
    else {
        var logoIconToUsed = isChristmasPeriod()
            ? ASSETS.logoRentioBadgeWhiteChristmas
            : ASSETS.logoRentioBadgeWhite;
        return (_jsx(S.Logo, { color: color, className: className, size: size, dangerouslySetInnerHTML: { __html: logoIconToUsed } }));
    }
};
var LogoMemo = React.memo(Logo);
export default LogoMemo;
