import styled, { css } from "@rentiohq/web-theme";

export const Wrap = styled.div``;

export const IconListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`;

export const IconListItemContent = styled.div``;

export const OptionsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const OptionWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
`;

export const OptionIconWrap = styled.div`
  position: relative;
`;

export const OptionIcon = styled.img<{ isActive: boolean }>`
  width: 35px;
  height: 35px;
  margin: 1px;
  ${props =>
    props.isActive
      ? css``
      : css`
          filter: grayscale(100%);
          opacity: 0.8;
        `}
`;

export const OptionIconCheck = styled.img`
  width: 16px;
  height: 16px;
  top: -12px;
  right: -12px;
  position: absolute;
`;

export const OptionName = styled.span<{ isActive: boolean }>`
  display: block;
  margin-top: 4px;
  color: ${props =>
    props.isActive ? props.theme.colors.global5 : props.theme.colors.global2};
  text-align: center;
`;
