export var triggerLiveChat = function () {
    var _a, _b, _c;
    // @ts-ignore
    (_a = window.zE) === null || _a === void 0 ? void 0 : _a.call(window, "webWidget", "updateSettings", {
        webWidget: {
            chat: {
                suppress: false,
            },
        },
    });
    // @ts-ignore
    (_c = (_b = window.zE) === null || _b === void 0 ? void 0 : _b.activate) === null || _c === void 0 ? void 0 : _c.call(_b, { hideOnClose: true });
};
export var triggerContactForm = function () {
    var _a, _b, _c;
    // @ts-ignore
    (_a = window.zE) === null || _a === void 0 ? void 0 : _a.call(window, "webWidget", "updateSettings", {
        webWidget: {
            chat: {
                suppress: true,
            },
        },
    });
    // @ts-ignore
    (_c = (_b = window.zE) === null || _b === void 0 ? void 0 : _b.activate) === null || _c === void 0 ? void 0 : _c.call(_b, { hideOnClose: true });
};
