var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import HttpStatus from "http-status-codes";
import { Platform } from "react-native";
import * as authActions from "../../../redux/auth/auth.actions";
import * as authApi from "../../../redux/auth/auth.api";
import * as authUtils from "../../../redux/auth/auth.utils";
import { getStore } from "../../../utils/redux/redux.utils";
import { cancelPendingCalls } from "../api.utils";
var isRefreshingAccessToken = false;
var waitingForRefreshingAccessTokenCompletion = [];
var waitForRefreshingAccessTokenCompletion = function () {
    return new Promise(function (resolve, reject) {
        waitingForRefreshingAccessTokenCompletion.push({ resolve: resolve, reject: reject });
    });
};
var pollAccessTokenRefreshCompletion = function (iteration) {
    if (iteration === void 0) { iteration = 0; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (iteration > 10) {
                        throw new Error("pollAccessTokenRefreshCompletion timeout after ".concat(iteration, " iterations"));
                    }
                    return [4 /*yield*/, new Promise(function (resolve) {
                            setTimeout(resolve, 1000);
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, authUtils.getAccessTokenRefreshIsInProgress()];
                case 2:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    return [4 /*yield*/, pollAccessTokenRefreshCompletion(iteration + 1)];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [2 /*return*/];
            }
        });
    });
};
var refreshAccessToken = function (refreshToken) { return __awaiter(void 0, void 0, void 0, function () {
    var data, unknownError_1, error_1, axiosError;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 11, , 13]);
                if (!(Platform.OS === "web")) return [3 /*break*/, 3];
                return [4 /*yield*/, authUtils.getAccessTokenRefreshIsInProgress()];
            case 1:
                if (!_d.sent()) return [3 /*break*/, 3];
                console.log("Refreshing access token already in progress on other tab. Polling for completion...");
                return [4 /*yield*/, pollAccessTokenRefreshCompletion()];
            case 2:
                _d.sent();
                return [2 /*return*/, true];
            case 3:
                if (!isRefreshingAccessToken) return [3 /*break*/, 5];
                console.log("Refreshing access token already in progress. Waiting for completion...");
                return [4 /*yield*/, waitForRefreshingAccessTokenCompletion()];
            case 4:
                _d.sent();
                return [2 /*return*/, true];
            case 5:
                // console.log("Refreshing access token started");
                isRefreshingAccessToken = true;
                return [4 /*yield*/, authUtils.setAccessTokenRefreshInProgress()];
            case 6:
                _d.sent();
                return [4 /*yield*/, authApi.refreshAccessToken(refreshToken)];
            case 7:
                data = (_d.sent()).data;
                cancelPendingCalls();
                // Store new tokens
                return [4 /*yield*/, authUtils.setAccessToken(data.accessToken)];
            case 8:
                // Store new tokens
                _d.sent();
                return [4 /*yield*/, authUtils.setRefreshToken(data.refreshToken)];
            case 9:
                _d.sent();
                (_a = getStore()) === null || _a === void 0 ? void 0 : _a.dispatch(authActions.refreshSuccess(data));
                isRefreshingAccessToken = false;
                return [4 /*yield*/, authUtils.setAccessTokenRefreshCompleted()];
            case 10:
                _d.sent();
                // Resolve & clear queue of pending calls
                waitingForRefreshingAccessTokenCompletion.forEach(function (completion) {
                    completion.resolve();
                });
                waitingForRefreshingAccessTokenCompletion = [];
                return [2 /*return*/, true];
            case 11:
                unknownError_1 = _d.sent();
                error_1 = unknownError_1;
                console.log("Refreshing access token failed with error: ", error_1);
                isRefreshingAccessToken = false;
                return [4 /*yield*/, authUtils.setAccessTokenRefreshCompleted()];
            case 12:
                _d.sent();
                // Reject & clear queue of pending calls
                waitingForRefreshingAccessTokenCompletion.forEach(function (completion) {
                    completion.reject(error_1);
                });
                waitingForRefreshingAccessTokenCompletion = [];
                axiosError = error_1;
                if (axiosError.isAxiosError &&
                    ((_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.status) !== HttpStatus.OK) {
                    cancelPendingCalls();
                    (_c = getStore()) === null || _c === void 0 ? void 0 : _c.dispatch({ type: "LOG_OUT" });
                }
                return [2 /*return*/, false];
            case 13: return [2 /*return*/];
        }
    });
}); };
// Interceptors
var success = function (response) { return response; };
var error = function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var refreshToken, success_1, newAccessToken, newConfig;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!(((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === HttpStatus.UNAUTHORIZED)) return [3 /*break*/, 4];
                console.log("".concat(HttpStatus.UNAUTHORIZED, " for request with base URL: ").concat(error.config.baseURL, ")"), error);
                return [4 /*yield*/, authUtils.getRefreshToken()];
            case 1:
                refreshToken = _e.sent();
                if (!refreshToken) {
                    cancelPendingCalls();
                    (_b = getStore()) === null || _b === void 0 ? void 0 : _b.dispatch({ type: "LOG_OUT" });
                    return [2 /*return*/, Promise.reject(error)];
                }
                return [4 /*yield*/, refreshAccessToken(refreshToken)];
            case 2:
                success_1 = _e.sent();
                if (!success_1) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, authUtils.getAccessToken()];
            case 3:
                newAccessToken = _e.sent();
                if (!newAccessToken) {
                    return [2 /*return*/, Promise.reject(error)];
                }
                newConfig = error.config;
                if (
                // TODO:
                ((_c = newConfig.baseURL) === null || _c === void 0 ? void 0 : _c.includes("serverless")) ||
                    (
                    // TODO:
                    // This is not the best way to check for the next api, but if the next api is connected via localhost the url does not contain 'next'
                    (_d = newConfig.baseURL) === null || _d === void 0 ? void 0 : _d.includes("v2"))) {
                    newConfig.headers.Authorization = "Bearer ".concat(newAccessToken);
                }
                else {
                    newConfig.headers.Authorization = newAccessToken;
                }
                return [2 /*return*/, Promise.resolve(axios(newConfig))];
            case 4: return [2 /*return*/, Promise.reject(error)];
        }
    });
}); };
export default {
    success: success,
    error: error,
};
