import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { subYears } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { ts } from "../../../../services";
import { EField } from "./schema.editContact.types";
var NOW = new Date();
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.Company] = {
            "ui:field": ERentioFormField.Text,
            title: ts.contactCompanyNameLabel(),
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: ts.system("address"),
        },
        _a[EField.VATNumber] = {
            "ui:field": ERentioFormField.Text,
            title: ts.contactCompanyVATNumberLabel(),
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("firstname"),
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("lastname"),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: ts.system("email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: ts.system("phone"),
        },
        _a[EField.Language] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: ts.system("language"),
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: ts.system("date_of_birth"),
            minDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MIN),
            maxDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MAX),
            formatSummary: function (formData) { return formData && formatDate(formData); },
        },
        _a[EField.PlaceOfBirth] = {
            "ui:field": ERentioFormField.Address,
            title: ts.system("place_of_birth"),
            placeholder: ts.addressBirthAddressPlaceholder(),
            lookupTypes: ["(cities)"],
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: ts.system("nationality"),
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: ts.system("identification"),
            //info: ts.system("identification.infotext"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": "rentio:field:text",
            title: ts.system("national_register_number.foreigner"),
        },
        _a[EField.BankAccounts] = {
            items: [
                {
                    "ui:field": "hidden",
                },
                {
                    "ui:field": ERentioFormField.Iban,
                    title: ts.bankAccountIbanLabel(),
                    placeholder: ts.bankAccountIbanPlaceholder(),
                },
                {
                    "ui:field": ERentioFormField.Text,
                    title: ts.bankAccountOwnerNameLabel(),
                },
            ],
        },
        _a);
};
