var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { compareAsc, startOfDay, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import groupBy from "lodash/groupBy";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import { createSelector } from "reselect";
import { REDUCER_KEY_CHAT } from "./chat.reducer";
import { EChatFetchType } from "./chat.types";
var NOW = new Date();
export var chatsSelector = function (state) {
    return getChats(state);
};
export var getMessagesRequest = function (state, ref) {
    return state[REDUCER_KEY_CHAT].messagesRequests[ref];
};
export var getIsChatExhausted = function (state, chatId) { var _a; return (_a = state[REDUCER_KEY_CHAT].chats[chatId]) === null || _a === void 0 ? void 0 : _a.isExhausted; };
export var hasMessagesForNotFetchedChats = function (state) {
    var chatIdsForMessages = Object.keys(state.chat.messages).map(function (chatId) {
        return parseInt(chatId, 0);
    });
    var chatIdsForFetchedChats = Object.keys(state.chat.chats).map(function (chatId) {
        return parseInt(chatId, 0);
    });
    return !!chatIdsForMessages.find(function (chatIdForMessage) { return !chatIdsForFetchedChats.includes(chatIdForMessage); });
};
export var messagesSelector = function (state) { return state.chat.messages; };
export var getChat = function (state, id) {
    return state.chat.chats[id];
};
export var getChats = function (state, chatsIdentifier) {
    var _a, _b, _c;
    if (chatsIdentifier === void 0) { chatsIdentifier = EChatFetchType.All; }
    return (_c = (_b = (_a = state.chat) === null || _a === void 0 ? void 0 : _a.chatsByIdentifier[chatsIdentifier]) === null || _b === void 0 ? void 0 : _b.chatIds) === null || _c === void 0 ? void 0 : _c.map(function (chatId) { return state.chat.chats[chatId]; }).filter(function (chat) { return !!chat; });
};
export var isFetchingChats = function (state, chatsIdentifier) {
    var _a, _b;
    return ((_b = (_a = state.chat) === null || _a === void 0 ? void 0 : _a.chatsByIdentifier[chatsIdentifier]) === null || _b === void 0 ? void 0 : _b.isFetching) || false;
};
export var fetchChatsError = function (state, chatsIdentifier) {
    var _a, _b;
    return (_b = (_a = state.chat) === null || _a === void 0 ? void 0 : _a.chatsByIdentifier[chatsIdentifier]) === null || _b === void 0 ? void 0 : _b.fetchError;
};
export var getMessagesSelector = function (state, chatId) {
    return state.chat.messages[chatId];
};
export var getMostRecentMessage = function (state, chatId) {
    var messages = state.chat.messages[chatId] || [];
    if (messages.length === 0) {
        return;
    }
    return messages[0];
};
export var getMostRecentMessageGeneral = function (state) {
    var messages = Object.keys(state.chat.messages).reduce(function (latestMessages, chatId) {
        var lastMessage = state.chat.messages[Number(chatId)][0];
        return (lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.sendBy)
            ? __spreadArray(__spreadArray([], latestMessages, true), [lastMessage], false) : latestMessages;
    }, []);
    if (messages.length === 0) {
        return;
    }
    var sortedBySendAt = messages.sort(function (a, b) {
        return compareAsc(new Date(b.sendAt), new Date(a.sendAt));
    });
    return __assign({}, sortedBySendAt[0]);
};
export var makeGetSortedChatsSelector = function () {
    return createSelector([chatsSelector, messagesSelector], function (chats, messages) {
        if (!chats) {
            return;
        }
        return reverse(chats.sort(function (a, b) {
            var messagesA = messages[a.id] || [];
            var messagesB = messages[b.id] || [];
            var messageA = messagesA.length > 0 ? messagesA[0] : a.lastMessage;
            var messageB = messagesB.length > 0 ? messagesB[0] : b.lastMessage;
            var sendAtA = messageA ? messageA.sendAt : NOW;
            var sendAtB = messageB ? messageB.sendAt : NOW;
            var dateCompare = compareAsc(sendAtA, sendAtB);
            if (dateCompare !== 0) {
                return dateCompare;
            }
            if (a.lastMessage && b.lastMessage) {
                var messageCompare = (a.lastMessage.text || "").localeCompare(b.lastMessage.text || "");
                return messageCompare;
            }
            return 0;
        }));
    });
};
export var getMessagesCount = function (state, chatId) {
    return (state.chat.messages[chatId] || []).length;
};
export var getIsTyping = function (state, chatId) {
    return state.chat.typing.includes(chatId);
};
export var makeGetMessagesDataSource = function () {
    return createSelector([getMessagesSelector, getIsTyping], function (messages, isTyping) {
        var sortedMessages = reverse(sortBy(messages, ["sendAt", "id"]));
        var groupedMessages = groupBy(sortedMessages, function (message) {
            return startOfDay(message.sendAt);
        });
        var dataSource = Object.keys(groupedMessages).map(function (date) {
            return { key: date, data: groupedMessages[date] };
        });
        if (isTyping) {
            dataSource.unshift({ data: ["typing"] });
        }
        return dataSource;
    });
};
export var makeGetUnreadChatsCount = function () {
    return createSelector([chatsSelector, messagesSelector], function (chats, messages) {
        var chatsCounter = (chats || []).reduce(function (accumulator, currentValue) {
            return (accumulator += currentValue.unreadCounter);
        }, 0);
        var messagesCounter = Object.keys(messages || {}).reduce(function (accumulator, chatId) {
            var foundChat = (chats || []).find(function (chat) { return chat.id === parseInt(chatId); });
            if (foundChat) {
                return accumulator;
            }
            return (accumulator += 1);
        }, 0);
        return chatsCounter + messagesCounter;
    });
};
