var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EPaymentRepetitionType, } from "@rentiohq/shared/dist/types/payment.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { compact, flatten } from "lodash";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.addLegalCase.types";
export var printUploadedFiles = function (value) {
    if (!value) {
        return undefined;
    }
    if (Array.isArray(value)) {
        return value
            .reduce(function (list, current) {
            if (current === null || current === void 0 ? void 0 : current.name) {
                list.push(current.name);
            }
            return list;
        }, [])
            .join(", ");
    }
    if (value.name) {
        return value.name;
    }
    return value.toString();
};
export var printDocumentNameSelector = function (state, formData) {
    var _a;
    if (!formData) {
        return undefined;
    }
    if (typeof formData === "string") {
        var document_1 = (_a = state.DOCUMENTS_V2.detail[formData]) === null || _a === void 0 ? void 0 : _a.entity;
        if (document_1) {
            return document_1.filename;
        }
        return getLocalizedText("summary.view.document");
    }
    return formData.name;
};
export var printDocumentNamesSelector = function (state, formData) {
    if (!Array.isArray(formData)) {
        return undefined;
    }
    return compact((formData || []).map(function (item) { return printDocumentNameSelector(state, item); })).join(", ");
};
export var manualLatePaymentsValueSelector = function (state, formData) {
    if (!formData) {
        return;
    }
    if (typeof formData !== "object") {
        return;
    }
    var values = Object.values(formData);
    var items = flatten(values.map(function (value) {
        var itemsToPush = [];
        var documentNames = printDocumentNamesSelector(state, value.documents);
        if (documentNames && (documentNames === null || documentNames === void 0 ? void 0 : documentNames.length) > 0) {
            itemsToPush.push(documentNames);
        }
        if (value.info && values.indexOf.length > 0) {
            itemsToPush.push(value.info);
        }
        return itemsToPush;
    }));
    return items.join(", ");
};
export var getInitialValues = function (extraData) {
    var _a;
    var leaseAgreementDocumentId = extraData.leaseAgreementDocumentId, rentDepositDocumentId = extraData.rentDepositDocumentId, propertyInspectionDocumentId = extraData.propertyInspectionDocumentId, proofOfPropertyInspectionRegistration = extraData.proofOfPropertyInspectionRegistration, proofOfRegistration = extraData.proofOfContractRegistration, legalCase = extraData.legalCase;
    var _b = legalCase || {}, manualContracts = _b.manualContracts, manualRentRegistrations = _b.manualRentRegistrations, manualRentDeposits = _b.manualRentDeposits, manualPropertyInspectionReports = _b.manualPropertyInspectionReports, damageDocumentIds = _b.damageDocumentIds, damageInfo = _b.damageInfo, extraDocumentIds = _b.extraDocumentIds, extraInfo = _b.extraInfo, payments = _b.payments, manualPayments = _b.manualPayments;
    var previouslyAddedPayments = manualPayments && manualPayments.length > 0
        ? manualPayments.reduce(function (all, payment, index) {
            var _a;
            return __assign(__assign({}, all), (_a = {}, _a[getManualPaymentsKey(index)] = {
                documents: payment.documentIds,
                info: payment.info,
            }, _a));
        }, {})
        : undefined;
    var initialValues = (_a = {},
        _a[EField.LeaseAgreement] = manualContracts && manualContracts.length > 0
            ? manualContracts
            : leaseAgreementDocumentId,
        _a[EField.ContractRegistrationProof] = manualRentRegistrations && manualRentRegistrations.length > 0
            ? manualRentRegistrations
            : proofOfRegistration,
        _a[EField.ActiveRentalDeposit] = manualRentDeposits && manualRentDeposits.length > 0
            ? manualRentDeposits
            : rentDepositDocumentId,
        _a[EField.DamageDocuments] = damageDocumentIds ? damageDocumentIds : [],
        _a[EField.DamageInfo] = damageInfo ? damageInfo : undefined,
        _a[EField.ExtraDocuments] = extraDocumentIds ? extraDocumentIds : [],
        _a[EField.ExtraInfo] = extraInfo ? extraInfo : undefined,
        _a[EField.LatePayments] = payments ? payments : [],
        _a[EField.PreviouslyAddedLatePayments] = previouslyAddedPayments !== null && previouslyAddedPayments !== void 0 ? previouslyAddedPayments : undefined,
        _a[EField.PropertyInspections] = manualPropertyInspectionReports &&
            manualPropertyInspectionReports.length > 0
            ? manualPropertyInspectionReports
            : propertyInspectionDocumentId,
        _a[EField.PropertyInspectionRegistrationProof] = proofOfPropertyInspectionRegistration,
        _a);
    return initialValues;
};
export var getManualPaymentsKey = function (index) {
    return "manual-payment-".concat(index + 1);
};
export var getLatePaymentOptions = function (latePayments) {
    return latePayments.map(function (p) {
        var amount = p.amount, originalAmount = p.originalAmount;
        return {
            id: p.id,
            value: "".concat(p.payoutReference, " - ").concat(formatCurrency(amount === 0 ? amount : originalAmount), " - ").concat(getPeriod(p)),
        };
    });
};
export var getPeriod = function (paymentRequest, dateFormat) {
    var startedAt = paymentRequest.startedAt, repetitionType = paymentRequest.repetitionType, endedAt = paymentRequest.endedAt;
    if (!startedAt) {
        return formatDate(paymentRequest.dueDateAt, dateFormat);
    }
    switch (repetitionType) {
        case EPaymentRepetitionType.Once:
            return formatDate(startedAt, dateFormat);
        default:
            return "".concat(formatDate(startedAt, dateFormat), " - ").concat(formatDate(endedAt, dateFormat));
    }
};
export var getRentioLegalDocumentsWhereFilter = function (extraData) {
    if (extraData.contract) {
        return {
            propertyId: { eq: extraData.contract.propertyId },
        };
    }
    return {};
};
