var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clone from "ramda/es/clone";
import isEmpty from "ramda/es/isEmpty";
import path from "ramda/es/path";
export var isObject = function (obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
};
export var removeEmpty = function (obj, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.removeNull, removeNull = _c === void 0 ? true : _c;
    if (!obj) {
        return obj;
    }
    Object.entries(obj).forEach(function (_a) {
        var key = _a[0], val = _a[1];
        if (val && typeof val === "object") {
            removeEmpty(val);
        }
        if (val === null && removeNull) {
            delete obj[key];
        }
        if (isEmpty(val) || val === undefined) {
            delete obj[key];
        }
    });
    return obj;
};
export var renameKeys = function (keysMap, obj) {
    return Object.keys(obj).reduce(function (acc, key) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[keysMap[key] || key] = obj[key], _a)));
    }, {});
};
export var isEmptyDeep = function (obj) {
    var clonedObj = clone(obj);
    var cleaned = removeEmpty(clonedObj);
    return isEmpty(cleaned);
};
export var hasPropertyAtPath = function (obj, pathString) {
    if (!obj)
        return !!obj;
    var pathArray = pathString.split(".");
    return !!path(pathArray, obj);
};
export var filterKeys = function (raw, keys) {
    return Object.keys(raw)
        .filter(function (key) { return keys.includes(key); })
        .reduce(function (obj, key) {
        obj[key] = raw[key];
        return obj;
    }, {});
};
export var removeKeys = function (raw, keys) {
    return Object.keys(raw)
        .filter(function (key) { return !keys.includes(key); })
        .reduce(function (obj, key) {
        obj[key] = raw[key];
        return obj;
    }, {});
};
// Based on lodash deepOmit
export var nullifyValues = function (obj) {
    var o = {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        o[key] =
            // eslint-disable-next-line no-nested-ternary
            value === undefined
                ? null
                : isObject(value)
                    ? nullifyValues(value)
                    : value;
    }
    return o;
};
