import merge from "lodash/merge";
import { getCreateDefaultState, registerCreateActions, } from "../generic/create.reducer";
import { getDeleteDefaultState, registerDeleteActions, } from "../generic/delete.reducer";
import { getDetailDefaultState, registerDetailActions, } from "../generic/detail.reducer";
import { getPagedDefaultState, registerPagedActions, } from "../generic/paged.reducer";
import { getUpdateDefaultState, registerUpdateActions, } from "../generic/update.reducer";
export var getDefaultStateAll = function () {
    return merge({}, getCreateDefaultState(), getDeleteDefaultState(), getDetailDefaultState(), getPagedDefaultState(), getUpdateDefaultState());
};
export var registerActions = function (params) {
    registerCreateActions(params);
    registerDeleteActions(params);
    registerDetailActions(params);
    registerPagedActions(params);
    registerUpdateActions(params);
};
