var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-ignore
import Ajv from "ajv";
import Engine from "json-rules-engine-simplified";
import debounce from "lodash/debounce";
import toPath from "lodash/toPath";
import clone from "ramda/es/clone";
import equals from "ramda/es/equals";
import isEmpty from "ramda/es/isEmpty";
import path from "ramda/es/path";
import React from "react";
import JsonSchemaForm from "react-jsonschema-form";
import applyRules from "react-jsonschema-form-conditionals";
import { JsonSchemaRender } from "../../components";
import * as t from "../../services/translationService";
import { iban, phone } from "../../utils";
import Banner from "../Banner";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import * as S from "./Form.styled";
import { ArrayFieldTemplate, FieldTemplate, ObjectFieldTemplate, } from "./components";
import { fields } from "./components/fields";
import { widgets } from "./components/widgets";
import { parseSchema, validateYupSchema, yupToFormErrors } from "./utils";
export var Form = React.forwardRef(function (initialProps, ref) {
    var id = initialProps.id, formSchema = initialProps.schema, onSubmit = initialProps.onSubmit, onStepSubmit = initialProps.onStepSubmit, onChange = initialProps.onChange, validationScheme = initialProps.validationScheme, _a = initialProps.initialData, initialData = _a === void 0 ? {} : _a, _b = initialProps.autoSave, autoSave = _b === void 0 ? true : _b, _c = initialProps.showAllSteps, showAllSteps = _c === void 0 ? false : _c, _d = initialProps.activeStep, activeStep = _d === void 0 ? 0 : _d, _e = initialProps.showActions, showActions = _e === void 0 ? true : _e, _f = initialProps.fullWidth, fullWidth = _f === void 0 ? false : _f, _g = initialProps.liveValidate, liveValidate = _g === void 0 ? false : _g, _h = initialProps.submitLabel, submitLabel = _h === void 0 ? t.system("save") : _h, props = __rest(initialProps, ["id", "schema", "onSubmit", "onStepSubmit", "onChange", "validationScheme", "initialData", "autoSave", "showAllSteps", "activeStep", "showActions", "fullWidth", "liveValidate", "submitLabel"]);
    // Set a name for the form
    var formName = "form-".concat(id);
    var _j = React.useState({}), schema = _j[0], setSchema = _j[1];
    var _k = React.useState({}), uiSchema = _k[0], setUiSchema = _k[1];
    var _l = React.useState([activeStep]), currentStep = _l[0], setStep = _l[1];
    var _m = React.useState([
        activeStep,
        0,
    ]), highestStep = _m[0], setHighestStep = _m[1];
    var _o = React.useState(initialData), formData = _o[0], setFormData = _o[1];
    var currentStepIndex = currentStep[0], currentSubStepIndex = currentStep[1];
    var _p = React.useState(), returnStep = _p[0], setReturnStep = _p[1];
    React.useEffect(function () {
        if (initialData && !isEmpty(initialData)) {
            setFormData(initialData);
        }
    }, [initialData]);
    React.useEffect(function () {
        setStep([activeStep]);
        setHighestStep([activeStep, 0]);
    }, [activeStep]);
    React.useEffect(function () {
        if (autoSave) {
            var maybeState = window.sessionStorage.getItem(formName);
            var maybeStep = window.sessionStorage.getItem("".concat(formName, "-step"));
            // Persist formData in localStorage
            if (maybeState && maybeState !== null) {
                setFormData(JSON.parse(maybeState));
            }
            if (maybeStep && maybeStep !== null) {
                setStep(JSON.parse(maybeStep));
            }
        }
    }, [autoSave, formName]);
    React.useEffect(function () {
        var _a, _b;
        var clonedSchema = clone(formSchema);
        var parsedSchema = parseSchema(clonedSchema);
        setSchema(parsedSchema[0]);
        setUiSchema(parsedSchema[1]);
        // @ts-ignore
        if (((_b = (_a = parsedSchema[0].steps) === null || _a === void 0 ? void 0 : _a[currentStep[0]]) === null || _b === void 0 ? void 0 : _b.steps) &&
            !currentSubStepIndex) {
            setStep([0, 0]);
        }
    }, [currentStep, currentSubStepIndex, formSchema]);
    var handleGoToStep = React.useCallback(function (_a) {
        var stepIndex = _a[0], subStepIndex = _a[1];
        if (!subStepIndex && schema.steps[stepIndex].steps) {
            setStep([stepIndex, 0]);
        }
        else {
            setStep([stepIndex, subStepIndex]);
        }
    }, [schema.steps]);
    React.useEffect(function () {
        if (schema.type !== "steps")
            return;
        var currentStepIndex = currentStep[0], currentSubStepIndex = currentStep[1];
        var highestStepIndex = highestStep[0], highestSubStepIndex = highestStep[1];
        if (currentStepIndex > highestStepIndex ||
            (currentSubStepIndex &&
                currentStepIndex >= highestStepIndex &&
                currentSubStepIndex > highestSubStepIndex)) {
            setHighestStep([currentStepIndex, currentSubStepIndex]);
        }
        if (autoSave) {
            window.sessionStorage.setItem("".concat(formName, "-step"), JSON.stringify(currentStep));
        }
    }, [
        autoSave,
        currentStep,
        formName,
        handleGoToStep,
        highestStep,
        schema,
        showAllSteps,
    ]);
    var saveForm = debounce(function (data) {
        window.sessionStorage.setItem(formName, JSON.stringify(data));
    }, 300);
    var setNewFormData = React.useCallback(function (data) {
        var currentFormData = ref && ref.current
            ? __assign({}, ref.current.state.formData) : {};
        var newFormData = __assign(__assign({}, currentFormData), data);
        setFormData(newFormData);
        if (autoSave) {
            saveForm(newFormData);
        }
    }, [autoSave, ref, saveForm]);
    var handleSummaryGoToStep = function (_a) {
        var stepIndex = _a[0], subStepIndex = _a[1];
        setReturnStep(currentStep);
        handleGoToStep([stepIndex, subStepIndex]);
    };
    var handleChange = function (_a) {
        var currentFormData = _a.formData;
        if (!equals(currentFormData, formData)) {
            onChange === null || onChange === void 0 ? void 0 : onChange(currentFormData);
            if (!autoSave) {
                return;
            }
            saveForm(currentFormData);
        }
    };
    var handlePrevious = function () {
        var previousStepIndex = currentStepIndex - 1;
        // Does the current step has sub-steps?
        // @ts-ignore
        if (schema.steps[currentStepIndex].steps) {
            var previousSubStepIndex = currentSubStepIndex - 1;
            if (previousSubStepIndex >= 0) {
                setStep([currentStepIndex, previousSubStepIndex]);
            }
            else {
                if (schema.steps[previousStepIndex].steps) {
                    setStep([
                        previousStepIndex,
                        schema.steps[previousStepIndex].steps.length - 1,
                    ]);
                }
                else {
                    setStep([previousStepIndex]);
                }
            }
        }
        else {
            // Does the previous step has sub-steps?
            if (schema.steps[previousStepIndex].steps) {
                setStep([
                    previousStepIndex,
                    schema.steps[previousStepIndex].steps.length - 1,
                ]);
            }
            else {
                setStep([previousStepIndex]);
            }
        }
    };
    var handleGlobalSubmit = function (_a) {
        var data = _a.formData;
        onSubmit(data);
        window.sessionStorage.removeItem(formName);
        window.sessionStorage.removeItem("".concat(formName, "-step"));
    };
    var handleSubmit = function (data) {
        if (data === void 0) { data = {}; }
        var currentStepIndex = currentStep[0], currentSubStepIndex = currentStep[1];
        var newFormData = __assign(__assign({}, formData), data.formData);
        // are there steps?
        if (schema.type !== "steps") {
            handleGlobalSubmit({ formData: newFormData });
            setFormData(newFormData);
            return;
        }
        if (returnStep) {
            setStep(returnStep);
            setReturnStep(undefined);
        }
        else {
            // Does the current step has sub-steps?
            // @ts-ignore
            var nextStepIndex = currentStepIndex + 1;
            if (schema.steps[currentStepIndex].steps) {
                // Is the current sub-step the last one?
                // @ts-ignore
                if (schema.steps[currentStepIndex].steps.length - 1 ===
                    currentSubStepIndex) {
                    if (schema.steps[nextStepIndex].steps) {
                        setStep([nextStepIndex, 0]);
                    }
                    else {
                        setStep([nextStepIndex]);
                    }
                }
                else {
                    setStep([currentStepIndex, currentSubStepIndex + 1]);
                }
            }
            else {
                // Is the current step the last one?
                if (schema.steps.length - 1 === currentStepIndex) {
                    handleGlobalSubmit({ formData: newFormData });
                }
                else {
                    if (schema.steps[nextStepIndex].steps) {
                        setStep([nextStepIndex, 0]);
                    }
                    else {
                        setStep([nextStepIndex]);
                    }
                    // setStep([nextStep, currentSubStepIndex])
                }
            }
        }
        setFormData(newFormData);
        if (onStepSubmit) {
            onStepSubmit(newFormData);
        }
    };
    var renderBackButton = (_jsx(Button, __assign({ type: "button", onClick: handlePrevious }, { children: t.system("previous") })));
    var currentSchema = 
    // eslint-disable-next-line no-nested-ternary
    schema.type === "steps"
        ? currentSubStepIndex !== undefined && currentSubStepIndex !== null
            ? schema.steps[currentStepIndex].steps[currentSubStepIndex]
            : schema.steps[currentStepIndex]
        : schema;
    var transformErrors = function (errors) {
        return errors
            .map(function (error) {
            switch (error.name) {
                case "required":
                    error.message = t.formErrorRequired();
                    break;
                case "format":
                    error.message = t.formErrorFormat(error.params.format);
                    break;
                case "minLength":
                    error.message = t.formErrorMinLength(error.params);
                    break;
                case "minItems":
                    error.message = t.formErrorMinItems(error.params);
                    break;
                case "type":
                    switch (error.params.type) {
                        case "number":
                            error.message = t.formErrorNumber();
                            break;
                    }
                    break;
            }
            return error;
        })
            .filter(function (error) { return error.params.type !== "object"; });
    };
    var isValidPhone = function (data) {
        return phone.phoneValidate(data);
    };
    var isValidIBAN = function (data) {
        return !!data && iban.IBAN.isValid(data);
    };
    var handleValidate = function (formData, errors) {
        var ajv = new Ajv({ allErrors: true, jsonPointers: true });
        // Phone validation
        ajv.addKeyword("validPhone", {
            validate: function (_schema, data) {
                return isValidPhone(data);
            },
            errors: true,
        });
        // IBAN validation
        ajv.addKeyword("validIBAN", {
            validate: function (_schema, data) {
                return isValidIBAN(data);
            },
            errors: true,
        });
        var validate = ajv.compile(currentSchema);
        // debug.log({ isValid, formData })
        // if (!isValid) {
        //   errors.addError(t.formError('general'))
        // }
        if (validate.errors && validate.errors.length > 0) {
            validate.errors.forEach(function (error) {
                var errorPath = error.dataPath.slice(1).split("/");
                var errorMsg = t.formError("general");
                // Only add errors for array items..
                if (!isNaN(errorPath[errorPath.length - 1])) {
                    errorPath.splice(-1, 1);
                    errorMsg = "".concat(error.dataPath.slice(1), ":").concat(t.formError("general"));
                }
                var SchemaError = path(errorPath, errors);
                switch (error.keyword) {
                    case "validPhone":
                        errorMsg = t.formErrorInvalidPhone();
                        break;
                    case "validIBAN":
                        errorMsg = t.formErrorInvalidIBAN();
                        break;
                }
                SchemaError === null || SchemaError === void 0 ? void 0 : SchemaError.addError(errorMsg);
            });
        }
        if (validationScheme) {
            try {
                validateYupSchema(formData, validationScheme);
                // return errors
            }
            catch (yupErrors) {
                var fieldErrors_1 = yupToFormErrors(yupErrors);
                Object.keys(fieldErrors_1).forEach(function (key) {
                    var keyPath = toPath(key);
                    var SchemaError = path(keyPath, errors);
                    if (SchemaError) {
                        // @ts-ignore
                        SchemaError.addError(fieldErrors_1[key]);
                    }
                    else {
                        errors.addError(fieldErrors_1[key]);
                    }
                });
            }
        }
        return errors;
    };
    if (isEmpty(schema) || !currentSchema) {
        return null;
    }
    var FormElement = JsonSchemaForm;
    if (currentSchema.rules && !isEmpty(currentSchema.rules)) {
        FormElement = applyRules(currentSchema, uiSchema, currentSchema.rules, Engine)(JsonSchemaForm);
    }
    if (currentSchema.type === "summary") {
        return (_jsxs(JsonSchemaForm, __assign({ schema: {}, onSubmit: handleGlobalSubmit, formData: formData }, { children: [_jsx(JsonSchemaRender, { formData: formData, schema: schema, goToStep: handleSummaryGoToStep }), _jsx(S.FormActions, { children: _jsxs(ButtonGroup, { children: [renderBackButton, _jsx(Button, __assign({ appearance: "primary", type: "submit" }, { children: submitLabel }))] }) })] })));
    }
    var errorList = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return (_jsx(Banner, { title: t.formErrorGeneral(), variation: "error", hasDismiss: false, icon: "alertDiamond" }));
    };
    var renderContent = function () {
        if (props.children) {
            return props.children;
        }
        if (showActions) {
            return (_jsx(S.FormActions, { children: _jsxs(ButtonGroup, { children: [currentStepIndex > 0 || currentSubStepIndex > 0
                            ? renderBackButton
                            : null, _jsx(Button, __assign({ appearance: "primary", type: "submit" }, { children: schema.type === "steps" ? t.system("next") : submitLabel }))] }) }));
        }
        return null;
    };
    return (_jsx(_Fragment, { children: _jsx(FormElement, __assign({ className: fullWidth ? "form-fullWidth" : undefined, idPrefix: formName, schema: currentSchema, onSubmit: handleSubmit, onChange: handleChange, formData: formData, validate: handleValidate, uiSchema: uiSchema, FieldTemplate: FieldTemplate, ObjectFieldTemplate: ObjectFieldTemplate, ArrayFieldTemplate: ArrayFieldTemplate, transformErrors: transformErrors, noHtml5Validate: true, showErrorList: true, ErrorList: errorList, liveValidate: liveValidate, 
            // @ts-ignore
            widgets: widgets, 
            // @ts-ignore
            fields: fields, formContext: { formData: formData, setFormData: setNewFormData }, autocomplete: "off", ref: ref }, { children: renderContent() })) }));
});
