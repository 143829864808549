import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { compact } from "lodash";
import React from "react";
import * as contactSelectors from "../../../redux/contact/contact.selectors";
import { areContactFieldsValid, hasValue, } from "../../../redux/form/form.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import { getStore } from "../../../utils/redux/redux.utils";
import { ERentioFormField } from "../Form";
export var FORM_FIELDS_WITH_DATA = [
    ERentioFormField.Array,
    ERentioFormField.ContactSelectInlineV2,
    ERentioFormField.Date,
    ERentioFormField.DocumentSelectInline,
    ERentioFormField.DropdownSelect,
    ERentioFormField.Email,
    ERentioFormField.IbanSelectInline,
    ERentioFormField.InlineSelect,
    ERentioFormField.ManualLatePaymentsSelect,
    ERentioFormField.Number,
    ERentioFormField.Phone,
    ERentioFormField.PhoneOrEmail,
    ERentioFormField.PropertySelectInline,
    ERentioFormField.SegmentedControl,
    ERentioFormField.Switch,
    ERentioFormField.Text,
];
export var customValidateErrorsForSchema = function (params) {
    var uiSchema = params.uiSchema, validate = params.validate, formData = params.formData;
    // Create mock formValidation object
    var formValidation = {};
    Object.keys(uiSchema).forEach(function (field) {
        var errors = [];
        formValidation[field] = {
            errors: errors,
            addError: function (newError) {
                errors.push(newError);
            },
        };
    });
    var errorsFromValidate = validate(formData, 
    // @ts-ignore
    formValidation);
    return errorsFromValidate;
};
var customValidateErrorsForField = function (params) {
    var _a;
    var uiSchema = params.uiSchema, validate = params.validate, formData = params.formData, fieldKey = params.fieldKey;
    return (((_a = customValidateErrorsForSchema({
        uiSchema: uiSchema,
        validate: validate,
        formData: formData,
    })[fieldKey]) === null || _a === void 0 ? void 0 : _a.errors) || []);
};
var getErrorForField = function (params) {
    var _a;
    var title = params.title, isRequired = params.isRequired, value = params.value, validate = params.validate, uiSchema = params.uiSchema, fieldKey = params.fieldKey, formData = params.formData;
    var uiField = uiSchema[fieldKey];
    var fieldType = uiField["ui:field"];
    if (fieldType === ERentioFormField.ContactSelectInlineV2) {
        var _b = uiField.withSummaryValidation, withSummaryValidation = _b === void 0 ? true : _b, requiredContactFieldsSchema_1 = uiField.requiredContactFieldsSchema, getRequiredContactFieldsSchema_1 = uiField.getRequiredContactFieldsSchema, requiredCompanyFieldsSchema_1 = uiField.requiredCompanyFieldsSchema, getRequiredCompanyFieldsSchema_1 = uiField.getRequiredCompanyFieldsSchema;
        if (withSummaryValidation &&
            value &&
            (requiredContactFieldsSchema_1 ||
                getRequiredContactFieldsSchema_1 ||
                requiredCompanyFieldsSchema_1 ||
                getRequiredCompanyFieldsSchema_1)) {
            var state_1 = (_a = getStore()) === null || _a === void 0 ? void 0 : _a.getState();
            if (state_1) {
                var accountIds = value;
                var contacts = compact(accountIds.map(function (accountId) {
                    return contactSelectors.getContact(state_1, undefined, accountId);
                }));
                var contactsAreValid_1 = true;
                contacts.forEach(function (contact) {
                    var requiredContactFieldsSchemaParsed = getRequiredContactFieldsSchema_1 && contact
                        ? getRequiredContactFieldsSchema_1(contact)
                        : requiredContactFieldsSchema_1;
                    var requiredCompanyFieldsSchemaParsed = getRequiredCompanyFieldsSchema_1 && contact
                        ? getRequiredCompanyFieldsSchema_1(contact)
                        : requiredCompanyFieldsSchema_1;
                    if (!areContactFieldsValid({
                        contact: contact,
                        requiredContactFieldsSchema: requiredContactFieldsSchemaParsed,
                        requiredCompanyFieldsSchema: requiredCompanyFieldsSchemaParsed,
                    })) {
                        contactsAreValid_1 = false;
                    }
                });
                if (!contactsAreValid_1) {
                    return getLocalizedText("form.error.invalid_contact_fields");
                }
            }
        }
    }
    if (validate) {
        var customErrors = customValidateErrorsForField({
            uiSchema: uiSchema,
            validate: validate,
            formData: formData,
            fieldKey: fieldKey,
        });
        if (customErrors[0]) {
            return getLocalizedText(customErrors[0], {
                field: title,
            });
        }
    }
    if (isRequired && !hasValue(value)) {
        return getLocalizedText("system.form.error.required.field", {
            field: title,
        });
    }
    return undefined;
};
var mapField = function (data, stepIndex, fieldKey, renderField, showHeader) {
    var _a, _b;
    var mergedPartialFormData = data.mergedPartialFormData, _c = data.schemas, schemas = _c === void 0 ? [] : _c;
    var _d = schemas[stepIndex], schemaGenerator = _d.schema, uiSchema = _d.uiSchema, validate = _d.validate;
    var disabled = uiSchema.disabledOnSummary || false;
    var uiField = uiSchema[fieldKey];
    var fieldType = uiField["ui:field"];
    // Check if the field is has the possibility to display data
    if (!FORM_FIELDS_WITH_DATA.includes(fieldType)) {
        return;
    }
    var title = uiField.summaryTitle || uiField.title;
    var summaryTitle = uiSchema["rentio:summaryTitle"];
    if (uiField.getTitle) {
        title = uiField.getTitle(mergedPartialFormData);
    }
    var value = mergedPartialFormData[fieldKey];
    if (uiField.formatSummary) {
        value = uiField.formatSummary(value, mergedPartialFormData);
    }
    if (uiField.hideOnSummaryIfEmpty && (value === undefined || value === null)) {
        return;
    }
    if (uiField.shouldShowOnSummary &&
        !uiField.shouldShowOnSummary(mergedPartialFormData)) {
        return;
    }
    var schema = schemaGenerator(mergedPartialFormData);
    var _e = schema.required, required = _e === void 0 ? [] : _e, properties = schema.properties;
    if (
    // @ts-ignore
    properties[fieldKey].showIf &&
        // @ts-ignore
        !properties[fieldKey].showIf(mergedPartialFormData)) {
        return;
    }
    var isRequired = required.includes(fieldKey) ||
        (
        // @ts-ignore
        (_b = (_a = schema.properties[fieldKey]).requiredIf) === null || _b === void 0 ? void 0 : _b.call(_a, mergedPartialFormData));
    var error = getErrorForField({
        fieldKey: fieldKey,
        formData: mergedPartialFormData,
        isRequired: isRequired,
        title: title,
        uiSchema: uiSchema,
        validate: validate,
        value: value,
    });
    var formattedValue = value || "-";
    return renderField(stepIndex, fieldKey, summaryTitle || title || "-", formattedValue, showHeader, uiField.valueSelector, error, disabled);
};
var mapSchema = function (data, stepIndex, renderSchemaHeader, renderField) {
    var mergedPartialFormData = data.mergedPartialFormData, _a = data.schemas, schemas = _a === void 0 ? [] : _a;
    var _b = schemas[stepIndex], schemaGenerator = _b.schema, uiSchema = _b.uiSchema, showIf = _b.showIf;
    if (showIf && !showIf(mergedPartialFormData)) {
        return;
    }
    var schemaTitle = uiSchema["rentio:title"];
    var summaryTitle = uiSchema["rentio:summaryTitle"];
    var schema = schemaGenerator(mergedPartialFormData);
    var fieldKeys = Object.keys(schema.properties || {});
    var visibleFieldLength = fieldKeys
        .filter(function (key) {
        if (!schema.properties) {
            return false;
        }
        //@ts-ignore
        if (!schema.properties[key].showIf) {
            return true;
        }
        //@ts-ignore
        return schema.properties[key].showIf(mergedPartialFormData);
    })
        .map(function (key) {
        return uiSchema[key]["ui:field"];
    })
        .filter(function (key) { return FORM_FIELDS_WITH_DATA.includes(key); }).length;
    var showHeader = visibleFieldLength > 1;
    return (_jsxs(React.Fragment, { children: [renderSchemaHeader(stepIndex, summaryTitle || schemaTitle || "-", showHeader), fieldKeys.map(function (fieldKey, fieldIndex) { return (_jsx(React.Fragment, { children: mapField(data, stepIndex, fieldKey, renderField, showHeader) }, fieldIndex)); })] }, stepIndex));
};
export var renderSummary = function (data, renderSchemaHeader, renderField) { return (_jsx(_Fragment, { children: (data.schemas || []).map(function (_, index) {
        return mapSchema(data, index, renderSchemaHeader, renderField);
    }) })); };
