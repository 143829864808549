import { createForm, updatePartialFormData, } from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { mergedPartialFormDataSelector } from "@rentiohq/shared-frontend/dist/redux/form/form.selectors";
import { connect } from "react-redux";
import MultiStepForm from "./MultiStepForm";
var mapStateToProps = function (state, _a) {
    var formId = _a.formId, schemas = _a.schemas, isPerformingSubmitActionSelector = _a.isPerformingSubmitActionSelector, performingSubmitActionResultSelector = _a.performingSubmitActionResultSelector, performingSubmitActionErrorSelector = _a.performingSubmitActionErrorSelector;
    var formState = state.form[formId];
    var showErrors = formState ? formState.showErrors : [];
    return {
        schemas: schemas,
        partialData: mergedPartialFormDataSelector(state, formId),
        isPerformingSubmitAction: isPerformingSubmitActionSelector
            ? isPerformingSubmitActionSelector(state)
            : false,
        performingSubmitActionResult: performingSubmitActionResultSelector
            ? performingSubmitActionResultSelector(state)
            : undefined,
        performingSubmitActionError: performingSubmitActionErrorSelector
            ? performingSubmitActionErrorSelector(state)
            : undefined,
        showErrors: showErrors,
    };
};
var mapDispatchToProps = function (dispatch, props) { return ({
    submitAction: function (formData) {
        if (props.submitActionCreator) {
            dispatch(props.submitActionCreator(formData, dispatch));
        }
    },
    createForm: function (payload) {
        dispatch(createForm(payload));
    },
    updateFormData: function (_a) {
        var fieldName = _a.fieldName, fieldData = _a.fieldData, _b = _a.stepIndex, stepIndex = _b === void 0 ? -1 : _b;
        var formId = props.formId;
        dispatch(updatePartialFormData({
            formId: formId,
            stepIndex: stepIndex,
            fieldName: fieldName,
            fieldData: fieldData,
        }));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MultiStepForm);
