var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var StyledStackItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  min-width: 0;\n  ", "\n"], ["\n  flex: 0 0 auto;\n  min-width: 0;\n  ", "\n"])), function (_a) {
    var hasFill = _a.hasFill;
    return hasFill && "flex: 1 1 auto;";
});
var templateObject_1;
