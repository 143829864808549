var modals = {};
var triggers = [];
export var registerTrigger = function (trigger) {
    triggers.push(trigger);
};
// Manage modals
export var getModals = function () {
    return modals;
};
export var showModal = function (id, modal) {
    triggers.forEach(function (trigger) {
        modals[id] = modal;
        trigger.didShowModal({ id: id, modals: modals });
    });
};
// export const updateModal = (id: string, modal: React.ReactNode) => {
//   triggers.forEach(trigger => {
//     modals[id] = modal;
//     trigger.didShowModal({ id, modals });
//   });
// };
export var closeModal = function (id) {
    triggers.forEach(function (trigger) {
        delete modals[id];
        trigger.didCloseModal({ id: id, modals: modals });
    });
};
