var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from "../../../Icon";
import * as S from "../../styled/OptionWidget.styled";
var selectValue = function (value, selected, all) {
    var at = all.indexOf(value);
    var updated = selected
        .slice(0, at)
        .concat(value, selected.slice(at));
    // As inserting values at predefined index positions doesn't work with empty
    // arrays, we need to reorder the updated selection to match the initial order
    return updated.sort(function (a, b) {
        if (all.indexOf(a) > all.indexOf(b)) {
            return 1;
        }
        else if (all.indexOf(a) < all.indexOf(b)) {
            return -1;
        }
        else {
            return 0;
        }
    });
};
var deselectValue = function (value, selected) {
    return selected.filter(function (v) { return v !== value; });
};
export var CheckboxesWidget = function (props) {
    var id = props.id, disabled = props.disabled, options = props.options, value = props.value, autofocus = props.autofocus, readonly = props.readonly, onChange = props.onChange;
    var enumOptions = options.enumOptions, enumDisabled = options.enumDisabled;
    var hasError = props.rawErrors && props.rawErrors.length > 0;
    return (_jsx(S.OptionList, __assign({ id: id }, { children: enumOptions.map(function (option, index) {
            var checked = value.indexOf(option.value) !== -1;
            var itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
            var handleChange = function (event) {
                var all = enumOptions.map(function (_a) {
                    var value = _a.value;
                    return value;
                });
                if (event.target.checked) {
                    onChange(selectValue(option.value, value, all));
                }
                else {
                    onChange(deselectValue(option.value, value));
                }
            };
            var checkbox = (_jsx("input", { type: "checkbox", id: "".concat(id, "_").concat(index), checked: checked, disabled: disabled || itemDisabled || readonly, autoFocus: autofocus && index === 0, onChange: handleChange }));
            return (_jsx(S.OptionWrapper, __assign({ isCard: false }, { children: _jsxs(S.Option, __assign({ isChecked: checked, hasError: hasError, isDisabled: disabled || readonly ? true : false }, { children: [_jsxs(S.Checkbox, __assign({ isChecked: checked, hasError: hasError }, { children: [_jsx(Icon, { source: "checkboxOutline" }), checked && _jsx(Icon, { source: "checkboxMark" }), checkbox] })), _jsx(S.OptionContent, { children: option.label })] })) }), index));
        }) })));
};
