import { getCreateSelectors } from "./create.selectors";
import { getDeleteSelectors } from "./delete.selectors";
import { getDetailSelectors } from "./detail.selectors";
import { getPagedSelectors } from "./paged.selectors";
import { getUpdateSelectors } from "./update.selectors";
export var getAllSelectors = function (
// TODO: Fix any
getBase) { return ({
    create: getCreateSelectors(getBase),
    deleteObject: getDeleteSelectors(getBase),
    detail: getDetailSelectors(getBase),
    paged: getPagedSelectors(getBase),
    update: getUpdateSelectors(getBase),
}); };
