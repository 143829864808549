var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Field as FormikField, getIn } from "formik";
import React from "react";
import Icon from "../../../Icon";
import TextField from "../../../TextField";
export var PasswordField = function (_a) {
    var name = _a.name, type = _a.type, rest = __rest(_a, ["name", "type"]);
    var _b = React.useState(true), passwordHidden = _b[0], setPasswordHidden = _b[1];
    var togglePasswordHidden = function () { return setPasswordHidden(!passwordHidden); };
    var render = function (_a) {
        var field = _a.field, _b = _a.form, touched = _b.touched, errors = _b.errors;
        var error = getIn(errors, name);
        var touch = getIn(touched, name);
        return (_jsx(TextField, __assign({ label: name, id: name, error: touch && error && error }, field, rest, { type: passwordHidden ? "password" : "text", floatIcon: _jsx(Icon, { source: passwordHidden ? "view" : "viewOff" }), floatIconClick: togglePasswordHidden })));
    };
    return _jsx(FormikField, { name: name, render: render });
};
