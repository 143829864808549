import { useNavigate } from "react-router-dom";
export var useNavigateAfterSuccess = function () {
    var navigate = useNavigate();
    var navigateAfterSuccess = function () {
        // Doesn't property work
        // if (!document.referrer) {
        //   navigate("/", { replace: true });
        //   return;
        // }
        // const referrer = new URL(document.referrer);
        // if (
        //   referrer.host === document.location.host &&
        //   !referrer.pathname.includes("/auth")
        // ) {
        //   navigate(referrer.pathname, { replace: true });
        //   return;
        // }
        navigate("/", { replace: true });
    };
    return navigateAfterSuccess;
};
