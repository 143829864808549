var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// cspell:disable
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatNumber } from "@rentiohq/shared/dist/utils/number.utils";
import flatten from "lodash/flatten";
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";
import { useSelector } from "react-redux";
import { createEnumParam, StringParam } from "serialize-query-params";
import { CallOutCard, ESpacings } from "../../components";
import DataTable from "../../components/DataTable";
import Error from "../../components/Error";
import Loading from "../../components/Loading/Loading";
import Lozenge from "../../components/Lozenge";
import { Page } from "../../components/Page/Page";
import * as statsSelectors from "../../redux/stats/stats.selectors";
import StatOptions from "./components/StatOptions";
import { EStatEntity } from "./DashboardStats.config";
import { useRange } from "./DashboardStats.hook";
import { formatFrom, generateIdentifier } from "./DashboardStats.utils";
var DashboardStatsDetail = function () {
    // Query params
    var queryParams = useQueryParams({
        statScope: StringParam,
        statEntity: createEnumParam(Object.values(EStatEntity)),
    })[0];
    var _a = useRange(), from = _a.from, to = _a.to, statInterval = _a.statInterval;
    // State
    var identifier = queryParams.statEntity
        ? generateIdentifier({
            entity: queryParams.statEntity,
            from: from,
            to: to,
            interval: statInterval,
            scope: queryParams.statScope,
        })
        : undefined;
    // Redux
    var stats = useSelector(function (state) {
        return identifier ? statsSelectors.getStats(state, identifier) : undefined;
    });
    var isFetchingStats = useSelector(function (state) {
        return identifier ? statsSelectors.isFetchingStats(state, identifier) : undefined;
    });
    var fetchStatsError = useSelector(function (state) {
        return identifier ? statsSelectors.fetchStatsError(state, identifier) : undefined;
    });
    // Helpers
    var getAllKeys = function (stats) {
        var allKeys = flatten(stats.data.map(function (entry) { return Object.keys(entry.details || {}); }));
        var allKeysUnique = uniq(allKeys);
        var allKeysUniqueSorted = sortBy(allKeysUnique);
        return allKeysUniqueSorted;
    };
    // Cell formatting
    var rightAlignCell = function (value) { return (_jsx("div", __assign({ style: { width: "100%", textAlign: "right" } }, { children: value }))); };
    var highlightCell = function (value) { return (_jsx(Lozenge, __assign({ isBold: true, appearance: "success" }, { children: value }))); };
    // Render
    var renderDetails = function (details, nestingLevel) {
        if (nestingLevel === void 0) { nestingLevel = 0; }
        // No data
        if (!details) {
            return;
        }
        if (details.count) {
            return "".concat(formatNumber(details.count, false));
        }
        if (details.details) {
            return (_jsx(_Fragment, { children: Object.keys(details.details).map(function (key) {
                    if (!details.details) {
                        return null;
                    }
                    return (_jsxs("span", { children: [_jsx("span", __assign({ style: { fontWeight: 900 - nestingLevel * 200 } }, { children: key })), _jsx("br", {}), renderDetails(details.details[key], nestingLevel + 1)] }));
                }) }));
        }
        return;
    };
    var renderContent = function () {
        if (stats) {
            var keys = getAllKeys(stats);
            var data_1 = sortBy(stats.data, "from");
            var rows = keys.map(function (key) {
                var rowName = getLocalizedText(key);
                // Get row data
                var rowData = data_1.map(function (_a) {
                    var details = _a.details;
                    return renderDetails(details ? details[key] : undefined) || "-";
                });
                // Get total per row
                var total = sumBy(data_1, "details.".concat(key, ".count"));
                return {
                    id: key,
                    content: __spreadArray(__spreadArray([rowName], rowData, true), [highlightCell(total)], false).map(function (heading, index) {
                        return index === 0 ? heading : rightAlignCell(heading);
                    }),
                };
            });
            var totals = data_1.map(function (section) { return section.total.count; });
            return (_jsx(CallOutCard, { spacing: ESpacings.tight, heading: _jsx("div", __assign({ style: { overflow: "scroll" } }, { children: _jsx(DataTable, { columnContentTypes: [], headings: __spreadArray(__spreadArray([
                            ""
                        ], data_1.map(function (data) { return formatFrom(data.from, statInterval); }), true), [
                            "Totaal",
                        ], false).map(function (heading) { return rightAlignCell(heading); }), showTotalsInFooter: true, rows: sortBy(rows, "content.0"), totals: __spreadArray(__spreadArray(["Totaal"], totals, true), [sum(totals)], false).map(function (total, index) {
                            if (index === 0)
                                return highlightCell(total);
                            return rightAlignCell(highlightCell(total));
                        }) }) })) }));
        }
        if (isFetchingStats) {
            return _jsx(Loading, {});
        }
        if (fetchStatsError) {
            return _jsx(Error, { errors: [fetchStatsError] });
        }
        return null;
    };
    return (_jsxs(Page, __assign({ fullWidth: true, breadcrumbs: { to: "/", content: getLocalizedText("dashboard.title") }, title: "Details " +
            getLocalizedText("system.entity.".concat(queryParams.statEntity).replace(/[/-]/g, "_")).toLowerCase() }, { children: [_jsx(StatOptions, { page: "detail" }), renderContent()] })));
};
export default DashboardStatsDetail;
