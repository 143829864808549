import ActivityText from "@rentiohq/shared-frontend/dist/components/components/ActivityText";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as leadApi from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.api";
import {
  formatDate,
  formatTime,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { logError } from "@rentiohq/shared/dist/logger/logger";
import { IActivityItem } from "@rentiohq/shared/dist/types/activity.types";
import { mapActivityTypeToString } from "@rentiohq/shared/dist/utils/activity.utils";
import { Loading, TextStyle } from "@rentiohq/web-shared/dist/components";
import React from "react";

interface IProps {
  leadId: string;
}

const LeadActivities: React.FC<IProps> = props => {
  const { leadId } = props;

  // State
  const [isFetching, setIsFetching] = React.useState(false);
  const [activities, setActivities] = React.useState<
    IActivityItem[] | undefined
  >();

  // Data
  const fetchData = React.useCallback(async () => {
    setIsFetching(true);

    try {
      const { data } = await leadApi.getLeadActivities(leadId);
      setActivities(data.items);
    } catch (unknownError) {
      const error = unknownError as any;
      logError({ error });
    }

    setIsFetching(false);
  }, [leadId, setActivities]);

  // Lifecycle
  React.useEffect(() => {
    fetchData();
  }, [fetchData, leadId]);

  // Render
  if (!activities) {
    if (isFetching) {
      return <Loading />;
    }

    return null;
  }

  if (activities.length === 0) {
    return <span>{getLocalizedText("activity.empty")}</span>;
  }

  return (
    <>
      {activities.map((activity, index) => {
        const date = activity.createdAt
          ? `${formatDate(new Date(activity.createdAt))} ${formatTime(
              new Date(activity.createdAt),
            )}`
          : "-";
        const type = getLocalizedText(
          `activity.${mapActivityTypeToString(
            activity.type,
            true,
          )}`.toLowerCase(),
        );

        return (
          <>
            {index > 0 && <ListItemSeparator />}
            <Spacer weight={ESpacerWeight.W08} />
            <ActivityText activityItem={activity} />
            <Spacer weight={ESpacerWeight.W02} />
            <TextStyle variation="subdued">{`${date} - ${type}`}</TextStyle>
            <Spacer weight={ESpacerWeight.W08} />
          </>
        );
      })}
    </>
  );
};

export default LeadActivities;
