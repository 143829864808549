var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { onMultiple } from "../utils/reducer.utils";
import * as contactActions from "./contact.actions";
import { addIban, addIbanForRentDepositPayout, createContact, deleteContact, deleteIban, getContact, getContacts, getIbans, searchContacts, } from "./contact.actions";
import { EContactCustomId } from "./contact.types";
import * as contactUtils from "./contact.utils";
export var REDUCER_KEY_CONTACT = "contact";
var getDefaultState = function () {
    var _a, _b;
    return ({
        // All contacts
        contacts: (_a = {},
            _a[EContactCustomId.Me] = undefined,
            _a[EContactCustomId.MeMaster] = undefined,
            _a[EContactCustomId.Support] = undefined,
            _a[EContactCustomId.Legal] = undefined,
            _a[EContactCustomId.Financial] = undefined,
            _a[EContactCustomId.Billing] = undefined,
            _a),
        // Contacts list
        isFetching: false,
        fetchError: undefined,
        isExhausted: false,
        contactIds: undefined,
        // Contacts by ID
        contactsById: (_b = {},
            _b[EContactCustomId.Me] = {},
            _b[EContactCustomId.MeMaster] = {},
            _b[EContactCustomId.Support] = {},
            _b[EContactCustomId.Legal] = {},
            _b[EContactCustomId.Financial] = {},
            _b[EContactCustomId.Billing] = {},
            _b),
        // Accounts by ID
        contactsByAccountId: {},
        // Contact create
        isCreatingContact: false,
        createContactError: undefined,
        createdContact: undefined,
        // Search contacts
        searchQuery: undefined,
        isSearchingContacts: false,
        searchContactsError: undefined,
        searchedContactIds: undefined,
        // All IBAN's
        ibans: {},
        // IBAN's by ID
        ibansById: {},
        // IBAN create
        isCreatingIban: false,
        createIbanError: undefined,
        createdIban: undefined,
        isDeletingIban: false,
        deleteIbanError: undefined,
        // IBAN rent deposit
        isAddingIbanForRentDepositPayout: false,
        addIbanForRentDepositPayoutError: undefined,
        rentDepositPayout: undefined,
        pagedContacts: {},
    });
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(getContacts.types.START, function (state) { return (__assign(__assign({}, state), { isFetching: true, fetchError: undefined })); });
reducer.on(getContacts.types.SUCCESS, function (state, _a) {
    var isExhausted = _a.isExhausted, refetch = _a.refetch, contacts = _a.contacts;
    var contactIds = contacts.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var contactsById = contacts.reduce(function (all, current) {
        all[current.id] = __assign(__assign({}, state.contactsById[current.id]), { isFetching: false, fetchError: undefined });
        return all;
    }, {});
    return __assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), keyBy(contacts, "id")), contactsById: __assign(__assign({}, state.contactsById), contactsById), isFetching: false, isExhausted: isExhausted, contactIds: refetch
            ? contactIds
            : __spreadArray(__spreadArray([], (state.contactIds || []), true), contactIds, true) });
});
reducer.on(getContacts.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetching: false, fetchError: error }));
});
reducer.on(getContact.types.START, function (state, _a) {
    var _b;
    var contactId = _a.contactId;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(getContact.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var contactId = _a.contactId, contact = _a.contact;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), (_b = {}, _b[contactId] = contact, _b[contact.id] = contact, _b)), contactsById: __assign(__assign({}, state.contactsById), (_c = {}, _c[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: false, fetchError: undefined }), _c)) }));
});
reducer.on(getContact.types.FAILURE, function (state, _a) {
    var _b;
    var contactId = _a.contactId, error = _a.error;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: false, fetchError: error }), _b)) }));
});
reducer.on(contactActions.getContactsByAccountIds.types.START, function (state, _a) {
    var accountIds = _a.accountIds;
    var contactsByAccountId = __assign({}, state.contactsByAccountId);
    accountIds.forEach(function (accountId) {
        contactsByAccountId[accountId] = __assign(__assign({}, state.contactsByAccountId[accountId]), { isFetching: true, fetchError: undefined });
    });
    return __assign(__assign({}, state), { contactsByAccountId: contactsByAccountId });
});
reducer.on(contactActions.getContactsByAccountIds.types.SUCCESS, function (state, _a) {
    var accountIds = _a.accountIds, contacts = _a.contacts;
    var contactsByAccountId = __assign({}, state.contactsByAccountId);
    accountIds.forEach(function (accountId) {
        contactsByAccountId[accountId] = __assign(__assign({}, state.contactsByAccountId[accountId]), { didFetch: true, isFetching: false, fetchError: undefined });
    });
    return __assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), keyBy(contacts, "id")), contactsByAccountId: contactsByAccountId });
});
reducer.on(contactActions.getContactsByAccountIds.types.FAILURE, function (state, _a) {
    var accountIds = _a.accountIds, error = _a.error;
    var contactsByAccountId = state.contactsByAccountId;
    accountIds.forEach(function (accountId) {
        contactsByAccountId[accountId] = __assign(__assign({}, state.contactsByAccountId[accountId]), { isFetching: false, fetchError: error });
    });
    return __assign(__assign({}, state), { contactsByAccountId: contactsByAccountId });
});
onMultiple(reducer, [
    contactActions.updateContact.types.START,
    contactActions.kycContact.types.START,
], function (state, _a) {
    var _b;
    var contactId = _a.contactId;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isUpdating: true, updateError: undefined }), _b)) }));
});
onMultiple(reducer, [
    contactActions.updateContact.types.SUCCESS,
    contactActions.kycContact.types.SUCCESS,
], function (state, _a) {
    var _b, _c;
    var contactId = _a.contactId, contact = _a.contact;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), (_b = {}, _b[contactId] = contact, _b)), contactsById: __assign(__assign({}, state.contactsById), (_c = {}, _c[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isUpdating: false, updateError: undefined }), _c)) }));
});
onMultiple(reducer, [
    contactActions.updateContact.types.FAILURE,
    contactActions.kycContact.types.FAILURE,
], function (state, _a) {
    var _b;
    var contactId = _a.contactId, error = _a.error;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isUpdating: false, updateError: error }), _b)) }));
});
reducer.on(deleteContact.types.START, function (state, _a) {
    var _b;
    var contactId = _a.contactId;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isDeleting: true, deleteError: undefined }), _b)) }));
});
reducer.on(deleteContact.types.SUCCESS, function (state, _a) {
    var _b;
    var contactId = _a.contactId;
    var contacts = __assign({}, state.contacts);
    delete contacts[contactId];
    return __assign(__assign({}, state), { contacts: contacts, contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isDeleting: false, deleteError: undefined }), _b)) });
});
reducer.on(deleteContact.types.FAILURE, function (state, _a) {
    var _b;
    var contactId = _a.contactId, error = _a.error;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isDeleting: false, deleteError: error }), _b)) }));
});
reducer.on(createContact.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingContact: true, createContactError: undefined })); });
reducer.on(createContact.types.SUCCESS, function (state, contact) {
    var _a;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), (_a = {}, _a[contact.id] = contact, _a)), contactIds: __spreadArray(__spreadArray([], (state.contactIds || []), true), [contact.id], false), isCreatingContact: false, createContactError: undefined, createdContact: contact }));
});
reducer.on(createContact.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingContact: false, createContactError: error }));
});
reducer.on(searchContacts.types.START, function (state, _a) {
    var query = _a.query;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingContacts: true, searchContactsError: undefined }));
});
reducer.on(searchContacts.types.SUCCESS, function (state, _a) {
    var query = _a.query, contacts = _a.contacts;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), keyBy(contacts, "id")), searchQuery: query, isSearchingContacts: false, searchContactsError: undefined, searchedContactIds: contacts.map(function (_a) {
            var id = _a.id;
            return id;
        }) }));
});
reducer.on(searchContacts.types.FAILURE, function (state, _a) {
    var query = _a.query, error = _a.error;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingContacts: false, searchContactsError: error }));
});
reducer.on(getIbans.types.START, function (state, _a) {
    var _b;
    var contactId = _a.contactId, accountId = _a.accountId;
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibansById: __assign(__assign({}, state.ibansById), (_b = {}, _b[ibanId] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) });
});
reducer.on(getIbans.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var contactId = _a.contactId, accountId = _a.accountId, ibans = _a.ibans;
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibans: __assign(__assign({}, state.ibans), (_b = {}, _b[ibanId] = ibans, _b)), ibansById: __assign(__assign({}, state.ibansById), (_c = {}, _c[ibanId] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) });
});
reducer.on(getIbans.types.FAILURE, function (state, _a) {
    var _b;
    var contactId = _a.contactId, accountId = _a.accountId, error = _a.error;
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibansById: __assign(__assign({}, state.ibansById), (_b = {}, _b[ibanId] = {
            isFetching: false,
            fetchError: error,
        }, _b)) });
});
reducer.on(addIban.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingIban: true, createIbanError: undefined })); });
reducer.on(addIban.types.SUCCESS, function (state, _a) {
    var _b;
    var contactId = _a.contactId, accountId = _a.accountId, iban = _a.iban;
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return __assign(__assign({}, state), { isCreatingIban: false, createIbanError: undefined });
    }
    return __assign(__assign({}, state), { ibans: __assign(__assign({}, state.ibans), (_b = {}, _b[ibanId] = __spreadArray([iban], (state.ibans[ibanId] || []), true), _b)), isCreatingIban: false, createIbanError: undefined });
});
reducer.on(addIban.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingIban: false, createIbanError: error }));
});
reducer.on(deleteIban.types.START, function (state) { return (__assign(__assign({}, state), { isDeletingIban: true, deleteIbanError: undefined })); });
reducer.on(deleteIban.types.SUCCESS, function (state, _a) {
    var _b;
    var contactId = _a.contactId, ibanId = _a.ibanId;
    var contactIbanId = contactUtils.getIbanId(contactId);
    if (!contactIbanId) {
        return state;
    }
    return __assign(__assign({}, state), { ibans: __assign(__assign({}, state.ibans), (_b = {}, _b[contactIbanId] = (state.ibans[ibanId] || []).filter(function (iban) { return iban.id !== ibanId; }), _b)), isDeletingIban: false, deleteIbanError: undefined });
});
reducer.on(deleteIban.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isDeletingIban: false, deleteIbanError: error }));
});
reducer.on(addIbanForRentDepositPayout.types.START, function (state) { return (__assign(__assign({}, state), { isAddingIbanForRentDepositPayout: true, addIbanForRentDepositPayoutError: undefined })); });
reducer.on(addIbanForRentDepositPayout.types.SUCCESS, function (state, _a) {
    var kycValidated = _a.kycValidated;
    return (__assign(__assign({}, state), { isAddingIbanForRentDepositPayout: false, addIbanForRentDepositPayoutError: undefined, rentDepositPayout: {
            kycValidated: kycValidated,
        } }));
});
reducer.on(addIbanForRentDepositPayout.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isAddingIbanForRentDepositPayout: false, addIbanForRentDepositPayoutError: error, rentDepositPayout: undefined }));
});
reducer.on(getContact.types.START, function (state, _a) {
    var _b;
    var contactId = _a.contactId;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(getContact.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var contactId = _a.contactId, contact = _a.contact;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), (_b = {}, _b[contactId] = contact, _b[contact.id] = contact, _b)), contactsById: __assign(__assign({}, state.contactsById), (_c = {}, _c[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: false, fetchError: undefined }), _c)) }));
});
reducer.on(getContact.types.FAILURE, function (state, _a) {
    var _b;
    var contactId = _a.contactId, error = _a.error;
    return (__assign(__assign({}, state), { contactsById: __assign(__assign({}, state.contactsById), (_b = {}, _b[contactId] = __assign(__assign({}, state.contactsById[contactId]), { isFetching: false, fetchError: error }), _b)) }));
});
// Create Broker Legal Contact
reducer.on(contactActions.createContactBroker.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingContact: true, createContactError: undefined })); });
reducer.on(contactActions.createContactBroker.types.SUCCESS, function (state, _a) {
    var _b;
    var customId = _a.customId, contact = _a.contact;
    return (__assign(__assign({}, state), { contacts: __assign(__assign({}, state.contacts), (_b = {}, _b[customId] = contact, _b[contact.id] = contact, _b)), contactIds: __spreadArray(__spreadArray([], (state.contactIds || []), true), [customId], false), isCreatingContact: false, createContactError: undefined, createdContact: contact }));
});
reducer.on(contactActions.createContactBroker.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingContact: false, createContactError: error }));
});
reducer.on(contactActions.getContactsPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, filterData = _a.filterData;
    return merge({}, state, {
        pagedContacts: (_b = {},
            _b[identifier] = {
                filterData: filterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(contactActions.getContactsPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, contacts = _a.contacts;
    var contactIds = contacts.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return merge({}, state, {
        // @ts-ignore
        contacts: __assign({}, keyBy(contacts, "id")),
        contactIds: contactIds,
        pagedContacts: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        contactIds: contactIds,
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(contactActions.getContactsPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, error = _a.error;
    return merge({}, state, {
        pagedContacts: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
export default reducer;
