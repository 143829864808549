export var EField;
(function (EField) {
    EField["Header"] = "header";
    EField["ContractInformation"] = "contractInformation";
    EField["LeaseAgreement"] = "leaseAgreement";
    EField["ContractRegistrationProof"] = "contractRegistrationProof";
    EField["ActiveRentalDeposit"] = "activeRentalDeposit";
    EField["DamageInfo"] = "damageInfo";
    EField["DamageDocuments"] = "damageDocuments";
    EField["ExtraInfo"] = "extraInfo";
    EField["ExtraDocuments"] = "extraDocuments";
    EField["LatePayments"] = "latePayments";
    EField["ExtraLatePaymentDoc"] = "extraLatePaymentDoc";
    EField["ExtraLatePaymentInfo"] = "extraLatePaymentInfo";
    EField["ManualLatePayments"] = "manualLatePayments";
    EField["PreviouslyAddedLatePayments"] = "previouslyAddedLatePayments";
    EField["PropertyInspections"] = "propertyInspection";
    EField["PropertyInspectionRegistrationProof"] = "propertyInspectionProof";
    EField["RegisteredLetters"] = "registeredLetters";
})(EField || (EField = {}));
