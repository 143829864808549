// @ts-ignore
import { extractRefSchema } from "json-rules-engine-simplified/lib/utils";
export var toArray = function (field) {
    if (Array.isArray(field)) {
        return field;
    }
    else {
        return [field];
    }
};
export var toError = function (message) {
    console.error(message);
};
/**
 * Find relevant schema for the field
 * @returns { field: "string", schema: "object" } relevant field and schema
 */
export var findRelSchemaAndField = function (field, schema) {
    var separator = field.indexOf(".");
    if (separator === -1) {
        return { field: field, schema: schema };
    }
    var parentField = field.substr(0, separator);
    var refSchema = extractRefSchema(parentField, schema);
    if (refSchema) {
        return findRelSchemaAndField(field.substr(separator + 1), refSchema);
    }
    toError("Failed to retrieve ".concat(refSchema, " from schema"));
    return { field: field, schema: schema };
};
