import { findRelSchemaAndField, toArray } from "../shared";
function doRemove(_a) {
    var field = _a.field, schema = _a.schema;
    var requiredIndex = schema.required ? schema.required.indexOf(field) : -1;
    if (requiredIndex !== -1) {
        schema.required.splice(requiredIndex, 1);
    }
    delete schema.properties[field];
}
/**
 * Remove specified field both from uiSchema and schema
 *
 * @param field
 * @param schema
 * @param uiSchema
 * @returns {{schema: *, uiSchema: *}}
 */
var remove = function (_a, schema) {
    var field = _a.field;
    var fieldArr = toArray(field);
    fieldArr.forEach(function (field) {
        return doRemove(findRelSchemaAndField(field, schema));
    });
};
export default remove;
// remove.validate = validateFields('remove', ({ field }: any) => field)
