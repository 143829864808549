var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as t from "../../services/translationService";
import Button from "../Button";
import { StyledDescriptionText, StyledError, StyledHelpText, } from "../Form/Form.styled";
import { ESpacings } from "../Grid";
import Icon from "../Icon";
import TextStyle from "../TextStyle";
import * as S from "./Labelled.styled";
var Labelled = function (_a) {
    var children = _a.children, label = _a.label, _b = _a.labelHidden, labelHidden = _b === void 0 ? false : _b, error = _a.error, optional = _a.optional, helpText = _a.helpText, descriptionText = _a.descriptionText, onPressExtraInfo = _a.onPressExtraInfo;
    return (_jsxs("div", { children: [!labelHidden && (_jsxs(S.StyledLabelWrapper, { children: [_jsx(S.Label, { children: label }), optional && (_jsx(S.StyledLabelOptional, __assign({ ml: ESpacings.extraTight }, { children: _jsxs(TextStyle, __assign({ size: "small", variation: "subdued" }, { children: [" ", "(", t.system("optional"), ")"] })) }))), onPressExtraInfo && (_jsx(Button, __assign({ appearance: "plain", onClick: onPressExtraInfo, tabIndex: "-1" }, { children: _jsx(Box, __assign({ ml: ESpacings.tight }, { children: _jsx(Icon, { source: "informationCircle", size: "small" }) })) })))] })), descriptionText ? (_jsx(StyledDescriptionText, { children: descriptionText })) : null, children, error && _jsx(StyledError, { children: error }), helpText && _jsx(StyledHelpText, { children: helpText })] }));
};
export default Labelled;
