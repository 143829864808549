import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import assets from "assets";
import React from "react";
import * as S from "./LeadListItem.styles";

export enum ESortField {
  Date = "createdAt",
  Property = "propertyCity",
  Contact = "contactLastname",
  Referrer = "referrer",
  Status = "status",
}

export enum ESortMethod {
  Ascending = "ASC",
  Descending = "DESC",
}

interface IProps {
  onChangeSort: (sortField?: ESortField, sortMethod?: ESortMethod) => void;
  sortField?: ESortField;
  sortMethod?: ESortMethod;
}

const LeadListItemHeader: React.FC<IProps> = props => {
  const { onChangeSort, sortField, sortMethod } = props;

  // Event handlers
  const handlePressSort = (newSortField: ESortField) => () => {
    let newSortMethod = ESortMethod.Ascending;

    // Switch sort method if sort field is pressed again
    if (newSortField === sortField && sortMethod === ESortMethod.Ascending) {
      newSortMethod = ESortMethod.Descending;
    }

    onChangeSort(newSortField, newSortMethod);
  };

  const handlePressUnsortable = () => {
    showAlert({
      type: "info",
      message: getLocalizedText("insurance.lead.header.cta.unsortable"),
    });
  };

  // Render
  const renderSortImage = (sortFieldToRender: ESortField) => {
    if (sortField !== sortFieldToRender) {
      return null;
    }

    return (
      <S.SortImage
        src={assets.ICO_DROPDOWN}
        isAscending={sortMethod === ESortMethod.Ascending}
      />
    );
  };

  return (
    <>
      <ResourceListItem item={{}}>
        <S.Wrap>
          <S.DateWrap onClick={handlePressSort(ESortField.Date)}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.date")}
            </TextStyle>
            {renderSortImage(ESortField.Date)}
          </S.DateWrap>

          <S.IdWrap onClick={handlePressUnsortable}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.id")}
            </TextStyle>
          </S.IdWrap>

          <S.AddressWrap onClick={handlePressSort(ESortField.Property)}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.property")}
            </TextStyle>
            {renderSortImage(ESortField.Property)}
          </S.AddressWrap>

          <S.ContactWrap onClick={handlePressSort(ESortField.Contact)}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.contact")}
            </TextStyle>
            {renderSortImage(ESortField.Contact)}
          </S.ContactWrap>

          <S.ReferrerWrap onClick={handlePressSort(ESortField.Referrer)}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.referrer")}
            </TextStyle>
            {renderSortImage(ESortField.Referrer)}
          </S.ReferrerWrap>

          <S.QuoteOptionsWrap onClick={handlePressUnsortable}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.options")}
            </TextStyle>
          </S.QuoteOptionsWrap>

          <S.PriceWrap onClick={handlePressUnsortable}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.price")}
            </TextStyle>
          </S.PriceWrap>

          <S.StatusWrap onClick={handlePressSort(ESortField.Status)}>
            <TextStyle variation={"strong"}>
              {getLocalizedText("insurance.lead.header.status")}
            </TextStyle>
            {renderSortImage(ESortField.Status)}
          </S.StatusWrap>
        </S.Wrap>
      </ResourceListItem>
      <div style={{ height: "1px", backgroundColor: "lightgray" }} />
    </>
  );
};

export default LeadListItemHeader;
