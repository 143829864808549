var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { StringParam, withDefault } from "serialize-query-params";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Grid from "../../components/Grid";
import { Page } from "../../components/Page/Page";
import { useRange } from "./DashboardStats.hook";
import { EChartSize } from "./DashboardStats.types";
import { getAllTabs, getTabCharts } from "./DashboardStats.utils";
import StatCard from "./components/StatCard";
import StatOptions from "./components/StatOptions";
var getTabInfo = function (selection, currentSelection) {
    return {
        name: selection,
        content: getLocalizedText("backoffice.".concat(selection)),
        url: "?selection=".concat(selection),
        isActive: currentSelection === selection ? true : false,
    };
};
var DashboardStats = function () {
    var _a;
    var navigate = useNavigate();
    // State
    var _b = useRange(), from = _b.from, to = _b.to, statInterval = _b.statInterval;
    var handleClickDetail = function (statEntity) {
        navigate("/stats?statEntity=".concat(statEntity));
    };
    var queryParams = useQueryParams({
        selection: withDefault(StringParam, getAllTabs()[0].name),
    })[0];
    var tabs = getAllTabs().map(function (tab) {
        return getTabInfo(tab.name, queryParams.selection);
    });
    // Render
    return (_jsxs(Page, __assign({ fullWidth: true, title: getLocalizedText("dashboard.title"), loading: false, error: undefined, tabs: tabs }, { children: [_jsx(StatOptions, { page: "global" }), _jsx(Grid, __assign({ alignItems: "flex-start", justifyContent: "space-between" }, { children: (_a = getTabCharts(queryParams.selection)) === null || _a === void 0 ? void 0 : _a.map(function (chart, index) { return (_jsx(Grid.Item, __assign({ width: [1, 1, 1, chart.size || EChartSize.MEDIUM] }, { children: _jsx(ErrorBoundary, { children: _jsx(StatCard
                        // statEntity={chart.entity}
                        , { 
                            // statEntity={chart.entity}
                            chart: chart, from: from, to: to, statInterval: statInterval, onClickDetail: handleClickDetail }) }) }), chart.entity)); }) }))] })));
};
export default DashboardStats;
