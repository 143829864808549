var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "../../Button";
import Icon from "../../Icon";
import * as S from "../DataTable.styled";
export var Cell = function (_a) {
    var content = _a.content, contentType = _a.contentType, firstColumn = _a.firstColumn, header = _a.header, _b = _a.verticalSpacing, verticalSpacing = _b === void 0 ? "base" : _b, _c = _a.horizontalSpacing, horizontalSpacing = _c === void 0 ? "tight" : _c, sorted = _a.sorted, sortable = _a.sortable, sortDirection = _a.sortDirection, _d = _a.hasBottomBorder, hasBottomBorder = _d === void 0 ? true : _d, onSort = _a.onSort, onClick = _a.onClick;
    var horizontalAlign = (function () {
        switch (contentType) {
            case "text-centered":
            case "action":
            case "icon":
                return "center";
            case "numeric":
                return "right";
            default:
                return "left";
        }
    })();
    var cellWidth = (function () {
        if (contentType === "icon") {
            return "56px";
        }
        if (contentType === "icon-large") {
            return "72px";
        }
    })();
    var renderWrappedContent = function () {
        var element = content;
        if (horizontalAlign === "center") {
            element = _jsx(S.CenterInnerWrap, { children: content });
        }
        return element;
    };
    if (header) {
        var direction = sorted ? sortDirection : undefined;
        var sortIconSource = direction === "DESC" ? "chevronDown" : "chevronUp";
        return (_jsx(S.Header, __assign({ horizontalSpacing: horizontalSpacing, verticalSpacing: "tight", horizontalAlign: horizontalAlign, scope: "col" }, { children: sortable ? (_jsxs(Button, __assign({ appearance: "reset", onClick: onSort }, { children: [content, _jsx(S.SortIcon, __assign({ sorted: sorted || false }, { children: _jsx(Icon, { source: sortIconSource, size: "small" }) }))] }))) : (renderWrappedContent()) })));
    }
    if (firstColumn) {
        return (_jsx(S.Clickable, __assign({ onClick: function () {
                if (onClick)
                    onClick();
            } }, { children: _jsx(S.Cell, __assign({ as: "th", scope: "row", verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing, horizontalAlign: horizontalAlign }, { children: renderWrappedContent() })) })));
    }
    return (_jsx(S.Cell, __assign({ contentType: contentType, width: cellWidth, horizontalSpacing: horizontalSpacing, verticalSpacing: verticalSpacing, horizontalAlign: horizontalAlign, hasBottomBorder: hasBottomBorder }, { children: renderWrappedContent() })));
};
