var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Labelled from "../../../Labelled/Labelled";
import VisuallyHidden from "../../../VisuallyHidden";
import * as S from "../../Form.styled";
import { formatJsonSchemaError, getUiOptions } from "../../utils";
export var FieldTemplate = function (_a) {
    var label = _a.label, id = _a.id, rawErrors = _a.rawErrors, displayLabel = _a.displayLabel, rawHelp = _a.rawHelp, rawDescription = _a.rawDescription, required = _a.required, children = _a.children, hidden = _a.hidden, rest = __rest(_a, ["label", "id", "rawErrors", "displayLabel", "rawHelp", "rawDescription", "required", "children", "hidden"]);
    var errors = formatJsonSchemaError(rawErrors);
    var error = typeof errors === "object" ? "" : errors;
    var options = getUiOptions(rest.uiSchema);
    if (hidden) {
        return _jsx(VisuallyHidden, { children: children });
    }
    return (_jsx(_Fragment, { children: displayLabel ? (_jsx(S.Field, { children: _jsx(Labelled, __assign({ label: label, error: error, labelHidden: !displayLabel, descriptionText: rawDescription, helpText: rawHelp, optional: !required && !options.required }, { children: children })) })) : (_jsx(S.Field, { children: children })) }));
};
