var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import mergeDeepRight from "ramda/es/mergeDeepRight";
export var setVariables = function (query, variables) {
    var updatedQuery = {
        query: query.query,
        options: __assign(__assign({}, query.options), { variables: mergeDeepRight(query.options.variables, variables) }),
    };
    return updatedQuery;
};
export var QUERY_SEARCH_LIMIT = 3;
