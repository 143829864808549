var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { formatPhoneWhileTyping, formatPhoneWhileTypingWithoutSpaces, } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import React from "react";
import { BaseInput } from "../BaseInput";
export var PhoneWidget = function (_a) {
    var onChange = _a.onChange, value = _a.value, props = __rest(_a, ["onChange", "value"]);
    var _b = React.useState(false), hasChanged = _b[0], setHasChanged = _b[1];
    var id = props.idSchema ? props.idSchema.$id : props.id;
    var defaultValue = "+32 4";
    var handleChange = function (valueOrEvent) {
        var isEventValue = typeof valueOrEvent === "object" && valueOrEvent.target;
        var value = isEventValue ? valueOrEvent.target.value : valueOrEvent;
        onChange(value);
    };
    var handleFocus = function () {
        setHasChanged(true);
        onChange(formatPhoneWhileTypingWithoutSpaces(value));
    };
    var handleBlur = function () {
        onChange(formatPhoneWhileTyping(value));
    };
    return (_jsx(BaseInput
    // @ts-ignore TODO: Typescript upgrade
    , __assign({ 
        // @ts-ignore TODO: Typescript upgrade
        id: id, type: "tel", value: hasChanged
            ? value || defaultValue
            : formatPhoneWhileTyping(value) || defaultValue }, props, { onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur })));
};
