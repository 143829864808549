var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { rem, transparentize } from "polished";
import TabComponent from "../Tabs";
import { TabContent as StyledTabContent, Tabs as StyledTabs, } from "../Tabs/Tabs.styled";
import { EModalType } from "./Modal.types";
var sizes;
(function (sizes) {
    sizes["large"] = "960px";
    sizes["medium"] = "640px";
    sizes["small"] = "460px";
})(sizes || (sizes = {}));
var getTypeColor = function (theme, type) {
    switch (type) {
        case EModalType.Warning:
            return theme.colors.yellow[800];
        case EModalType.Error:
            return theme.colors.red[800];
        default:
            return;
    }
};
export var OuterWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 99999;\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 99999;\n"])));
export var Background = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  background: ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  background: ", ";\n"])), function (props) {
    return transparentize(0.35, props.theme.getColor("gray", 1000));
});
export var ModalContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - ", " - ", ");\n  margin-top: ", ";\n  margin-left: auto;\n  margin-right: auto;\n  max-width: calc(100% - ", ");\n  pointer-events: none;\n  ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - ", " - ", ");\n  margin-top: ", ";\n  margin-left: auto;\n  margin-right: auto;\n  max-width: calc(100% - ", ");\n  pointer-events: none;\n  ", ";\n"])), spacing("extraLoose"), spacing("extraLoose"), spacing("extraLoose"), spacing("extraLoose"), function (props) { return "width: ".concat(sizes[props.width]); });
export var Dialog = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  box-shadow:\n    0 0 0 1px rgba(6, 44, 82, 0.1),\n    0 2px 16px rgba(33, 43, 54, 0.08);\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  max-height: calc(100% - 2px);\n  pointer-events: auto;\n  border-radius: 4px;\n  outline: 0px;\n"], ["\n  background-color: ", ";\n  box-shadow:\n    0 0 0 1px rgba(6, 44, 82, 0.1),\n    0 2px 16px rgba(33, 43, 54, 0.08);\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  max-height: calc(100% - 2px);\n  pointer-events: auto;\n  border-radius: 4px;\n  outline: 0px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
});
export var Wrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  height: 100%;\n"])));
export var Header = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  padding: ", ";\n  border-bottom: 1px solid\n    ", ";\n  background: ", ";\n  color: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  padding: ", ";\n  border-bottom: 1px solid\n    ", ";\n  background: ", ";\n  color: ", ";\n"])), spacing("loose"), function (_a) {
    var type = _a.type, theme = _a.theme;
    var colorForType = getTypeColor(theme, type);
    if (colorForType) {
        return transparentize(0.5, colorForType);
    }
    return theme.getColor("gray", 200);
}, function (_a) {
    var type = _a.type, theme = _a.theme;
    return transparentize(0.9, getTypeColor(theme, type) || "transparent");
}, function (_a) {
    var type = _a.type, theme = _a.theme;
    return getTypeColor(theme, type) || "inherit";
});
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  overflow: scroll;\n  flex: 1 1 auto;\n"], ["\n  overflow: scroll;\n  flex: 1 1 auto;\n"])));
export var Body = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (_a) {
    var fullBody = _a.fullBody;
    return fullBody ? "" : "".concat(spacing("extraLoose"), " ").concat(spacing("loose"));
});
export var Tabs = styled(TabComponent)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", " {\n    padding: 0 ", ";\n    margin-top: 4px;\n    border-bottom: 1px solid ", ";\n  }\n  ", " {\n    padding: ", " ", ";\n  }\n  + ", " {\n    border-top: 1px solid ", ";\n  }\n"], ["\n  ", " {\n    padding: 0 ", ";\n    margin-top: 4px;\n    border-bottom: 1px solid ", ";\n  }\n  ", " {\n    padding: ", " ", ";\n  }\n  + ", " {\n    border-top: 1px solid ", ";\n  }\n"])), StyledTabs, spacing("loose"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
}, StyledTabContent, spacing("extraLoose"), spacing("loose"), Body, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
});
export var Footer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  padding: ", " ", ";\n  border-top: 1px solid ", ";\n  ", " + & {\n    border-top: none;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  padding: ", " ", ";\n  border-top: 1px solid ", ";\n  ", " + & {\n    border-top: none;\n  }\n"])), spacing(), spacing("loose"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
}, Header);
export var SuccessHeader = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: ", ";\n  background: ", ";\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: ", ";\n  background: ", ";\n"])), spacing("extraLoose"), function (props) { return props.theme.colors.green10; });
export var SuccessClose = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  button {\n    color: ", ";\n  }\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  button {\n    color: ", ";\n  }\n"])), function (props) { return rem(16, props.theme.baseFontSize); }, function (props) { return rem(16, props.theme.baseFontSize); }, function (props) { return props.theme.colors.neutral90; });
export var SuccessImage = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (props) { return rem(160, props.theme.baseFontSize); }, function (props) { return rem(160, props.theme.baseFontSize); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
