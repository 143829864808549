var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { HollowDotsSpinner } from "react-epic-spinners";
import Icon from "../Icon";
import { StyledButton, StyledButtonContent, StyledButtonLink, } from "./Button.styled";
var Button = React.forwardRef(function (_a, ref) {
    var _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isSubmitting, isSubmitting = _c === void 0 ? false : _c, _d = _a.type, type = _d === void 0 ? "button" : _d, _e = _a.isFullWidth, isFullWidth = _e === void 0 ? false : _e, url = _a.url, children = _a.children, onClick = _a.onClick, onFocus = _a.onFocus, onBlur = _a.onBlur, _f = _a.size, size = _f === void 0 ? "medium" : _f, _g = _a.appearance, appearance = _g === void 0 ? "default" : _g, color = _a.color, ariaLabel = _a.ariaLabel, iconBefore = _a.iconBefore, iconBeforeName = _a.iconBeforeName, iconAfter = _a.iconAfter, iconAfterName = _a.iconAfterName, rest = __rest(_a, ["isDisabled", "isSubmitting", "type", "isFullWidth", "url", "children", "onClick", "onFocus", "onBlur", "size", "appearance", "color", "ariaLabel", "iconBefore", "iconBeforeName", "iconAfter", "iconAfterName"]);
    var onClickWrapper = onClick
        ? function (e) {
            var _a, _b, _c;
            if (!url) {
                (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
                (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
                // e.nativeEvent?.stopImmediatePropagation?.();
            }
            onClick(e);
        }
        : undefined;
    // eslint-disable-next-line no-nested-ternary
    var iconBeforeMarkup = (function () {
        if (iconBefore) {
            return iconBefore;
        }
        if (iconBeforeName) {
            return _jsx(Icon, { size: "small", source: iconBeforeName });
        }
        return null;
    })();
    var iconAfterMarkup = (function () {
        if (iconAfter) {
            return iconAfter;
        }
        if (iconAfterName) {
            return _jsx(Icon, { size: "small", source: iconAfterName });
        }
        return null;
    })();
    var childMarkup = children ? (_jsxs(_Fragment, { children: [_jsx(StyledButtonContent, __assign({ isSubmitting: isSubmitting }, { children: children })), isSubmitting && (_jsx(HollowDotsSpinner, { size: 12, color: appearance === "primary" ? "white" : "black" }))] })) : null;
    return url ? (_jsxs(StyledButtonLink, __assign({ to: url, size: size, onClick: onClickWrapper, onFocus: onFocus, onBlur: onBlur, appearance: appearance, color: color, isFullWidth: isFullWidth, "aria-label": ariaLabel, ref: ref }, rest, { children: [iconBeforeMarkup, childMarkup, iconAfterMarkup] }))) : (_jsxs(StyledButton, __assign({ size: size, type: type, onClick: onClickWrapper, onFocus: onFocus, onBlur: onBlur, disabled: isDisabled || isSubmitting, appearance: appearance, color: color, isFullWidth: isFullWidth, "aria-label": ariaLabel, ref: ref }, rest, { children: [iconBeforeMarkup, childMarkup, iconAfterMarkup] })));
});
export default Button;
