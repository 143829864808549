var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, FontWeights, resetList, spacing, } from "@rentiohq/web-theme";
import { rem } from "polished";
var currentColorLine = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-repeat: repeat-y;\n  background-image: linear-gradient(\n    to right,\n    currentColor 100%,\n    currentColor 0\n  );\n  background-size: 2px 2px;\n  background-position: 11px 0;\n"], ["\n  background-repeat: repeat-y;\n  background-image: linear-gradient(\n    to right,\n    currentColor 100%,\n    currentColor 0\n  );\n  background-size: 2px 2px;\n  background-position: 11px 0;\n"])));
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
export var List = styled.ol(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  counter-reset: section;\n  margin: ", ";\n  /* margin-left: ", "; */\n  li {\n    &:last-child {\n      ol {\n        li:last-child {\n          padding-bottom: 0;\n        }\n      }\n    }\n  }\n"], ["\n  ", ";\n  counter-reset: section;\n  margin: ", ";\n  /* margin-left: ", "; */\n  li {\n    &:last-child {\n      ol {\n        li:last-child {\n          padding-bottom: 0;\n        }\n      }\n    }\n  }\n"])), resetList, spacing(), spacing());
export var SubList = styled.ol(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  flex: 1 0 100%;\n"], ["\n  ", ";\n  flex: 1 0 100%;\n"])), resetList);
export var Step = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  ", "\n  span {\n    flex: 1;\n  }\n  &::before {\n    width: ", ";\n    text-align: center;\n    display: inline-block;\n    margin-right: ", ";\n    font-family: ", ";\n    counter-increment: section;\n    content: counters(section, \".\") \" \";\n    background-color: ", ";\n    vertical-align: top;\n    align-self: flex-start;\n  }\n  ", ";\n  ", "\n"], ["\n  font-weight: ", ";\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  ", "\n  span {\n    flex: 1;\n  }\n  &::before {\n    width: ", ";\n    text-align: center;\n    display: inline-block;\n    margin-right: ", ";\n    font-family: ", ";\n    counter-increment: section;\n    content: counters(section, \".\") \" \";\n    background-color: ", ";\n    vertical-align: top;\n    align-self: flex-start;\n  }\n  ", ";\n  ", "\n"])), FontWeights.Bold, currentColorLine, function (_a) {
    var theme = _a.theme;
    return rem(24, theme.baseFontSize);
}, spacing(), function (props) { return props.theme.fonts.financial; }, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var theme = _a.theme, isPassed = _a.isPassed, isCurrent = _a.isCurrent;
    if (isPassed && !isCurrent) {
        return "\n        color: ".concat(theme.getColor("gray", 1000), ";\n        span {\n          cursor: pointer;\n        }\n      ");
    }
    else if (isPassed && isCurrent) {
        return "\n        background-image: linear-gradient(to right, ".concat(theme.getColor("gray", 1000), " 100%, ").concat(theme.getColor("gray", 1000), " 0);\n        color: ").concat(theme.getColor("primary"), ";\n      ");
    }
    else {
        return "color: ".concat(theme.getColor("gray", 400), ";");
    }
}, function (_a) {
    var hasChildren = _a.hasChildren;
    return !hasChildren
        ? "& + & {\n    padding-top: ".concat(spacing("extraLoose"), ";\n  }")
        : null;
});
export var SubStep = styled.li(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding-top: ", ";\n  padding-left: ", "px;\n  padding-bottom: ", ";\n  ", "\n  font-weight: ", ";\n  span {\n    cursor: initial;\n  }\n  &::before {\n    display: none;\n    content: \"\";\n  }\n  &:first-child {\n    padding-top: ", ";\n  }\n  &:last-child {\n    padding-bottom: ", ";\n  }\n  font-weight: ", ";\n  ", ";\n"], ["\n  padding-top: ", ";\n  padding-left: ", "px;\n  padding-bottom: ", ";\n  ", "\n  font-weight: ", ";\n  span {\n    cursor: initial;\n  }\n  &::before {\n    display: none;\n    content: \"\";\n  }\n  &:first-child {\n    padding-top: ", ";\n  }\n  &:last-child {\n    padding-bottom: ", ";\n  }\n  font-weight: ", ";\n  ", ";\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return theme.baseUnit * 10;
}, spacing("tight"), currentColorLine, FontWeights.Medium, spacing(), spacing("extraLoose"), FontWeights.Medium, function (_a) {
    var theme = _a.theme, isPassed = _a.isPassed, isCurrent = _a.isCurrent;
    if (isPassed && !isCurrent) {
        return "\n        color: ".concat(theme.getColor("gray", 1000), ";\n        span {\n          cursor: pointer;\n        }\n      ");
    }
    else if (isPassed && isCurrent) {
        return "\n      color: ".concat(theme.getColor("primary"), ";\n      &&& {\n        background-image: linear-gradient(to bottom, ").concat(theme.getColor("gray", 1000), " 0, ").concat(theme.getColor("gray", 1000), " 22px, ").concat(theme.getColor("gray", 400), " 22px);\n        background-repeat: no-repeat;\n        background-size: 2px 100%;\n        padding-left: ").concat(spacing("tight"), ";\n        &:before {\n          display: inline-block;\n          width: ").concat(rem(8, theme.baseFontSize), ";\n          height: ").concat(rem(8, theme.baseFontSize), ";\n          background: ").concat(theme.getColor("primary"), ";\n          border-radius: 50%;\n          vertical-align: middle;\n          margin-right: ").concat(spacing("loose"), ";\n        }\n      }\n    ");
    }
    else {
        return "color: ".concat(theme.getColor("gray", 400), ";");
    }
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
