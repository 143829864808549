var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { REDUCER_KEY_AUTH, } from "@rentiohq/shared-frontend/dist/redux/auth/auth.reducer";
import { REDUCER_KEY_CONTACT, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import { getContactMe, getContactMeMaster, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as systemActions from "../../../../redux/system/system.actions";
import { REDUCER_KEY_SYSTEM_LOCAL, } from "../../../../redux/system/system.reducer";
import * as systemSelectors from "../../../../redux/system/system.selectors";
import { ts } from "../../../../services";
import * as utils from "../../../../utils";
import { triggerLiveChat } from "../../../../utils/zendesk.utils";
import DropdownMenu from "../../../DropdownMenu";
import { ESpacings } from "../../../Grid";
import Icon from "../../../Icon";
import Logo from "../../../Logo";
import ProfilePicture from "../../../ProfilePicture";
import { ResourceList, ResourceListItem } from "../../../ResourceList";
import SearchList from "../../../SearchList";
import TextStyle from "../../../TextStyle";
import * as S from "./Header.styled";
export var Header = function (_a) {
    var userMenuActions = _a.userMenuActions, showHelp = _a.showHelp, showSearch = _a.showSearch, _b = _a.extraItems, extraItems = _b === void 0 ? [] : _b;
    var dispatch = useDispatch();
    var user = authHooks.useSelf().user;
    var contactMeMaster = useSelector(function (state) {
        return getContactMeMaster(state);
    });
    var contactMe = useSelector(function (state) { return getContactMe(state); });
    var hasFocus = React.useRef(false);
    var _c = React.useState(""), queryInput = _c[0], setQueryInput = _c[1];
    var _d = React.useState(false), showSearchResults = _d[0], setShowSearchResults = _d[1];
    // Debounce search
    var _e = useDebounce(queryInput), debouncedSearch = _e[0], cancel = _e[1];
    React.useEffect(function () { return cancel; }, [cancel]);
    var searchIdentifier = React.useMemo(function () {
        if (debouncedSearch.length > 0) {
            return "query-".concat(debouncedSearch);
        }
        return "all";
    }, [debouncedSearch]);
    var searchResults = useSelector(function (state) {
        return systemSelectors.searchResult(state, searchIdentifier) || [];
    });
    React.useEffect(function () {
        if (!debouncedSearch) {
            return;
        }
        dispatch(systemActions.search.actions.start({
            identifier: searchIdentifier,
            query: debouncedSearch,
        }));
    }, [searchIdentifier, debouncedSearch]);
    // Utils
    var getHelpUrl = function () {
        switch (getLocale()) {
            case ELocale.NL:
                return "https://rentiodashboard.zendesk.com/hc/nl";
            case ELocale.FR:
                return "https://rentiodashboard.zendesk.com/hc/fr-be";
            default:
                return "https://rentiodashboard.zendesk.com/hc/en-be";
        }
    };
    // Event handles
    var handleSearchChange = function (_a) {
        var _b = _a.currentTarget.value, value = _b === void 0 ? "" : _b;
        setQueryInput(value);
    };
    var handleSearchFocus = function (e) {
        var _a, _b, _c, _d, _e;
        (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
        (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
        (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
        (_e = (_d = e.nativeEvent) === null || _d === void 0 ? void 0 : _d.stopImmediatePropagation) === null || _e === void 0 ? void 0 : _e.call(_d);
        hasFocus.current = true;
        setShowSearchResults(true);
    };
    var handleSearchBlur = function (event) {
        hasFocus.current = false;
        // Small delay to take click on dropdown item in account
        setTimeout(function () {
            setShowSearchResults(false);
        }, 250);
    };
    var handleSearchLinkClick = function () {
        setQueryInput("");
    };
    var handleFAQClick = function () {
        var url = getHelpUrl();
        utils.general.openWindow(url);
    };
    var handleMailClick = function () {
        window.location.href = "mailto:".concat(getLocalizedText("help_menu.action.mail.value"));
    };
    var handleCallClick = function () {
        window.location.href = "tel:".concat(getLocalizedText("help_menu.action.phone.value"));
    };
    // const handleContactClick = () => {
    //   triggerContactForm();
    // };
    var handleChatClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            triggerLiveChat();
            return [2 /*return*/];
        });
    }); };
    var helpActions = [
        {
            content: ts.helpMenuActionFAQ(),
            onClick: handleFAQClick,
        },
        // {
        //   content: ts.helpMenuActionContact(),
        //   onClick: handleContactClick,
        // },
        {
            content: ts.helpMenuActionContact(),
            onClick: handleChatClick,
        },
        {
            content: ts.helpMenuActionMail(),
            onClick: handleMailClick,
        },
        {
            content: ts.helpMenuActionPhone(),
            onClick: handleCallClick,
        },
    ];
    // Renders
    var renderUserAction = function (item, index) {
        return (_jsx(ResourceListItem, __assign({ media: item.media, mediaSize: "medium", link: item.url, onClick: item.onClick, item: item, boxProps: { p: ESpacings.tight } }, { children: item.content }), "user-action-".concat(index)));
    };
    var renderUserInfoHeader = function () {
        if (!user) {
            return null;
        }
        if (!contactMe || !contactMeMaster) {
            // Insurance only has user
            return (_jsxs(TextStyle, __assign({ variation: "strong", element: "div" }, { children: [user.firstname, " ", user.lastname] })));
        }
        var isCompany = user.personalAccountId !== user.activeAccountId;
        if (isCompany) {
            return (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ element: "div", variation: "strong" }, { children: contactMeMaster.company
                            ? contactMeMaster.company
                            : "".concat(contactMeMaster.firstname, " ").concat(contactMeMaster.lastname) })), _jsxs(TextStyle, __assign({ element: "div" }, { children: [contactMe.firstname, " ", contactMe.lastname] }))] }));
        }
        return (_jsxs(TextStyle, __assign({ variation: "strong", element: "div" }, { children: [contactMe.firstname, " ", contactMe.lastname] })));
    };
    var renderUserInfo = function () {
        if (!user) {
            return null;
        }
        if (!contactMe || !contactMeMaster) {
            // Insurance only has user
            return (_jsxs(TextStyle, __assign({ element: "div", variation: "strong", size: "medium" }, { children: [user.firstname, " ", user.lastname] })));
        }
        var isCompany = user.personalAccountId !== user.activeAccountId;
        if (isCompany) {
            return (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ element: "div", variation: "subdued" }, { children: contactMeMaster.company
                            ? contactMeMaster.company
                            : "".concat(contactMeMaster.firstname, " ").concat(contactMeMaster.lastname) })), _jsxs(TextStyle, __assign({ element: "div", variation: "strong", size: "medium" }, { children: [contactMe.firstname, " ", contactMe.lastname] }))] }));
        }
        return (_jsxs(TextStyle, __assign({ variation: "strong", element: "div", size: "medium" }, { children: [contactMe.firstname, " ", contactMe.lastname] })));
    };
    var profile = user || contactMe;
    return (_jsx(S.Wrapper, { children: _jsxs(S.Header, __assign({ role: "banner" }, { children: [_jsxs(S.ContentLeft, { children: [_jsx(S.Logo, __assign({ to: "/" }, { children: _jsx(Logo, { type: "icon", size: "medium" }) })), showSearch && (_jsx(S.SearchFormWrapper, __assign({ hasFocus: hasFocus.current }, { children: _jsx(S.SearchForm, { children: _jsx(DropdownMenu, { children: _jsxs(S.SearchFormContent, __assign({ hasFocus: hasFocus.current }, { children: [_jsx(S.SearchFormIcon, { children: _jsx(Icon, { source: "search" }) }), _jsx(S.SearchFormField, { autoComplete: "off", value: queryInput, placeholder: ts.searchPlaceholder(), onChange: handleSearchChange, name: "searchValue", id: "searchValue", onFocus: handleSearchFocus, onBlur: handleSearchBlur })] })), extraData: {
                                        searchResults: searchResults,
                                        handleSearchLinkClick: handleSearchLinkClick,
                                        queryInput: queryInput,
                                    }, renderContent: function (closeDropdownMenu) { return (_jsx(SearchList, { results: searchResults, linkHandler: handleSearchLinkClick, searchValue: queryInput })); }, tooltipShown: showSearchResults, triggerProps: {
                                        trigger: "none",
                                        placement: "bottom-start",
                                        modifiers: {
                                            setWidth: {
                                                enabled: true,
                                                order: 840,
                                                fn: function (data) {
                                                    data.offsets.popper.left =
                                                        data.offsets.reference.left;
                                                    data.offsets.popper.right =
                                                        data.offsets.reference.right;
                                                    data.offsets.popper.width = data.styles.width =
                                                        Math.round(data.offsets.reference.width);
                                                    return data;
                                                },
                                            },
                                        },
                                    } }) }) })))] }), userMenuActions && (_jsxs(S.ContentRight, { children: __spreadArray(__spreadArray([], extraItems.map(function (extraItem, index) { return (_jsx(Box, __assign({ mr: ESpacings.base }, { children: extraItem }), index)); }), true), [showHelp && (_jsx(Box, __assign({ mr: ESpacings.base }, { children: _jsx("div", { children: _jsx(DropdownMenu, { children: _jsx("div", { children: _jsx(Icon, { source: "questionMark" }) }), actions: helpActions, actionListProps: { heading: ts.helpMenuHeading() } }) }) }))), _jsx("div", { children: _jsx(DropdownMenu, { children: _jsxs(S.Profile, { children: [_jsx("div", { children: _jsx(Icon, { source: "profile" }) }), _jsx(S.UserInfo, { children: renderUserInfoHeader() }), _jsx("div", { children: _jsx(Icon, { source: "chevronDown" }) })] }), extraData: {
                                    profile: profile,
                                    renderUserInfo: renderUserInfo,
                                    userMenuActions: userMenuActions,
                                }, renderContent: function (closeDropdownMenu) { return (_jsxs(S.DropdownWrap, { children: [_jsxs(S.UserProfile, { children: [profile && (_jsx(S.UserProfilePicture, { children: _jsx(ProfilePicture, { profile: profile }) })), renderUserInfo()] }), _jsx(ResourceList, { items: userMenuActions, renderItem: renderUserAction })] })); } }) })], false) }))] })) }));
};
