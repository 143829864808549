import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { registerTrigger } from "./modal.utils";
var ModalProvider = function (props) {
    var children = props.children;
    var didInitialize = React.useRef(false);
    var _a = React.useState([]), modals = _a[0], setModals = _a[1];
    // Lifecycle methods
    React.useEffect(function () {
        if (didInitialize.current) {
            return;
        }
        didInitialize.current = true;
        var genericTrigger = function (params) {
            setModals(Object.values(params.modals));
        };
        registerTrigger({
            didShowModal: genericTrigger,
            didCloseModal: genericTrigger,
            didUpdateModal: genericTrigger,
        });
    }, []);
    // Render
    return (_jsxs(_Fragment, { children: [children, modals] }));
};
export default ModalProvider;
