var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  ", "\n  margin-bottom: ", ";\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  ", "\n  margin-bottom: ", ";\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), function (_a) {
    var isInline = _a.isInline;
    return (isInline ? "" : "flex-wrap: wrap;");
}, spacing("extraTight"));
export var Description = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  margin: 0;\n"], ["\n  flex: 1;\n  margin: 0;\n"])));
export var Term = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: ", ";\n  padding-right: ", ";\n  ", " + & {\n    margin-top: ", ";\n  }\n"], ["\n  flex: ", ";\n  padding-right: ", ";\n  ", " + & {\n    margin-top: ", ";\n  }\n"])), function (_a) {
    var termWidth = _a.termWidth;
    return (termWidth ? " 0 1 ".concat(termWidth, "px") : "1 0 100%");
}, spacing(), Description, spacing("tight"));
var templateObject_1, templateObject_2, templateObject_3;
