var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as S from "../Modal.styled";
import DisplayText from "../../DisplayText";
import Button from "../../Button";
import Icon from "../../Icon";
// @ts-ignore TODO: Typescript upgrade
export var ModalHeader = function (_a) {
    var type = _a.type, heading = _a.heading, component = _a.component, onClose = _a.onClose, hasDismiss = _a.hasDismiss;
    if (!component && !heading) {
        return null;
    }
    if (component && heading) {
        return null;
    }
    if (component) {
        // @ts-ignore
        return React.createElement(component, {
            onClose: onClose,
            hasDismiss: hasDismiss,
        });
    }
    return (_jsxs(S.Header, __assign({ type: type }, { children: [_jsx("div", { children: _jsx(DisplayText, __assign({ size: "large" }, { children: heading })) }), hasDismiss && (_jsx(Button, __assign({ appearance: "plain", onClick: onClose }, { children: _jsx(Icon, { source: "close" }) })))] })));
};
