var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { animationEasing, animationSpeed, css, spacing, } from "@rentiohq/web-theme";
export var centerContent = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 auto;\n  /* padding: 0 ", "; */\n  width: 100%;\n  max-width: 1400px;\n  ", ";\n"], ["\n  margin: 0 auto;\n  /* padding: 0 ", "; */\n  width: 100%;\n  max-width: 1400px;\n  ", ";\n"])), spacing("extraLoose"), function (_a) {
    var fullWidth = _a.fullWidth;
    return fullWidth && "max-width: none";
});
export var Page = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  min-height: 100%;\n  ", ";\n  transition: background-color ", "\n    ", ";\n"], ["\n  flex: 1 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  min-height: 100%;\n  ", ";\n  transition: background-color ", "\n    ", ";\n"])), function (_a) {
    var theme = _a.theme, variation = _a.variation;
    switch (variation) {
        case "error":
            return "background-color: ".concat(theme.getColor("red", 0), ";");
        default:
            return "background-color: ".concat(theme.colors.neutral10, ";");
    }
}, animationSpeed("fast"), animationEasing("easeInOut"));
export var PageContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  /* display: flex; */\n  flex: 1 0 auto;\n  padding: ", ";\n"], ["\n  ", "\n  /* display: flex; */\n  flex: 1 0 auto;\n  padding: ", ";\n"])), centerContent, spacing("extraLoose"));
export var PageContentMain = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  /* max-width: 100%;\n  flex: 1; */\n"], ["\n  /* max-width: 100%;\n  flex: 1; */\n"])));
export var PageBanners = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), spacing());
export var PageError = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", ";\n  text-align: center;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", ";\n  text-align: center;\n"])), spacing("extraLoose"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
