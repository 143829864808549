var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared/dist/config/app.config.types";
import React from "react";
import DropdownMenu from "../DropdownMenu";
import { Checkbox } from "../Form";
import { ESpacings } from "../Grid";
import Icon from "../Icon";
import Loading from "../Loading";
import { Tooltip } from "../Tooltip";
import * as S from "./DataTable.styled";
import { Cell } from "./components/Cell";
var DataTable = React.forwardRef(function (props, ref) {
    var headings = props.headings, totals = props.totals, showTotalsInFooter = props.showTotalsInFooter, rows = props.rows, footerContent = props.footerContent, onRowClick = props.onRowClick, onSort = props.onSort, verticalSpacing = props.verticalSpacing, horizontalSpacing = props.horizontalSpacing, _a = props.sortDirection, sortDirection = _a === void 0 ? "ASC" : _a, _b = props.sortColumnIndex, sortColumnIndex = _b === void 0 ? 0 : _b, _c = props.totalPages, totalPages = _c === void 0 ? 1 : _c, emptyState = props.emptyState, _d = props.isLoadingData, isLoadingData = _d === void 0 ? undefined : _d, _e = props.asDots, asDots = _e === void 0 ? false : _e, _f = props.multiSelect, multiSelect = _f === void 0 ? false : _f, onSelectRow = props.onSelectRow, onUnselectRow = props.onUnselectRow, onSelectPage = props.onSelectPage, onUnselectPage = props.onUnselectPage, selectedIds = props.selectedIds, sortable = props.sortable, columnContentTypes = props.columnContentTypes, _g = props.verticalAlign, verticalAlign = _g === void 0 ? "top" : _g;
    // Validate data table
    if ([EEnvironment.Dev, EEnvironment.Stag].includes(CONFIG.ENVIRONMENT)) {
        var headingsCount = headings.length;
        var columnContentTypesCount = columnContentTypes.length;
        if (headingsCount !== columnContentTypesCount) {
            console.log({ headingsCount: headingsCount, columnContentTypesCount: columnContentTypesCount });
            throw new Error("Headings count and column content types count must be equal");
        }
        if (sortable) {
            var sortableCount = sortable === null || sortable === void 0 ? void 0 : sortable.length;
            if (headingsCount !== sortableCount) {
                console.log({ headingsCount: headingsCount, sortableCount: sortableCount });
                throw new Error("Headings count and sortable count must be equal");
            }
        }
    }
    // Event handlers
    var handleSort = function (headingIndex) {
        var newSortDirection = sortDirection;
        if (sortColumnIndex === headingIndex) {
            newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
        }
        return function () {
            onSort === null || onSort === void 0 ? void 0 : onSort(headingIndex, newSortDirection);
        };
    };
    // Render
    var renderHeading = function (heading, headingIndex) {
        var id = "heading-cell-".concat(headingIndex);
        var sortableHeadingProps;
        if (sortable) {
            var isSortable = sortable[headingIndex];
            var isSorted = isSortable && sortColumnIndex === headingIndex;
            var direction = isSorted ? sortDirection : "none";
            sortableHeadingProps = {
                sorted: isSorted,
                sortable: isSortable,
                sortDirection: direction,
                onSort: handleSort(headingIndex),
            };
        }
        return (_jsx(Cell, __assign({ header: true, content: heading, contentType: columnContentTypes[headingIndex], firstColumn: headingIndex === 0, verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing }, sortableHeadingProps), id));
    };
    var renderTotals = function (total, totalIndex) {
        return (_jsx(Cell, { content: total, firstColumn: multiSelect ? false : totalIndex === 0, contentType: columnContentTypes[totalIndex], verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing }, totalIndex));
    };
    var renderMultiSelectHeader = function () {
        var isChecked = false;
        var allIds = rows.filter(function (r) { return !r.multiSelectDisabled; }).map(function (r) { return r.id; });
        if (selectedIds) {
            isChecked =
                allIds.every(function (id) { return selectedIds.find(function (i) { return i === id; }) !== undefined; }) &&
                    allIds.length !== 0;
        }
        return (_jsx(Cell, { header: true, content: _jsx(Checkbox, { onChangeValue: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!isChecked && onSelectPage)
                        onSelectPage(allIds);
                    if (isChecked && onUnselectPage)
                        onUnselectPage(allIds);
                }, checked: isChecked, appearance: isChecked ? "success" : "default", error: false }), contentType: "icon", firstColumn: true, verticalSpacing: verticalSpacing, horizontalSpacing: "tight" }, "multiselect-header"));
    };
    var renderMultiSelectRowItem = function (rowIndex, id, multiSelectDisabled, multiSelectTooltip, disabled) {
        if (multiSelectDisabled === void 0) { multiSelectDisabled = false; }
        if (disabled === void 0) { disabled = false; }
        var isChecked = !!((selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.find(function (i) { return i === id; })) !== undefined);
        var checkbox = (_jsx(Checkbox, { onChangeValue: function (e) {
                e.preventDefault();
                e.stopPropagation();
                if (!isChecked && onSelectRow) {
                    onSelectRow(id);
                    return;
                }
                if (isChecked && onUnselectRow)
                    onUnselectRow(id);
            }, checked: multiSelectDisabled ? false : isChecked, appearance: isChecked ? "success" : "default", error: false, disabled: multiSelectDisabled || disabled }));
        return (_jsx(Cell, { contentType: "icon", verticalSpacing: verticalSpacing, horizontalSpacing: "tight", content: multiSelectDisabled && multiSelectTooltip ? (_jsx(Tooltip, __assign({ content: multiSelectTooltip }, { children: checkbox }))) : (checkbox) }, "cell-multiselect-row-".concat(rowIndex)));
    };
    var renderRow = function (row, rowIndex) {
        var handleRowClick = function () {
            if (onRowClick && !row.disabled)
                onRowClick(rowIndex);
        };
        return (_jsxs(S.TableRow, __assign({ urgency: row.urgency, isSelected: row.isSelected, shouldShowLastBorder: totalPages > 1, verticalAlign: verticalAlign, background: row.background, onClick: handleRowClick }, { children: [multiSelect &&
                    renderMultiSelectRowItem(rowIndex, row.id, row.multiSelectDisabled, row.multiSelectTooltip, row.disabled), row.content.map(function (content, cellIndex) {
                    var id = "cell-".concat(cellIndex, "-row-").concat(rowIndex);
                    return (_jsx(Cell, { content: content, contentType: columnContentTypes[cellIndex], verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing }, id));
                }), row.actions && (_jsx(Cell, { content: row.actions.length > 0 && (_jsx(DropdownMenu, { onOpen: row.onDropdownOpen, passDownClickEvents: false, children: _jsx("span", { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: row.actions })), contentType: "action", verticalSpacing: verticalSpacing, horizontalSpacing: horizontalSpacing }))] }), "row-".concat(rowIndex)));
    };
    var totalsMarkup = totals ? _jsx("tr", { children: totals.map(renderTotals) }) : null;
    var headingMarkup = headings ? (_jsxs("tr", { children: [multiSelect ? renderMultiSelectHeader() : null, headings === null || headings === void 0 ? void 0 : headings.map(function (heading, index) { return renderHeading(heading, index); })] })) : null;
    var headerTotalsMarkup = !showTotalsInFooter ? totalsMarkup : null;
    var footerTotalsMarkup = showTotalsInFooter ? (_jsx("tfoot", { children: totalsMarkup })) : null;
    var bodyMarkup = rows.map(renderRow);
    var footerMarkup = footerContent ? _jsx("div", { children: footerContent }) : null;
    var emptyStateMarkup = rows.length === 0 && emptyState ? (_jsx(Box, __assign({ my: ESpacings.base }, { children: emptyState }))) : null;
    var loadingData = function () {
        if (isLoadingData !== undefined && isLoadingData) {
            if (asDots) {
                return _jsx(Loading, { asDots: true });
            }
            return _jsx(Loading, {});
        }
        return null;
    };
    return (_jsxs(S.Wrapper, __assign({ ref: ref }, { children: [_jsxs(S.ScrollContainer, { children: [_jsxs(S.Table, { children: [_jsxs("thead", { children: [headingMarkup, headerTotalsMarkup] }), _jsx("tbody", { children: bodyMarkup }), footerTotalsMarkup] }), loadingData(), !isLoadingData && emptyStateMarkup] }), footerMarkup] })));
});
export default DataTable;
