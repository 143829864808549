var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// cspell:disable
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { endOfDay } from "date-fns";
import { capitalize } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEnumParam, StringParam } from "serialize-query-params";
import Grid, { ESpacings } from "../../../../components/Grid";
import Select from "../../../../components/Select";
import DateFieldInternal from "../../../../components/SharedForm/fields/DateField/DateFieldInternal";
import * as statsActions from "../../../../redux/stats/stats.actions";
import * as statsSelectors from "../../../../redux/stats/stats.selectors";
import { EStatEntity } from "../../DashboardStats.config";
import { useRange } from "../../DashboardStats.hook";
import { EStatDuration, } from "../../DashboardStats.types";
import { generateIdentifier, getAllCharts, getFrom, statEntityToOption, statScopeToOption, } from "../../DashboardStats.utils";
var StatOptions = function (props) {
    var _a;
    var page = props.page;
    var chartOptions = (_a = getAllCharts()) === null || _a === void 0 ? void 0 : _a.map(function (chart) { return statEntityToOption(chart.entity); }).sort(function (a, b) { return String(a.label).localeCompare(b.label); });
    var _b = useRange(), 
    // Values
    statDuration = _b.statDuration, from = _b.from, to = _b.to, statInterval = _b.statInterval, 
    // Handlers
    onSetStatDuration = _b.onSetStatDuration, onSetFrom = _b.onSetFrom, onSetTo = _b.onSetTo, onSetStatInterval = _b.onSetStatInterval, 
    // Options
    statDurationOptions = _b.statDurationOptions, statIntervalOptions = _b.statIntervalOptions;
    var _c = useQueryParams({
        statScope: StringParam,
        statEntity: createEnumParam(Object.values(EStatEntity)),
    }), queryParams = _c[0], setQueryParams = _c[1];
    var identifier = queryParams.statEntity
        ? generateIdentifier({
            entity: queryParams.statEntity,
            from: from,
            to: to,
            interval: statInterval,
            scope: queryParams.statScope,
        })
        : undefined;
    // Redux
    var dispatch = useDispatch();
    var stats = useSelector(function (state) {
        return identifier ? statsSelectors.getStats(state, identifier) : undefined;
    });
    var scopeList = (stats === null || stats === void 0 ? void 0 : stats.metadata.scopelist) || [];
    var statScope = queryParams.statScope || scopeList[0];
    // Handlers
    var handleChangeEntity = function (option) {
        setQueryParams({
            statEntity: option.value,
            statScope: undefined,
        });
    };
    var handleChangeDuration = function (option) {
        onSetStatDuration(option.value);
    };
    var handleChangeInterval = function (option) {
        onSetStatInterval(option.value);
    };
    var handleChangeFrom = function (date) {
        if (!date) {
            return;
        }
        onSetFrom(date);
    };
    var handleChangeTo = function (date) {
        if (!date) {
            return;
        }
        onSetTo(endOfDay(date));
    };
    var handleChangeScope = function (option) {
        setQueryParams({
            statScope: option.value,
        });
    };
    // Lifecycle
    React.useEffect(function () {
        fetchStats();
    }, [identifier]);
    // Data
    var fetchStats = function () {
        if (!identifier) {
            return;
        }
        if (!queryParams.statEntity) {
            return;
        }
        dispatch(statsActions.getStats.actions.start({
            identifier: identifier,
            params: {
                entity: queryParams.statEntity,
                from: from,
                to: to,
                scope: statScope,
                interval: statInterval,
                details: true,
            },
        }));
    };
    var isDetailView = page === "detail";
    var widthEntities = page === "detail" ? 2 : 3; // More space before first select if 'global' view (because of no-scopes)
    var widthScopes = page === "detail" ? 1 : 0; // No scopes on 'global' view
    return (_jsxs(Grid, __assign({ alignItems: "flex-start", justifyContent: "space-between", mb: ESpacings.loose }, { children: [_jsx(Grid.Item, __assign({ width: [1, 1, 1, widthEntities / 7] }, { children: isDetailView && (_jsx(Select, { id: "STAT_ENTITY", label: "Statistiek", options: chartOptions, value: queryParams.statEntity, onChange: handleChangeEntity })) })), _jsx(Grid.Item, __assign({ width: [1, 1, 1, 1 / 7] }, { children: _jsx(Select, { id: "STAT_DURATION", label: "Looptijd", options: statDurationOptions, value: statDuration, onChange: handleChangeDuration }) })), _jsx(Grid.Item, __assign({ width: [1, 1, 1, 1 / 7] }, { children: _jsx(DateFieldInternal, { title: capitalize(getLocalizedText("system.from")), value: from, minDate: getFrom(EStatDuration.All), maxDate: to, onChange: handleChangeFrom, hideIcon: true }) })), _jsx(Grid.Item, __assign({ width: [1, 1, 1, 1 / 7] }, { children: _jsx(DateFieldInternal, { title: capitalize(getLocalizedText("system.to")), value: to, minDate: from, maxDate: new Date(), onChange: handleChangeTo, hideIcon: true }) })), _jsx(Grid.Item, __assign({ width: [1, 1, 1, 1 / 7] }, { children: _jsx(Select, { id: "STAT_INTERVAL", label: "Interval", options: statIntervalOptions, value: statInterval, onChange: handleChangeInterval }) })), _jsx(Grid.Item, __assign({ width: [1, 1, 1, widthScopes / 7] }, { children: isDetailView && (_jsx(Select, { id: "STAT_SCOPE", 
                    // cspell:disable-next-line
                    label: "Onderdeel", options: scopeList.map(function (scope) { return statScopeToOption(scope); }), value: statScope || undefined, onChange: handleChangeScope })) }))] })));
};
export default StatOptions;
