import { addIban } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
export var isPerformingSubmitActionSelector = function () { return function (state) {
    return contactSelectors.isCreatingIban(state);
}; };
export var performingSubmitActionResultSelector = function (accountId, contactId) { return function (state) {
    var ibans = contactSelectors.getIbans(state, contactId, accountId) || [];
    if (ibans.length === 0) {
        return false;
    }
    return ibans[0].id;
}; };
export var performingSubmitActionResultSelectorIban = function (accountId, contactId) { return function (state) {
    var ibans = contactSelectors.getIbans(state, contactId, accountId) || [];
    if (ibans.length === 0) {
        return false;
    }
    return ibans[0];
}; };
export var performingSubmitActionErrorSelector = function () { return function (state) {
    return contactSelectors.createIbanError(state);
}; };
export var submitActionCreator = function (accountId, contactId, onSuccess) {
    return function (formData) {
        return addIban.actions.start({ accountId: accountId, contactId: contactId, iban: formData, onSuccess: onSuccess });
    };
};
