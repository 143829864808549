var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { EField as EContactField } from "../forms/addContact/schema.addContact.types";
var allPropertySchemas = (_a = {},
    _a[EContactField.Address] = {
        type: ["string", "object"],
        required: ["street", "number", "zip", "city", "country"],
        properties: {
            street: { type: "string" },
            number: { type: "string" },
            zip: { type: "string" },
            city: { type: "string" },
            country: { type: "string" },
        },
    },
    _a[EContactField.DateOfBirth] = { type: "string" },
    _a[EContactField.PlaceOfBirth] = {
        type: ["object"],
        required: ["city", "country"],
        properties: {
            city: { type: "string" },
            country: { type: "string" },
        },
    },
    _a[EContactField.Nationality] = { type: "string" },
    _a[EContactField.NationalRegisterNumber] = { type: "string" },
    _a[EContactField.ForeignIdentityNumber] = { type: "string" },
    _a[EContactField.VATNumber] = { type: "string" },
    _a[EContactField.Company] = { type: "string" },
    _a);
var isConsideredBelgian = function (_a) {
    var nationalRegisterNumber = _a.nationalRegisterNumber, nationality = _a.nationality;
    return nationality === "BE" || !!nationalRegisterNumber;
};
export var getRequiredIdentityNumberKey = function (contact) {
    var consideredAsBelgian = isConsideredBelgian(contact);
    if (consideredAsBelgian) {
        return EContactField.NationalRegisterNumber;
    }
    return EContactField.ForeignIdentityNumber;
};
export var getAllRequiredFields = function (params) {
    var contact = params.contact, requiredFields = params.requiredFields, _a = params.appendIdentityNumberToRequiredFields, appendIdentityNumberToRequiredFields = _a === void 0 ? false : _a;
    // Get required
    var required = __spreadArray([], requiredFields, true);
    var identityNumberKey = getRequiredIdentityNumberKey(contact);
    if (appendIdentityNumberToRequiredFields) {
        required.push(identityNumberKey);
    }
    // Get properties
    var properties = {};
    requiredFields.forEach(function (requiredField) {
        switch (requiredField) {
            case "address":
                properties.address = allPropertySchemas.address;
                break;
            case "dateOfBirth":
                properties.dateOfBirth = allPropertySchemas.dateOfBirth;
                break;
            case "nationality":
                properties.nationality = allPropertySchemas.nationality;
                break;
            case "placeOfBirth":
                properties.placeOfBirth = allPropertySchemas.placeOfBirth;
                break;
            case "VATNumber":
                properties.VATNumber = allPropertySchemas.VATNumber;
                break;
        }
    });
    if (appendIdentityNumberToRequiredFields) {
        properties[identityNumberKey] = allPropertySchemas[identityNumberKey];
    }
    return {
        // Validation for schema in modal
        required: required,
        // Validation for list item in form
        properties: properties,
    };
};
export var getAllRequiredContactFields = function (params) { return getAllRequiredFields(__assign({ isCompany: false }, params)); };
export var getAllRequiredCompanyFields = function (params) { return getAllRequiredFields(__assign({ isCompany: true }, params)); };
