import { getLocalizedText } from "./i18n/i18n.utils";
export var avjErrorsToFieldErrors = function (errors) {
    if (errors === true || !errors) {
        return {};
    }
    var result = {};
    errors.forEach(function (error) {
        var property = error.dataPath.replace(/^\./, "");
        result[property] = ["required", "type"].includes(error.keyword)
            ? getLocalizedText("system.form.error.required")
            : getLocalizedText("system.form.error.format.".concat(property));
    });
    return result;
};
