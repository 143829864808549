var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as activityActions from "./activity.actions";
import * as activityApi from "./activity.api";
import { getActivities as getActivitiesSelector } from "./activity.selectors";
function getActivitiesStartFlow(_a) {
    var activityIdentifier, refetch, activityTypes, extraFilterData, limit, activities, _b, data, isExhausted, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                activityIdentifier = payload.activityIdentifier, refetch = payload.refetch, activityTypes = payload.activityTypes, extraFilterData = payload.extraFilterData, limit = payload.limit;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                _b = getActivitiesSelector;
                return [4 /*yield*/, select()];
            case 2:
                activities = _b.apply(void 0, [_c.sent(), activityIdentifier]);
                return [4 /*yield*/, call(activityApi.getActivities, refetch ? 0 : (activities || []).length, activityTypes, { where: extraFilterData }, limit)];
            case 3:
                data = (_c.sent()).data;
                isExhausted = data.length < (limit || activityApi.FETCH_LIMIT);
                return [4 /*yield*/, put(activityActions.getActivities.actions.success({
                        activityIdentifier: activityIdentifier,
                        activities: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_1 = _c.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(activityActions.getActivities.actions.failure({
                        error: error,
                        activityIdentifier: activityIdentifier,
                    }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getActivitiesPagedStartFlow(_a) {
    var identifier, page, _b, limit, filterData, skip, data, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                identifier = payload.identifier, page = payload.page, _b = payload.limit, limit = _b === void 0 ? activityApi.FETCH_LIMIT : _b, filterData = payload.filterData;
                skip = (page - 1) * limit;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(activityApi.getActivities, skip, [], filterData, limit)];
            case 2:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(activityActions.getActivitiesPaged.actions.success({
                        identifier: identifier,
                        activities: data,
                        page: page,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _c.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(activityActions.getActivitiesPaged.actions.failure({
                        identifier: identifier,
                        page: page,
                        error: error,
                    }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                    var payload = _a.payload;
                    return payload.activityIdentifier;
                }, activityActions.getActivities.types.START, getActivitiesStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(activityActions.getActivitiesPaged.types.START, getActivitiesPagedStartFlow)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
