import { AsYouType, parsePhoneNumberFromString, isValidNumber, } from "libphonenumber-js";
var cleanPhone = function (phone) {
    if (!phone) {
        return;
    }
    var result = phone;
    result.replace(" ", "");
    if (result && result.length > 0) {
        // Replace '00' at start with '+'
        if (result.startsWith("00")) {
            result = "+" + result.substring(2);
        }
        // Replace '04' at start with '+324'
        if (result.startsWith("04")) {
            result = "+32 4" + result.substring(2);
        }
        // Replace '+3204' at start with '+324'
        if (result.startsWith("+3204")) {
            result = "+32 4" + result.substring(5);
        }
        // Replace '+3200' at start with '+32'
        if (result.startsWith("+3200")) {
            result = "+32" + result.substring(5);
        }
    }
    return result;
};
export var formatPhoneWhileTyping = function (phone) {
    var cleaned = cleanPhone(phone);
    var parsed = parsePhoneNumberFromString(cleaned || "");
    if (!parsed) {
        parsed = cleaned;
    }
    if (!cleaned) {
        return;
    }
    return new AsYouType().input(cleaned);
};
export var formatPhoneWhileTypingWithoutSpaces = function (phone) {
    var parsed = formatPhoneWhileTyping(phone);
    if (!parsed) {
        return;
    }
    return parsed.replace(/ /g, "");
};
export var formatPhone = function (phone) {
    var cleaned = cleanPhone(phone);
    var parsed = parsePhoneNumberFromString(cleaned || "");
    if (!parsed) {
        return;
    }
    return parsed.formatInternational();
};
export var isValidPhone = function (phone) { return !!phone && isValidNumber(phone); };
