var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
var sizeMap = {
    small: "32px",
    medium: "64px",
    large: "128px",
};
export var CircleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  margin-right: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  margin-right: ", ";\n"])), function (_a) {
    var size = _a.size;
    return sizeMap[size];
}, function (_a) {
    var size = _a.size;
    return sizeMap[size];
}, spacing("extraTight"));
export var IndicatorCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 50%;\n  border: 2px solid\n    ", ";\n  width: ", ";\n  height: ", ";\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: white;\n  font-weight: bold;\n  cursor: pointer;\n"], ["\n  border-radius: 50%;\n  border: 2px solid\n    ", ";\n  width: ", ";\n  height: ", ";\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: white;\n  font-weight: bold;\n  cursor: pointer;\n"])), function (_a) {
    var selected = _a.selected, theme = _a.theme;
    return selected ? theme.colors.warning : theme.colors.grey;
}, function (_a) {
    var size = _a.size;
    return sizeMap[size];
}, function (_a) {
    var size = _a.size;
    return sizeMap[size];
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.grey;
});
var templateObject_1, templateObject_2;
