var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var Step = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", "px;\n    /* padding-top: ", "; */\n    /* border-top: 1px solid ", "; */\n  }\n"], ["\n  & + & {\n    margin-top: ", "px;\n    /* padding-top: ", "; */\n    /* border-top: 1px solid ", "; */\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.baseUnit * 10;
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing("loose");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
});
export var ArrayItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  & + & {\n    margin-top: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing();
});
var templateObject_1, templateObject_2;
