var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Grid from "../Grid";
import * as S from "./ButtonGroup.styled";
var ButtonGroup = function (props) {
    var children = props.children, _a = props.spacing, spacing = _a === void 0 ? "base" : _a, _b = props.align, align = _b === void 0 ? "center" : _b;
    return (_jsx(S.ButtonGroup, __assign({ spacing: spacing, justifyContent: align }, { children: React.Children.map(children, function (child, idx) {
            if (!child) {
                return null;
            }
            return _jsx(Grid.Item, { children: child }, idx);
        }) })));
};
export default ButtonGroup;
