var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as t from "../../../../services/translationService";
import { NumberInput } from "./components/NumberInput";
var NumberField = /** @class */ (function (_super) {
    __extends(NumberField, _super);
    function NumberField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NumberField.prototype.render = function () {
        return _jsx(NumberInput, __assign({}, this.props));
    };
    // Validate
    NumberField.validate = function (formData, errors, schemaGenerator, uiSchema, mergedPartialFormData) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:number") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            var schema = schemaGenerator({});
            var fieldSchema = schema.properties[fieldName];
            // @ts-ignore
            if (fieldSchema.getMinimum) {
                // @ts-ignore
                var minimum = fieldSchema.getMinimum(__assign(__assign({}, (mergedPartialFormData || {})), (formData || {})));
                if (minimum > data) {
                    fieldError.addError(t.formErrorNumberMin({
                        value: "".concat(minimum),
                    }));
                }
            }
            // @ts-ignore
            if (fieldSchema.getMaximum) {
                // @ts-ignore
                var maximum = fieldSchema.getMaximum(__assign(__assign({}, (mergedPartialFormData || {})), (formData || {})));
                if (maximum && maximum < data) {
                    fieldError.addError(t.formErrorNumberMax({
                        value: "".concat(maximum),
                    }));
                }
            }
        });
    };
    return NumberField;
}(React.PureComponent));
export { NumberField };
