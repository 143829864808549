import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as S from "./Frame.styled";
import { Header } from "./components";
var Frame = function (props) {
    var children = props.children, navigation = props.navigation, _a = props.userMenuActions, userMenuActions = _a === void 0 ? [] : _a, _b = props.showHelp, showHelp = _b === void 0 ? false : _b, _c = props.showSearch, showSearch = _c === void 0 ? false : _c, headerItems = props.headerItems;
    return (_jsxs(S.Wrap, { children: [_jsx(S.HeaderWrap, { children: _jsx(Header, { showHelp: showHelp, showSearch: showSearch, extraItems: headerItems, userMenuActions: userMenuActions }) }), _jsxs(S.ContentWrap, { children: [_jsxs(S.SidebarWrap, { children: [_jsx(S.SidebarBackground, {}), _jsx(S.NavigationWrap, { children: navigation })] }), _jsx(S.ChildrenWrap, { children: _jsx(S.ChildrenInnerWrap, { children: children }) })] })] }));
};
var FrameMemo = React.memo(Frame);
export default FrameMemo;
