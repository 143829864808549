var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights, css, iconSize } from "@rentiohq/web-theme";
var defaultProfile = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  font-size: 2em;\n  font-weight: ", ";\n  border-radius: 50%;\n  overflow: hidden;\n  padding-bottom: 100%;\n"], ["\n  background: ", ";\n  font-size: 2em;\n  font-weight: ", ";\n  border-radius: 50%;\n  overflow: hidden;\n  padding-bottom: 100%;\n"])), function (props) {
    return !!props.profile.company
        ? props.theme.colors.neutral20
        : props.theme.colors.neutral30;
}, FontWeights.SemiBold);
var lightProfile = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid gray;\n  background: ", ";\n  font-size: 11px;\n  color: ", ";\n  font-weight: ", ";\n"], ["\n  border: 1px solid gray;\n  background: ", ";\n  font-size: 11px;\n  color: ", ";\n  font-weight: ", ";\n"])), function (props) {
    return !!props.profile.company ? "transparent" : props.theme.colors.neutral10;
}, function (props) { return props.theme.colors.neutral70; }, FontWeights.Regular);
export var InitialsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  border-radius: 50%;\n  overflow: hidden;\n  ", ";\n"], ["\n  ", "\n  position: relative;\n  border-radius: 50%;\n  overflow: hidden;\n  ", ";\n"])), function (_a) {
    var size = _a.size;
    return size &&
        "\n    height: ".concat(iconSize(size), ";\n    width: ").concat(iconSize(size), ";\n  ");
}, function (_a) {
    var variationProfile = _a.variationProfile;
    switch (variationProfile) {
        case "light":
            return lightProfile;
        case "default":
            return defaultProfile;
        default:
            return defaultProfile;
    }
});
export var InitialsContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
