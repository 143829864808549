import { formatAddress } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.utils";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { ILead } from "@rentiohq/shared/dist/types/insurance.lead.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import {
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import assets from "assets";
import React from "react";
import Truncate from "react-truncate";
import LeadStatusLozenge from "../LeadStatusLozenge";
import * as S from "./LeadListItem.styles";

interface IProps {
  lead: ILead;
}

const LeadListItem: React.FC<IProps> = props => {
  const { lead } = props;

  // Custom hooks
  const { quote } = useGetQuote({
    leadId: lead.id,
    quoteId: lead.activeQuoteId,
  });

  // Render
  const { createdAt, referrer, contactFirstname, contactLastname } = lead;

  const renderQuoteOptions = () => {
    if (!quote) {
      return (
        <>
          <S.OptionIcon src={assets.ICO_HOUSEHOLD_EFFECTS} isActive={false} />
          <S.OptionIcon src={assets.ICO_THEFT} isActive={false} />
          <br />
          <S.OptionIcon src={assets.ICO_CAR} isActive={false} />
          <S.OptionIcon src={assets.ICO_LAW} isActive={false} />
        </>
      );
    }

    const {
      optionContentPremium,
      optionTheftPremium,
      optionMotorVehiclesPremium,
      optionLegalAssistancePremium,
    } = quote;

    return (
      <>
        <S.OptionIcon
          src={assets.ICO_HOUSEHOLD_EFFECTS}
          isActive={optionContentPremium}
        />
        <S.OptionIcon src={assets.ICO_THEFT} isActive={optionTheftPremium} />
        <br />
        <S.OptionIcon
          src={assets.ICO_CAR}
          isActive={optionMotorVehiclesPremium}
        />
        <S.OptionIcon
          src={assets.ICO_LAW}
          isActive={optionLegalAssistancePremium}
        />
      </>
    );
  };

  return (
    <ResourceListItem item={lead} link={`/leads/${lead.id}`}>
      <S.Wrap>
        <S.DateWrap>
          <TextStyle>{formatDate(createdAt)}</TextStyle>
        </S.DateWrap>

        <S.IdWrap>
          <TextStyle variation={quote?.publicCode ? "default" : "subdued"}>
            <Truncate
              width={S.WIDTH_ID}
              trimWhitespace={true}
              style={{ width: S.WIDTH_ID, fontSize: 14 }}
            >
              {quote?.publicCode || "-"}
            </Truncate>
          </TextStyle>
        </S.IdWrap>

        <S.AddressWrap>
          <TextStyle>
            <Truncate
              width={S.WIDTH_ADDRESS}
              trimWhitespace={true}
              style={{ width: S.WIDTH_ADDRESS, fontSize: 14 }}
            >
              {formatAddress(lead)}
            </Truncate>
          </TextStyle>
        </S.AddressWrap>

        <S.ContactWrap>
          <TextStyle>{`${contactFirstname} ${contactLastname}`}</TextStyle>
        </S.ContactWrap>

        <S.ReferrerWrap>
          <TextStyle variation={referrer ? "default" : "subdued"}>
            {referrer || "-"}
          </TextStyle>
        </S.ReferrerWrap>

        <S.QuoteOptionsWrap>{renderQuoteOptions()}</S.QuoteOptionsWrap>

        <S.PriceWrap>
          <TextStyle variation={quote ? "code" : "subdued"}>
            {quote ? formatCurrency(quote.calculationValue) : "-"}
          </TextStyle>
        </S.PriceWrap>

        <S.StatusWrap>
          <LeadStatusLozenge lead={lead} />
        </S.StatusWrap>
      </S.Wrap>
    </ResourceListItem>
  );
};

export default LeadListItem;
