var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { EFontSizes, FontWeights, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
// loosely based on https://www.modularscale.com/?14&px&1.25 (round up)
export var displayTextSizes = {
    extraLarge: EFontSizes.SuperLarge,
    large: EFontSizes.ExtraExtraLarge,
    medium: EFontSizes.ExtraLarge,
    small: EFontSizes.Large,
    extraSmall: EFontSizes.Normal,
};
export var StyledDisplayText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &:not(:last-child) {\n    ", ";\n  }\n  ", ";\n  letter-spacing: 0.05rem;\n  ", ";\n  font-weight: ", ";\n  line-height: 1.3;\n\n  h1& {\n    font-weight: ", ";\n  }\n"], ["\n  &:not(:last-child) {\n    ", ";\n  }\n  ", ";\n  letter-spacing: 0.05rem;\n  ", ";\n  font-weight: ", ";\n  line-height: 1.3;\n\n  h1& {\n    font-weight: ", ";\n  }\n"])), function (_a) {
    var space = _a.space;
    return "margin-bottom: ".concat(spacing(space));
}, function (_a) {
    var _b = _a.size, size = _b === void 0 ? "medium" : _b, _c = _a.preventUppercase, preventUppercase = _c === void 0 ? false : _c, theme = _a.theme;
    return "\n    font-size: ".concat(rem(theme.fontSizes[displayTextSizes[size]], theme.baseFontSize), ";\n    ").concat(size === "extraSmall" && !preventUppercase
        ? "text-transform: uppercase"
        : "", ";\n  ");
}, function (_a) {
    var subdued = _a.subdued, theme = _a.theme;
    return subdued &&
        "\n    color: ".concat(theme.getColor("gray", 400), ";\n    ");
}, FontWeights.SemiBold, FontWeights.Bold);
var templateObject_1;
