var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { animationEasing, animationSpeed, baseUnit, spacing, } from "@rentiohq/web-theme";
import { rem, transparentize } from "polished";
export var Background = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 101;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  background: ", ";\n\n  .drawer-enter & {\n    opacity: 0;\n  }\n  .drawer-enter-active & {\n    opacity: 1;\n    transition: opacity ", "\n      ", ";\n  }\n  .drawer-exit & {\n    opacity: 1;\n  }\n  .drawer-exit-active & {\n    opacity: 0;\n    transition: opacity ", "\n      ", ";\n    transition-delay: 100ms;\n  }\n"], ["\n  position: fixed;\n  z-index: 101;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: block;\n  background: ", ";\n\n  .drawer-enter & {\n    opacity: 0;\n  }\n  .drawer-enter-active & {\n    opacity: 1;\n    transition: opacity ", "\n      ", ";\n  }\n  .drawer-exit & {\n    opacity: 1;\n  }\n  .drawer-exit-active & {\n    opacity: 0;\n    transition: opacity ", "\n      ", ";\n    transition-delay: 100ms;\n  }\n"])), function (props) {
    return transparentize(0.35, props.theme.getColor("gray", 1000));
}, animationSpeed("fast"), animationEasing("easeInOut"), animationSpeed("extraFast"), animationEasing("easeInOut"));
// loosely based on https://www.modularscale.com/?14&px&1.25 (round up)
var widths = {
    medium: "".concat(120 * baseUnit, "px"),
    wide: "".concat(150 * baseUnit, "px"),
    half: "50vw",
    extended: "95vw",
    full: "100vw",
};
export var Drawer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  width: ", ";\n  max-width: 100%;\n  height: 100vh;\n  top: 0;\n  ", ";\n  background-color: ", ";\n  z-index: 102;\n  display: flex;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);\n  ", ";\n"], ["\n  position: fixed;\n  width: ", ";\n  max-width: 100%;\n  height: 100vh;\n  top: 0;\n  ", ";\n  background-color: ", ";\n  z-index: 102;\n  display: flex;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);\n  ", ";\n"])), function (_a) {
    var width = _a.width;
    return widths[width];
}, function (_a) {
    var position = _a.position;
    return (position === "left" ? "left: 0" : "right: 0");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var position = _a.position;
    return "\n    .drawer-enter & {\n      transform: translateX(".concat(position === "left" ? "-100%" : "100%", ");\n    }\n    .drawer-enter-active & {\n      transform: translateX(0);\n      transition: all ").concat(animationSpeed("extraFast"), " ").concat(animationEasing("easeOutCubic"), ";\n      transition-delay: 100ms;\n    }\n    .drawer-exit & {\n      transform: translateX(0);\n    }\n    .drawer-exit-active & {\n      transform: translateX(").concat(position === "left" ? "-100%" : "100%", ");\n      transition: all ").concat(animationSpeed("extraFast"), " ").concat(animationEasing("easeOutSine"), ";\n    }\n  ");
});
export var DrawerAside = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: ", ";\n  margin-top: ", ";\n  padding-top: ", ";\n  padding-bottom: ", ";\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  width: ", ";\n  margin-top: ", ";\n  padding-top: ", ";\n  padding-bottom: ", ";\n  flex-direction: column;\n  align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return rem("64px", theme.baseFontSize);
}, spacing(), spacing("extraTight"), spacing());
export var DrawerContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100%;\n  overflow: auto;\n  flex: 1;\n  ", "\n"], ["\n  height: 100%;\n  overflow: auto;\n  flex: 1;\n  ", "\n"])), function (_a) {
    var isFullWidth = _a.isFullWidth;
    if (isFullWidth) {
        return;
    }
    else {
        return "\n        margin-top: ".concat(spacing(), ";\n        margin-bottom: ").concat(spacing(), ";\n        padding-right: ").concat(spacing(), ";\n      ");
    }
});
export var DrawerClose = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 2;\n  button {\n    padding: ", ";\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 2;\n  button {\n    padding: ", ";\n  }\n"])), spacing());
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
