var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights } from "@rentiohq/web-theme";
import { rem } from "polished";
var sizes = {
    extraLarge: "28px",
    large: "22px",
    medium: "16px",
    default: "14px",
    small: "12px",
    extraSmall: "10px",
};
var colorVariations = ["subdued", "negative", "positive", "warn", "info"];
export var TextStyle = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", ";\n  word-wrap: break-word;\n"], ["\n  ", "\n  ", ";\n  word-wrap: break-word;\n"])), function (_a) {
    var variations = _a.variations, theme = _a.theme;
    var VariationToColor = {
        subdued: theme.getColor("gray", 400),
        negative: theme.getColor("red", 800),
        positive: theme.getColor("green", 800),
        warn: theme.getColor("yellow", 800),
        info: theme.getColor("primary", 1000),
    };
    return variations
        ? variations.map(function (variation) {
            switch (true) {
                case colorVariations.includes(variation):
                    return "color: ".concat(VariationToColor[variation], ";");
                case variation === "strong":
                    return "font-weight: ".concat(FontWeights.SemiBold, ";");
                case variation === "code":
                    return "font-family: ".concat(theme.fonts.financial, ";");
                default:
                    return "";
            }
        })
        : "";
}, function (_a) {
    var size = _a.size, theme = _a.theme;
    return "\n    font-size: ".concat(rem(sizes[size], theme.baseFontSize), ";\n  ");
});
var templateObject_1;
