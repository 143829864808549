import * as brokerSelectors from "../../redux/broker/broker.selectors";
import { createContact, createContactBroker, updateContact, updateContactBroker, } from "../../redux/contact/contact.actions";
import { EContactCustomId } from "../../redux/contact/contact.types";
import { getContact } from "./schema.addContact.actions.utils";
import { EField } from "./schema.addContact.types";
export var isPerformingSubmitActionSelector = function (contactId) { return function (state) {
    if (contactId) {
        var updateState = state.contact.contactsById[contactId];
        if (updateState) {
            return updateState.isUpdating || false;
        }
        return false;
    }
    return state.contact.isCreatingContact;
}; };
export var performingSubmitActionResultSelector = function (contactId) { return function (state) {
    if (contactId) {
        // TODO: Check
        var me = state.contact.contacts[EContactCustomId.Me];
        if (me && me.id === contactId) {
            return me;
        }
        return state.contact.contacts[contactId];
    }
    return state.contact.createdContact;
}; };
export var performingSubmitActionErrorSelector = function (contactId) { return function (state) {
    if (contactId) {
        var updateState = state.contact.contactsById[contactId];
        if (updateState) {
            return updateState.updateError;
        }
        return;
    }
    return state.contact.createContactError;
}; };
export var submitActionCreator = function (contactId, baseAccountId, prefilledAddress, optionalFields) {
    return function (formData) {
        var contact = getContact(formData, contactId, baseAccountId, prefilledAddress, optionalFields);
        if (contactId) {
            return updateContact.actions.start({ contactId: contactId, contact: contact });
        }
        var sendInvite = !(formData[EField.SendInvite] === "no");
        return createContact.actions.start({
            contact: contact,
            sendInvite: sendInvite,
        });
    };
};
export var performingSubmitBrokerActionResultSelector = function (brokerId, customId) {
    return function (state) {
        var contact = state.contact.createdContact;
        if (customId) {
            contact = state.contact.contacts[customId];
        }
        var broker = brokerSelectors.detail.detail(state, brokerId);
        return {
            contact: contact,
            broker: broker,
        };
    };
};
export var submitBrokerContactActionCreator = function (brokerId, customId, contactId) {
    return function (formData) {
        var contact = getContact(formData);
        var IDCardFrontside = formData.IDCardFrontside, IDCardBackside = formData.IDCardBackside;
        if (contactId) {
            return updateContactBroker.actions.start({
                brokerId: brokerId,
                contactId: contactId,
                customId: customId,
                data: contact,
            });
        }
        return createContactBroker.actions.start({
            brokerId: brokerId,
            customId: customId,
            contact: contact,
            IDCardFrontside: IDCardFrontside ? IDCardFrontside[0] : undefined,
            IDCardBackside: IDCardBackside ? IDCardBackside[0] : undefined,
        });
    };
};
