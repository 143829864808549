var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerDirection, ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EDocumentPackageSignerStatus } from "@rentiohq/shared/dist/types/documentPackage.types";
import { getTypeData } from "../../../../utils/document";
import DocumentFetchListItem from "../../../DocumentFetchListItem";
import Icon from "../../../Icon";
import { ResourceListItem } from "../../../ResourceList";
import TextStyle from "../../../TextStyle";
var SignersInfo = function (_a) {
    var incompleteSignersCount = _a.incompleteSignersCount, signersCount = _a.signersCount;
    return (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08, direction: ESpacerDirection.Horizontal }), _jsx(Icon, { source: "contentPenWrite", size: "small", color: incompleteSignersCount > 0 ? "red" : "green" }), _jsx(Spacer, { weight: ESpacerWeight.W04, direction: ESpacerDirection.Horizontal }), _jsxs(TextStyle, __assign({ variation: ["code", incompleteSignersCount > 0 ? "warn" : "positive"], size: "small" }, { children: [signersCount - incompleteSignersCount, "/", signersCount] }))] }));
};
export var InternalDocumentListItem = function (_a) {
    var item = _a.item, files = _a.files, documentCategories = _a.documentCategories, _b = _a.showActions, showActions = _b === void 0 ? true : _b, setQueryParamValue = _a.setQueryParamValue, onChangeHandler = _a.onChangeHandler;
    var getDocumentActions = function (document) {
        return showActions
            ? [
                {
                    media: _jsx(Icon, { source: getTypeData(document.mimeType || "").icon }),
                    content: getLocalizedText("document.button.view_document"),
                    onClick: function () {
                        setQueryParamValue({ documentId: document.id });
                    },
                },
                {
                    media: _jsx(Icon, { source: "bin", color: "red" }),
                    content: (_jsx(TextStyle, __assign({ variation: "negative" }, { children: getLocalizedText("system.remove") }))),
                    onClick: function () {
                        onChangeHandler(__spreadArray([], files, true).filter(function (x) { return x !== item; }));
                    },
                },
            ]
            : [];
    };
    return (_jsx(DocumentFetchListItem, { documentId: item, fetchDocumentPackage: true, renderDocument: function (document, documentPackage) {
            var signersCount = 0;
            var incompleteSignersCount = 0;
            if (documentPackage) {
                signersCount = documentPackage.signers.length;
                incompleteSignersCount = documentPackage.signers.filter(function (signer) { return signer.status !== EDocumentPackageSignerStatus.Finished; }).length;
            }
            return (_jsxs(ResourceListItem, __assign({ item: document, media: _jsx(Icon, { source: getTypeData(document.mimeType || "").icon, size: "mediumLarge" }), variation: incompleteSignersCount > 0 ? "warning" : undefined, mediaSize: "mediumLarge", boxProps: { p: 2 }, actions: getDocumentActions(document) }, { children: [_jsx("div", { children: document.filename }), _jsxs("div", __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                        } }, { children: [_jsx(TextStyle, __assign({ variation: "subdued", size: "small" }, { children: documentUtils.getCategoryName(document.categoryId, documentCategories || []) })), documentPackage && signersCount > 0 && (_jsx(SignersInfo, { signersCount: signersCount, incompleteSignersCount: incompleteSignersCount }))] }))] })));
        } }));
};
