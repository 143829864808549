var _a;
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import { stringToSnakeCase } from "../string.utils";
import { capitalize } from "../text/text.utils";
import MESSAGES_EN from "./locale/en.json";
import MESSAGES_FR from "./locale/fr.json";
import MESSAGES_NL from "./locale/nl_BE.json";
var DEFAULT_LOCALE = ELocale.EN;
var MESSAGES = (_a = {},
    _a[ELocale.EN] = MESSAGES_EN,
    _a[ELocale.NL] = MESSAGES_NL,
    _a[ELocale.FR] = MESSAGES_FR,
    _a[ELocale.INTERNAL] = {},
    _a);
var locale = DEFAULT_LOCALE;
export var getLocale = function () { return locale; };
var rawLocale = DEFAULT_LOCALE;
export var getRawLocale = function () { return rawLocale; };
export var setMappedLocale = function (newLocale) {
    locale = newLocale;
};
export var setLocale = function (newLocale) {
    rawLocale = newLocale;
    if (newLocale.toLowerCase().includes("nl")) {
        locale = ELocale.NL;
        return;
    }
    if (newLocale.toLowerCase().includes("fr")) {
        locale = ELocale.FR;
        return;
    }
    if (newLocale === ELocale.INTERNAL) {
        locale = ELocale.INTERNAL;
        return;
    }
    locale = ELocale.EN;
};
var getMessagesForLocale = function (locale) {
    return MESSAGES[locale];
};
var getMessageForId = function (id, pluralIndex, customLocale) {
    if (pluralIndex === void 0) { pluralIndex = 0; }
    var messages = getMessagesForLocale(customLocale || locale || DEFAULT_LOCALE);
    var message = messages[id];
    // Fallback if key not translated
    if (!message) {
        return id;
    }
    // Plural
    if (typeof message === "object" && message !== null) {
        var pluralStrings = ["zero", "one", "many"];
        // INFO: Get absolute value to fix negative indexes
        var pluralString = pluralStrings[Math.min(pluralStrings.length - 1, Math.abs(pluralIndex))];
        message = message[pluralString];
        if (!message) {
            return id;
        }
    }
    if (typeof message !== "string") {
        return id;
    }
    return message;
};
export var getLocalizedText = function (id, values, pluralIndex, customLocale) {
    if (values === void 0) { values = {}; }
    if (pluralIndex === void 0) { pluralIndex = 0; }
    var message = getMessageForId(id, pluralIndex, customLocale);
    // Replace translation for values
    if (values) {
        Object.keys(values).forEach(function (value) {
            var regex = new RegExp("{{".concat(value, "}}"), "g");
            message = message.replace(regex, values[value]);
        });
    }
    return message;
};
// Feedback messages
var sanitize = function (input) { return capitalize(input.toLowerCase()); };
var getReadableModel = function (modelType) {
    return getLocalizedText("system.model.".concat(stringToSnakeCase(modelType)).toLowerCase());
};
export var getGeneralCreateSuccessText = function (type) {
    return sanitize(getLocalizedText("system.create.success", {
        type: getReadableModel(type),
    }));
};
export var getGeneralCreateFailureText = function (type) {
    return sanitize(getLocalizedText("system.create.failure", {
        type: getReadableModel(type),
    }));
};
export var getGeneralUpdateSuccessText = function (type) {
    return sanitize(getLocalizedText("system.update.success", {
        type: getReadableModel(type),
    }));
};
export var getGeneralUpdateFailureText = function (type) {
    return sanitize(getLocalizedText("system.update.failure", {
        type: getReadableModel(type),
    }));
};
export var getGeneralRemoveSuccessText = function (type) {
    return sanitize(getLocalizedText("system.delete.success", {
        type: getReadableModel(type),
    }));
};
export var getGeneralRemoveFailureText = function (type) {
    return sanitize(getLocalizedText("system.delete.failure", {
        type: getReadableModel(type),
    }));
};
export var getGeneralArchiveSuccessText = function (type) {
    return sanitize(getLocalizedText("system.archive.success", {
        type: getReadableModel(type),
    }));
};
export var getGeneralUnarchiveSuccessText = function (type) {
    return sanitize(getLocalizedText("system.unarchive.success", {
        type: getReadableModel(type),
    }));
};
