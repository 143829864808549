var _a;
import { getAllSelectors } from "../generic/all.selectors";
import CONFIG from "./broker.config";
export var create = (_a = getAllSelectors(function (state) { return state[CONFIG.REDUCER_KEY]; }), _a.create), update = _a.update, detail = _a.detail;
export var getEmployeeByAccountId = function (state, accountId) {
    if (!accountId)
        return;
    return (state[CONFIG.REDUCER_KEY].employees.employees || []).find(function (e) { return e.id === accountId; });
};
export var getAllEmployees = function (state) {
    return state[CONFIG.REDUCER_KEY].employees.employees;
};
export var isFetchingEmployees = function (state) {
    return state[CONFIG.REDUCER_KEY].employees.isFetching;
};
export var getEmployeesError = function (state) {
    return state[CONFIG.REDUCER_KEY].employees.error;
};
