import { Platform } from "react-native";
import rentioTheme from "./theme.rentio";
import merge from "lodash/merge";
var rentioInsuranceTheme = merge({}, rentioTheme, {
    name: "rentio-insurance",
    colors: {
        global5: "rgb(45, 156, 219)",
        global6: "rgb(9, 82, 123)", // Rentio insurance dark blue
    },
});
export var rentioInsuranceThemeNative = merge({}, rentioInsuranceTheme, {
    name: "rentio-insurance-native",
    fonts: Platform.OS === "ios"
        ? {
            primary: "System",
            financial: "Courier",
        }
        : {
            primary: "System",
            financial: "monospace",
        },
});
export default rentioInsuranceTheme;
