import { ERentioFormField, ERentioFormFormat, } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { CONTACT_AGE_RANGE_MAX, CONTACT_AGE_RANGE_MIN, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.constants";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getMappedNationalities } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { ts } from "../../../../services";
import { EField } from "./schema.editContact.types";
export var EIdCardOptions;
(function (EIdCardOptions) {
    EIdCardOptions["BelgianIdCardNumber"] = "belgian_id_card_number";
    EIdCardOptions["PassportNumber"] = "passport_number";
})(EIdCardOptions || (EIdCardOptions = {}));
export var getSchema = function (initialValues, _a) {
    var contact = _a.contact;
    return function (defaultValues) {
        var _a;
        return ({
            required: [],
            properties: (_a = {},
                _a[EField.DateOfBirth] = {
                    default: getValue(EField.DateOfBirth, initialValues, defaultValues),
                },
                _a[EField.PlaceOfBirth] = {
                    default: getValue(EField.PlaceOfBirth, initialValues, defaultValues),
                },
                _a[EField.Nationality] = {
                    default: getValue(EField.Nationality, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedNationalities(),
                },
                _a[EField.IdType] = {
                    default: getValue(EField.IdType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        EIdCardOptions.BelgianIdCardNumber,
                        EIdCardOptions.PassportNumber,
                    ].map(function (id) { return ({
                        id: id,
                        value: ts.system("national_register_number.".concat(id)),
                    }); }),
                    showIf: function (formData) { return formData[EField.Nationality] !== "BE"; },
                },
                _a[EField.NationalRegisterNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] === "BE" ||
                            (formData[EField.Nationality] !== "BE" &&
                                formData[EField.IdType] === EIdCardOptions.BelgianIdCardNumber);
                    },
                    format: ERentioFormFormat.NationalRegisterNumber,
                    default: getValue(EField.NationalRegisterNumber, initialValues, defaultValues),
                },
                _a[EField.ForeignIdentityNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    default: getValue(EField.ForeignIdentityNumber, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": ts.contactEditContact(),
            "rentio:trackingKey": "contact_edit"
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: ts.system("date_of_birth"),
            minDate: CONTACT_AGE_RANGE_MIN,
            maxDate: CONTACT_AGE_RANGE_MAX,
            formatSummary: function (formData) { return formData && formatDate(formData); },
        },
        _a[EField.PlaceOfBirth] = {
            "ui:field": ERentioFormField.Address,
            title: ts.system("place_of_birth"),
            placeholder: ts.addressBirthAddressPlaceholder(),
            lookupTypes: ["(cities)"],
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: ts.system("nationality"),
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: ts.system("identification"),
            //info: ts.system("identification.infotext"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: ts.system("national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": "rentio:field:text",
            title: ts.system("national_register_number.foreigner"),
        },
        _a);
};
