var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { getCreateAction } from "./create.actions";
export var getCreateDefaultState = function () { return ({
    data: {},
    isCreating: false,
    createError: undefined,
    lastCreatedId: undefined,
}); };
export var registerCreateActions = function (params) {
    var entityName = params.entityName, reducer = params.reducer;
    var routine = getCreateAction({ entityName: entityName });
    // Start
    reducer.on(routine.types.START, function (state) {
        return merge({}, state, {
            isCreating: true,
            createError: undefined,
            lastCreatedId: undefined,
        });
    });
    // Success
    reducer.on(routine.types.SUCCESS, function (state, _a) {
        var _b;
        var data = _a.data;
        return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[data.id] = data, _b)), isCreating: false, createError: undefined, lastCreatedId: data.id }));
    });
    // Failure
    reducer.on(routine.types.FAILURE, function (state, _a) {
        var error = _a.error;
        return merge({}, state, {
            isCreating: false,
            createError: error,
            lastCreatedId: undefined,
        });
    });
};
