var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "@rentiohq/shared/dist/utils/api.utils";
import api from "../../utils/api/api.utils";
export var FETCH_LIMIT = 20;
export var getActivities = function (skip, activityTypes, filterData, limit) {
    if (activityTypes === void 0) { activityTypes = []; }
    if (limit === void 0) { limit = FETCH_LIMIT; }
    if (activityTypes.length > 0) {
        filterData.where = __assign(__assign({}, filterData.where), { type: { inq: activityTypes } });
    }
    var filter = __assign({ order: "datetime DESC", limit: limit, skip: skip }, filterData);
    return api.get(appendFilter("/activities", filter));
};
