import { setHours, setMinutes, setSeconds, subDays, addDays, } from "./date-fns.utils";
export var stripTime = function (date) {
    return setSeconds(setMinutes(setHours(date, 0), 0), 0);
};
export var addWeekdays = function (date, daysToAdd) {
    var subtractDays = daysToAdd < 0;
    var result = date;
    var remainingWeekdaysCounter = Math.abs(daysToAdd);
    while (remainingWeekdaysCounter > 0) {
        result = subtractDays ? subDays(result, 1) : addDays(result, 1);
        var dayOfWeek = result.getDay();
        // If not weekend
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
            remainingWeekdaysCounter -= 1;
        }
    }
    return result;
};
