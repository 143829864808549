var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ts } from "../../services";
export var setCaretPosition = function (ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
        ctrl.setSelectionRange(pos, pos);
    }
    else if (ctrl.createTextRange) {
        // IE8 and below
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", pos);
        range.moveStart("character", pos);
        range.select();
    }
};
export var mapErrors = function (error) {
    var _a, _b, _c;
    var fieldErrors = {};
    if ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) {
        var serverError_1 = (_b = error.response.data) === null || _b === void 0 ? void 0 : _b.error;
        if (serverError_1 === null || serverError_1 === void 0 ? void 0 : serverError_1.code) {
            fieldErrors = __assign(__assign({}, fieldErrors), { general: serverError_1.code });
        }
        if ((_c = serverError_1 === null || serverError_1 === void 0 ? void 0 : serverError_1.details) === null || _c === void 0 ? void 0 : _c.codes) {
            Object.keys(serverError_1.details.codes).forEach(function (key) {
                serverError_1.details.codes[key] = serverError_1.details.codes[key].map(function (code) { return ts.formError("".concat(key, ".").concat(code)); });
            });
            fieldErrors = __assign(__assign({}, fieldErrors), serverError_1.details.codes);
        }
    }
    return fieldErrors;
};
