import { rem } from "polished";
import { defaultTheme } from "../global-theme";
var ICON_SIZE_DATA = {
    extraSmall: 12,
    small: 16,
    medium: 24,
    mediumLarge: 32,
    large: 40,
    extraLarge: 64,
};
export var iconSize = function (value, calculation) {
    var sizeInPixels = ICON_SIZE_DATA[value];
    if (calculation) {
        sizeInPixels = calculation(sizeInPixels);
    }
    return rem("".concat(sizeInPixels, "px"), defaultTheme.baseFontSize);
};
