import { ERentioFormField } from "../../components/components/Form";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getValue } from "../../redux/form/form.utils";
import { EField } from "./schema.verifyPhone.types";
export var getSchema = function (initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Code],
            properties: (_a = {},
                _a[EField.Code] = {
                    default: getValue(EField.Code, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function () {
    var _a;
    return (_a = {
            "rentio:trackingKey": "verify_phone"
        },
        _a[EField.Code] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("auth.verification.token.label"),
            placeholder: getLocalizedText("auth.verification.token.placeholder"),
        },
        _a);
};
