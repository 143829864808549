import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatAddress } from "../../utils/address.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle } from "./schema.createPayment.utils";
export var ETypeSelect;
(function (ETypeSelect) {
    ETypeSelect["Once"] = "ONCE";
    ETypeSelect["Recurring"] = "RECURRING";
})(ETypeSelect || (ETypeSelect = {}));
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var _b = extraData.activeContracts, activeContracts = _b === void 0 ? [] : _b;
        return {
            required: [EField.TypeSelect],
            properties: (_a = {},
                _a[EField.Header + "0"] = {
                    default: undefined,
                },
                _a[EField.Info + "0"] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function () { return activeContracts.length === 0; },
                },
                _a[EField.TypeSelect] = {
                    default: getValue(EField.TypeSelect, initialValues, defaultValues),
                    // @ts-ignore
                    options: [ETypeSelect.Recurring, ETypeSelect.Once].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.repetition_type.".concat(id.toLowerCase())),
                        disabled: id === ETypeSelect.Recurring && activeContracts.length === 0,
                    }); }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.repetition_type.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:navigationSubTitle": formatAddress(extraData.property, false),
            "rentio:trackingKey": "payment",
            disabledOnSummary: true
        },
        _a[EField.Header + "0"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("payments.create.repetition_type.heading"),
        },
        _a[EField.Info + "0"] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("payment.payment_order.contract_info.heading"),
            subtitle: getLocalizedText("payments.create.add_costs.no_contract.content"),
        },
        _a[EField.TypeSelect] = {
            "ui:field": ERentioFormField.InlineSelect,
            // TODO: @bart
            // "ui:options": {
            //   variant: "card",
            // },
            title: getLocalizedText("payment.payment_order.repetition_type.title"),
            formatSummary: function (value) {
                return getLocalizedText("payment.payment_order.repetition_type.".concat(value.toLowerCase()));
            },
            hideOnSummaryIfEmpty: true,
        },
        _a);
};
