var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
import { StyledButton } from "../Button/Button.styled";
import Grid from "../Grid";
export var ButtonGroup = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  ", "\n"], ["\n  display: inline-flex;\n  ", "\n"])), function (_a) {
    var spacing = _a.spacing;
    return spacing === "none" &&
        "\n      &&& {\n        > *:not(:last-child) {\n          ".concat(StyledButton, " {\n            border-top-right-radius: 0;\n            border-bottom-right-radius: 0;\n          }\n        }\n        > *:not(:first-child) {\n          ").concat(StyledButton, " {\n            border-top-left-radius: 0;\n            border-bottom-left-radius: 0;\n          }\n        }\n        ").concat(StyledButton, " {\n          outline: none;\n        }\n      }\n    ");
});
var templateObject_1;
