var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Field as FormikField, getIn } from "formik";
import React from "react";
import DropdownMenu from "../../../DropdownMenu";
import TextField from "../../../TextField";
export var AutocompleteField = function (_a) {
    var onChange = _a.onChange, loadOptions = _a.loadOptions, optionRender = _a.optionRender, onInputChange = _a.onInputChange, props = __rest(_a, ["onChange", "loadOptions", "optionRender", "onInputChange"]);
    var _b = React.useState([]), options = _b[0], setOptions = _b[1];
    var _c = React.useState(false), showDropdown = _c[0], setShowDropdown = _c[1];
    var handleLoadOptions = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var loadedOptions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (value === "") {
                        setOptions([]);
                        setShowDropdown(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, loadOptions(value)];
                case 1:
                    loadedOptions = _a.sent();
                    if (loadedOptions && loadedOptions.length > 0) {
                        setOptions(loadedOptions);
                        setShowDropdown(true);
                    }
                    else {
                        setOptions([]);
                        setShowDropdown(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (item) {
        setShowDropdown(false);
        onChange(item);
    };
    var renderField = function (_a) {
        var _b = _a.field, formikOnChange = _b.onChange, formikOnBlur = _b.onBlur, field = __rest(_b, ["onChange", "onBlur"]), _c = _a.form, touched = _c.touched, errors = _c.errors, setFieldValue = _c.setFieldValue;
        var name = props.name, rest = __rest(props, ["name"]);
        var error = getIn(errors, name);
        var touch = getIn(touched, name);
        var handleChange = function (event) {
            var value = event.target.value;
            if (onInputChange) {
                value = onInputChange(value);
            }
            setFieldValue(event.target.name, value);
            handleLoadOptions(value);
        };
        var handleFocus = function () {
            options && options.length > 0 && setShowDropdown(true);
        };
        var handleBlur = function (event) {
            formikOnBlur(event);
            // Small delay to take click on dropdown item in account
            setTimeout(function () {
                setShowDropdown(false);
            }, 250);
        };
        return (_jsx(DropdownMenu, { passDownClickEvents: false, children: _jsx("div", { children: _jsx(TextField
                // @ts-ignore TODO: Typescript upgrade
                , __assign({ 
                    // @ts-ignore TODO: Typescript upgrade
                    label: name, id: name, error: touch && error && error, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, autoComplete: "off" }, field, rest, { type: "text" })) }), actions: options.map(function (option) {
                return optionRender
                    ? {
                        content: optionRender({ data: option }),
                        data: option,
                        onClick: function () { return handleClick(option); },
                    }
                    : { content: option.value || "" };
            }), tooltipShown: showDropdown, triggerProps: {
                trigger: "none",
                placement: "bottom-start",
                modifiers: {
                    setWidth: {
                        enabled: true,
                        order: 840,
                        fn: function (data) {
                            data.offsets.popper.left = data.offsets.reference.left;
                            data.offsets.popper.right = data.offsets.reference.right;
                            data.offsets.popper.width = data.styles.width = Math.round(data.offsets.reference.width);
                            return data;
                        },
                    },
                },
            } }));
    };
    return _jsx(FormikField, { name: props.name, render: renderField });
};
