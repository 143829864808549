import { EPaymentOrderType, } from "@rentiohq/shared/dist/types/payment.types";
import { EPropertyMemberTypes, } from "@rentiohq/shared/dist/types/property.types";
import { groupBy, orderBy } from "lodash";
import { getLocalizedText } from "./i18n/i18n.utils";
export var getTitle = function (_a) {
    var otherDescription = _a.otherDescription, type = _a.type;
    return otherDescription ||
        getLocalizedText("payment.payment_order.type.".concat(type).toLowerCase());
};
export var groupPaymentOrdersByContract = function (paymentOrders) {
    var paymentOrdersGroupedByContract = Object.values(groupBy(paymentOrders, function (paymentOrder) { var _a; return ((_a = paymentOrder.contract) === null || _a === void 0 ? void 0 : _a.relatedContractId) || paymentOrder.contractId; }));
    var result = paymentOrdersGroupedByContract.map(function (paymentOrders) {
        var sortedPaymentOrders = orderBy(paymentOrders, [
            function (paymentOrder) {
                var typeWeight = [
                    EPaymentOrderType.Rent,
                    EPaymentOrderType.CommonCosts,
                    EPaymentOrderType.NonCommonCosts,
                    EPaymentOrderType.ManagementCosts,
                    EPaymentOrderType.Rentio,
                    EPaymentOrderType.OtherRecurring,
                    EPaymentOrderType.LocationDescription,
                    EPaymentOrderType.BrokerFee,
                    EPaymentOrderType.OtherOneoff,
                    EPaymentOrderType.RentDepositPayout,
                    EPaymentOrderType.RentDiscount,
                    EPaymentOrderType.RentDeposit,
                ];
                return typeWeight.indexOf(paymentOrder.type);
            },
            "createdAt",
            "id",
        ]);
        return {
            contract: paymentOrders[0].contract,
            paymentOrders: sortedPaymentOrders,
        };
    });
    // Sort groups by contract start date
    var orderedResult = orderBy(result, function (group) { var _a; return (_a = group.contract) === null || _a === void 0 ? void 0 : _a.startDate; });
    return orderedResult;
};
export var canAddPaymentOrders = function (params) {
    var isBroker = params.isBroker, property = params.property;
    return (isBroker || property.roles.includes(EPropertyMemberTypes.FinancialManager));
};
