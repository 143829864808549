var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var StyledActionListHeading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " ", ";\n  color: ", ";\n  font-size: ", ";\n  text-transform: uppercase;\n"], ["\n  padding: ", " ", ";\n  color: ", ";\n  font-size: ", ";\n  text-transform: uppercase;\n"])), spacing("tight"), spacing(), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 400);
}, function (_a) {
    var theme = _a.theme;
    return rem("12px", theme.baseFontSize);
});
export var StyledActionList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0;\n  padding: ", " 0;\n  &:not(:first-child) {\n    border-top: 1px solid ", ";\n  }\n"], ["\n  list-style: none;\n  margin: 0;\n  padding: ", " 0;\n  &:not(:first-child) {\n    border-top: 1px solid ", ";\n  }\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral30;
});
export var StyledActionListItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  button,\n  a {\n    appearance: none;\n    margin: 0;\n    padding: 0;\n    background: none;\n    border: none;\n    font-size: inherit;\n    font-weight: inherit;\n    line-height: inherit;\n    color: inherit;\n    text-decoration: none;\n    text-transform: none;\n    display: block;\n    width: 100%;\n    min-height: ", ";\n    padding: ", " ", ";\n    text-align: left;\n    letter-spacing: 0;\n    cursor: pointer;\n    border-radius: 0;\n    &:hover {\n      background-color: ", ";\n      text-decoration: none;\n    }\n  }\n"], ["\n  button,\n  a {\n    appearance: none;\n    margin: 0;\n    padding: 0;\n    background: none;\n    border: none;\n    font-size: inherit;\n    font-weight: inherit;\n    line-height: inherit;\n    color: inherit;\n    text-decoration: none;\n    text-transform: none;\n    display: block;\n    width: 100%;\n    min-height: ", ";\n    padding: ", " ", ";\n    text-align: left;\n    letter-spacing: 0;\n    cursor: pointer;\n    border-radius: 0;\n    &:hover {\n      background-color: ", ";\n      text-decoration: none;\n    }\n  }\n"])), rem("40px"), spacing("tight"), spacing(), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 0);
});
var templateObject_1, templateObject_2, templateObject_3;
