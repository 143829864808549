var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import Button from "../Button";
import VisuallyHidden from "../VisuallyHidden";
export var Form = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  &&& {\n    > button {\n      margin-top: ", ";\n    }\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  margin-bottom: ", ";\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  &&& {\n    > button {\n      margin-top: ", ";\n    }\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), spacing("loose"), spacing(), spacing("extraLoose"));
export var PreviewAction = styled(function (props) { return _jsx(Button, __assign({}, props)); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  padding: ", ";\n  background: ", ";\n  color: ", ";\n  border-radius: 0;\n  border: 0;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  padding: ", ";\n  background: ", ";\n  color: ", ";\n  border-radius: 0;\n  border: 0;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);\n  &:hover {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "0 ".concat(theme.spacing(), " 0 ").concat(theme.spacing("tight"));
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 600);
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 0);
});
export var PreviewActionText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 24px;\n  height: 400px;\n  span {\n    display: block;\n    transform: rotate(-90deg) translateX(-24px);\n    white-space: nowrap;\n    text-align: center;\n    height: 24px;\n    width: 400px;\n    line-height: 24px;\n    position: absolute;\n    bottom: 0;\n    transform-origin: top left;\n  }\n"], ["\n  position: relative;\n  width: 24px;\n  height: 400px;\n  span {\n    display: block;\n    transform: rotate(-90deg) translateX(-24px);\n    white-space: nowrap;\n    text-align: center;\n    height: 24px;\n    width: 400px;\n    line-height: 24px;\n    position: absolute;\n    bottom: 0;\n    transform-origin: top left;\n  }\n"])));
export var PreviewWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  height: 100%;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n"])));
export var Fieldset = styled.fieldset(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  border: none;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n  > *:not(h2):not(", ") + * {\n    margin-top: ", ";\n  }\n  > button {\n    &:not(:first-child) {\n      margin-top: ", ";\n    }\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  border: none;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n  > *:not(h2):not(", ") + * {\n    margin-top: ", ";\n  }\n  > button {\n    &:not(:first-child) {\n      margin-top: ", ";\n    }\n  }\n"])), function (_a) {
    var divider = _a.divider, theme = _a.theme;
    return divider
        ? "\n    border-bottom: 1px solid ".concat(theme.getColor("gray", 200), ";\n    margin-bottom: ").concat(spacing(), ";\n    padding-bottom: ").concat(spacing("extraLoose"), ";\n  ")
        : null;
}, spacing("extraLoose"), VisuallyHidden, spacing("loose"), spacing("extraLoose"));
export var Field = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 640px;\n  .form-fullWidth & {\n    max-width: 100%;\n  }\n"], ["\n  max-width: 640px;\n  .form-fullWidth & {\n    max-width: 100%;\n  }\n"])));
export var ArrayField = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  & + & {\n    margin-top: ", ";\n  }\n  ", ";\n  ", "\n"], ["\n  display: flex;\n  & + & {\n    margin-top: ", ";\n  }\n  ", ";\n  ", "\n"])), spacing("loose"), function (_a) {
    var hasAdd = _a.hasAdd, theme = _a.theme;
    return hasAdd ? "margin-bottom: ".concat(theme.spacing("loose"), ";") : "";
}, function (_a) {
    var isContact = _a.isContact, isValid = _a.isValid, theme = _a.theme;
    return isContact
        ? "\n    padding: ".concat(spacing("loose"), " ").concat(spacing(), ";\n    background-color: ").concat(isValid ? theme.getColor("gray", 0) : theme.getColor("red", 0), ";\n    border-radius: ").concat(borderRadius(), ";\n  ")
        : "";
});
export var ArrayFieldRemove = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: ", ";\n  margin-right: ", ";\n"], ["\n  width: ", ";\n  margin-right: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("24px", theme.baseFontSize);
}, spacing());
export var ArrayFieldContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var FormActions = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), spacing("loose"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
