import { EField } from "./schema.editContact.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { ts } from "../../../../services";
export var getSchema = function (initialValues, _a) {
    var contact = _a.contact;
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Company, EField.VATNumber, EField.Address],
            properties: (_a = {},
                _a[EField.Company] = {
                    default: getValue(EField.Company, initialValues, defaultValues),
                },
                _a[EField.VATNumber] = {
                    default: getValue(EField.VATNumber, initialValues, defaultValues),
                },
                _a[EField.Address] = {
                    default: getValue(EField.Address, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": ts.contactEditContact(),
            "rentio:trackingKey": "contact_edit"
        },
        _a[EField.Company] = {
            "ui:field": ERentioFormField.Text,
            title: ts.contactCompanyNameLabel(),
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: ts.system("address"),
        },
        _a[EField.VATNumber] = {
            "ui:field": ERentioFormField.Text,
            title: ts.contactCompanyVATNumberLabel(),
        },
        _a);
};
