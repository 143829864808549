var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { getGetPagedAction } from "./paged.actions";
import { getPagedId } from "./paged.utils";
export var getPagedDefaultState = function () {
    return {
        data: {},
        paged: {},
    };
};
export var registerPagedActions = function (params) {
    var entityName = params.entityName, reducer = params.reducer;
    var routine = getGetPagedAction({ entityName: entityName });
    // Start
    reducer.on(routine.types.START, function (state, _a) {
        var _b, _c;
        var id = _a.id, _d = _a.page, page = _d === void 0 ? 0 : _d, order = _a.order, filter = _a.filter;
        return merge({}, state, {
            paged: (_b = {},
                _b[id || getPagedId({ entityName: entityName, order: order, filter: filter })] = {
                    filter: filter,
                    pages: (_c = {},
                        _c[page] = {
                            isFetching: true,
                            fetchError: undefined,
                        },
                        _c),
                },
                _b),
        });
    });
    // Success
    reducer.on(routine.types.SUCCESS, function (state, _a) {
        var _b, _c;
        var _d, _e, _f, _g, _h, _j;
        var id = _a.id, _k = _a.page, page = _k === void 0 ? 0 : _k, data = _a.data;
        return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), keyBy(data, "id")), paged: __assign(__assign({}, state.paged), (_b = {}, _b[id] = __assign(__assign({}, (_d = state.paged) === null || _d === void 0 ? void 0 : _d[id]), { pages: __assign(__assign({}, (_f = (_e = state.paged) === null || _e === void 0 ? void 0 : _e[id]) === null || _f === void 0 ? void 0 : _f.pages), (_c = {}, _c[page] = __assign(__assign({}, (_j = (_h = (_g = state.paged) === null || _g === void 0 ? void 0 : _g[id]) === null || _h === void 0 ? void 0 : _h.pages) === null || _j === void 0 ? void 0 : _j[page]), { ids: data.map(function (object) { return object.id; }), isFetching: false, fetchError: undefined }), _c)) }), _b)) }));
    });
    // Failure
    reducer.on(routine.types.FAILURE, function (state, _a) {
        var _b, _c;
        var id = _a.id, _d = _a.page, page = _d === void 0 ? 0 : _d, error = _a.error;
        return merge({}, state, {
            paged: (_b = {},
                _b[id] = {
                    pages: (_c = {},
                        _c[page] = {
                            isFetching: false,
                            fetchError: error,
                        },
                        _c),
                },
                _b),
        });
    });
};
