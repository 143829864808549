var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { getGetDetailAction } from "./detail.actions";
export var getDetailDefaultState = function () { return ({
    data: {},
    detail: {},
}); };
export var registerDetailActions = function (params) {
    var entityName = params.entityName, reducer = params.reducer;
    var routine = getGetDetailAction({ entityName: entityName });
    // Start
    reducer.on(routine.types.START, function (state, _a) {
        var _b;
        var id = _a.id;
        return merge({}, state, {
            // TODO:
            // @ts-ignore
            detail: (_b = {},
                _b[id] = {
                    isFetching: true,
                    fetchError: undefined,
                },
                _b),
        });
    });
    // Success
    reducer.on(routine.types.SUCCESS, function (state, _a) {
        var _b, _c;
        var data = _a.data;
        return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[data.id] = data, _b)), detail: __assign(__assign({}, state.detail), (_c = {}, _c[data.id] = {
                isFetching: false,
                fetchError: undefined,
            }, _c)) }));
    });
    // Failure
    reducer.on(routine.types.FAILURE, function (state, _a) {
        var _b;
        var id = _a.id, error = _a.error;
        return merge({}, state, {
            // TODO:
            // @ts-ignore
            detail: (_b = {},
                _b[id] = {
                    isFetching: false,
                    fetchError: error,
                },
                _b),
        });
    });
};
