var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DisplayText, Link, TextContainer } from "../../components";
import Button from "../../components/Button";
import * as t from "../../services/translationService";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
var VerifyEmail = function () {
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    // State
    var _a = React.useState(true), preventRedirect = _a[0], setPreventRedirect = _a[1];
    // Redux
    var dispatch = useDispatch();
    var hasAccessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!user || user.unverifiedEmail) {
            return;
        }
        navigateAfterSuccess();
    }, [hasAccessToken, navigateAfterSuccess, preventRedirect, user]);
    // Event handlers
    var handleResendEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(authActions.signUpResendVerificationEmail());
            return [2 /*return*/];
        });
    }); };
    var handleVerify = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setPreventRedirect(false);
            dispatch(authActions.signUpVerifiedEmail());
            return [2 /*return*/];
        });
    }); };
    // Render
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authVerifyEmailTitle() }), _jsxs(TextContainer, { children: [_jsx("p", { children: t.authVerifyEmailMessage({
                                                email: (user === null || user === void 0 ? void 0 : user.unverifiedEmail) || "No email available",
                                            }) }), _jsxs("p", { children: [t.authVerifyEmailResendEmailMessage(), " ", _jsx(Button, __assign({ appearance: "link", onClick: handleResendEmail }, { children: t.authVerifyEmailResendEmailAction() }))] }), _jsx(Button, __assign({ onClick: handleVerify, appearance: "primary", isFullWidth: true }, { children: t.authVerifyEmailVerifiedAction() }))] }), _jsx(StyledAuthActions, { children: _jsx(Link, __assign({ to: "/auth/signin" }, { children: t.authToLogin() })) })] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
export default VerifyEmail;
