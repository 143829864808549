var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/redux/chat/chat.selectors"; // 30 MB
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DisplayText, Icon, Tabs, TextStyle } from "../../components";
import { ts } from "../../services";
import { showDropErrors } from "../../utils/file";
import Button, { renderActions } from "../Button";
import DropdownMenu from "../DropdownMenu";
import { ESpacings } from "../Grid";
import * as S from "./PageHeader.styled";
var PageHeader = function (_a) {
    var icon = _a.icon, image = _a.image, title = _a.title, subtitle = _a.subtitle, metadata = _a.metadata, tabs = _a.tabs, breadcrumbs = _a.breadcrumbs, actions = _a.actions, secondaryActions = _a.secondaryActions, dropdown = _a.dropdown, hasBorder = _a.hasBorder, isSticky = _a.isSticky, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, context = _a.context, onImageChange = _a.onImageChange, _c = _a.setDocumentTitle, setDocumentTitle = _c === void 0 ? true : _c, documentTitle = _a.documentTitle;
    var navigate = useNavigate();
    var getUnreadChatsCount = makeGetUnreadChatsCount();
    var unreadChatsCount = useSelector(getUnreadChatsCount);
    React.useEffect(function () {
        if (!setDocumentTitle) {
            return;
        }
        var titleArray = [];
        if (unreadChatsCount > 0) {
            titleArray.push("(".concat(unreadChatsCount, ")"));
        }
        titleArray.push("Rentio");
        titleArray.push(documentTitle || title || "Dashboard");
        document.title = titleArray.join(" | ");
    }, [title, documentTitle, setDocumentTitle, unreadChatsCount]);
    // Event handlers
    var handlePressBreadcrumb = function () {
        if (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.goBack) {
            navigate(-1);
            return;
        }
        if (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.to) {
            navigate(breadcrumbs.to);
            return;
        }
    };
    var handleDrop = function (acceptedFiles, fileRejections, event) {
        showDropErrors({ acceptedFiles: acceptedFiles, fileRejections: fileRejections, event: event });
        if (acceptedFiles.length === 0) {
            return;
        }
        onImageChange === null || onImageChange === void 0 ? void 0 : onImageChange(acceptedFiles[0]);
    };
    var _d = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        maxSize: CONFIG.UPLOAD_MAX_FILE_SIZE,
        accept: "image/*",
    }), getRootProps = _d.getRootProps, getInputProps = _d.getInputProps, isDragActive = _d.isDragActive;
    var dropzoneProps = __assign({}, getRootProps());
    var renderImageMarkup = function () {
        if (!image) {
            return null;
        }
        if (onImageChange) {
            return (_jsx(S.ImageWrapper, { children: _jsxs(S.ImageUploadWrapper, __assign({}, dropzoneProps, { isDragActive: isDragActive }, { children: [_jsx("input", __assign({}, getInputProps())), typeof image === "string" ? (_jsx("img", { alt: "", src: image })) : (_jsx(_Fragment, { children: image })), _jsxs(S.UploadIconWrapper, { children: [_jsx(Icon, { source: "camera", size: "large" }), ts.imageUpdate()] })] })) }));
        }
        return (_jsx(S.ImageWrapper, { children: typeof image === "string" ? _jsx("img", { alt: "", src: image }) : _jsx(_Fragment, { children: image }) }));
    };
    var iconMarkup = icon && !image ? (_jsx(S.IconWrapper, { children: _jsx(Icon, { source: icon, size: "large" }) })) : null;
    var dropdownMarkup = dropdown && dropdown.length > 0 ? (_jsx(DropdownMenu, { children: _jsx(Box, __assign({ ml: ESpacings.tight }, { children: _jsx(Icon, { source: "navigationMenuVertical" }) })), actions: dropdown })) : null;
    var titleMarkup = title ? (_jsxs(Flex, __assign({ alignItems: "center" }, { children: [_jsx(DisplayText, __assign({ size: "extraLarge", element: "h1", space: "tight" }, { children: title })), dropdownMarkup] }))) : null;
    var subtitleMarkup = subtitle ? _jsx(S.SubTitle, { children: subtitle }) : null;
    var metadataMarkup = metadata ? (_jsx(TextStyle, __assign({ variation: "subdued" }, { children: metadata }))) : null;
    var breadcrumbsMarkup = breadcrumbs ? (_jsx(S.Breadcrumbs, { children: _jsx(Button, __assign({ appearance: "link", onClick: handlePressBreadcrumb }, { children: "\u2039 ".concat(breadcrumbs.content) })) })) : null;
    var tabsMarkup = tabs ? (_jsx(S.Tabs, { children: _jsx(Tabs, { tabs: tabs }) })) : null;
    var actionsMarkup = actions && actions.length > 0 ? (
    /**{ overrides: { appearance: "primary" } } */
    _jsx(S.Actions, { children: renderActions(actions) })) : null;
    var secondaryActionsMarkup = secondaryActions ? (_jsx(DropdownMenu, { children: _jsx(Button, __assign({ appearance: "add", color: "primary" }, { children: _jsx(Icon, { source: "add", size: "large" }) })), actions: secondaryActions })) : null;
    var hasTabs = !!tabs;
    return (_jsx(S.Wrapper, __assign({ isSticky: isSticky, fullWidth: fullWidth }, { children: _jsxs(S.Container, __assign({ hasBorder: hasBorder, hasTabs: hasTabs }, { children: [_jsxs(S.Content, { children: [breadcrumbsMarkup, _jsxs(Flex, __assign({ alignItems: "center" }, { children: [renderImageMarkup(), iconMarkup, _jsxs(S.ContentMain, { children: [subtitleMarkup, titleMarkup, metadataMarkup] }), _jsxs(S.ContentAside, { children: [actionsMarkup, secondaryActionsMarkup] })] }))] }), tabsMarkup] })) })));
};
export default PageHeader;
