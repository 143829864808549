import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

const middlewares: any[] = [];

export const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({ level: "info", collapsed: true });
  middlewares.push(logger);
}

export default middlewares;
