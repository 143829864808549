export var isArrayOfArrays = function (array) {
    return array.every(function (arr) { return arr.constructor === Array; });
};
export var intersection = function () {
    var arrays = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrays[_i] = arguments[_i];
    }
    return arrays.shift().filter(function (randomArray) {
        return arrays.every(function (randomItem) {
            return randomItem.indexOf(randomArray) !== -1;
        });
    });
};
export var intersectionIfNotEmpty = function () {
    var arrays = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrays[_i] = arguments[_i];
    }
    var nonEmptyArrays = (arrays || []).filter(function (randomArray) { return randomArray.length > 0; });
    if (nonEmptyArrays.length === 0) {
        return [];
    }
    return intersection.apply(void 0, nonEmptyArrays);
};
