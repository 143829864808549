var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getFieldTitle } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import DisplayText from "../../DisplayText";
export var HeaderField = function (_a) {
    var uiSchema = _a.uiSchema, _b = _a.formContext, formContext = _b === void 0 ? {} : _b;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    return (_jsx(DisplayText, __assign({ element: "h3", size: "small" }, { children: title })));
};
