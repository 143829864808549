// import truncate from 'lodash/truncate'
export var truncate = function (text, maxLength, cutInMiddle) {
    if (cutInMiddle === void 0) { cutInMiddle = true; }
    if (text.length <= maxLength) {
        return text;
    }
    var separator = "...";
    if (!cutInMiddle) {
        return text.slice(0, maxLength) + "...";
    }
    var charsToShow = maxLength - separator.length;
    var frontChars = Math.ceil(charsToShow / 2);
    var backChars = Math.floor(charsToShow / 2);
    return (text.substr(0, frontChars) +
        separator +
        text.substr(text.length - backChars));
};
