var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatPhoneWhileTyping, formatPhoneWhileTypingWithoutSpaces, isValidPhone, } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import React from "react";
import { i18n } from "../../../utils/";
import { parsePhoneNumber } from "../../../utils/phone";
import { StyledHelpText } from "../../Form/Form.styled";
import Labelled from "../../Labelled";
import { BaseInput } from "../components/BaseInput";
var defaultValue = "+32 4";
var PhoneField = /** @class */ (function (_super) {
    __extends(PhoneField, _super);
    function PhoneField(props) {
        var _this = _super.call(this, props) || this;
        _this.handleFocus = function () {
            // setHasChanged(true)
            _this.handleChange(formatPhoneWhileTypingWithoutSpaces(_this.props.formData) || "");
        };
        _this.handleBlur = function () {
            _this.handleChange(formatPhoneWhileTyping(_this.props.formData) || "");
        };
        _this.handleChange = function (input) {
            var _a = _this.props, onChange = _a.onChange, _b = _a.formContext, formContext = _b === void 0 ? {} : _b;
            var formattedInput = input.length === 0
                ? undefined
                : formatPhoneWhileTypingWithoutSpaces(input);
            onChange(formattedInput);
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, formattedInput);
                }
            }, 0);
        };
        var formData = _this.props.formData;
        if (formData === null || formData === undefined || formData === "") {
            _this.handleChange(defaultValue);
        }
        else {
            _this.handleChange(parsePhoneNumber(formData));
        }
        return _this;
    }
    PhoneField.prototype.render = function () {
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, idSchema = _a.idSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        var placeholder = customUiSchema.placeholder, suffixText = customUiSchema.suffixText, _d = customUiSchema.optional, optional = _d === void 0 ? false : _d;
        var id = idSchema.$id;
        // @ts-ignore TODO: Typescript upgrade
        var title = getFieldTitle(uiSchema, formContext);
        // @ts-ignore TODO: Typescript upgrade
        var info = getFieldInfo(uiSchema, formContext);
        var error = rawErrors[0];
        var inputMarkup = (_jsxs(_Fragment, { children: [_jsx(BaseInput, { id: id, type: "tel", name: name, value: formData, placeholder: placeholder, suffix: suffixText, onChange: this.handleChange, onFocus: this.handleFocus, onBlur: this.handleBlur, error: error }), info && _jsx(StyledHelpText, { children: info })] }));
        return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, __assign({ label: title, error: error, optional: optional }, { children: inputMarkup }))) : (_jsx(_Fragment, { children: inputMarkup })) }));
    };
    PhoneField.validate = function (formData, errors, schema, uiSchema) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:phone") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            if (isValidPhone(data)) {
                return;
            }
            fieldError.addError(i18n.t("system.form.error.invalid_phone"));
        });
    };
    return PhoneField;
}(React.PureComponent));
export { PhoneField };
