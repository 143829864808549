import { REDUCER_KEY_ACTIVITY } from "./activity.reducer";
import { getActivitiesSectionListDataSource } from "./activity.utils";
export var getActivities = function (state, activityIdentifier) {
    var activityState = state.activity.activitiesByIdentifier[activityIdentifier];
    if (!activityState) {
        return;
    }
    return activityState.activities;
};
export var getActivitiesSectionListDataSourceSelector = function (state, activityIdentifier) {
    var activityState = state.activity.activitiesByIdentifier[activityIdentifier];
    if (!activityState) {
        return;
    }
    var activities = activityState.activities;
    return getActivitiesSectionListDataSource(activities);
};
export var getIsFetchingActivities = function (state, activityIdentifier) {
    var activityState = state.activity.activitiesByIdentifier[activityIdentifier];
    if (!activityState) {
        return false;
    }
    return activityState.isFetching;
};
export var getFetchActivitiesError = function (state, activityIdentifier) {
    var activityState = state.activity.activitiesByIdentifier[activityIdentifier];
    if (!activityState) {
        return;
    }
    return activityState.fetchError;
};
export var getActivitiesPaged = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.activity.pagedActivities[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.activityIds) === null || _c === void 0 ? void 0 : _c.map(function (activityId) { var _a; return (_a = state.activity.activitiesById) === null || _a === void 0 ? void 0 : _a[activityId]; }).filter(Boolean);
};
export var isFetchingActivitiesForPage = function (state, identifier, page) { var _a, _b; return ((_b = (_a = state.activity.pagedActivities[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) || false; };
export var activitiesForPageFetchError = function (state, identifier, page) { var _a, _b; return (_b = (_a = state.activity.pagedActivities[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
export var getActivity = function (state, id) { var _a; return (_a = state.activity.activitiesById) === null || _a === void 0 ? void 0 : _a[id]; };
