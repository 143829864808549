var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from "./SegmentedControl.styled";
var SegmentedControl = function (_a) {
    var name = _a.name, selected = _a.selected, options = _a.options, error = _a.error, onChange = _a.onChange, disabled = _a.disabled, _b = _a.size, size = _b === void 0 ? "regular" : _b;
    return (_jsx(S.Wrapper, __assign({ hasError: !!error }, { children: options.map(function (_a) {
            var id = _a.id, value = _a.value;
            var isSelected = selected === id;
            return (_jsx(S.Button, __assign({ disabled: disabled, onClick: onChange.bind(null, id), selected: isSelected, size: size }, { children: value }), "".concat(name, "-").concat(id)));
        }) })));
};
export default SegmentedControl;
