var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextStyle, TruncateMiddle } from "../..";
import utils from "../../../utils";
export var AddressCell = function (_a) {
    var cellKey = _a.cellKey, property = _a.property, address = _a.address;
    var addressParts = property
        ? utils.address.addressObjToString(property, true)
        : undefined;
    var line1 = (address === null || address === void 0 ? void 0 : address.line1) || (addressParts === null || addressParts === void 0 ? void 0 : addressParts[0]);
    var line2 = (address === null || address === void 0 ? void 0 : address.line2) || (addressParts === null || addressParts === void 0 ? void 0 : addressParts[1]);
    if (!line1 || !line2) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx(TruncateMiddle, { input: line1, skipInitialChars: 10, maxWidthAfterTruncation: 100 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: _jsx(TruncateMiddle, { input: line2, maxWidthAfterTruncation: 100, skipInitialChars: 100 }) }))] }, cellKey !== null && cellKey !== void 0 ? cellKey : property === null || property === void 0 ? void 0 : property.id));
};
