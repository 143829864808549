var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { EFontSizes, FontWeights, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var displayTextSizes = {
    extraLarge: EFontSizes.SuperLarge,
    large: EFontSizes.ExtraExtraLarge,
    medium: EFontSizes.ExtraLarge,
    small: EFontSizes.Large,
    extraSmall: EFontSizes.Normal,
};
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p:not(:only-child) {\n    margin: 0px 0 ", " 0;\n  }\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h4 {\n    font-weight: ", ";\n    letter-spacing: 0.05rem;\n    line-height: 1.3;\n  }\n\n  h1 {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  h2 {\n    font-size: ", ";\n  }\n\n  h3 {\n    font-size: ", ";\n  }\n\n  h4 {\n    font-size: ", ";\n  }\n\n  ul,\n  ol {\n    padding-left: 0;\n    padding-left: ", ";\n  }\n\n  li {\n    ", "\n  }\n"], ["\n  p:not(:only-child) {\n    margin: 0px 0 ", " 0;\n  }\n\n  h1,\n  h2,\n  h3,\n  h4,\n  h4 {\n    font-weight: ", ";\n    letter-spacing: 0.05rem;\n    line-height: 1.3;\n  }\n\n  h1 {\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  h2 {\n    font-size: ", ";\n  }\n\n  h3 {\n    font-size: ", ";\n  }\n\n  h4 {\n    font-size: ", ";\n  }\n\n  ul,\n  ol {\n    padding-left: 0;\n    padding-left: ", ";\n  }\n\n  li {\n    ", "\n  }\n"])), spacing(), FontWeights.SemiBold, function (props) {
    return rem(props.theme.fontSizes[EFontSizes.ExtraExtraLarge], props.theme.baseFontSize);
}, FontWeights.Bold, function (props) {
    return rem(props.theme.fontSizes[EFontSizes.ExtraLarge], props.theme.baseFontSize);
}, function (props) {
    return rem(props.theme.fontSizes[EFontSizes.Large], props.theme.baseFontSize);
}, function (props) {
    return rem(props.theme.fontSizes[EFontSizes.Normal], props.theme.baseFontSize);
}, function (props) {
    return rem(props.listAsChecklist ? 16 : 24, props.theme.baseFontSize);
}, function (props) {
    return props.listAsChecklist
        ? "\n      padding-left: ".concat(rem(16, props.theme.baseFontSize), ";\n      margin: ").concat(spacing("tight"), " 0;\n      list-style-type: '").concat(props.checklistCharacter || "✓", "';\n    ")
        : "";
});
var templateObject_1;
