var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { compact } from "lodash";
import React from "react";
import TooltipTrigger from "react-popper-tooltip";
import utils from "../../utils";
import ActionList from "../ActionList";
import * as S from "./DropdownMenu.styled";
import { StyledDropdownMenu, StyledDropdownMenuWrapper, } from "./DropdownMenu.styled";
var DropdownMenu = function (props) {
    var _a = props.passDownClickEvents, passDownClickEvents = _a === void 0 ? true : _a, children = props.children, tooltipShownProps = props.tooltipShown, _b = props.shouldCloseOnClick, shouldCloseOnClick = _b === void 0 ? true : _b, actions = props.actions, triggerProps = props.triggerProps, actionListProps = props.actionListProps, onClick = props.onClick, onOpen = props.onOpen, onVisibilityChange = props.onVisibilityChange, renderContent = props.renderContent;
    var _c = React.useState(tooltipShownProps || true), tooltipShown = _c[0], setTooltipVisibility = _c[1];
    var _d = React.useState(false), isClicked = _d[0], setIsClicked = _d[1];
    React.useEffect(function () {
        setIsClicked(false);
        setTooltipVisibility(false);
    }, []);
    React.useLayoutEffect(function () {
        if (!isClicked)
            return;
        setTooltipVisibility(false);
        setIsClicked(false);
    }, [isClicked]);
    var handleDropdownClick = function (e) {
        var _a, _b, _c, _d, _e;
        if (!passDownClickEvents) {
            (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
            (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
            (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
            (_e = (_d = e.nativeEvent) === null || _d === void 0 ? void 0 : _d.stopImmediatePropagation) === null || _e === void 0 ? void 0 : _e.call(_d);
        }
        if (shouldCloseOnClick) {
            setIsClicked(true);
        }
    };
    var handleDropdownVisibility = function (visible) {
        if (visible && onOpen)
            onOpen();
        if (onVisibilityChange) {
            onVisibilityChange(visible);
        }
        setTooltipVisibility(visible);
    };
    React.useEffect(function () {
        setTooltipVisibility(tooltipShownProps || false);
    }, [tooltipShownProps]);
    var renderContentInternal = function () {
        if (renderContent) {
            return renderContent(function () {
                setTooltipVisibility(false);
            });
        }
        if (!actions || actions.length === 0) {
            return null;
        }
        var compactActions = compact(actions.map(function (item) {
            if (Array.isArray(item)) {
                var compactItems = compact(item);
                if (compactItems.length === 0) {
                    return undefined;
                }
                return compactItems;
            }
            return item;
        }));
        var hasNestedActions = !!compactActions.find(function (action) {
            return Array.isArray(action);
        });
        if (hasNestedActions) {
            return compactActions.map(function (item, index) {
                if (Array.isArray(item)) {
                    return (_createElement(ActionList, __assign({}, actionListProps, { key: index, items: item, onClick: function (_) {
                            setTooltipVisibility(false);
                        } })));
                }
                return (_createElement(ActionList, __assign({}, actionListProps, { key: index, items: [item], onClick: function (_) {
                        setTooltipVisibility(false);
                    } })));
            });
        }
        return (_jsx(ActionList, __assign({}, actionListProps, { items: compactActions, onClick: function (_) {
                setTooltipVisibility(false);
            } })));
    };
    var renderDropdown = function (_a) {
        var tooltipRef = _a.tooltipRef, getTooltipProps = _a.getTooltipProps;
        return (_jsx(StyledDropdownMenu, __assign({}, getTooltipProps({
            ref: tooltipRef,
            className: "tooltip-container",
            onClick: handleDropdownClick,
        }), { children: _jsx(StyledDropdownMenuWrapper, { children: renderContentInternal() }) })));
    };
    return (_jsx(TooltipTrigger, __assign({ trigger: "click", placement: "bottom-end", tooltipShown: tooltipShown, tooltip: renderDropdown, onVisibilityChange: handleDropdownVisibility }, triggerProps, { children: function (_a) {
            var getTriggerProps = _a.getTriggerProps, triggerRef = _a.triggerRef;
            return (_jsx(S.DropdownTrigger, { children: React.cloneElement(children, __assign({}, getTriggerProps({
                    ref: triggerRef,
                    onClick: function (e) {
                        var _a, _b, _c, _d, _e;
                        if (!passDownClickEvents) {
                            (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                            (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
                            (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
                            (_e = (_d = e.nativeEvent) === null || _d === void 0 ? void 0 : _d.stopImmediatePropagation) === null || _e === void 0 ? void 0 : _e.call(_d);
                        }
                        onClick === null || onClick === void 0 ? void 0 : onClick(e);
                    },
                }))) }));
        } })));
};
var DropdownMenuMemo = React.memo(DropdownMenu, utils.components.arePropsEqual);
export default DropdownMenuMemo;
