var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import AutocompleteField from "../../AutocompleteField";
import Icon from "../../Icon";
import { CompanyIndicator } from "../AccountIndicators/CompanyIndicator";
import { EmployeeIndicator } from "../AccountIndicators/EmployeeIndicator";
import * as S from "./EmployeeRoles.styled";
var MAX_EMPLOYEES = 5;
var getMembersAsOptions = function (members) {
    return members.map(function (member) { return ({
        data: member,
        content: "".concat(member.firstname, " ").concat(member.lastname),
        media: _jsx(Icon, { source: "profile" }),
    }); });
};
export var EmployeeRoles = function (_a) {
    var members = _a.members, selectedMemberIds = _a.selectedMemberIds, companyName = _a.companyName, onChange = _a.onChange;
    var getFilteredMembers = function (filterValue) {
        return members.filter(function (_a) {
            var firstname = _a.firstname, lastname = _a.lastname, id = _a.id;
            return (firstname.toLowerCase().includes(filterValue) ||
                lastname.toLowerCase().includes(filterValue)) &&
                !selectedMemberIds.includes(id);
        });
    };
    if (!members || members.length === 0) {
        return null;
    }
    if (members.length > MAX_EMPLOYEES) {
        return (_jsx(AutocompleteField, { placeholder: getLocalizedText("filter.employees.placeholder"), initialOptions: __spreadArray(__spreadArray([], getMembersAsOptions(members.filter(function (_a) {
                var id = _a.id;
                return !selectedMemberIds.includes(id);
            })), true), [
                {
                    data: { id: -1 },
                    content: getLocalizedText("filter.employees.unassigned"),
                    media: _jsx(Icon, { source: "profile" }),
                },
            ], false), name: "selectedEmployee", loadOptions: function (value) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, getMembersAsOptions(getFilteredMembers(value))];
            }); }); }, onChange: function (selectedAccount) {
                onChange(selectedAccount.id);
            } }));
    }
    return (_jsxs(S.EmployeeRolesContainer, { children: [members.map(function (employeeWithRole) { return (_jsx(EmployeeIndicator, { size: "small", employeeAccount: employeeWithRole, selected: !!(selectedMemberIds === null || selectedMemberIds === void 0 ? void 0 : selectedMemberIds.includes(employeeWithRole.id)), onClick: function () { return onChange(employeeWithRole.id); } }, employeeWithRole.id)); }), companyName && (_jsx(CompanyIndicator, { size: "small", companyName: companyName, selected: !!(selectedMemberIds === null || selectedMemberIds === void 0 ? void 0 : selectedMemberIds.includes(-1)), onClick: function () { return onChange(-1); } }))] }));
};
