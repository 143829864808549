var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EProduct } from "@rentiohq/shared/dist/config/app.config.types";
import logger from "@rentiohq/shared/dist/logger";
import { mapActivitySubtypeToString, mapActivityTypeToString, } from "@rentiohq/shared/dist/utils/activity.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import React from "react";
import { getMappedName, getName } from "../../../redux/contact/contact.utils";
import { formatAddress as formatAddressLead } from "../../../reduxInsurance/lead/lead.utils";
import { formatAddress } from "../../../utils/address.utils";
import { formatDate } from "../../../utils/date.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import * as paymentOrderUtils from "../../../utils/paymentOrder.utils";
import * as paymentRequestUtils from "../../../utils/paymentRequest.utils";
import { capitalize } from "../../../utils/text/text.utils";
import * as S from "./ActivityText.styles";
var ActivityText = /** @class */ (function (_super) {
    __extends(ActivityText, _super);
    // Initialize
    function ActivityText(props) {
        var _this = _super.call(this, props) || this;
        // Render
        _this.render = function () { return _jsx(S.Wrap, { children: _this.renderContent() }); };
        _this.renderContent = function () {
            var activityItem = _this.props.activityItem;
            var actor = activityItem.actor, amount = activityItem.amount, application = activityItem.application, asset = activityItem.asset, broker = activityItem.broker, contact = activityItem.contact, contract = activityItem.contract, contractNewPrice = activityItem.contractNewPrice, contractOldPrice = activityItem.contractOldPrice, contractRentDiscountPrice = activityItem.contractRentDiscountPrice, documentFilename = activityItem.documentFilename, documentPackage = activityItem.documentPackage, email = activityItem.email, employee = activityItem.employee, invitingAccount = activityItem.invitingAccount, invoiceAmount = activityItem.invoiceAmount, lead = activityItem.lead, 
            // linkedUser,
            locationDescriptionRejectionComment = activityItem.locationDescriptionRejectionComment, newStopDate = activityItem.newStopDate, notificationType = activityItem.notificationType, oldStopDate = activityItem.oldStopDate, stopDate = activityItem.stopDate, participant = activityItem.participant, payee = activityItem.payee, payeeBankAccount = activityItem.payeeBankAccount, payer = activityItem.payer, payerBankAccount = activityItem.payerBankAccount, paymentOrder = activityItem.paymentOrder, paymentRequest = activityItem.paymentRequest, paymentTransaction = activityItem.paymentTransaction, property = activityItem.property, propertyInfo = activityItem.propertyInfo, quote = activityItem.quote, reference = activityItem.reference, rentAmount = activityItem.rentAmount, rentContractRejectionComment = activityItem.rentContractRejectionComment, roles = activityItem.roles, source = activityItem.source, step = activityItem.step, subtype = activityItem.subtype, tacVersion = activityItem.tacVersion, taskTitle = activityItem.taskTitle, type = activityItem.type, visitUrl = activityItem.visitUrl, usedSigningType = activityItem.usedSigningType, datetime = activityItem.datetime, paymentPayIn = activityItem.paymentPayIn, paymentPayInRemainingBalance = activityItem.paymentPayInRemainingBalance, epcLabel = activityItem.epcLabel, epcValue = activityItem.epcValue, epcExpirationDate = activityItem.epcExpirationDate, reasonForCancellation = activityItem.reasonForCancellation, dateOfCancellation = activityItem.dateOfCancellation;
            if (mapActivityTypeToString(type, _this.isInsurance()) === undefined ||
                mapActivitySubtypeToString(subtype, _this.isInsurance()) === undefined) {
                return _this.renderFallbackInfo();
            }
            var paymentType = "invalid payment order";
            if (paymentOrder) {
                paymentType = paymentOrderUtils.getTitle(paymentOrder);
            }
            var paymentRequestPeriod = "invalid payment request";
            if (paymentRequest) {
                if (paymentRequest.startedAt === paymentRequest.endedAt) {
                    paymentRequestPeriod = formatDate(paymentRequest.startedAt);
                }
                else {
                    paymentRequestPeriod = "".concat(formatDate(paymentRequest.startedAt), " - ").concat(formatDate(paymentRequest.endedAt));
                }
            }
            var paymentRequestEstimatedCollectionDate = "invalid payment request";
            if (paymentRequest === null || paymentRequest === void 0 ? void 0 : paymentRequest.estimatedCollectionDate) {
                paymentRequestEstimatedCollectionDate = formatDate(paymentRequest.estimatedCollectionDate);
            }
            var paymentRequestTitle = "invalid payment request";
            if (paymentRequest) {
                paymentRequestTitle = "".concat(paymentRequestUtils.getTitle(paymentRequest), " (").concat(paymentRequestPeriod, ")");
            }
            var actorFormatted = actor
                ? getMappedName(actor, false)
                : getLocalizedText("system.rentio");
            if ((employee === null || employee === void 0 ? void 0 : employee.firstname) || (employee === null || employee === void 0 ? void 0 : employee.lastname)) {
                actorFormatted = "".concat(actorFormatted, " (").concat([
                    employee.firstname,
                    employee.lastname,
                ]
                    .filter(Boolean)
                    .join(" "), ")");
            }
            var values = {
                createdAtDateAndTime: datetime
                    ? formatDate(datetime, "dd/MM/yyyy HH:mm")
                    : "invalid datetime",
                createdAtDate: datetime ? formatDate(datetime) : "invalid datetime",
                createdAtTime: datetime
                    ? formatDate(datetime, "HH:mm")
                    : "invalid datetime",
                actor: actorFormatted,
                amount: amount ? formatCurrency(amount) : "invalid amount",
                application: application
                    ? getName(application.contacts[0])
                    : getLocalizedText("activity.param_not_available.application"),
                applicationEmail: (application === null || application === void 0 ? void 0 : application.applicationEmail) || "No email",
                asset: asset ? asset.name : "invalid asset",
                broker: broker ? broker.name : "invalid broker",
                contact: contact ? getName(contact) : "invalid contact",
                contract: contract
                    ? getLocalizedText("contract.type.".concat(contract.contractType)).toLowerCase()
                    : "invalid contract",
                contractNewPrice: contractNewPrice
                    ? formatCurrency(contractNewPrice)
                    : "invalid amount",
                contractOldPrice: contractOldPrice
                    ? formatCurrency(contractOldPrice)
                    : "invalid amount",
                contractRentDiscountPrice: contractRentDiscountPrice
                    ? formatCurrency(contractRentDiscountPrice)
                    : "invalid amount",
                documentFilename: documentFilename
                    ? documentFilename
                    : "invalid document filename",
                documentPackage: documentPackage
                    ? documentPackage.name
                    : "invalid document package",
                iban: payeeBankAccount ? payeeBankAccount.iban : "invalid iban",
                payeeBankAccount: payeeBankAccount
                    ? payeeBankAccount.iban
                    : "invalid iban",
                payerBankAccount: payerBankAccount
                    ? payerBankAccount.iban
                    : "invalid iban",
                invitingAccount: invitingAccount
                    ? getMappedName(invitingAccount, false)
                    : getLocalizedText("system.rentio"),
                invoiceAmount: invoiceAmount
                    ? formatCurrency(invoiceAmount)
                    : "invalid amount",
                // linkedUser: linkedUser
                //   ? getMappedName(linkedUser)
                //   : "invalid linked user",
                newStopDate: newStopDate
                    ? formatDate(newStopDate)
                    : "invalid new stop date",
                notificationType: notificationType
                    ? getLocalizedText("activity.notification_type.".concat(notificationType)).toLowerCase()
                    : "unknown type: ".concat(notificationType),
                oldStopDate: oldStopDate
                    ? formatDate(oldStopDate)
                    : "invalid old stop date",
                participant: participant
                    ? getMappedName(participant, false)
                    : "invalid participant",
                payee: payee ? getMappedName(payee, false) : "invalid payee",
                payer: payer ? getMappedName(payer, false) : "invalid payer",
                paymentRequestEstimatedCollectionDate: paymentRequestEstimatedCollectionDate,
                paymentRequestAmount: paymentRequest
                    ? formatCurrency(paymentRequest.originalAmount)
                    : "invalid amount",
                paymentRequestPeriod: paymentRequestPeriod,
                paymentRequestInterface: (paymentRequest === null || paymentRequest === void 0 ? void 0 : paymentRequest.paymentInterface)
                    ? getLocalizedText("payment_request.interface.".concat(paymentRequest.paymentInterface))
                    : "invalid payment interface",
                paymentRequestMethod: (paymentRequest === null || paymentRequest === void 0 ? void 0 : paymentRequest.paymentMethod)
                    ? getLocalizedText("payment_request.method.".concat(paymentRequest.paymentMethod))
                    : "invalid payment method",
                paymentTransactionAmount: paymentTransaction
                    ? formatCurrency(paymentTransaction.amount)
                    : "invalid amount",
                paymentRequest: paymentRequestTitle,
                paymentType: paymentType,
                paymentPayInPayerName: (paymentPayIn === null || paymentPayIn === void 0 ? void 0 : paymentPayIn.authorName) || "invalid author",
                paymentPayInPayerIban: (paymentPayIn === null || paymentPayIn === void 0 ? void 0 : paymentPayIn.authorIban) || "invalid author",
                paymentPayInRemainingBalance: paymentPayInRemainingBalance
                    ? formatCurrency(paymentPayInRemainingBalance)
                    : "invalid balance",
                property: property ? formatAddress(property) : "invalid property",
                propertyInfo: propertyInfo
                    ? propertyInfo.reference
                    : "invalid property info",
                roles: roles
                    ? roles
                        .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                        .join(", ")
                    : "invalid roles",
                reference: reference ? reference : "no reference",
                rentAmount: rentAmount ? formatCurrency(rentAmount) : "invalid amount",
                source: source
                    ? getLocalizedText("source.".concat(source.toLowerCase()))
                    : "Rentio",
                step: step
                    ? getLocalizedText("property.dashboard.application.".concat(step, ".title"))
                    : "invalid step",
                tacVersion: tacVersion || "invalid version",
                taskTitle: taskTitle || "invalid task",
                usedSigningType: usedSigningType
                    ? getLocalizedText("used_signing_type.".concat(usedSigningType.toLowerCase()))
                    : "invalid used signing type",
                leadId: lead ? lead.id : "invalid lead",
                leadAddress: lead ? formatAddressLead(lead) : "invalid lead",
                leadName: lead
                    ? "".concat(lead.contactFirstname, " ").concat(lead.contactLastname)
                    : "invalid lead",
                leadReferrer: lead ? lead.referrer : "invalid lead",
                leadEmail: lead ? lead.contactEmail : "invalid email",
                quoteValue: quote
                    ? formatCurrency(quote.calculationValue)
                    : "invalid quote",
                rentContractRejectionComment: rentContractRejectionComment || "invalid reason",
                locationDescriptionRejectionComment: locationDescriptionRejectionComment || "invalid reason",
                epcLabel: epcLabel,
                epcValue: epcValue,
                epcExpirationDate: epcExpirationDate,
                stopDate: stopDate ? formatDate(stopDate) : "invalid stop date",
                reasonForCancellation: reasonForCancellation
                    ? getLocalizedText("contract.cancellation.".concat(reasonForCancellation))
                    : "invalid reason",
                dateOfCancellation: dateOfCancellation
                    ? formatDate(dateOfCancellation)
                    : "invalid cancelled date",
            };
            return _this.renderTouchableActivityText(activityItem, {
                createdAtDateAndTime: values.createdAtDateAndTime,
                createdAtDate: values.createdAtDate,
                createdAtTime: values.createdAtTime,
                amount: values.amount,
                application: values.application,
                applicationEmail: values.applicationEmail,
                asset: values.asset,
                broker: values.broker,
                contract: values.contract,
                contractNewPrice: values.contractNewPrice,
                contractOldPrice: values.contractOldPrice,
                contractRentDiscountPrice: values.contractRentDiscountPrice,
                documentPackage: values.documentPackage,
                email: email,
                // Deprecated => use payerBankAccount / payeeBankAccount
                iban: values.iban,
                payerBankAccount: values.payerBankAccount,
                payeeBankAccount: values.payeeBankAccount,
                invitingAccount: values.invitingAccount,
                invoiceAmount: values.invoiceAmount,
                leadAddress: values.leadAddress,
                leadId: values.leadId,
                leadName: values.leadName,
                leadReferrer: values.leadReferrer,
                leadEmail: values.leadEmail,
                newStopDate: values.newStopDate,
                notificationType: values.notificationType,
                oldStopDate: values.oldStopDate,
                paymentRequestAmount: values.paymentRequestAmount,
                paymentRequestEstimatedCollectionDate: values.paymentRequestEstimatedCollectionDate,
                paymentRequestInterface: values.paymentRequestInterface,
                paymentRequestMethod: values.paymentRequestMethod,
                paymentRequestPeriod: values.paymentRequestPeriod,
                paymentTransactionAmount: values.paymentTransactionAmount,
                paymentPayInPayerName: values.paymentPayInPayerName,
                paymentPayInPayerIban: values.paymentPayInPayerIban,
                paymentPayInRemainingBalance: values.paymentPayInRemainingBalance,
                propertyInfo: values.propertyInfo,
                quoteValue: values.quoteValue,
                rentAmount: values.rentAmount,
                roles: values.roles,
                source: values.source,
                reference: values.reference,
                step: values.step,
                tacVersion: values.tacVersion,
                usedSigningType: values.usedSigningType,
                rentContractRejectionComment: values.rentContractRejectionComment,
                locationDescriptionRejectionComment: values.locationDescriptionRejectionComment,
                epcLabel: values.epcLabel,
                epcValue: values.epcValue,
                epcExpirationDate: values.epcExpirationDate,
                stopDate: values.stopDate,
                reasonForCancellation: values.reasonForCancellation,
                dateOfCancellation: values.dateOfCancellation,
            }, {
                actor: values.actor,
                contact: values.contact,
                documentFilename: documentFilename,
                // linkedUser: values.linkedUser,
                participant: values.participant,
                payee: values.payee,
                payer: values.payer,
                paymentRequest: values.paymentRequest,
                paymentType: values.paymentType,
                property: values.property,
                taskTitle: taskTitle,
                visitUrl: visitUrl,
            });
        };
        _this.renderFallbackInfo = function () { return getLocalizedText("activity.not_supported"); };
        _this.renderTouchableActivityText = function (activityItem, nonTouchableValues, touchableValues) {
            var type = activityItem.type, subtype = activityItem.subtype, lokaliseKey = activityItem.lokaliseKey;
            var key = "activity.".concat(mapActivityTypeToString(type, _this.isInsurance()), ".").concat(mapActivitySubtypeToString(subtype, _this.isInsurance())).toLowerCase();
            // Use custom lokalise key if translated
            if (lokaliseKey && getLocalizedText(lokaliseKey) !== lokaliseKey) {
                key = lokaliseKey;
            }
            var baseString = getLocalizedText(key, nonTouchableValues);
            var stringComponents = baseString.split(/{{|}}/);
            return (_jsx(_Fragment, { children: stringComponents.map(function (stringComponent, index) {
                    var touchableValue = touchableValues[stringComponent];
                    if (touchableValue) {
                        var onParamPress_1 = _this.props.onParamPress;
                        var handlePress = function () {
                            if (onParamPress_1) {
                                onParamPress_1(stringComponent);
                            }
                        };
                        // @ts-ignore
                        return activityItem[stringComponent] ? (_jsx(S.ParamText, __assign({ onPress: onParamPress_1 ? handlePress : undefined }, { children: touchableValue }), index)) : (touchableValue);
                    }
                    if (index === 0) {
                        return capitalize(stringComponent);
                    }
                    return stringComponent;
                }) }));
        };
        // Helpers
        _this.logUnsupportedActivity = function () {
            var _a = _this.props.activityItem, type = _a.type, subtype = _a.subtype;
            if (mapActivityTypeToString(type, _this.isInsurance()) === undefined ||
                mapActivitySubtypeToString(subtype, _this.isInsurance()) === undefined) {
                logger.logError({
                    error: new Error("Unknown activity type or subtype"),
                    parameters: {
                        type: type,
                        subtype: subtype,
                    },
                });
            }
        };
        _this.isInsurance = function () {
            return [EProduct.Insurance, EProduct.InsuranceBackoffice].includes(CONFIG.PRODUCT);
        };
        _this.logUnsupportedActivity();
        return _this;
    }
    return ActivityText;
}(React.PureComponent));
export default ActivityText;
