var _a;
import { positions, transitions } from "react-alert";
import { EEnvironment, EProduct } from "./app.config.types";
import DEPLOY_DATE from "./deployDate";
// import AlertTemplate from "../components/Alert";
import SENTRY_RELEASE from "./sentryRelease";
export default {
    // Main
    ENVIRONMENT: EEnvironment.Prod,
    DEPLOY_DATE: DEPLOY_DATE,
    // Rentio API
    BASE_URL: "https://api.rentio.be/v1",
    BASE_URL_INSURANCE: "https://api.rentio-insurance.be/v1",
    BASE_URL_V2: "https://api-next.rentio.be/v2",
    BASE_URL_SERVERLESS: "https://serverless.rentio.be",
    URL_SOCKETS: "wss://api.rentio.be/v1/websocket?access_token={{accessToken}}&locale={{locale}}",
    // Sentry
    SENTRY: (_a = {},
        _a[EProduct.Dashboard] = "https://26eba06c75064f67ad118854fd777205@o341612.ingest.sentry.io/5449970",
        _a[EProduct.Backoffice] = "https://266d69b7a6e1424baa33580a99fcc82d@o341612.ingest.sentry.io/5449969",
        _a[EProduct.PasswordReset] = "https://26eba06c75064f67ad118854fd777205@o341612.ingest.sentry.io/5449970",
        _a[EProduct.Pay] = "https://5fbbb8ff713b4ed9a2b2d2b2fbab9d3c@o341612.ingest.sentry.io/5429946",
        _a[EProduct.Move] = "https://92e7a7d0f3a74f2ea18904ce91c66d56@o341612.ingest.sentry.io/5506115",
        _a[EProduct.Insurance] = "https://24770a131e814893baa68604b7f74391@o341612.ingest.sentry.io/5429933",
        _a[EProduct.InsuranceBackoffice] = "https://205eec1114e0467db941fc0c05b887c5@o341612.ingest.sentry.io/5444933",
        _a[EProduct.App] = "https://7413e2da0f144ff8861cf7e91bbcf0df@o341612.ingest.sentry.io/5449981",
        _a),
    SENTRY_RELEASE: SENTRY_RELEASE,
    // Google
    CAPTCHA_KEY: 
    // cspell:disable-next-line
    "6Le_KJokAAAAAACam3Q_XPYweNuu7JOJEXkui1tw",
    GOOGLE_MAPS_API: 
    // cspell:disable-next-line
    "AIzaSyD99GIWadWtiPXjtuYdyg8wkJ_BaIqkz_Q",
    // Rentio
    RENTIO_BROKER_ID: 1,
    CONTRACT_BASE_YEARS: [
        2013, 2004, 1996, 1988, 1981, 1974, 1971, 1966, 1953, 1936, 1914,
    ],
    UPLOAD_MAX_FILE_SIZE: 100000000,
    CONTACT_AGE_RANGE: { MIN: 120, MAX: 16 },
    DECIMALS_FOR_CURRENCY: 7,
    PAYOUT_REFERENCE_REGEX: /^[a-zA-Z0-9 +-/()]+$/,
    // Move, pay & insurance
    RESPONSIVE: {
        SIZE: {
            MOBILE: "768px",
        },
        BREAKPOINT: {
            DESKTOP: "(min-width: 768px)",
            MOBILE: "(max-width: 767px)",
        },
    },
    ALERT: {
        position: positions.TOP_RIGHT,
        transition: transitions.FADE,
        timeout: 5000,
    },
    // Pay
    PAYMENT_LIMIT_AMOUNT_NOTIFICATION: 500,
    // Partners
    INSURANCE_TENANT_FLOW_URL: "https://app.rentio-insurance.be",
    KEYPOINT_REPAIR_URL: "https://case.keypoint.be",
    // Insurance
    INSURANCE_PREMIUMS: {
        CONTENT_DEFAULT_AMOUNT_OF_CONTENT: 20000,
        CONTENT_MIN_AMOUNT_OF_CONTENT: 5000,
        CONTENT_MAX_AMOUNT_OF_CONTENT: 125000,
        MOTOR_VEHICLES_DEFAULT_AMOUNT_OF_MOTOR_VEHICLES: 1,
        MOTOR_VEHICLES_MIN_AMOUNT_OF_MOTOR_VEHICLES: 1,
        MOTOR_VEHICLES_MAX_AMOUNT_OF_MOTOR_VEHICLES: 4,
    },
};
