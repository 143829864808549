var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DescriptionList from "../../../DescriptionList";
import DescriptionListItem from "../../../DescriptionListItem";
export var FormSummaryItem = function (_a) {
    var title = _a.title, value = _a.value, valueSelector = _a.valueSelector, selectedValue = _a.selectedValue, error = _a.error;
    return (_jsx(DescriptionList, __assign({ termWidth: 400 }, { children: _jsx(DescriptionListItem, { term: title, description: valueSelector ? selectedValue : value, error: error }) })));
};
