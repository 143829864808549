var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Platform } from "react-native";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import AddListItem from "../AddListItem";
import ListItemSeparator from "../ListItemSeparator";
import * as S from "./FormInputBase.styles";
export var EExtraButtonPlacement;
(function (EExtraButtonPlacement) {
    EExtraButtonPlacement["Top"] = "TOP";
    EExtraButtonPlacement["Bottom"] = "BOTTOM";
})(EExtraButtonPlacement || (EExtraButtonPlacement = {}));
var FormInputBase = /** @class */ (function (_super) {
    __extends(FormInputBase, _super);
    function FormInputBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderExtraButton = function () {
            var _a = _this.props, extraButtonTitle = _a.extraButtonTitle, onPressExtraButton = _a.onPressExtraButton;
            if (!extraButtonTitle || !onPressExtraButton) {
                return;
            }
            return (_jsxs(S.ButtonBasicWrap, { children: [_jsx(AddListItem, { text: extraButtonTitle, onPress: onPressExtraButton }), _jsx(ListItemSeparator, {})] }));
        };
        return _this;
        // Helpers
        // private getTitle = () => {
        //   const { schema, formContext } = this.props;
        //   // @ts-ignore
        //   const { title, getTitle } = schema;
        //   if (getTitle) {
        //     return getTitle(formContext.getFormData());
        //   }
        //   return title || "";
        // };
    }
    // Render
    FormInputBase.prototype.render = function () {
        var _a = this.props, title = _a.title, info = _a.info, error = _a.error, _b = _a.extraButtonPlacement, extraButtonPlacement = _b === void 0 ? EExtraButtonPlacement.Bottom : _b, children = _a.children, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.optional, optional = _d === void 0 ? false : _d, _e = _a.showDisclosureIcon, showDisclosureIcon = _e === void 0 ? false : _e, _f = _a.isFetching, isFetching = _f === void 0 ? false : _f, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, headerStyles = _a.headerStyles, 
        // Don't show border on mobile
        _g = _a.showBorder, 
        // Don't show border on mobile
        showBorder = _g === void 0 ? Platform.OS === "web" : _g;
        var hasError = !!error;
        return (_jsxs(S.Wrap, __assign({ disabled: disabled }, { children: [_jsxs(S.HeaderWrap, __assign({ style: headerStyles }, { children: [_jsx(S.HeaderText, __assign({ hasError: hasError }, { children: title })), !!extraInfoUrl && (_jsx(S.InfoButton, __assign({ onPress: onPressExtraInfo }, { children: _jsx(S.InfoIcon, {}) }))), optional && (_jsx(S.OptionalText, { children: getLocalizedText("system.optional").toUpperCase() }))] })), extraButtonPlacement === EExtraButtonPlacement.Top &&
                    this.renderExtraButton(), _jsx(S.ContentWrap, __assign({ showBorder: showBorder }, { children: (function () {
                        if (isFetching) {
                            return _jsx(S.ActivityIndicatorStyled, {});
                        }
                        return (_jsxs(_Fragment, { children: [_jsx(S.ChildrenWrap, { children: children }), showDisclosureIcon && _jsx(S.DisclosureIcon, {})] }));
                    })() })), _jsx(S.Separator, { hasError: hasError }), error && _jsx(S.ErrorText, { children: error }), info && _jsx(S.InfoText, { children: info }), extraButtonPlacement === EExtraButtonPlacement.Bottom &&
                    this.renderExtraButton()] })));
    };
    return FormInputBase;
}(React.PureComponent));
export default FormInputBase;
