var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { defaultTheme, spacing } from "@rentiohq/web-theme";
import { ELoadingSize } from "./Loading.types";
var getSpacing = function (size) {
    switch (size) {
        case ELoadingSize.Small:
        case ELoadingSize.Medium:
            return "tight";
        default:
            return "loose";
    }
};
export var Loading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fill: ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  fill: ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.hasOwnProperty("getColor")
        ? theme.getColor("primary", 1000)
        : defaultTheme.getColor("primary", 1000);
}, function (_a) {
    var size = _a.size;
    return spacing(getSpacing(size));
});
var templateObject_1;
