var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import media from "styled-media-query";
export var StyledEmptyState = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  ", "\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  border-radius: ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme, backgroundColor = _a.backgroundColor;
    return theme.getColor(backgroundColor, 0);
}, spacing(), function (props) { return (props.isShownInline ? 0 : borderRadius("large")); }, media.greaterThan("medium")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: ", ";\n  "], ["\n    padding: ", ";\n  "])), spacing("extraLoose")));
export var StyledEmptyStateActions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), spacing("loose"));
var templateObject_1, templateObject_2, templateObject_3;
