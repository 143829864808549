var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import * as S from "./AddListItem.styles";
import images from "../../assets";
var AddListItem = /** @class */ (function (_super) {
    __extends(AddListItem, _super);
    function AddListItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderAddIcon = function () {
            var _a = _this.props.showAddIcon, showAddIcon = _a === void 0 ? true : _a;
            if (!showAddIcon) {
                return null;
            }
            return (_jsx(S.IconWrap, { children: _jsx(S.AddIcon, {}) }));
        };
        _this.renderText = function () {
            var _a = _this.props, text = _a.text, _b = _a.showAddIcon, showAddIcon = _b === void 0 ? true : _b;
            return (_jsx(S.TextWrap, __assign({ showAddIcon: showAddIcon }, { children: _jsx(S.AddText, { children: text }) })));
        };
        _this.renderDisclosureIcon = function () {
            if (!_this.props.showDisclosureIcon) {
                return null;
            }
            return (_jsx(S.IconDisclosureWrap, { children: _jsx(S.IconDisclosure, { source: images.ICO_DISCLOSURE }) }));
        };
        return _this;
    }
    AddListItem.prototype.render = function () {
        var _a = this.props, onPress = _a.onPress, rest = __rest(_a, ["onPress"]);
        return (_jsx(_Fragment, { children: _jsxs(S.Wrap, __assign({ onPress: onPress }, rest, { children: [this.renderAddIcon(), this.renderText(), this.renderDisclosureIcon()] })) }));
    };
    return AddListItem;
}(React.PureComponent));
export default AddListItem;
