import { createAction } from "redux-act";
import { createRoutine } from "../utils/routine.utils";
export var getChats = createRoutine("CHATS_GET");
export var searchChats = createRoutine("CHATS_SEARCH");
export var createChat = createRoutine("CHAT_CREATE");
export var getMessages = createRoutine("CHAT_GET_MESSAGES");
export var requestForMessages = createAction("CHAT_REQUEST_FOR_MESSAGES");
export var requestForMessagesFulfilled = createAction("CHAT_REQUEST_FOR_MESSAGES_FULFILLED");
export var setChatIsExhausted = createAction("CHAT_EXHAUSTED");
export var sendMessage = createRoutine("CHAT_SEND_MESSAGE");
export var sendTyping = createRoutine("CHAT_SEND_TYPING");
export var sendReadReceipt = createRoutine("CHAT_SEND_READ_RECEIPT");
export var stopTyping = createAction("CHAT_STOP_TYPING");
export var messagesReceived = createAction("CHAT_MESSAGES_RECEIVED");
export var isTypingReceived = createAction("CHAT_IS_TYPING_RECEIVED");
export var counterInfoReceived = createAction("CHAT_COUNTER_INFO_RECEIVED");
export var statusInfoReceived = createAction("CHAT_STATUS_INFO_RECEIVED");
export var getChatDetail = createRoutine("CHAT_GET_DETAIL");
export var updateChatMembers = createRoutine("CHAT_UPDATE_MEMBERS");
export var updateChat = createRoutine("CHAT_UPDATE");
export var getChatsByContactId = createAction("CHATS_CONTACT_GET");
