var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { getDeleteAction } from "./delete.actions";
export var getDeleteDefaultState = function () { return ({
    data: {},
    delete: {},
}); };
export var registerDeleteActions = function (params) {
    var entityName = params.entityName, reducer = params.reducer;
    var routine = getDeleteAction({ entityName: entityName });
    // Start
    reducer.on(routine.types.START, function (state, _a) {
        var _b;
        var id = _a.id;
        return merge({}, state, {
            // TODO:
            // @ts-ignore
            delete: (_b = {},
                _b[id] = {
                    isDeleting: true,
                    deleteError: undefined,
                },
                _b),
        });
    });
    // Success
    reducer.on(routine.types.SUCCESS, function (state, _a) {
        var _b, _c;
        var id = _a.id;
        return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[id] = undefined, _b)), delete: __assign(__assign({}, state.delete), (_c = {}, _c[id] = {
                isDeleting: false,
                deleteError: undefined,
            }, _c)) }));
    });
    // Failure
    reducer.on(routine.types.FAILURE, function (state, _a) {
        var _b;
        var id = _a.id, error = _a.error;
        return merge({}, state, {
            // TODO:
            // @ts-ignore
            delete: (_b = {},
                _b[id] = {
                    isDeleting: false,
                    deleteError: error,
                },
                _b),
        });
    });
};
