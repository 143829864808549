var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "../../components";
import Icon from "../Icon";
import * as S from "./Tags.styled";
var Tags = function (_a) {
    var items = _a.items, onRemove = _a.onRemove;
    return (_jsx(Grid, __assign({ spacing: "extraTight", alignItems: "center" }, { children: items.map(function (item, index) {
            var handleRemove = function () {
                var _a;
                onRemove === null || onRemove === void 0 ? void 0 : onRemove(item.key);
                (_a = item.onRemove) === null || _a === void 0 ? void 0 : _a.call(item, item.key);
            };
            var hasCloseButton = onRemove || item.onRemove;
            return (_jsx(Grid.Item, { children: _jsxs(S.Tag, __assign({ hasCloseButton: hasCloseButton }, { children: [_jsx(S.Text, { children: item.label }), hasCloseButton && (_jsx(S.Close, __assign({ onClick: handleRemove }, { children: _jsx(Icon, { source: "close", size: "extraSmall" }) })))] })) }, index));
        }) })));
};
export default Tags;
