var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Platform, Text, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 16px;\n  margin-bottom: 16px;\n\n  ", "\n"], ["\n  width: 100%;\n  margin-top: 16px;\n  margin-bottom: 16px;\n\n  ", "\n"])), Platform.select({
    web: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-top: 12px;\n      margin-bottom: 12px;\n    "], ["\n      margin-top: 12px;\n      margin-bottom: 12px;\n    "]))),
}));
export var Content = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  padding: 0 16px;\n  ", "\n  ", "\n"], ["\n  flex-direction: row;\n  align-items: center;\n  padding: 0 16px;\n  ", "\n  ", "\n"])), function (props) { return (props.hasTitle ? "margin-top: 8px;" : ""); }, Platform.select({
    web: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      padding: 0;\n    "], ["\n      padding: 0;\n    "]))),
}));
export var Label = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  flex: 1;\n  margin-right: 24px;\n  ", "\n"], ["\n  color: ", ";\n  flex: 1;\n  margin-right: 24px;\n  ", "\n"])), function (props) { return props.theme.colors.neutral1; }, Platform.select({
    web: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), function (props) { return props.theme.colors.global1; }),
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
