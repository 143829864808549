var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var Washout = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  ", ";\n  ", ";\n"], ["\n  position: relative;\n  ", ";\n  ", ";\n"])), function (_a) {
    var isDisabled = _a.isDisabled;
    return isDisabled
        ? "\n    pointer-events: none;\n    user-select: none;\n    &:after {\n      content: '';\n      position: absolute;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      left: 0;\n      z-index: 100;\n    }"
        : "";
}, function (_a) {
    var variation = _a.variation;
    return variation !== "off"
        ? "opacity: ".concat(variation === "light" ? "0.2" : "0.5")
        : "";
});
var templateObject_1;
