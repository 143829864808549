var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import styled, { borderRadius, css, spacing } from "@rentiohq/web-theme";
import { ellipsis, rem } from "polished";
import { Link } from "react-router-dom";
import { ESpacings } from "../Grid";
var getBorderColor = function (_a) {
    var appearance = _a.appearance, theme = _a.theme, defaultColor = _a.defaultColor;
    switch (appearance) {
        case "info":
            return theme.colors.blue[800];
        case "success":
            return theme.colors.green[800];
        case "warning":
            return theme.colors.yellow[800];
        case "error":
            return theme.colors.red[800];
        default:
            return defaultColor || theme.colors.neutral40;
    }
};
var getActiveColor = function (_a) {
    var appearance = _a.appearance, theme = _a.theme, defaultColor = _a.defaultColor;
    switch (appearance) {
        case "info":
            return theme.colors.blue[0];
        case "success":
            return theme.colors.green[0];
        case "warning":
            return theme.colors.yellow[0];
        case "error":
            return theme.colors.red[0];
        default:
            return defaultColor || theme.colors.neutral10;
    }
};
export var Wrapper = styled(function (props) { return _jsx(Box, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Stage = styled(function (props) { return (_jsx(Box, __assign({ p: ESpacings.base }, props))); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n  position: relative;\n  width: 100%;\n  border: 1px solid ", ";\n  border-right: 8px solid\n    ", ";\n  border-radius: ", ";\n  display: inline-flex;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n  > h2 {\n    ", "\n  }\n"], ["\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n  position: relative;\n  width: 100%;\n  border: 1px solid ", ";\n  border-right: 8px solid\n    ", ";\n  border-radius: ", ";\n  display: inline-flex;\n  flex-direction: column;\n  ", "\n  ", "\n  ", "\n  > h2 {\n    ", "\n  }\n"])), function (props) { return getBorderColor(props); }, function (props) {
    return getBorderColor(__assign(__assign({}, props), { defaultColor: props.theme.colors.green60 }));
}, borderRadius(), function (props) {
    return props.hasClick
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          cursor: pointer;\n          &:hover {\n            background-color: ", ";\n          }\n        "], ["\n          cursor: pointer;\n          &:hover {\n            background-color: ", ";\n          }\n        "])), props.theme.colors.neutral10) : "";
}, function (props) {
    return props.isActive
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          background-color: ", ";\n        "], ["\n          background-color: ", ";\n        "])), getActiveColor(props)) : "";
}, function (props) {
    return !props.isLast
        ? "\n          &::after {\n            position: absolute;\n            left: calc(100% + 8px);\n            top: 50%;\n            display: block;\n            content: '';\n            width: 0;\n            height: 0;\n            border-style: solid;\n            border-width: 6px 0 6px 6px;\n            border-color: transparent transparent transparent ".concat(getBorderColor(__assign(__assign({}, props), { defaultColor: props.theme.colors.green60 })), ";\n            transform: translate(0%, -50%);\n          }\n      ")
        : "";
}, ellipsis());
export var StyledLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: none;\n  color: rgb(73, 73, 73);\n"], ["\n  text-decoration: none;\n  color: rgb(73, 73, 73);\n"])));
export var Body = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  height: 100%;\n"])));
export var Count = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  flex: 1 1 auto;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  flex: 1 1 auto;\n  height: 100%;\n"])));
export var CountNumber = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  line-height: 1;\n"], ["\n  margin-right: ", ";\n  font-family: ", ";\n  font-size: ", ";\n  line-height: 1;\n"])), spacing("tight"), function (props) { return props.theme.fonts.financial; }, function (props) { return rem(40, props.theme.baseFontSize); });
export var CountDescription = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return props.theme.colors.neutral60; });
export var Icon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  width: ", ";\n  margin-left: ", ";\n  color: ", ";\n"], ["\n  flex: 0 0 auto;\n  width: ", ";\n  margin-left: ", ";\n  color: ", ";\n"])), function (props) { return rem(40, props.theme.baseFontSize); }, spacing("loose"), function (props) { return props.theme.colors.neutral60; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
