export var FontWeights;
(function (FontWeights) {
    FontWeights[FontWeights["Regular"] = 400] = "Regular";
    FontWeights[FontWeights["Medium"] = 500] = "Medium";
    FontWeights[FontWeights["SemiBold"] = 600] = "SemiBold";
    FontWeights[FontWeights["Bold"] = 700] = "Bold";
})(FontWeights || (FontWeights = {}));
export var EFontSizes;
(function (EFontSizes) {
    EFontSizes[EFontSizes["Small"] = 0] = "Small";
    EFontSizes[EFontSizes["Normal"] = 1] = "Normal";
    EFontSizes[EFontSizes["Medium"] = 2] = "Medium";
    EFontSizes[EFontSizes["Large"] = 3] = "Large";
    EFontSizes[EFontSizes["ExtraLarge"] = 4] = "ExtraLarge";
    EFontSizes[EFontSizes["ExtraExtraLarge"] = 5] = "ExtraExtraLarge";
    EFontSizes[EFontSizes["SuperLarge"] = 6] = "SuperLarge";
    EFontSizes[EFontSizes["HyperLarge"] = 7] = "HyperLarge";
    EFontSizes[EFontSizes["UltraLarge"] = 8] = "UltraLarge";
})(EFontSizes || (EFontSizes = {}));
