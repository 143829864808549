var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "../../theme/styled-components.native";
import { View, Platform } from "react-native";
export var Wrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), Platform.OS.match(/ios|android/)
    ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n      "], ["\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n      "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))));
export var FormTopSpacer = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 8px;\n"], ["\n  width: 100%;\n  height: 8px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
