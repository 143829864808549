var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
// @ts-ignore
import media from "styled-media-query";
import { StyledIcon } from "../../components/Icon/Icon.styled";
export var StyledNoResults = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: ", " auto;\n  text-align: center;\n  ", "\n  ", " {\n    ", "\n    margin-bottom: ", ";\n  }\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: ", " auto;\n  text-align: center;\n  ", "\n  ", " {\n    ", "\n    margin-bottom: ", ";\n  }\n  ", "\n"])), spacing("loose"), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 800), ";\n  ");
}, StyledIcon, function (_a) {
    var theme = _a.theme;
    return "\n      color: ".concat(theme.getColor("primary"), ";\n    ");
}, spacing("loose"), media.greaterThan("medium")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: ", "px;\n  "], ["\n    max-width: ", "px;\n  "])), function (_a) {
    var maxWidth = _a.maxWidth;
    return maxWidth;
}));
export var StyledNoResultsContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 400), ";\n  ");
});
var templateObject_1, templateObject_2, templateObject_3;
