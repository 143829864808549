var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as t from "../../../services/translationService";
import * as S from "../SharedForm.styled";
export var FormAside = function (_a) {
    var _b = _a.schemas, schemas = _b === void 0 ? [] : _b, currentStep = _a.currentStep, withSummary = _a.withSummary, onStepClick = _a.onStepClick, mergedPartialFormData = _a.mergedPartialFormData;
    var summaryActive = currentStep === schemas.length;
    return (_jsx(S.List, { children: _jsxs(_Fragment, { children: [schemas.map(function (schema, index) {
                    if (schema.showIf && !schema.showIf(mergedPartialFormData)) {
                        return null;
                    }
                    var uiSchema = schemas[index].uiSchema;
                    var title = uiSchema["rentio:title"] || "".concat(t.system("step"), " ").concat(index + 1);
                    var isCurrent = currentStep === index;
                    var isPassed = index <= currentStep;
                    var handleStepClick = function () {
                        if (!isPassed || isCurrent)
                            return;
                        onStepClick(index);
                    };
                    return (_jsx(S.AsideStep, __assign({ isPassed: isPassed, isCurrent: isCurrent, onClick: handleStepClick }, { children: title }), index));
                }), withSummary && (_jsx(S.AsideStep, __assign({ isPassed: summaryActive, isCurrent: summaryActive }, { children: t.system("summary") })))] }) }));
};
