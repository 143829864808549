var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as authActions from "../../redux/auth/auth.actions";
export var isPerformingSubmitActionSelector = function () { return function (state) {
    return state.auth.isSigningUp;
}; };
export var performingSubmitActionResultSelector = function () { return function (state) {
    return state.auth.user;
}; };
export var performingSubmitActionErrorSelector = function () { return function (state) {
    return state.auth.signUpError;
}; };
export var submitActionCreator = function (locale, platform, captchaVersion, captchaEnterprisePlatform) {
    return function (formData) {
        return authActions.signUp.actions.start(__assign({ locale: locale, platform: platform, captchaVersion: captchaVersion, captchaEnterprisePlatform: captchaEnterprisePlatform }, formData));
    };
};
