var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css, spacing as getSpacing, styled } from "@rentiohq/web-theme";
import { StyledStackItem } from "./components/Item/Item.styled";
var distributionMap = {
    leading: "align-items: flex-start",
    trailing: "justify-content: flex-end",
    center: "justify-content: center",
    equalSpacing: "justify-content: space-between",
    fill: " > ".concat(StyledStackItem, " { \n    flex: 1 1 auto;\n  }"),
    fillEvenly: "\n    > ".concat(StyledStackItem, " {\n      flex: 1 1 auto;\n      @supports (min-width: fit-content) {\n        flex: 1 1 0%;\n        min-width: fit-content;\n      }\n    }\n  "),
};
var alignmentMap;
(function (alignmentMap) {
    alignmentMap["leading"] = "align-items: flex-start";
    alignmentMap["trailing"] = "align-items: flex-end";
    alignmentMap["center"] = "align-items: center";
    alignmentMap["fill"] = "align-items: stretch;";
    alignmentMap["baseline"] = "align-items: baseline;";
})(alignmentMap || (alignmentMap = {}));
var StackSpacing = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: -", ";\n  margin-left: -", ";\n\n  > * {\n    margin-top: ", ";\n    margin-left: ", ";\n    max-width: calc(100% - ", ");\n  }\n"], ["\n  margin-top: -", ";\n  margin-left: -", ";\n\n  > * {\n    margin-top: ", ";\n    margin-left: ", ";\n    max-width: calc(100% - ", ");\n  }\n"])), function (_a) {
    var spacing = _a.spacing;
    return getSpacing(spacing);
}, function (_a) {
    var spacing = _a.spacing;
    return getSpacing(spacing);
}, function (_a) {
    var spacing = _a.spacing;
    return getSpacing(spacing);
}, function (_a) {
    var spacing = _a.spacing;
    return getSpacing(spacing);
}, function (_a) {
    var spacing = _a.spacing;
    return getSpacing(spacing);
});
export var StyledStack = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  display: ", ";\n  flex-wrap: wrap;\n  align-items: stretch;\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  ", ";\n  ", ";\n  display: ", ";\n  flex-wrap: wrap;\n  align-items: stretch;\n  ", "\n  ", "\n  ", "\n  ", "\n"])), StackSpacing, function (props) { return (props.vertical ? 1 : 1); }, function (_a) {
    var display = _a.display;
    return (display ? display : "flex");
}, function (_a) {
    var wrap = _a.wrap;
    return (wrap ? "flex-wrap: nowrap;" : "");
}, function (_a) {
    var vertical = _a.vertical;
    return (vertical ? "flex-direction: column;" : "");
}, function (_a) {
    var distribution = _a.distribution;
    return distribution
        ? distributionMap[distribution]
        : "";
}, function (_a) {
    var alignment = _a.alignment;
    return alignment ? alignmentMap[alignment] : "";
});
var templateObject_1, templateObject_2;
