var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import isArray from "lodash/isArray";
import * as S from "./TextStyle.styled";
var TextStyle = function (_a) {
    var _b = _a.element, element = _b === void 0 ? "span" : _b, children = _a.children, _c = _a.size, size = _c === void 0 ? "default" : _c, _d = _a.variation, variation = _d === void 0 ? "default" : _d, _e = _a.style, style = _e === void 0 ? {} : _e;
    return (_jsx(S.TextStyle, __assign({ as: element, variations: isArray(variation) ? variation : [variation], size: size, style: style }, { children: children })));
};
export default TextStyle;
