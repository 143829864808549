import { REDUCER_KEY_REQUIREMENTS, } from "./requirements.reducer";
export var isFetching = function (state) {
    return state.requirements.isFetching;
};
export var fetchError = function (state) {
    return state.requirements.fetchError;
};
export var getRequirements = function (state) {
    return state.requirements.requirements;
};
