var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { FontWeights, spacing } from "@rentiohq/web-theme";
import { hideVisually, rem } from "polished";
import Button from "../Button";
export var TabComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Tabs = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  font-weight: ", ";\n  list-style-type: none;\n  margin: ", " 0 0;\n  padding: 0;\n  overflow: auto;\n"], ["\n  display: flex;\n  font-weight: ", ";\n  list-style-type: none;\n  margin: ", " 0 0;\n  padding: 0;\n  overflow: auto;\n"])), FontWeights.SemiBold, spacing("loose"));
export var TabCount = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 4px;\n  margin-top: -2px;\n  margin-bottom: -2px;\n  padding: 2px 3px 2px 4px;\n  border-radius: 2px;\n  background-color: ", ";\n  color: white;\n  font-size: ", ";\n  /* font-weight: 700; */\n"], ["\n  margin-left: 4px;\n  margin-top: -2px;\n  margin-bottom: -2px;\n  padding: 2px 3px 2px 4px;\n  border-radius: 2px;\n  background-color: ", ";\n  color: white;\n  font-size: ", ";\n  /* font-weight: 700; */\n"])), function (props) {
    return props.isActive
        ? props.theme.getColor("primary")
        : props.theme.getColor("gray", 400);
}, function (props) { return rem("13px", props.theme.baseFontSize); });
export var Tab = styled(function (_a) {
    var isActive = _a.isActive, rest = __rest(_a, ["isActive"]);
    return (_jsx(Button, __assign({}, rest)));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  flex: 0 0 auto;\n  position: relative;\n  display: block;\n  white-space: nowrap;\n  text-decoration: none;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  margin: 0;\n  padding: ", ";\n  overflow: hidden;\n  border: none;\n  border-bottom: 2px solid transparent;\n  border-radius: 0;\n  background: none;\n  ", "\n  &:hover {\n    ", " {\n      background-color: ", ";\n    }\n  }\n"], ["\n  cursor: pointer;\n  flex: 0 0 auto;\n  position: relative;\n  display: block;\n  white-space: nowrap;\n  text-decoration: none;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  margin: 0;\n  padding: ", ";\n  overflow: hidden;\n  border: none;\n  border-bottom: 2px solid transparent;\n  border-radius: 0;\n  background: none;\n  ", "\n  &:hover {\n    ", " {\n      background-color: ", ";\n    }\n  }\n"])), spacing(), function (_a) {
    var theme = _a.theme, isActive = _a.isActive;
    return "\n    font-size: ".concat(rem("14px", theme.baseFontSize), ";\n    color: ").concat(theme.getColor("gray", 400), ";\n    &:hover {\n      color: ").concat(theme.getColor("primary"), ";\n    }\n    &:hover,\n    &:focus {\n      background: inherit;\n      outline: none;\n    }\n    ").concat(isActive
        ? "\n      color: ".concat(theme.getColor("primary"), ";\n      border-bottom-color: ").concat(theme.getColor("primary"), ";\n    ")
        : "", " \n  ");
}, TabCount, function (props) { return props.theme.getColor("primary"); });
export var TabActionTrigger = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  margin-left: auto;\n  border-bottom: 3px solid transparent;\n  &:hover,\n  &:active {\n    ", ";\n  }\n"], ["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  margin-left: auto;\n  border-bottom: 3px solid transparent;\n  &:hover,\n  &:active {\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "color: ".concat(theme.getColor("primary"), ";");
});
export var TabContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var isHidden = _a.isHidden;
    return isHidden && hideVisually();
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
