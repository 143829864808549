var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, FontWeights, spacing } from "@rentiohq/web-theme";
import { border, hideVisually, rem, stripUnit, transitions } from "polished";
var horizontalSpacingAddon = spacing("extraTight");
var textStyleInput = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: ", ";\n"], ["\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: ", ";\n"])), FontWeights.Regular);
export var StyledBackdrop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "border-radius: ".concat(theme.inputStyles.borderRadius, ";");
}, function (_a) {
    var theme = _a.theme;
    return "background: ".concat(theme.inputStyles.backgroundColor.base, ";");
}, function (_a) {
    var theme = _a.theme;
    return border("1px", "solid", theme.inputStyles.borderColor.base);
}, transitions("border-color 250ms ease-in", "box-shadow 150ms ease-in"), function (_a) {
    var isHidden = _a.isHidden;
    return isHidden && hideVisually();
});
export var StyledPrefix = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n  flex: 0 0 auto;\n  margin-right: ", ";\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: relative;\n  z-index: 2;\n  flex: 0 0 auto;\n  margin-right: ", ";\n  color: ", ";\n  pointer-events: none;\n"])), horizontalSpacingAddon, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 600);
});
export var StyledSuffix = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n  flex: 0 0 auto;\n  margin-left: ", ";\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: relative;\n  z-index: 2;\n  flex: 0 0 auto;\n  margin-left: ", ";\n  color: ", ";\n  pointer-events: none;\n"])), horizontalSpacingAddon, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 600);
});
export var StyledFloatIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  z-index: 3;\n  flex: 0 0 auto;\n  margin-left: ", ";\n  color: ", ";\n  pointer-events: fill;\n  cursor: pointer;\n"], ["\n  position: relative;\n  z-index: 3;\n  flex: 0 0 auto;\n  margin-left: ", ";\n  color: ", ";\n  pointer-events: fill;\n  cursor: pointer;\n"])), horizontalSpacingAddon, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 600);
});
// @ts-ignore TODO: Typescript upgrade
export var InputBase = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  z-index: 1;\n  display: block;\n  flex: 1 1 0%;\n  width: 100%;\n  min-width: 0;\n  margin: 1px 0;\n  padding: ", " 0;\n  background: none;\n  border: none;\n  appearance: none;\n  line-height: ", ";\n  &::placeholder {\n    color: ", ";\n    opacity: 1; /* Firefox */\n  }\n  &:focus::placeholder {\n    color: transparent;\n  }\n  &:focus {\n    outline: none;\n  }\n  &:focus ~ ", " {\n    border-color: ", ";\n  }\n  &[readonly],\n  &:disabled {\n    color: ", ";\n    cursor: initial;\n  }\n  &:disabled ~ ", ", &[readonly] ~ ", " {\n    background-color: ", ";\n    box-shadow: none;\n    &:hover {\n      border-color: ", ";\n    }\n  }\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset;\n  }\n"], ["\n  ", "\n  position: relative;\n  z-index: 1;\n  display: block;\n  flex: 1 1 0%;\n  width: 100%;\n  min-width: 0;\n  margin: 1px 0;\n  padding: ", " 0;\n  background: none;\n  border: none;\n  appearance: none;\n  line-height: ", ";\n  &::placeholder {\n    color: ", ";\n    opacity: 1; /* Firefox */\n  }\n  &:focus::placeholder {\n    color: transparent;\n  }\n  &:focus {\n    outline: none;\n  }\n  &:focus ~ ", " {\n    border-color: ", ";\n  }\n  &[readonly],\n  &:disabled {\n    color: ", ";\n    cursor: initial;\n  }\n  &:disabled ~ ", ", &[readonly] ~ ", " {\n    background-color: ", ";\n    box-shadow: none;\n    &:hover {\n      border-color: ", ";\n    }\n  }\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus,\n  &:-webkit-autofill:active {\n    box-shadow: 0 0 0 30px white inset;\n  }\n"])), textStyleInput, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.spacing.vertical;
}, function (_a) {
    var theme = _a.theme;
    return rem(40 - 2 - Number(stripUnit(spacing("tight"))) * 2, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral50;
}, StyledBackdrop, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.borderColor.focus;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral60;
}, StyledBackdrop, StyledBackdrop, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral10;
}, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.borderColor.base;
});
export var StyledInput = styled.input(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  resize: none;\n  height: ", ";\n"], ["\n  ", "\n  resize: none;\n  height: ", ";\n"])), InputBase, function (props) {
    switch (true) {
        case props.isTextarea:
            return "16rem";
        case props.size === "extraSmall":
            return "32px";
        case props.size === "small":
            return "38px";
        case props.size === "medium":
            return "48px";
        case props.size === "large":
            return "66px";
        default:
            return "";
    }
});
export var StyledTextField = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  color: ", ";\n  cursor: text;\n  input {\n    ", "\n  }\n  &:hover:not(:disabled, [readonly]) ", " {\n    border-color: ", ";\n  }\n  ", "\n"], ["\n  ", "\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 0 ", ";\n  color: ", ";\n  cursor: text;\n  input {\n    ", "\n  }\n  &:hover:not(:disabled, [readonly]) ", " {\n    border-color: ", ";\n  }\n  ", "\n"])), textStyleInput, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.spacing.horizontal;
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 1000);
}, InputBase, StyledBackdrop, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.borderColor.hover;
}, function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return (hasError &&
        "\n      &&& {\n        ".concat(StyledBackdrop, " {\n          background-color: ").concat(theme.inputStyles.backgroundColor.error, ";\n          border-color: ").concat(theme.inputStyles.borderColor.error, ";\n          box-shadow: none;\n        }\n        ").concat(StyledInput, "  {\n          &:focus {\n            ~ ").concat(StyledBackdrop, " {\n              box-shadow: 0 0 2px 1px ").concat(theme.inputStyles.boxShadowColor.error, ";\n            }\n          }\n        }\n      }\n      "));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
