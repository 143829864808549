import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePrevious } from "../../utils/hooks.utils";
import * as agreementActions from "./agreement.actions";
import * as agreementSelectors from "./agreement.selectors";
export var useGetAgreement = function (params) {
    var leadId = params.leadId, quoteId = params.quoteId, agreementId = params.agreementId, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var agreement = useSelector(function (state) {
        return agreementSelectors.getAgreement(state, agreementId || "-1");
    });
    var isFetching = useSelector(function (state) {
        return agreementSelectors.isFetchingAgreement(state, agreementId || "-1");
    });
    var fetchError = useSelector(function (state) {
        return agreementSelectors.fetchAgreementError(state, agreementId || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (isFetching) {
            return;
        }
        if (!leadId || !quoteId || !agreementId) {
            return;
        }
        dispatch(agreementActions.getAgreement.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            agreementId: agreementId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId, quoteId, agreementId]);
    // Actions
    var fetchAgreement = function () {
        if (!leadId || !quoteId || !agreementId) {
            return;
        }
        dispatch(agreementActions.getAgreement.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            agreementId: agreementId,
            publicId: publicId,
        }));
    };
    return { agreement: agreement, isFetching: isFetching, fetchError: fetchError, fetchAgreement: fetchAgreement };
};
// Initialization flow:
// Fetches quote's activeAgreementId if available & sets fetched agreement as activeAgreement
export var useActiveAgreement = function (params) {
    var leadId = params.leadId, quote = params.quote, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var activeAgreement = useSelector(function (state) {
        return agreementSelectors.getActiveAgreementByQuoteId(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var isFetching = useSelector(function (state) {
        return agreementSelectors.isFetchingAgreement(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var fetchError = useSelector(function (state) {
        return agreementSelectors.fetchAgreementError(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var isCreating = useSelector(function (state) {
        return agreementSelectors.isCreatingAgreement(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var createError = useSelector(function (state) {
        return agreementSelectors.createAgreementError(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (!leadId || !quote || !publicId) {
            return;
        }
        if (!quote.activeAgreementId) {
            return;
        }
        if (isFetching) {
            return;
        }
        dispatch(agreementActions.getAgreement.actions.start({
            leadId: leadId,
            quoteId: quote.id,
            agreementId: quote.activeAgreementId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId, quote]);
    // Actions
    var createAgreement = function () {
        if (!leadId || !quote || !publicId) {
            return;
        }
        if (isCreating) {
            return;
        }
        dispatch(agreementActions.createAgreement.actions.start({
            leadId: leadId,
            quoteId: quote.id,
            publicId: publicId,
        }));
    };
    return {
        activeAgreement: activeAgreement,
        isFetching: isFetching,
        fetchError: fetchError,
        isCreating: isCreating,
        createError: createError,
        createAgreement: createAgreement,
    };
};
export var useMailAgreement = function (params) {
    var leadId = params.leadId, quote = params.quote, publicId = params.publicId;
    // Router
    var navigate = useNavigate();
    // State
    var _a = React.useState(false), enableAutoNavigate = _a[0], setEnableAutoNavigate = _a[1];
    // Custom hooks
    var _b = useActiveAgreement({
        leadId: leadId,
        quote: quote,
        publicId: publicId,
    }), activeAgreement = _b.activeAgreement, createAgreement = _b.createAgreement;
    var previousActiveAgreement = usePrevious(activeAgreement);
    // Redux
    var dispatch = useDispatch();
    var isMailing = useSelector(function (state) {
        return agreementSelectors.isMailingAgreement(state, (activeAgreement === null || activeAgreement === void 0 ? void 0 : activeAgreement.id) || "-1");
    });
    var wasMailing = usePrevious(isMailing);
    var mailError = useSelector(function (state) {
        return agreementSelectors.mailAgreementError(state, (activeAgreement === null || activeAgreement === void 0 ? void 0 : activeAgreement.id) || "-1");
    });
    // Actions
    var mailAgreementAndNavigate = function () {
        if (!leadId || !quote || !publicId) {
            return;
        }
        setEnableAutoNavigate(true);
        if (!activeAgreement) {
            createAgreement();
            return;
        }
        dispatch(agreementActions.mailAgreement.actions.start({
            leadId: leadId,
            quoteId: quote.id,
            agreementId: activeAgreement.id,
            publicId: publicId,
        }));
    };
    // Lifecycle
    React.useEffect(function () {
        if (!enableAutoNavigate) {
            return;
        }
        if (activeAgreement && !previousActiveAgreement) {
            mailAgreementAndNavigate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeAgreement, previousActiveAgreement]);
    React.useEffect(function () {
        if (!enableAutoNavigate) {
            return;
        }
        if (!isMailing && wasMailing && !mailError) {
            navigate(appendQueryParams({
                path: "/request/mailed",
                queryParams: { leadId: leadId, quoteId: quote === null || quote === void 0 ? void 0 : quote.id, publicId: publicId },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMailing, wasMailing, mailError]);
    return { mailAgreementAndNavigate: mailAgreementAndNavigate, isMailing: isMailing, mailError: mailError };
};
