var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import utils from "../../utils";
import { StyledScrollable } from "./Scrollable.styled";
var Scrollable = function (_a) {
    var _b = _a.vertical, vertical = _b === void 0 ? true : _b, _c = _a.horizontal, horizontal = _c === void 0 ? false : _c, children = _a.children;
    return (_jsx(StyledScrollable, __assign({ vertical: vertical, horizontal: horizontal }, { children: children })));
};
var ScrollableMemo = React.memo(Scrollable, utils.components.arePropsEqual);
export default ScrollableMemo;
