var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import media from "styled-media-query";
import { EUrgency, } from "./DataTable.types";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: auto;\n"], ["\n  position: relative;\n  overflow: auto;\n"])));
export var ScrollContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var Table = styled.table(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  border-spacing: 0;\n"], ["\n  width: 100%;\n  border-spacing: 0;\n"])));
export var Clickable = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: ", ";\n"], ["\n  cursor: ", ";\n"])), function (_a) {
    var onClick = _a.onClick;
    return (onClick ? "pointer" : "default");
});
export var Header = styled.th(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: ", " ", ";\n  align-items: baseline;\n  border-bottom: 2px solid ", ";\n  font-weight: ", ";\n  line-height: 1.2;\n  text-align: ", ";\n"], ["\n  padding: ", " ", ";\n  align-items: baseline;\n  border-bottom: 2px solid ", ";\n  font-weight: ", ";\n  line-height: 1.2;\n  text-align: ", ";\n"])), spacing("tight"), function (props) { return spacing(props.horizontalSpacing); }, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral30;
}, FontWeights.SemiBold, function (_a) {
    var _b = _a.horizontalAlign, horizontalAlign = _b === void 0 ? "left" : _b;
    return horizontalAlign;
});
export var Cell = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: ", "\n    ", ";\n  text-align: ", ";\n\n  ", ";\n\n  ", "\n  line-height: 1.2;\n  ", "\n  background-color: ", ";\n\n  transition: background-color 0.2s ease-in-out;\n"], ["\n  padding: ", "\n    ", ";\n  text-align: ", ";\n\n  ", ";\n\n  ", "\n  line-height: 1.2;\n  ", "\n  background-color: ", ";\n\n  transition: background-color 0.2s ease-in-out;\n"])), function (props) { return spacing(props.verticalSpacing); }, function (props) { return spacing(props.horizontalSpacing); }, function (_a) {
    var _b = _a.horizontalAlign, horizontalAlign = _b === void 0 ? "left" : _b;
    return horizontalAlign;
}, function (_a) {
    var theme = _a.theme, width = _a.width;
    return width ? "width: ".concat(rem(width, theme.baseFontSize), ";") : "";
}, function (props) {
    switch (props.contentType) {
        case "icon":
        case "icon-large":
            return "padding-right: 0";
        default:
            return "";
    }
}, function (_a) {
    var hasBottomBorder = _a.hasBottomBorder, theme = _a.theme;
    return hasBottomBorder
        ? "border-bottom: 1px solid ".concat(theme.colors.neutral30, ";")
        : "";
}, function (_a) {
    var _b = _a.horizontalAlign, horizontalAlign = _b === void 0 ? "left" : _b;
    return horizontalAlign;
});
export var CenterInnerWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0 auto;\n  text-align: center;\n  display: inline-block;\n"], ["\n  margin: 0 auto;\n  text-align: center;\n  display: inline-block;\n"])));
export var TableRow = styled.tr(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  vertical-align: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  vertical-align: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var verticalAlign = _a.verticalAlign;
    return verticalAlign;
}, function (_a) {
    var background = _a.background;
    return (background ? "background: ".concat(background, ";") : "");
}, function (_a) {
    var theme = _a.theme, onClick = _a.onClick;
    return "\n    ".concat(onClick ? "cursor: pointer;" : "", "\n    &:hover ").concat(Cell, " {\n      background: ").concat(theme.colors.neutral10, ";\n      ").concat(media.greaterThan("medium")(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        background: ", ";\n      "], ["\n        background: ", ";\n      "])), theme.colors.neutral10), "\n    }\n  ");
}, function (_a) {
    var theme = _a.theme, isSelected = _a.isSelected;
    return isSelected
        ? " ".concat(Cell, " {\n    background: ").concat(theme.colors.green10, ";\n    }")
        : "";
}, function (_a) {
    var theme = _a.theme, urgency = _a.urgency;
    switch (urgency) {
        case EUrgency.High:
            return "border-left: 3px solid ".concat(theme.getColor("red"));
        case EUrgency.Medium:
            return "border-left: 3px solid ".concat(theme.getColor("yellow"));
        case EUrgency.Low:
            return "border-left: 3px solid ".concat(theme.colors.neutral60);
        case EUrgency.None:
            return "border-left: 3px solid ".concat(theme.colors.green80);
        default:
            return "";
    }
}, function (_a) {
    var shouldShowLastBorder = _a.shouldShowLastBorder;
    return !shouldShowLastBorder
        ? "&:last-child ".concat(Cell, " {\n    border-bottom: none;\n  }")
        : "";
});
export var SortIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  opacity: ", ";\n"], ["\n  opacity: ", ";\n"])), function (props) { return (props.sorted ? "1" : ".4"); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
