import { normalizeDate, startOfDay, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { useDispatch, useSelector } from "react-redux";
import * as systemActions from "../../redux/system/system.actions";
import * as systemSelectors from "../../redux/system/system.selectors";
import { EPreferencePersistScope } from "../../redux/system/system.types";
import { STAT_DURATIONS, STAT_INTERVALS_PER_DURATION, } from "./DashboardStats.config";
import { EStatDuration, } from "./DashboardStats.types";
import { getFrom, getTo, statDurationToOption, statIntervalToOption, } from "./DashboardStats.utils";
var PREFERENCE_KEY_STAT_DURATION = "STAT_DURATION";
var PREFERENCE_KEY_STAT_FROM = "STAT_FROM";
var PREFERENCE_KEY_STAT_TO = "STAT_TO";
var PREFERENCE_KEY_STAT_INTERVAL = "STAT_INTERVAL";
var INITIAL_STAT_DURATION = EStatDuration.Last365Days;
export var useRange = function () {
    // Redux
    var dispatch = useDispatch();
    var statDuration = useSelector(function (state) {
        return systemSelectors.getPreference(state, PREFERENCE_KEY_STAT_DURATION) || INITIAL_STAT_DURATION;
    });
    var from = useSelector(function (state) {
        var value = systemSelectors.getPreference(state, PREFERENCE_KEY_STAT_FROM);
        if (value) {
            return normalizeDate(value);
        }
        return getFrom(INITIAL_STAT_DURATION);
    });
    var to = useSelector(function (state) {
        var value = systemSelectors.getPreference(state, PREFERENCE_KEY_STAT_TO);
        if (value) {
            return normalizeDate(value);
        }
        return getTo(INITIAL_STAT_DURATION);
    });
    var statInterval = useSelector(function (state) {
        return systemSelectors.getPreference(state, PREFERENCE_KEY_STAT_INTERVAL) || STAT_INTERVALS_PER_DURATION[INITIAL_STAT_DURATION][0];
    });
    // Options
    var statDurationOptions = STAT_DURATIONS.map(statDurationToOption);
    var statIntervalOptions = STAT_INTERVALS_PER_DURATION[statDuration].map(statIntervalToOption);
    // Handlers
    var onSetFrom = function (newDate) {
        var _a;
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[PREFERENCE_KEY_STAT_DURATION] = EStatDuration.Custom,
                _a[PREFERENCE_KEY_STAT_FROM] = startOfDay(newDate),
                _a),
        }));
    };
    var onSetTo = function (newDate) {
        var _a;
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[PREFERENCE_KEY_STAT_DURATION] = EStatDuration.Custom,
                _a[PREFERENCE_KEY_STAT_TO] = newDate,
                _a),
        }));
    };
    var onSetStatDuration = function (newStatDuration) {
        var _a;
        // Get new interval
        var statIntervalsForStatDuration = STAT_INTERVALS_PER_DURATION[newStatDuration];
        var newStatInterval = 
        // statIntervalsForStatDuration[statIntervalsForStatDuration.length - 1];
        statIntervalsForStatDuration[0];
        // Get new from & to
        var newFrom = getFrom(newStatDuration);
        var newTo = getTo(newStatDuration);
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[PREFERENCE_KEY_STAT_DURATION] = newStatDuration,
                _a[PREFERENCE_KEY_STAT_FROM] = newFrom,
                _a[PREFERENCE_KEY_STAT_TO] = newTo,
                _a[PREFERENCE_KEY_STAT_INTERVAL] = newStatInterval,
                _a),
        }));
    };
    var onSetStatInterval = function (newStatInterval) {
        var _a;
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[PREFERENCE_KEY_STAT_INTERVAL] = newStatInterval,
                _a),
        }));
    };
    // Export
    return {
        // Values
        statDuration: statDuration,
        from: from,
        to: to,
        statInterval: statInterval,
        // Handlers
        onSetStatDuration: onSetStatDuration,
        onSetFrom: onSetFrom,
        onSetTo: onSetTo,
        onSetStatInterval: onSetStatInterval,
        // Options
        statDurationOptions: statDurationOptions,
        statIntervalOptions: statIntervalOptions,
    };
};
