var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var sortByAccountId = function (memberA, memberB) {
    if (memberA.account.id < memberB.account.id) {
        return -1;
    }
    if (memberA.account.id > memberB.account.id) {
        return 1;
    }
    return 0;
};
export var getCurrentRolesForEmployee = function (members, enumValues, newEmployeeId) {
    return members.reduce(function (allRoles, member) {
        enumValues.forEach(function (role) {
            var _a, _b;
            if ((_b = (_a = member.employeeRoles) === null || _a === void 0 ? void 0 : _a[role]) === null || _b === void 0 ? void 0 : _b.map(function (account) { return account.id; }).includes(newEmployeeId)) {
                allRoles.push(role);
            }
        });
        return allRoles;
    }, []);
};
var transformExistingMembers = function (members, broker, ALLOWED_ROLES, addExistingMembers) {
    if (addExistingMembers === void 0) { addExistingMembers = false; }
    /** This code maps the existing members to an array of objects that can be used to update the members.
     * They have the form:
     * {
     *  accountId: number,
     *  employeeAccountId: number | null,
     *  roles: AMemberTypeEnum[]
     * }
     */
    var transformedEmployees = members
        .filter(function (member) {
        return member.account.id === broker.accountId &&
            member.roles.some(function (r) { return ALLOWED_ROLES.includes(r); });
    })
        .reduce(function (all, _a) {
        var account = _a.account, employeeRoles = _a.employeeRoles;
        if (employeeRoles) {
            ALLOWED_ROLES.forEach(function (role) {
                if (employeeRoles[role]) {
                    var employeeIdsForRole = employeeRoles[role].map(function (_a) {
                        var id = _a.id;
                        return id;
                    });
                    var updateMemberStructures = employeeIdsForRole.map(function (employeeId) {
                        var existingUpdateStructureForEmployee = all.find(function (update) { return update.employeeAccountId === employeeId; });
                        if (existingUpdateStructureForEmployee) {
                            // remove that existing structure from the array;
                            var index = all.indexOf(existingUpdateStructureForEmployee);
                            all.splice(index, 1);
                            // Add one with the new role
                            return __assign(__assign({}, existingUpdateStructureForEmployee), { roles: __spreadArray(__spreadArray([], existingUpdateStructureForEmployee.roles, true), [role], false) });
                        }
                        else {
                            return {
                                accountId: account.id,
                                employeeAccountId: employeeId,
                                roles: [role],
                            };
                        }
                    });
                    all = __spreadArray(__spreadArray([], all, true), updateMemberStructures, true);
                }
            });
        }
        return all;
    }, []);
    return __spreadArray(__spreadArray([], transformedEmployees, true), (addExistingMembers
        ? members
            .filter(function (m) {
            return m.account.id !== broker.accountId &&
                m.roles.every(function (role) { return ALLOWED_ROLES.includes(role); });
        })
            .map(function (member) {
            return {
                accountId: member.account.id,
                employeeAccountId: null,
                roles: member.roles.filter(function (role) { return ALLOWED_ROLES.includes(role); }),
            };
        })
        : []), true);
};
export var transformMembersAndRemoveRole = function (roleToRemove, employeeToBeRemovedId, members, broker, ALLOWED_ROLES, addExistingMembers) {
    if (addExistingMembers === void 0) { addExistingMembers = false; }
    var transformedExistingMembers = transformExistingMembers(members, broker, ALLOWED_ROLES, addExistingMembers);
    var existingRoleForEmployee = transformedExistingMembers.find(function (_a) {
        var roles = _a.roles, employeeAccountId = _a.employeeAccountId;
        return employeeAccountId === employeeToBeRemovedId &&
            roles.includes(roleToRemove);
    });
    if (!existingRoleForEmployee)
        return;
    var index = transformedExistingMembers.indexOf(existingRoleForEmployee);
    var copy = __assign({}, existingRoleForEmployee);
    if (copy.roles.length === 1) {
        // Only this role, so remove employeeId
        copy.employeeAccountId = null;
        transformedExistingMembers.splice(index, 1, copy);
    }
    else {
        // More roles, so just remove this role from the object
        copy.roles = copy.roles.filter(function (existingRole) { return existingRole !== roleToRemove; });
        transformedExistingMembers.splice(index, 1, copy);
    }
    return transformedExistingMembers;
};
// TODO: this stuff is way to complicated. It works for now, but we should simplify this.
export var transformMembersAndAddNewRole = function (newRole, newEmployeeId, members, broker, ALLOWED_ROLES, roleCanBeAssignedToMultipleEmployees, accountIdToBeReplaced, addExistingMembers) {
    if (roleCanBeAssignedToMultipleEmployees === void 0) { roleCanBeAssignedToMultipleEmployees = false; }
    if (addExistingMembers === void 0) { addExistingMembers = false; }
    var transformedExistingMembers = transformExistingMembers(members, broker, ALLOWED_ROLES, addExistingMembers);
    // After the transform, we actually update the members
    // 1. Remove the new role from another employee if it was formerly assigned to them
    if (!roleCanBeAssignedToMultipleEmployees) {
        var employeeFormerlyAssignedToRole = transformedExistingMembers.find(function (_a) {
            var roles = _a.roles, employeeAccountId = _a.employeeAccountId;
            if (accountIdToBeReplaced) {
                return (roles.includes(newRole) &&
                    accountIdToBeReplaced === employeeAccountId);
            }
            else {
                return roles.includes(newRole);
            }
        });
        if (employeeFormerlyAssignedToRole) {
            var index = transformedExistingMembers.indexOf(employeeFormerlyAssignedToRole);
            var copy = __assign(__assign({}, employeeFormerlyAssignedToRole), { roles: employeeFormerlyAssignedToRole.roles.filter(function (r) { return r !== newRole; }) });
            transformedExistingMembers.splice(index, 1, copy);
        }
    }
    var wasReplaced = false;
    if (accountIdToBeReplaced) {
        var oldAssignee = transformedExistingMembers.find(function (_a) {
            var employeeAccountId = _a.employeeAccountId, roles = _a.roles;
            return accountIdToBeReplaced === employeeAccountId && roles.includes(newRole);
        });
        if (oldAssignee) {
            var index = transformedExistingMembers.indexOf(oldAssignee);
            if (oldAssignee.roles.length === 1) {
                var copy = __assign(__assign({}, oldAssignee), { employeeAccountId: newEmployeeId });
                transformedExistingMembers.splice(index, 1, copy);
                wasReplaced = true;
            }
            else {
                var copy = __assign(__assign({}, oldAssignee), { roles: oldAssignee.roles.filter(function (r) { return r !== newRole; }) });
                transformedExistingMembers.splice(index, 1, copy);
            }
        }
    }
    // 2. add the new role to the chosen employee if we haven't already replaced one
    // (add if this employee already has another role,  or push a new object if
    // they don't have any role yet)
    if (!wasReplaced) {
        var existingRoleForEmployee = transformedExistingMembers.find(function (update) { return update.employeeAccountId === newEmployeeId; });
        if (existingRoleForEmployee) {
            // add the new role to this structure
            // remove the old structure from the array
            var index = transformedExistingMembers.indexOf(existingRoleForEmployee);
            var copy = __assign(__assign({}, existingRoleForEmployee), { roles: __spreadArray(__spreadArray([], existingRoleForEmployee.roles, true), [newRole], false) });
            transformedExistingMembers.splice(index, 1, copy);
        }
        else {
            transformedExistingMembers.push({
                accountId: broker.accountId,
                employeeAccountId: newEmployeeId,
                roles: [newRole],
            });
        }
    }
    return transformedExistingMembers;
};
export var findManagerOrAssignedEmployee = function (members, role, broker) {
    var _a, _b;
    if (!broker)
        return undefined;
    var manager = members.find(function (m) { return m.roles.includes(role) && m.account.id === broker.accountId; });
    if (!manager)
        return undefined;
    var assignedEmployee = (_b = (_a = manager === null || manager === void 0 ? void 0 : manager.employeeRoles) === null || _a === void 0 ? void 0 : _a[role]) === null || _b === void 0 ? void 0 : _b[0];
    return assignedEmployee !== null && assignedEmployee !== void 0 ? assignedEmployee : manager.account;
};
