var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { borderRadius, FontWeights, resetList, spacing, } from "@rentiohq/web-theme";
import { hideVisually, rem } from "polished";
import { Option } from "../Option/Option";
import { OptionContent as StyledOptionContent, OptionInput as StyledOptionInput, } from "../Option/Option.styled";
export var StyledOptionList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), resetList);
export var StyledOptionListItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  display: flex;\n  ", "\n  & + & {\n    margin-top: ", ";\n  }\n"])), function (_a) {
    var _b = _a.hasError, hasError = _b === void 0 ? false : _b, theme = _a.theme;
    return hasError &&
        "".concat(StyledOptionListItemOption, " {\n      color: ").concat(theme.getColor("red", 600), ";\n      border-color: ").concat(theme.getColor("red"), ";\n      background-color: ").concat(theme.getColor("red", 0), ";\n    }\n  ");
}, spacing());
// @ts-ignore
export var StyledOptionListItemOption = styled(function (props) { return (_jsx(Option, __assign({}, props))); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  border-radius: ", ";\n  text-align: left;\n  padding: ", ";\n  ", "\n  ", " {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: left;\n  }\n  &:hover {\n    color: ", ";\n    border-color: ", ";\n    background-color: inherit;\n  }\n  ", "\n"], ["\n  flex: 1;\n  border-radius: ", ";\n  text-align: left;\n  padding: ", ";\n  ", "\n  ", " {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: left;\n  }\n  &:hover {\n    color: ", ";\n    border-color: ", ";\n    background-color: inherit;\n  }\n  ", "\n"])), borderRadius(), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return "border: 1px solid ".concat(theme.getColor("gray", 200), ";");
}, StyledOptionContent, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary");
}, function (_a) {
    var checked = _a.checked, theme = _a.theme;
    return checked &&
        "\n      color: ".concat(theme.getColor("primary"), ";\n      border: 2px solid ").concat(theme.getColor("primary"), ";\n      &, &:hover {\n        background-color: ").concat(theme.getColor("primary", 0), ";\n      }\n  ");
});
export var StyledCardSelector = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  display: grid;\n  grid-gap: ", ";\n  grid-template-columns: repeat(\n    auto-fill,\n    minmax(calc(160px + (", " * 2)), 1fr)\n  );\n  margin-top: -", ";\n  margin-left: -", ";\n  margin-right: -", ";\n"], ["\n  ", "\n  display: grid;\n  grid-gap: ", ";\n  grid-template-columns: repeat(\n    auto-fill,\n    minmax(calc(160px + (", " * 2)), 1fr)\n  );\n  margin-top: -", ";\n  margin-left: -", ";\n  margin-right: -", ";\n"])), resetList, spacing("tight"), spacing(), spacing("tight"), spacing("tight"), spacing("tight"));
export var StyledCardSelectorCard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  padding: ", ";\n  text-transform: uppercase;\n  font-weight: ", ";\n  ", "\n  ", "\n"], ["\n  display: flex;\n  padding: ", ";\n  text-transform: uppercase;\n  font-weight: ", ";\n  ", "\n  ", "\n"])), spacing("tight"), FontWeights.SemiBold, function (_a) {
    var theme = _a.theme;
    return "\n    font-size: ".concat(rem("14px", theme.baseFontSize), ";\n    color: ").concat(theme.getColor("gray", 400), ";\n  ");
}, function (_a) {
    var _b = _a.hasError, hasError = _b === void 0 ? false : _b, theme = _a.theme;
    return hasError &&
        "".concat(StyledCardSelectorOption, " {\n      color: ").concat(theme.getColor("red", 600), ";\n      border-color: ").concat(theme.getColor("red"), ";\n      background-color: ").concat(theme.getColor("red", 0), ";\n    }\n  ");
});
export var StyledCardSelectorOption = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  padding: calc(", " + 1px);\n  border-radius: ", ";\n  text-align: center;\n  ", "\n  ", " {\n    ", ";\n  }\n  ", " {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n  }\n  ", "\n  ", "\n"], ["\n  width: 100%;\n  padding: calc(", " + 1px);\n  border-radius: ", ";\n  text-align: center;\n  ", "\n  ", " {\n    ", ";\n  }\n  ", " {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n  }\n  ", "\n  ", "\n"])), spacing(), borderRadius(), function (_a) {
    var theme = _a.theme;
    return "border: 1px solid ".concat(theme.getColor("gray", 200), ";");
}, StyledOptionInput, hideVisually(), StyledOptionContent, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled
        ? "\n    opacity: .5;\n    cursor: not-allowed;\n    "
        : "\n  &:hover {\n    color: ".concat(theme.getColor("primary"), ";\n    border-color: ").concat(theme.getColor("primary"), ";\n    background-color: inherit;\n  }\n  ");
}, function (_a) {
    var checked = _a.checked, theme = _a.theme;
    return checked &&
        "\n      color: ".concat(theme.getColor("primary"), ";\n      padding: ").concat(spacing(), ";\n      border: 2px solid ").concat(theme.getColor("primary"), ";\n      &, &:hover {\n        background-color: ").concat(theme.getColor("primary", 0), ";\n      }\n  ");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
