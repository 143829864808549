import { isEqual } from "lodash";
import React from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
var getDecodedSearchParams = function (params) {
    var searchParams = params.searchParams, configMap = params.configMap;
    var results = {};
    Object.keys(configMap).forEach(function (key) {
        var config = configMap[key];
        if (!config) {
            return;
        }
        var rawResult = searchParams.getAll(key);
        var result = config.decode(rawResult);
        if (result === null) {
            return;
        }
        // INFO: Key is string and does not match
        // @ts-ignore
        results[key] = result;
    });
    return results;
};
export var useQueryParams = function (configMap) {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    // INFO: Assume all inputs get mapped below
    var _b = React.useState(getDecodedSearchParams({ searchParams: searchParams, configMap: configMap })), results = _b[0], setResults = _b[1];
    React.useEffect(function () {
        var newResults = getDecodedSearchParams({ searchParams: searchParams, configMap: configMap });
        if (isEqual(newResults, results)) {
            return;
        }
        setResults(newResults);
    }, [searchParams, configMap, results]);
    var setResultsPublic = function (changes, navigateOptions) {
        var newSearchParams = createSearchParams(searchParams);
        Object.keys(changes).forEach(function (key) {
            var config = configMap[key];
            if (!config) {
                return;
            }
            var rawNewValue = changes[key];
            var newValue = config.encode(rawNewValue);
            // Set new value or remove query param
            if (typeof newValue === "string") {
                newSearchParams.set(key, newValue);
            }
            else if (Array.isArray(newValue)) {
                newSearchParams.delete(key);
                newValue.forEach(function (item) {
                    if (typeof item === "string") {
                        newSearchParams.append(key, item);
                    }
                });
            }
            else if (!newValue) {
                newSearchParams.delete(key);
            }
        });
        // Sort query params for consistent URLS
        newSearchParams.sort();
        setSearchParams(newSearchParams, navigateOptions);
    };
    return [results, setResultsPublic];
};
