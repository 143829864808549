import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Frame, Icon, Navigation } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

const AppMemo = React.memo(() => <Outlet />);

export const App = () => {
  // Redux
  const dispatch = useDispatch();

  // Render
  const userMenuActions = [
    {
      content: getLocalizedText("system.logout"),
      onClick: () => {
        dispatch({ type: "LOG_OUT" });
      },
      media: <Icon source="logout" color="red" />,
    },
  ];

  return (
    <Frame
      userMenuActions={userMenuActions}
      navigation={
        <Navigation
          items={[
            {
              label: getLocalizedText("insurance.backoffice.dashboard.title"),
              icon: "dashboard",
              url: "/",
            },
            {
              label: getLocalizedText("insurance.backoffice.leads.title"),
              icon: "contacts",
              url: "/leads/",
            },
          ]}
        />
      }
    >
      <AppMemo />
    </Frame>
  );
};
