var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getInitials } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as S from "./ProfilePicture.styled";
var ProfilePicture = function (_a) {
    var profile = _a.profile, size = _a.size, variationProfile = _a.variationProfile;
    var pictureUrl = profile.pictureUrl;
    var initials = getInitials(profile);
    if (pictureUrl) {
        return _jsx("img", { src: pictureUrl, alt: initials });
    }
    return (_jsx(S.InitialsWrapper, __assign({ profile: profile, size: size, variationProfile: variationProfile }, { children: _jsx(S.InitialsContent, { children: initials }) })));
};
export default ProfilePicture;
