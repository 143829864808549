var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
export var reduceContactAddress = function (contact) {
    var addressKeys = ["street", "number", "box", "zip", "city", "countryId"];
    var contactAddressData = Object.keys(contact).reduce(function (obj, key) {
        !obj.address && (obj.address = {});
        addressKeys.includes(key)
            ? //
                // @ts-ignore
                (obj.address[key] = contact[key])
            : //
                // @ts-ignore
                (obj[key] = contact[key]);
        return obj;
    }, {});
    return contactAddressData;
};
export var getMetaData = function (_a) {
    var contact = _a.contact, _b = _a.metaKeys, metaKeys = _b === void 0 ? ["email", "phone"] : _b;
    var contactMetaData = metaKeys.reduce(
    // @ts-ignore
    function (currentKeys, key) {
        // @ts-ignore
        if (contact.hasOwnProperty(key) && contact[key]) {
            if (key === "phone") {
                var formattedPhone = formatPhone(contact[key]);
                if (formattedPhone) {
                    return __spreadArray(__spreadArray([], currentKeys, true), [formattedPhone], false);
                }
            }
            // @ts-ignore
            return __spreadArray(__spreadArray([], currentKeys, true), [contact[key]], false);
        }
        return currentKeys;
    }, []);
    return contactMetaData.join(" | ");
};
