import * as brokerSelectors from "@rentiohq/shared-frontend/dist/redux/broker/broker.selectors";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { connect } from "react-redux";
import ContactFetchListItem from "./ContactFetchListItem";
import * as utils from "./ContactFetchListItem.utils";
var mapStateToProps = function (state, props) {
    var contactId = utils.getContactId(props);
    var accountId = utils.getAccountId(props);
    return {
        isFetching: contactSelectors.getContactIsFetching(state, contactId, accountId),
        fetchError: contactSelectors.getContactFetchError(state, contactId, accountId),
        fetchedContact: contactSelectors.getContact(state, contactId, accountId),
        accountFromStore: brokerSelectors.getEmployeeByAccountId(state, accountId),
    };
};
var mapDispatchToProps = {
    getContact: contactActions.getContact.actions.start,
    getContactsByAccountIds: contactActions.getContactsByAccountIds.actions.start,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactFetchListItem);
