var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var EmployeeRolesContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: ", ";\n  padding-bottom: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: ", ";\n  padding-bottom: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing("tight");
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing("tight");
});
var templateObject_1;
