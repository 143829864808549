import { useDispatch, useSelector } from "react-redux";
import * as systemActions from "./system.actions";
import * as systemSelectors from "./system.selectors";
import { EPreferencePersistScope } from "./system.types";
export var useInternalMode = function () {
    var dispatch = useDispatch();
    var KEY = "RENTIO_INTERNAL";
    var internalModeEnabled = useSelector(function (state) {
        return systemSelectors.getPreference(state, KEY) || false;
    });
    var toggleInternalMode = function () {
        var _a;
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[KEY] = !internalModeEnabled,
                _a),
        }));
    };
    return { internalModeEnabled: internalModeEnabled, toggleInternalMode: toggleInternalMode };
};
export var useLocaleAsKeys = function () {
    var dispatch = useDispatch();
    var KEY = "LOCALE_AS_KEYS";
    var localeAsKeysEnabled = useSelector(function (state) {
        return systemSelectors.getPreference(state, KEY) || false;
    });
    var toggleLocaleAsKeys = function () {
        var _a;
        dispatch(systemActions.setPreferences({
            persistScope: EPreferencePersistScope.LocalStorage,
            preferences: (_a = {},
                _a[KEY] = !localeAsKeysEnabled,
                _a),
        }));
    };
    return { localeAsKeysEnabled: localeAsKeysEnabled, toggleLocaleAsKeys: toggleLocaleAsKeys };
};
