var _a;
import { generateSelectors } from "../generators/generator.selectors";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./contract.constants";
export var getCount = (_a = generateSelectors(GENERATOR_PARAMS), _a.getCount), getDetail = _a.getDetail, getPaged = _a.getPaged, getAllWhere = _a.getAllWhere, isFetchingAllWhere = _a.isFetchingAllWhere, isFetchingCount = _a.isFetchingCount, isFetchingDetail = _a.isFetchingDetail, isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError;
var isIndexing = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.indexContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isIndexingContract; };
var indexError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.indexContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.indexContractError; };
var didIndex = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.indexContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.didIndexContract; };
var isRenewing = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.renewContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isRenewing; };
var renewError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.renewContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.renewError; };
var requestMoveServiceError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestMoveService) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.requestMoveServiceError; };
var isRequestingMoveService = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestMoveService) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isRequestingMoveService; };
var requestInsuranceError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestInsurance) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.requestInsuranceError; };
var isRequestingInsurance = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestInsurance) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isRequestingInsurance; };
var activationError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestInsurance) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.requestInsuranceError; };
var isActivatingLegal = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.requestInsurance) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isRequestingInsurance; };
var isCanceling = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.cancelContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.isCancelingContract; };
var cancellationError = function (state, id) { var _a, _b; return (_b = (_a = state.CONTRACT_V2.cancelContract) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.cancelContractError; };
export { activationError, cancellationError, didIndex, indexError, isActivatingLegal, isCanceling, isIndexing, isRenewing, isRequestingInsurance, isRequestingMoveService, renewError, requestInsuranceError, requestMoveServiceError, };
