export var getPagedSelectors = function (getBase) {
    return {
        dataForPage: function (rootState, params) {
            var _a, _b, _c;
            var id = params.id, _d = params.page, page = _d === void 0 ? 0 : _d;
            var state = getBase(rootState);
            return (_c = (_b = (_a = state.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.ids) === null || _c === void 0 ? void 0 : _c.map(function (randomId) { return state.data[randomId]; }).filter(function (data) { return !!data; });
        },
        isFetchingPage: function (rootState, params) {
            var _a, _b;
            var id = params.id, _c = params.page, page = _c === void 0 ? 0 : _c;
            var state = getBase(rootState);
            return (_b = (_a = state.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching;
        },
        fetchPageError: function (rootState, params) {
            var _a, _b;
            var id = params.id, _c = params.page, page = _c === void 0 ? 0 : _c;
            var state = getBase(rootState);
            return (_b = (_a = state.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError;
        },
    };
};
