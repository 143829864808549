var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, css, FontWeights, spacing, } from "@rentiohq/web-theme";
import { hideVisually, rem } from "polished";
import { StyledIcon } from "../../Icon/Icon.styled";
export var Checkbox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  cursor: pointer;\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"], ["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  cursor: pointer;\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme, isChecked = _a.isChecked, hasError = _a.hasError;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), ";\n    height: ").concat(rem(24, theme.baseFontSize), ";\n    ").concat(isChecked ? "color: ".concat(theme.getColor("primary", 1000)) : "", ";\n    ").concat(hasError ? "color: ".concat(theme.getColor("red")) : "", ";\n  ");
}, hideVisually(), StyledIcon);
export var OptionContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var OptionWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var isCard = _a.isCard;
    return (isCard ? "" : "flex: 1 0 100%;");
});
export var OptionLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var isHidden = _a.isHidden;
    return isHidden && hideVisually();
});
export var DefaultOptionStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  border-radius: ", ";\n  text-align: left;\n  padding: ", ";\n  cursor: pointer;\n  ", "\n  ", " {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: left;\n  }\n  &:hover {\n    color: ", ";\n    background-color: inherit;\n  }\n  ", "\n  ", "\n"], ["\n  display: flex;\n  border-radius: ", ";\n  text-align: left;\n  padding: ", ";\n  cursor: pointer;\n  ", "\n  ", " {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    text-align: left;\n  }\n  &:hover {\n    color: ", ";\n    background-color: inherit;\n  }\n  ", "\n  ", "\n"])), borderRadius(), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return "border: 1px solid ".concat(theme.getColor("gray", 200), ";");
}, OptionContent, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 1000);
}, function (_a) {
    var isChecked = _a.isChecked, theme = _a.theme;
    return isChecked
        ? "\n    color: ".concat(theme.getColor("primary"), ";\n    border: 2px solid ").concat(theme.getColor("primary"), ";\n    padding: calc(").concat(spacing("tight"), " - 1px);\n    &, &:hover {\n      background-color: ").concat(theme.getColor("primary", 0), ";\n    };\n    ")
        : "";
}, function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return hasError
        ? "\n      border: 1px solid ".concat(theme.getColor("red"), ";\n      background-color: ").concat(theme.getColor("red", 0), ";\n    ")
        : "";
});
export var OptionChecked = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-flex;\n  ", "\n  border-radius: 50%;\n"], ["\n  display: inline-flex;\n  ", "\n  border-radius: 50%;\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), "\n    height: ").concat(rem(24, theme.baseFontSize), "\n    background-color: ").concat(theme.getColor("primary", 600), ";\n    color: ").concat(theme.getColor("white"), ";\n  ");
});
export var Option = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  flex: 1 0 100%;\n"], ["\n  ", "\n  flex: 1 0 100%;\n"])), DefaultOptionStyle);
var largeOptionCard = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(220, theme.baseFontSize), "\n    height: ").concat(rem(150, theme.baseFontSize), "\n  ");
});
var defaultOptionCard = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(120, theme.baseFontSize), "\n    height: ").concat(rem(120, theme.baseFontSize), "\n  ");
});
export var OptionCard = styled.label(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  text-transform: uppercase;\n  font-weight: ", ";\n  ", "\n  ", "\n  ", " {\n    position: absolute;\n    ", "\n  }\n  ", " {\n    ", "\n  }\n  ", " {\n    padding: ", ";\n    align-items: center;\n    ", ";\n  }\n  ", " {\n    text-align: center;\n    margin-top: auto;\n  }\n"], ["\n  ", "\n  position: relative;\n  text-transform: uppercase;\n  font-weight: ", ";\n  ", "\n  ", "\n  ", " {\n    position: absolute;\n    ", "\n  }\n  ", " {\n    ", "\n  }\n  ", " {\n    padding: ", ";\n    align-items: center;\n    ", ";\n  }\n  ", " {\n    text-align: center;\n    margin-top: auto;\n  }\n"])), DefaultOptionStyle, FontWeights.SemiBold, function (_a) {
    var theme = _a.theme;
    return "\n  letter-spacing: ".concat(rem(0.5, theme.baseFontSize), ";\n    font-size:  ").concat(rem(12, theme.baseFontSize), ";\n    color: ").concat(theme.getColor("gray", 400), "\n  ");
}, function (_a) {
    var isChecked = _a.isChecked, theme = _a.theme;
    return isChecked
        ? "\n      color: ".concat(theme.getColor("primary"), ";\n      &, &:hover {\n        background-color: ").concat(theme.getColor("primary", 0), ";\n      };\n    ")
        : "";
}, OptionChecked, function (_a) {
    var theme = _a.theme;
    return "\n      top: ".concat(theme.baseUnit * 2, "px;\n      right: ").concat(theme.baseUnit * 2, "px;\n    ");
}, Checkbox, hideVisually(), OptionContent, spacing("tight"), function (_a) {
    var size = _a.size;
    switch (size) {
        case "large":
            return largeOptionCard;
        default:
            return defaultOptionCard;
    }
}, OptionLabel);
export var OptionInput = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"], ["\n  display: inline-block;\n  position: relative;\n  margin-right: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme, checked = _a.checked;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), "\n    height: ").concat(rem(24, theme.baseFontSize), "\n  ");
}, hideVisually(), StyledIcon);
export var OptionList = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-left: -", ";\n  margin-top: -", ";\n  ", " {\n    padding-left: ", ";\n    padding-top: ", ";\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  margin-left: -", ";\n  margin-top: -", ";\n  ", " {\n    padding-left: ", ";\n    padding-top: ", ";\n  }\n"])), spacing(), spacing(), OptionWrapper, spacing(), spacing());
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
