var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Platform, Text } from "react-native";
import styled, { css } from "../../theme/styled-components.native";
export var Wrap = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return props.theme.colors.neutral1; });
// @ts-ignore TODO: Typescript upgrade
export var ParamText = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  ", ";\n"], ["\n  color: ", ";\n  ", ";\n"])), function (props) { return props.theme.colors.global5; }, Platform.select({
    web: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      cursor: pointer;\n    "], ["\n      cursor: pointer;\n    "]))),
}));
var templateObject_1, templateObject_2, templateObject_3;
