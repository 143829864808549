import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.signIn.types";
export var getSchema = function (initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [
                EField.PhoneOrEmail,
                EField.Password,
                // EField.Captcha
            ],
            properties: (_a = {},
                _a[EField.PhoneOrEmail] = {
                    default: getValue(EField.PhoneOrEmail, initialValues, defaultValues),
                },
                _a[EField.Password] = {
                    default: getValue(EField.Password, initialValues, defaultValues),
                    minLength: 8,
                },
                _a),
        });
    };
};
export var uiSchema = function () {
    var _a;
    return (_a = {
            "rentio:trackingKey": "sign-in"
        },
        _a[EField.PhoneOrEmail] = {
            "ui:field": ERentioFormField.PhoneOrEmail,
            title: getLocalizedText("auth.phone_or_email.label"),
            placeholder: getLocalizedText("auth.phone_or_email.label"),
        },
        _a[EField.Password] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.password"),
            placeholder: getLocalizedText("form.sign_in.password.placeholder"),
            secureTextEntry: true,
        },
        _a);
};
