import { EEnvironment } from "./app.config.types";
export default {
    // Main
    ENVIRONMENT: EEnvironment.Dev,
    // Rentio API
    BASE_URL: "https://api.dev.rentio.be/v1",
    // BASE_URL: "http://localhost:3100/v1",
    BASE_URL_INSURANCE: "https://dev.api.rentio-insurance.be/v1",
    BASE_URL_V2: "https://api-next.dev.rentio.be/v2",
    // BASE_URL_V2: "http://localhost:3000/v2",
    BASE_URL_SERVERLESS: "https://serverless.dev.rentio.be",
    URL_SOCKETS: "wss://api.dev.rentio.be/v1/websocket?access_token={{accessToken}}&locale={{locale}}",
    // Google
    CAPTCHA_KEY: 
    // cspell:disable-next-line
    "6LcxNJokAAAAAO7A3tB4AGgq8GI-_4TXjjy0Bpuy",
    // Rentio
    RENTIO_BROKER_ID: 1000,
    // Partners
    INSURANCE_TENANT_FLOW_URL: "https://dev.rentio-insurance.be",
    KEYPOINT_REPAIR_URL: "https://uat-case.keypoint.be",
};
