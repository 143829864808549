var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { elementChildren } from "../../utils/components";
import * as S from "./DescriptionList.styled";
import DescriptionListItem from "../DescriptionListItem";
var DescriptionList = function (props) {
    var _a = props.items, items = _a === void 0 ? [] : _a, children = props.children, itemProps = __rest(props, ["items", "children"]);
    return (_jsx(S.Wrap, { children: __spreadArray(__spreadArray([], items, true), elementChildren(children), true).map(function (child, index) {
            var isReactElement = React.isValidElement(child);
            return (child && (_jsx(React.Fragment, { children: isReactElement ? (React.cloneElement(child, itemProps)) : (_jsx(DescriptionListItem, __assign({ term: child.term, description: child.description }, itemProps))) }, index)));
        }) }));
};
export default DescriptionList;
