var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  &&& {\n    > button {\n      margin-top: ", ";\n    }\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  margin-bottom: ", ";\n  > *:not(h2) + * {\n    margin-top: ", ";\n  }\n  &&& {\n    > button {\n      margin-top: ", ";\n    }\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), spacing("loose"), spacing("loose"), spacing("extraLoose"));
export var StyledDescriptionText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  margin-bottom: ", ";\n"], ["\n  ", "\n  margin-bottom: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    font-size: ".concat(rem("14px", theme.baseFontSize), ";\n    color: ").concat(theme.getColor("gray", 400), ";\n  ");
}, spacing("extraTight"));
export var StyledHelpText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  margin-top: ", ";\n"], ["\n  font-size: ", ";\n  color: ", ";\n  margin-top: ", ";\n"])), function (props) { return rem("14px", props.theme.baseFontSize); }, function (props) { return props.theme.getColor("gray", 400); }, spacing("extraTight"));
export var StyledError = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  &:first-child {\n    margin-top: 0;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  font-size: ", ";\n  color: ", ";\n  margin-top: ", ";\n  margin-bottom: ", ";\n  &:first-child {\n    margin-top: 0;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), function (props) { return rem("14px", props.theme.baseFontSize); }, function (props) { return props.theme.getColor("red"); }, spacing("extraTight"), spacing("extraTight"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
