var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FormInputCaptcha from "@rentiohq/shared-frontend/dist/components/components/FormInputCaptcha/FormInputCaptcha";
import * as authApi from "@rentiohq/shared-frontend/dist/redux/auth/auth.api";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { isValidEmail } from "@rentiohq/shared-frontend/dist/utils/email.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isValidPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { ECaptchaVersion } from "@rentiohq/shared/dist/types/auth.types";
import { Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { DisplayText, Field, Fieldset, Form, Link, StyledError, TextContainer, } from "../../components";
import Button from "../../components/Button";
import * as t from "../../services/translationService";
import { parsePhoneNumber } from "../../utils/phone";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
var forgotPassword = Yup.object().shape({
    phoneOrEmail: Yup.string().required(t.formErrorRequired(t.authPhoneOrEmailLabel())),
    captcha: Yup.string().required(t.formErrorRequired(getLocalizedText("system.captcha"))),
});
var ResetPassword = function () {
    var location = useLocation();
    // Query params
    var params = queryString.parse(location.search);
    // State
    var _a = React.useState(null), requestSentTo = _a[0], setRequestSentTo = _a[1];
    // Event handlers
    var handleFormSubmit = function (params, actions) { return __awaiter(void 0, void 0, void 0, function () {
        var phoneOrEmail, captcha, phone, email, unknownError_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    phoneOrEmail = params.phoneOrEmail, captcha = params.captcha;
                    if (!captcha) {
                        showAlert({ type: "error", message: getLocalizedText("general.error") });
                        return [2 /*return*/];
                    }
                    if (!phoneOrEmail) {
                        showAlert({ type: "error", message: getLocalizedText("general.error") });
                        return [2 /*return*/];
                    }
                    if (isValidPhone(phoneOrEmail)) {
                        phone = phoneOrEmail;
                    }
                    else if (isValidEmail(phoneOrEmail)) {
                        email = phoneOrEmail;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, authApi.requestPasswordReset({
                            phone: phone,
                            email: email,
                            captcha: captcha,
                            captchaVersion: ECaptchaVersion.Legacy,
                        })];
                case 2:
                    _a.sent();
                    setRequestSentTo(phoneOrEmail);
                    return [3 /*break*/, 4];
                case 3:
                    unknownError_1 = _a.sent();
                    showAlert({ type: "error", message: getLocalizedText("general.error") });
                    return [3 /*break*/, 4];
                case 4:
                    actions.setSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Render
    var renderRequestPasswordResetForm = function (_a) {
        var status = _a.status, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue;
        var handleInputChange = function (event) {
            var inputValue = event.target.value;
            var phoneRegex = RegExp(/^[0-9+\s]+$/g);
            var isPhone = phoneRegex.test(inputValue);
            if (!isPhone)
                return;
            var newValue = parsePhoneNumber(inputValue);
            setFieldValue("phoneOrEmail", newValue);
        };
        return (
        // @ts-ignore
        _jsxs(Form, { children: [_jsxs(Fieldset, { children: [_jsx(Field, { name: "phoneOrEmail", label: t.authPhoneOrEmailLabel(), onChange: handleInputChange, autoFocus: true }), _jsx(FormInputCaptcha, { onChangeValue: function (value) {
                                setFieldValue("captcha", value);
                            } })] }), status && _jsx(StyledError, { children: status }), _jsx(Button, __assign({ type: "submit", isDisabled: isSubmitting, appearance: "primary", isFullWidth: true }, { children: t.authRequestPasswordResetSubmitLabel() }))] }));
    };
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authRequestPasswordResetTitle() }), requestSentTo ? (_jsxs(_Fragment, { children: [_jsxs(TextContainer, { children: [_jsx("p", { children: t.authRequestPasswordResetSentMessage() }), _jsx("b", { children: requestSentTo })] }), _jsx(StyledAuthActions, __assign({ align: "left" }, { children: _jsx(Link, __assign({ to: "/auth/signin" }, { children: t.authToLogin() })) }))] })) : (_jsxs(_Fragment, { children: [_jsx(Formik, { initialValues: {
                                                phoneOrEmail: (params.phoneOrEmail &&
                                                    decodeURI(String(params.phoneOrEmail))) ||
                                                    "",
                                                captcha: undefined,
                                            }, onSubmit: handleFormSubmit, validationSchema: forgotPassword, render: renderRequestPasswordResetForm }), _jsx(StyledAuthActions, __assign({ align: "center" }, { children: _jsx(Link, __assign({ to: "/auth/signin" }, { children: t.authToLogin() })) }))] }))] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
export default ResetPassword;
