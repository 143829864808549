var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import throttle from "lodash/throttle";
import React from "react";
import * as t from "../../services/translationService";
import utils from "../../utils";
import { renderActions } from "../Button";
import Grid, { ESpacings } from "../Grid";
import Icon from "../Icon";
import NoResults from "../NoResults";
import Pagination from "../Pagination";
import TextField from "../TextField";
import { StyledFilter, StyledItemWrapper, StyledList, StyledListWrapper, } from "./ResourceList.styled";
var ResourceList = /** @class */ (function (_super) {
    __extends(ResourceList, _super);
    function ResourceList(props) {
        var _this = _super.call(this, props) || this;
        _this.initialState = {
            searchValue: "",
            currentPage: 0,
            totalPages: 0,
        };
        _this.throttleHandleChange = throttle(function (value) {
            var _a, _b;
            var newQuery = utils.query.setVariables(_this.props.searchQuery, {
                filter: { search: value, skip: 0 },
            });
            _this.setState({ currentPage: 0 });
            (_b = (_a = _this.props).onSearch) === null || _b === void 0 ? void 0 : _b.call(_a, newQuery);
        }, 300, { leading: false });
        _this.setTotalPages = function () {
            var limit = Number(_this.props.searchQuery.options.variables.filter.limit);
            var count = Number(_this.props.searchCount);
            _this.setState({
                totalPages: Math.ceil(count / limit),
            });
        };
        _this.handleChange = function (_a) {
            var _b = _a.currentTarget.value, value = _b === void 0 ? "" : _b;
            _this.throttleHandleChange(value);
            _this.setState({
                searchValue: value,
            });
        };
        _this.updatePage = function (page) {
            var _a, _b;
            var limit = Number(_this.props.searchQuery.options.variables.filter.limit);
            var newQuery = utils.query.setVariables(_this.props.searchQuery, {
                filter: { skip: (page - 1) * limit },
            });
            _this.setState({
                currentPage: page,
            });
            (_b = (_a = _this.props).onSearch) === null || _b === void 0 ? void 0 : _b.call(_a, newQuery);
        };
        _this.scrollToTop = function () {
            if (!_this.wrapperRef)
                return;
            var viewportOffset = _this.wrapperRef.getBoundingClientRect();
            var offsetTop = viewportOffset.top;
            if (offsetTop > 0)
                return;
            window.scrollTo({
                top: offsetTop,
            });
        };
        _this.handlePageClick = function (data) {
            _this.updatePage(data.selected + 1);
            _this.scrollToTop();
        };
        _this.renderRowItem = function (item, index) {
            var _a = _this.props, _b = _a.items, items = _b === void 0 ? [] : _b, renderItem = _a.renderItem, _c = _a.hasSeparator, hasSeparator = _c === void 0 ? true : _c, _d = _a.hasLastSeparator, hasLastSeparator = _d === void 0 ? true : _d;
            if (!item) {
                return null;
            }
            var id = typeof item === "object" && item.hasOwnProperty("id")
                ? item.id
                : index.toString();
            var isLastItem = index === items.length - 1;
            return (_jsx(StyledItemWrapper, __assign({ hasSeparator: isLastItem ? hasLastSeparator && hasSeparator : hasSeparator }, { children: renderItem(item, index) }), id));
        };
        _this.state = _this.initialState;
        return _this;
    }
    ResourceList.prototype.componentDidMount = function () {
        if (!this.props.searchQuery)
            return;
        this.setTotalPages();
    };
    ResourceList.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.searchCount !== this.props.searchCount) {
            this.setTotalPages();
        }
    };
    ResourceList.prototype.render = function () {
        var _this = this;
        var _a = this.props, items = _a.items, model = _a.model, variation = _a.variation;
        var namespace = "".concat(model && "".concat(model, "."), "search");
        var itemsExist = items.length > 0;
        var listMarkup = itemsExist ? (_jsx(StyledList, __assign({ variation: variation }, { children: items.map(this.renderRowItem) }))) : null;
        var _b = this.props, actions = _b.actions, noResultsContent = _b.noResultsContent, noResultsHeading = _b.noResultsHeading, renderFilter = _b.renderFilter, _c = _b.searchLabel, searchLabel = _c === void 0 ? "" : _c, searchPlaceholder = _b.searchPlaceholder, searchQuery = _b.searchQuery, searchCount = _b.searchCount, _d = _b.searchHidden, searchHidden = _d === void 0 ? false : _d, _e = _b.searchCountHidden, searchCountHidden = _e === void 0 ? true : _e;
        var hasSearch = searchQuery && !searchHidden;
        var hasFilter = !!renderFilter;
        var hasActions = !!actions;
        return (_jsxs(_Fragment, { children: [(hasSearch || hasFilter || hasActions) && (_jsxs(Grid, __assign({ justifyContent: "stretch" }, { children: [_jsx(Grid.Item, __assign({ flex: "1 0 auto" }, { children: !searchHidden && (_jsx(StyledFilter, { children: _jsx(TextField, { label: searchLabel, placeholder: searchPlaceholder, value: this.state.searchValue, labelHidden: true, onChange: this.handleChange, name: namespace, id: namespace, prefix: _jsx(Icon, { source: "search" }) }) })) })), renderFilter && _jsx(Grid.Item, { children: renderFilter() }), actions && (_jsx(Grid.Item, { children: renderActions(actions, {
                                overrides: { appearance: "primary" },
                            }) }))] }))), items.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: !searchCountHidden && (_jsx(Box, __assign({ mb: ESpacings.tight }, { children: t.searchResultsCount({ count: searchCount }) }))) }), _jsx(StyledListWrapper, __assign({ ref: function (el) {
                                _this.wrapperRef = el;
                            } }, { children: listMarkup })), this.state.totalPages > 1 && (_jsx(Pagination, { initialPage: this.state.currentPage, pageCount: this.state.totalPages, onPageChange: this.handlePageClick }))] })) : ((noResultsHeading || noResultsContent) && (_jsx(NoResults, __assign({ heading: noResultsHeading }, { children: noResultsContent && _jsx("p", { children: noResultsContent }) }))))] }));
    };
    return ResourceList;
}(React.PureComponent));
export { ResourceList };
