var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { iconSize, } from "@rentiohq/web-theme";
var getColor = function (useCurrentColor, theme, color, realColor) {
    if (realColor) {
        return realColor;
    }
    if (!color && useCurrentColor) {
        return "currentColor";
    }
    if (!color && !useCurrentColor) {
        return theme.colors.neutral60;
    }
    if (theme.getColor(color)) {
        return theme.getColor(color);
    }
    if (typeof theme.colors[color] === "string") {
        return theme.colors[color];
    }
    return "";
};
export var StyledIcon = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: block;\n  max-height: 100%;\n  max-width: 100%;\n  margin: 0;\n  ", "\n  svg {\n    display: block;\n    width: 100%;\n    max-width: 100%;\n    max-height: 100%;\n    pointer-events: none;\n  }\n"], ["\n  position: relative;\n  display: block;\n  max-height: 100%;\n  max-width: 100%;\n  margin: 0;\n  ", "\n  svg {\n    display: block;\n    width: 100%;\n    max-width: 100%;\n    max-height: 100%;\n    pointer-events: none;\n  }\n"])), function (_a) {
    var color = _a.color, realColor = _a.realColor, theme = _a.theme, size = _a.size, useCurrentColor = _a.useCurrentColor;
    return "\n    height: ".concat(iconSize(size), ";\n    width: ").concat(iconSize(size), ";\n    padding: ").concat(iconSize(size, function (x) { return x / 10; }), ";\n    fill: ").concat(getColor(useCurrentColor, theme, color, realColor), ";\n    ");
});
export var IconWrap = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
