var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from "./Lozenge.styled";
var Lozenge = function (_a) {
    var _b = _a.appearance, appearance = _b === void 0 ? "default" : _b, _c = _a.isBold, isBold = _c === void 0 ? false : _c, _d = _a.isUpperCase, isUpperCase = _d === void 0 ? true : _d, customTextColor = _a.customTextColor, customBackgroundColor = _a.customBackgroundColor, children = _a.children;
    return (_jsx(S.Wrapper, __assign({ appearance: appearance, isBold: isBold, isUpperCase: isUpperCase, customTextColor: customTextColor, customBackgroundColor: customBackgroundColor }, { children: children })));
};
export default Lozenge;
