var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { canUseDOM } from "exenv";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Icon } from "../../components";
import Button from "../Button";
import * as S from "./Drawer.styled";
var Drawer = function (_a) {
    var _b = _a.width, width = _b === void 0 ? "medium" : _b, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, _d = _a.position, position = _d === void 0 ? "left" : _d, _e = _a.lazy, lazy = _e === void 0 ? false : _e, children = _a.children, onClose = _a.onClose, _f = _a.isFullWidth, isFullWidth = _f === void 0 ? false : _f;
    var _g = React.useState(!lazy), renderDrawer = _g[0], setRenderDrawer = _g[1];
    var body = canUseDOM ? document.querySelector("body") : undefined;
    React.useEffect(function () {
        if (isOpen) {
            window.addEventListener("keydown", handleKeyDown);
        }
        else {
            window.removeEventListener("keydown", handleKeyDown);
        }
    }, [isOpen]);
    var handleBackdropClick = function (event) {
        handleClose(event);
    };
    var handleBackButtonClick = function (event) {
        handleClose(event);
    };
    var handleKeyDown = function (event) {
        if (event.key === "Escape" && isOpen) {
            handleClose(event);
        }
    };
    var handleClose = function (event) {
        if (onClose) {
            onClose(event);
        }
    };
    if (!body) {
        return null;
    }
    var toggleDrawer = function () {
        if (!lazy)
            return;
        setRenderDrawer(!renderDrawer);
    };
    return (_jsx(TransitionGroup, { children: isOpen && (_jsx(CSSTransition, __assign({ classNames: "drawer", timeout: 300, onEntered: toggleDrawer, onExited: toggleDrawer }, { children: _jsxs("div", { children: [_jsx(S.Background, { onClick: handleBackdropClick }), _jsxs(S.Drawer, __assign({ width: width, position: position }, { children: [isFullWidth ? (_jsx(S.DrawerClose, { children: _jsx(Button, __assign({ appearance: "plain", onClick: handleBackButtonClick }, { children: _jsx(Icon, { source: "close" }) })) })) : (_jsx(S.DrawerAside, { children: _jsx(Button, __assign({ appearance: "plain", onClick: handleBackButtonClick }, { children: _jsx(Icon, { size: "large", source: position === "left" ? "chevronLeft" : "chevronRight" }) })) })), renderDrawer && (_jsx(S.DrawerContent, __assign({ isFullWidth: isFullWidth }, { children: children })))] }))] }) }), "drawer-anim")) }));
};
export default Drawer;
