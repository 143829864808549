var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEqual, isFunction, omitBy } from "lodash";
import React from "react";
import JsonSchemaForm from "react-native-jsonschema-form-test";
import { showErrors } from "../../../redux/form/form.actions";
import { isValidRRNo, isValidVATNumber } from "../../../redux/form/form.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import { getStore } from "../../../utils/redux/redux.utils";
import * as S from "./Form.styles";
import { ERentioFormFormat } from "./Form.types";
import withOnChangeHandlerProvider from "./components/withOnChangeHandlerProvider";
import withShowErrorsProvider from "./components/withShowErrorsProvider";
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form(props) {
        var _this = _super.call(this, props) || this;
        _this.onFieldChangeHandlers = [];
        // Lifecycle methods
        // TODO: Why is this necessary?
        // INFO: When a field (e.g. inline contact select) dispatches an action, the form rerenders without new props
        _this.shouldComponentUpdate = function (nextProps) {
            var oldProps = omitBy(_this.props, isFunction);
            var newProps = omitBy(nextProps, isFunction);
            var shouldUpdate = !isEqual(oldProps, newProps);
            return shouldUpdate;
        };
        _this.renderContent = function () {
            var _a;
            var _b = _this.props, formId = _b.formId, schema = _b.schema, mergedPartialFormData = _b.mergedPartialFormData, uiSchema = _b.uiSchema, renderControls = _b.renderControls, FieldTemplate = _b.FieldTemplate, ObjectFieldTemplate = _b.ObjectFieldTemplate, ArrayFieldTemplate = _b.ArrayFieldTemplate, _c = _b.showErrorList, showErrorList = _c === void 0 ? false : _c, errorList = _b.errorList;
            var formContext = {
                formId: formId,
                registerOnFieldChangeHandler: _this.registerOnFieldChangeHandler,
                unregisterOnFieldChangeHandler: _this.unregisterOnFieldChangeHandler,
                onFieldChange: _this.handleFieldChange,
                onSelectMore: _this.handleSelectMore,
                onPressExtraInfo: _this.props.onPressExtraInfo,
                getFormData: _this.getFormData,
                getAllFormData: _this.getAllFormData,
                onSubmitAttempt: _this.handleSubmitAttempt,
            };
            var newSchema = _this.getSchemaWithConditionalFields(schema(mergedPartialFormData));
            var customFormats = (_a = {},
                _a[ERentioFormFormat.NationalRegisterNumber] = isValidRRNo,
                _a[ERentioFormFormat.VATNumber] = isValidVATNumber,
                _a);
            return (_jsx(JsonSchemaForm, __assign({ schema: newSchema, uiSchema: uiSchema, onSubmit: _this.handleSubmit, onChange: function (event) {
                    _this.setState({ formState: event.formData }, function () {
                        _this.handleSchemaChange(event);
                    });
                    if (_this.props.onChange)
                        _this.props.onChange(event);
                }, onError: _this.handleError, fields: _this.getFields(), FieldTemplate: FieldTemplate, ObjectFieldTemplate: ObjectFieldTemplate, ArrayFieldTemplate: ArrayFieldTemplate, showErrorList: showErrorList, ErrorList: errorList, liveValidate: true, validate: _this.validate, transformErrors: _this.transformErrors, formContext: formContext, customFormats: customFormats, ref: function (ref) { return (_this.form = ref); } }, { children: renderControls === null || renderControls === void 0 ? void 0 : renderControls(_this.handleSubmitAttempt) })));
        };
        _this.handleSubmit = function (event) {
            _this.props.onSubmit(event);
        };
        _this.handleSchemaChange = function (event) {
            // TODO: Call on change when fields are changed (based on custom showIf method in schema fields)
            var _a = _this.props, mergedPartialFormData = _a.mergedPartialFormData, schema = _a.schema, onSchemaChange = _a.onSchemaChange;
            var oldSchema = _this.getSchemaWithConditionalFields(schema(mergedPartialFormData));
            var oldSchemaFields = Object.keys(oldSchema.properties);
            var newSchema = _this.getSchemaWithConditionalFields(schema(mergedPartialFormData), event.formData);
            var newSchemaFields = Object.keys(newSchema.properties);
            if (onSchemaChange &&
                (!isEqual(newSchemaFields, oldSchemaFields) ||
                    !isEqual(oldSchema.required, newSchema.required))) {
                onSchemaChange(event);
            }
        };
        _this.handleError = function (errors) {
            var onError = _this.props.onError;
            if (onError) {
                onError(errors);
            }
        };
        // Public functions
        _this.handleSubmitAttempt = function () {
            if (!_this.form) {
                return;
            }
            var store = getStore();
            if (store) {
                var _a = _this.props, formId = _a.formId, stepIndex = _a.stepIndex;
                store.dispatch(showErrors({ formId: formId, stepIndex: stepIndex }));
            }
            // @ts-ignore
            _this.form.submit();
        };
        // Event handlers
        _this.registerOnFieldChangeHandler = function (handler) {
            _this.onFieldChangeHandlers.push(handler);
        };
        _this.unregisterOnFieldChangeHandler = function (handler) {
            _this.onFieldChangeHandlers = _this.onFieldChangeHandlers.filter(function (randomHandler) { return handler !== randomHandler; });
        };
        _this.handleFieldChange = function (fieldName, fieldValue) {
            var fieldProps = _this.props.uiSchema[fieldName];
            // If it is a 'secureTextEntry' field, we don't want to trim it, because that's likely a password
            var isSecureText = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.secureTextEntry;
            // Also provide the option to prevent trimming on any textfield
            var preventTrim = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.preventTrim;
            var trimmedValue = fieldValue;
            if (typeof fieldValue === "string" && !isSecureText && !preventTrim) {
                // The replace regex catches line breaks at the start (^) or end ($) of a string in all forms (CR: \r, LF: \n, CRLF: \r\n) and replaces them with an empty string.
                trimmedValue = fieldValue.trim().replace(/^[\r|\n|\r\n]$/, "");
            }
            _this.onFieldChangeHandlers.forEach(function (onFieldChangeHandler) {
                onFieldChangeHandler(fieldName, trimmedValue, __assign(__assign({}, _this.props.mergedPartialFormData), _this.form.state.formData));
            });
        };
        _this.handleSelectMore = function (fieldName, fieldValue, extraData) {
            if (!_this.form) {
                return;
            }
            var onSelectMore = _this.props.onSelectMore;
            if (onSelectMore) {
                onSelectMore(fieldName, fieldValue, 
                // @ts-ignore
                _this.form.state.formData, extraData);
            }
        };
        _this.getFormData = function () {
            var getFormData = _this.props.getFormData;
            if (getFormData) {
                return getFormData();
            }
            return {};
        };
        _this.getAllFormData = function () {
            var _a = _this.props, getFormData = _a.getFormData, mergedPartialFormData = _a.mergedPartialFormData;
            if (getFormData) {
                return __assign(__assign({}, (getFormData() || {})), (mergedPartialFormData || {}));
            }
            return mergedPartialFormData;
        };
        // Helpers
        _this.getFields = function () {
            var _a = _this.props, _b = _a.fields, fields = _b === void 0 ? {} : _b, formId = _a.formId, stepIndex = _a.stepIndex;
            var result = {};
            Object.keys(fields || {}).forEach(function (fieldKey) {
                result[fieldKey] = withShowErrorsProvider(withOnChangeHandlerProvider(fields[fieldKey]), formId, stepIndex);
            });
            return result;
        };
        _this.getSchemaWithConditionalFields = function (schema, formData) {
            var properties = {};
            var required = __spreadArray([], (schema.required || []), true);
            Object.keys(schema.properties || {}).forEach(function (property) {
                var _a, _b, _c;
                var propertyValue = (_a = schema.properties) === null || _a === void 0 ? void 0 : _a[property];
                if (
                // @ts-ignore
                (_b = propertyValue.requiredIf) === null || _b === void 0 ? void 0 : _b.call(propertyValue, __assign(__assign({}, (_this.props.mergedPartialFormData || {})), (formData || {})), schema)) {
                    required.push(property);
                }
                if (
                // @ts-ignore
                !propertyValue.showIf ||
                    // @ts-ignore
                    propertyValue.showIf(__assign(__assign({}, (_this.props.mergedPartialFormData || {})), (formData || {})), schema)) {
                    properties[property] = (_c = schema.properties) === null || _c === void 0 ? void 0 : _c[property];
                    // TODO: This is a dirty fix that forces fields to rerender when data is set from outside form
                    properties[property].randomize = Math.random();
                    return;
                }
            });
            return __assign(__assign({}, schema), { required: required, properties: properties });
        };
        // Error helpers
        _this.validate = function (formData, errors) {
            var _a = _this.props, schema = _a.schema, uiSchema = _a.uiSchema, fields = _a.fields, validate = _a.validate, mergedPartialFormData = _a.mergedPartialFormData;
            if (validate) {
                validate(formData, errors, mergedPartialFormData);
            }
            Object.values(fields || {}).forEach(function (field) {
                // @ts-ignore
                if (field.validate) {
                    // @ts-ignore
                    field.validate(formData, errors, schema, uiSchema, mergedPartialFormData);
                }
            });
            return errors;
        };
        _this.transformErrors = function (errors) {
            return errors.map(function (error) {
                var uiSchema = _this.props.uiSchema;
                var name = error.name, params = error.params;
                switch (name) {
                    case "minimum":
                        error.message = getLocalizedText("system.form.error.number.min", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "maximum":
                        error.message = getLocalizedText("system.form.error.number.max", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "exclusiveMinimum":
                        error.message = getLocalizedText("system.form.error.number.exclusive_min", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "exclusiveMaximum":
                        error.message = getLocalizedText("system.form.error.number.exclusive_max", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "minLength":
                        error.message = getLocalizedText("system.form.error.text.min_length", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "maxLength":
                        error.message = getLocalizedText("system.form.error.text.max_length", {
                            value: "".concat(params.limit),
                        });
                        break;
                    case "required": {
                        // Get name of field
                        var fieldTitle = undefined;
                        var missingProperty = params.missingProperty;
                        if (missingProperty) {
                            var field = uiSchema[missingProperty];
                            if (field) {
                                fieldTitle = field.title;
                                if (field.getTitle) {
                                    fieldTitle = field.getTitle(_this.props.mergedPartialFormData);
                                }
                            }
                        }
                        if (fieldTitle) {
                            error.message = getLocalizedText("system.form.error.required.field", { field: fieldTitle });
                        }
                        else {
                            error.message = getLocalizedText("system.form.error.required");
                        }
                        break;
                    }
                    case "format": {
                        var format = params.format;
                        switch (format) {
                            case ERentioFormFormat.Email:
                                error.message = getLocalizedText("system.form.error.invalid_email");
                                break;
                            case ERentioFormFormat.NationalRegisterNumber:
                                error.message = getLocalizedText("system.form.error.invalid_national_number");
                                break;
                            case ERentioFormFormat.VATNumber:
                                error.message = getLocalizedText("system.form.error.invalid_vat_number");
                                break;
                        }
                        break;
                    }
                    default:
                        break;
                }
                return error;
            });
        };
        _this.state = {
            formState: undefined,
        };
        return _this;
    }
    // Render
    Form.prototype.render = function () {
        var _a = this.props, renderContentWrapper = _a.renderContentWrapper, renderExternalControls = _a.renderExternalControls;
        return (_jsxs(S.Wrap, { children: [renderContentWrapper
                    ? renderContentWrapper(this.renderContent)
                    : this.renderContent(), renderExternalControls === null || renderExternalControls === void 0 ? void 0 : renderExternalControls(this.handleSubmitAttempt)] }, this.props.uiSchema["rentio:title"] || "x"));
    };
    return Form;
}(React.Component));
export default Form;
