import PrivateRoute from "@rentiohq/web-shared/dist/components/PrivateRoute";
import { getAuthScene } from "@rentiohq/web-shared/dist/scenes/Auth";
import DashboardStats from "@rentiohq/web-shared/dist/scenes/DashboardStats/DashboardStats";
import DashboardStatsDetail from "@rentiohq/web-shared/dist/scenes/DashboardStats/DashboardStatsDetail";
import Leads from "modules/general/routes/Leads";
import { default as NotFound } from "modules/general/routes/NotFound";
import { Route, Routes } from "react-router-dom";
import { App } from "./App";

export const AppRouter = () => {
  return (
    <Routes>
      {getAuthScene()}

      <Route element={<PrivateRoute />}>
        <Route path="*" element={<App />}>
          <Route path="leads">
            <Route path=":leadId" element={<Leads />} />

            <Route index element={<Leads />} />
          </Route>

          <Route path="stats" element={<DashboardStatsDetail />} />

          <Route index element={<DashboardStats />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
