import { EPaymentOrderType, } from "@rentiohq/shared/dist/types/payment.types";
export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Info"] = "_info";
    EField["Subtitle"] = "_subtitle";
    EField["TypeSelect"] = "typeSelect";
    EField["TypeRecurring"] = "typeRecurring";
    EField["TypeOnce"] = "typeOnce";
    EField["OtherDescription"] = "otherDescription";
    EField["PriceType"] = "priceType";
    EField["Price"] = "price";
    EField["PriceVat"] = "priceVat";
    EField["PriceVatHelpInfo"] = "priceVatHelpInfo";
    EField["CommissionVat"] = "commissionVat";
    EField["CommissionVatHelpInfo"] = "commissionVatHelpInfo";
    EField["ManagementFeeVat"] = "managementFeeVat";
    EField["ManagementFeeVatHelpInfo"] = "managementFeeVatHelpInfo";
    EField["DiscountAmount"] = "discountAmount";
    EField["PriceCommonCosts"] = "priceCommonCosts";
    EField["PriceCommonCostsVat"] = "priceCommonCostsVat";
    EField["PriceCommonCostsVatHelpInfo"] = "priceCommonCostsVatHelpInfo";
    EField["PriceNonCommonCosts"] = "priceNonCommonCosts";
    EField["PriceNonCommonCostsVat"] = "priceNonCommonCostsVat";
    EField["PriceNonCommonCostsVatHelpInfo"] = "priceNonCommonCostsVatHelpInfo";
    EField["PaymentRepetition"] = "paymentRepetition";
    EField["StartDate"] = "startDate";
    EField["StartDateDropdown"] = "startDateDropdown";
    EField["PaydateOffset"] = "paydateOffset";
    EField["FirstPaymentReceivedDate"] = "firstPaymentReceivedDate";
    EField["FirstPaymentReceivedDateProRata"] = "firstPaymentReceivedDateProRata";
    EField["ProRata"] = "proRata";
    EField["FirstPrice"] = "firstPrice";
    EField["FirstPriceVatInfo"] = "firstPriceVatInfo";
    EField["FirstPriceCommonCosts"] = "firstPriceCommonCosts";
    EField["FirstPriceNonCommonCosts"] = "firstPriceNonCommonCosts";
    EField["Payer"] = "payer";
    EField["PaymentMethods"] = "paymentMethods";
    EField["PaymentMethodsIbanisationInfo"] = "paymentMethodsIbanisationInfo";
    EField["PaymentMethodsTooLargeInfo"] = "paymentMethodsTooLargeInfo";
    EField["Payee"] = "payee";
    EField["PayoutReferenceType"] = "payoutReferenceType";
    EField["PayoutReference"] = "payoutReference";
    EField["PayoutReferenceCustom"] = "payoutReferenceCustom";
    EField["Iban"] = "iban";
    EField["IncludeCommission"] = "includeCommission";
    EField["IncludeManagementFee"] = "includeManagementFee";
    EField["CommissionPayee"] = "commissionPayee";
    EField["CommissionPayoutReferenceType"] = "commissionPayoutReferenceType";
    EField["CommissionPayoutReference"] = "commissionPayoutReference";
    EField["CommissionPayoutReferenceCustom"] = "commissionPayoutReferenceCustom";
    EField["CommissionPrice"] = "commissionPrice";
    EField["CommissionIban"] = "commissionIban";
    EField["ManagementFeePayee"] = "managementFeePayee";
    EField["ManagementFeePayoutReferenceType"] = "managementFeePayoutReferenceType";
    EField["ManagementFeePayoutReference"] = "managementFeePayoutReference";
    EField["ManagementFeePayoutReferenceCustom"] = "managementFeePayoutReferenceCustom";
    EField["ManagementFeeType"] = "managementFeeType";
    EField["ManagementFeePercentage"] = "managementFeePercentage";
    EField["ManagementFeePrice"] = "managementFeePrice";
    EField["ManagementFeeShouldIndex"] = "managementFeeShouldIndex";
    EField["ManagementFeeIban"] = "managementFeeIban";
    EField["OwnerPaymentMethod"] = "ownerPaymentMethod";
    EField["UpdatePaymentRequests"] = "updatePaymentRequests";
    EField["ActiveContract"] = "activeContract";
    EField["EndedContract"] = "finishedContract";
    EField["WithoutContract"] = "withoutContract";
    EField["BookkeepingPeriodStart"] = "bookkeepingPeriodStart";
    EField["BookkeepingPeriodEnd"] = "bookkeepingPeriodEnd";
    EField["BookkeepingGeneralLedgerAccount"] = "bookkeepingGeneralLedgerAccount";
})(EField || (EField = {}));
export var RECURRING_COSTS = [
    EPaymentOrderType.Rent,
    EPaymentOrderType.CommonCosts,
    EPaymentOrderType.NonCommonCosts,
    EPaymentOrderType.ManagementCosts,
    EPaymentOrderType.Rentio,
    EPaymentOrderType.OtherRecurring,
];
export var NON_RECURRING_COSTS = [
    EPaymentOrderType.RentDeposit,
    EPaymentOrderType.LocationDescription,
    EPaymentOrderType.BrokerFee,
    EPaymentOrderType.OtherOneoff,
];
export var COSTS_WITH_VAT = [
    EPaymentOrderType.ManagementCosts,
    EPaymentOrderType.Rentio,
    EPaymentOrderType.OtherRecurring,
    EPaymentOrderType.LocationDescription,
    EPaymentOrderType.BrokerFee,
    EPaymentOrderType.OtherOneoff,
];
export var ECreatePaymentMethod;
(function (ECreatePaymentMethod) {
    ECreatePaymentMethod["Sepa"] = "sepa";
    ECreatePaymentMethod["Online"] = "online";
    ECreatePaymentMethod["Ibanisation"] = "ibanisation";
})(ECreatePaymentMethod || (ECreatePaymentMethod = {}));
