var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { REDUCER_KEY_CONTACT, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import * as t from "../../services/translationService";
import Banner from "../Banner";
import ContactList from "../ContactList";
import { MultiStepForm } from "../SharedForm/MultiStepForm";
import ModalTabFinancial from "./components/ModalTabFinancial";
import { companySchemas, extraSchemas, personSchemas, } from "./forms/editContact";
var ContactEditModal = function (props) {
    var contactId = props.contactId, onClose = props.onClose, onSubmit = props.onSubmit, fields = props.fields;
    var formId = useRef(generateFormId());
    var dispatch = useDispatch();
    // const { broker } = useSelf();
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(null), contactState = _b[0], setContactState = _b[1];
    var isFetchingContact = useSelector(function (state) {
        return contactSelectors.getContactIsFetching(state, contactId);
    });
    var contact = useSelector(function (state) {
        return contactSelectors.getContact(state, contactId);
    });
    useEffect(function () {
        if (!contact) {
            dispatch(contactActions.getContact.actions.start({
                contactId: contactId,
            }));
        }
        if (contact) {
            setContactState(__assign({}, contact));
            dispatch(contactActions.getIbans.actions.start({ accountId: contact.accountId }));
        }
    }, [contact, contactId]);
    if (isFetchingContact || !contactId || !contact) {
        return null;
    }
    var updateContactState = function (newData) {
        // Map form data back to contact data
        var _a = newData, 
        // TODO: Enum from create form, but values should map to edit form
        _b = EContactField.Address, 
        // TODO: Enum from create form, but values should map to edit form
        address = _a[_b], _c = EContactField.PlaceOfBirth, placeOfBirth = _a[_c], restNewData = __rest(_a, [typeof _b === "symbol" ? _b : _b + "", typeof _c === "symbol" ? _c : _c + ""]);
        var newDataMapped = {};
        if (address === null || address === void 0 ? void 0 : address.street) {
            newDataMapped.street = address.street;
            newDataMapped.state = address.state;
            newDataMapped.number = address.number;
            newDataMapped.box = address.box;
            newDataMapped.zip = address.zip;
            newDataMapped.city = address.city;
            newDataMapped.countryId = address.country;
        }
        //  else {
        //   newDataMapped.street = null;
        //   newDataMapped.state = null;
        //   newDataMapped.number = null;
        //   newDataMapped.box = null;
        //   newDataMapped.zip = null;
        //   newDataMapped.city = null;
        //   newDataMapped.countryId = null;
        // }
        if (placeOfBirth === null || placeOfBirth === void 0 ? void 0 : placeOfBirth.city) {
            newDataMapped.placeOfBirth = placeOfBirth.city;
            newDataMapped.countryOfBirth = placeOfBirth.country;
        }
        //  else {
        //   newDataMapped.placeOfBirth = null;
        //   newDataMapped.countryOfBirth = null;
        // }
        setContactState(__assign(__assign(__assign({}, contactState), restNewData), newDataMapped));
    };
    var isCompany = contact.isCompany;
    var displayName = getName(contact);
    var heading = t.contactEditModalHeading({ values: { name: displayName } });
    var formRefPerson = createRef();
    var formRefCompany = createRef();
    var formRefFinancial = createRef();
    var formRefExtra = createRef();
    var handleModalSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            setIsSubmitting(true);
            (_a = formRefPerson.current) === null || _a === void 0 ? void 0 : _a.handleExternalSubmit();
            (_b = formRefFinancial.current) === null || _b === void 0 ? void 0 : _b.handleExternalSubmit();
            (_c = formRefExtra.current) === null || _c === void 0 ? void 0 : _c.handleExternalSubmit();
            (_d = formRefCompany.current) === null || _d === void 0 ? void 0 : _d.handleExternalSubmit();
            if (!formRefFinancial.current &&
                !formRefPerson.current &&
                !formRefExtra.current &&
                !formRefCompany.current) {
                onSubmit(contactState);
            }
            setIsSubmitting(false);
            return [2 /*return*/];
        });
    }); };
    var handleSubmit = function (formType) { return function (values) {
        onSubmit(values, formType);
    }; };
    var requiredFieldsTab = {
        content: getLocalizedText("contact.details.required_fields"),
        component: (_jsx(MultiStepForm, { intro: _jsxs(_Fragment, { children: [_jsx(Box, __assign({ mb: 4 }, { children: _jsx(Banner, { title: t.formCompleteMissingFieldsMessage(), variation: "warning", hasDismiss: false, icon: "alertDiamond" }) })), _jsx(Box, __assign({ mb: 4 }, { children: _jsx(ContactList, { contacts: [contact], readOnly: true }) }))] }), formId: "edit-required-fields-".concat(formId.current), schemas: addContactSchemas({
                isCompany: isCompany,
                contact: contact,
                fields: fields,
                showSendInvite: false,
            }), withAside: false, modalProps: { onClose: onClose, heading: heading }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(contact.id), performingSubmitActionResultSelector: performingSubmitActionResultSelector(contact.id), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(contact.id), submitActionCreator: submitActionCreator(contact.id), submitLabel: t.system("save"), ref: formRefPerson, showControls: false, onSuccess: handleSubmit("requiredFields") })),
    };
    var personTab = {
        content: isCompany ? t.contactDetailsContact() : t.contactDetailsPersonal(),
        component: (_jsx(MultiStepForm, { onChange: updateContactState, schemas: personSchemas({ contact: contactState !== null && contactState !== void 0 ? contactState : contact }), formId: "edit-contact-person-".concat(formId.current), withAside: false, modalProps: { onClose: onClose, heading: heading }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(contact.id), performingSubmitActionResultSelector: performingSubmitActionResultSelector(contact.id), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(contact.id), submitActionCreator: submitActionCreator(contact.id), submitLabel: t.system("save"), ref: formRefPerson, showControls: false, onSuccess: handleSubmit("person") })),
    };
    var companyTab = {
        content: t.contactDetailsCompany(),
        component: (_jsx(MultiStepForm, { onChange: updateContactState, schemas: companySchemas({ contact: contactState !== null && contactState !== void 0 ? contactState : contact }), formId: "edit-contact-company-".concat(formId.current), withAside: false, modalProps: { onClose: onClose, heading: heading }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(contact.id), performingSubmitActionResultSelector: performingSubmitActionResultSelector(contact.id), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(contact.id), submitActionCreator: submitActionCreator(contact.id), submitLabel: t.system("add"), ref: formRefCompany, showControls: false, onSuccess: handleSubmit("company") })),
    };
    var financialTab = {
        content: t.contactDetailsFinancial(),
        component: (_jsx(ModalTabFinancial, { onChange: updateContactState, contact: contactState !== null && contactState !== void 0 ? contactState : contact, onSubmit: handleSubmit("financial"), ref: formRefFinancial })),
    };
    var extraTab = {
        content: t.contactDetailsExtra(),
        component: (_jsx(MultiStepForm, { onChange: updateContactState, schemas: extraSchemas({ contact: contactState !== null && contactState !== void 0 ? contactState : contact }), formId: "edit-contact-extra-".concat(formId.current), withAside: false, modalProps: { onClose: onClose, heading: heading }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(contact.id), performingSubmitActionResultSelector: performingSubmitActionResultSelector(contact.id), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(contact.id), submitActionCreator: submitActionCreator(contact.id), submitLabel: t.system("save"), ref: formRefExtra, showControls: false, onSuccess: handleSubmit("extra") })),
    };
    // const invoicingConfigTab = {
    //   content: getLocalizedText("contact.invoicing_config.extra"),
    //   component: (
    //     <MultiStepForm
    //       onChange={updateFormState}
    //       schemas={invoicingConfigSchemas({ contact: formState ?? contact })}
    //       formId={`edit-contact-invoicing_config-${formId.current}`}
    //       withAside={false}
    //       modalProps={{ onClose, heading }}
    //       isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
    //         contact.id,
    //       )}
    //       performingSubmitActionResultSelector={performingSubmitActionResultSelector(
    //         contact.id,
    //       )}
    //       performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
    //         contact.id,
    //       )}
    //       submitActionCreator={submitActionCreator(contact.id)}
    //       submitLabel={t.system("save")}
    //       ref={formRefInvoicingConfig}
    //       showControls={false}
    //       onSuccess={handleSubmit("invoicingConfig")}
    //     />
    //   ),
    // };
    var tabs = [];
    if (fields) {
        tabs.push(requiredFieldsTab);
    }
    if (isCompany) {
        tabs.push(companyTab, financialTab, personTab);
    }
    else {
        tabs.push(personTab, financialTab, extraTab);
    }
    // if (
    //   broker?.features.includes(EBrokerFeature.Invoicing) &&
    //   broker?.type === EClientType.PatrimonyCompany
    // ) {
    //   tabs.push(invoicingConfigTab);
    // }
    var modalActions = [
        {
            content: t.system("save"),
            onClick: handleModalSubmit,
            appearance: "primary",
            isSubmitting: isSubmitting,
        },
    ];
    return (_jsx(Modal, { onClose: onClose, heading: heading, actions: modalActions, width: "medium", tabs: tabs, shouldCloseOnOverlayClick: false, forceRenderTabPanel: false }));
};
export default ContactEditModal;
