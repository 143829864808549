var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.addIban.types";
export var getSchema = function (_a, initialValues) {
    var _b = _a.showHeader, showHeader = _b === void 0 ? true : _b;
    return function (defaultValues) {
        var _a, _b;
        return ({
            required: [EField.Iban, EField.OwnerName],
            properties: __assign(__assign({}, (showHeader
                ? (_a = {},
                    _a[EField.Header] = {
                        default: undefined,
                    },
                    _a) : {})), (_b = {}, _b[EField.Iban] = {
                default: getValue(EField.Iban, initialValues, defaultValues),
            }, _b[EField.OwnerName] = {
                default: getValue(EField.OwnerName, initialValues, defaultValues),
            }, _b)),
        });
    };
};
export var uiSchema = function (_a) {
    var _b, _c;
    var _d = _a.showHeader, showHeader = _d === void 0 ? true : _d;
    return (__assign(__assign({ 
        // cspell:ignore ibanaccount
        "rentio:navigationTitle": getLocalizedText("array_item.add.ibanaccount"), "rentio:trackingKey": "iban" }, (showHeader
        ? (_b = {},
            _b[EField.Header] = {
                "ui:field": ERentioFormField.SectionHeader,
                title: getLocalizedText("iban.add"),
            },
            _b) : {})), (_c = {}, _c[EField.Iban] = {
        "ui:field": ERentioFormField.Iban,
        title: getLocalizedText("payment_detail.form.iban.label"),
        placeholder: getLocalizedText("payment_detail.form.iban.placeholder"),
        autoFocus: true,
    }, _c[EField.OwnerName] = {
        "ui:field": ERentioFormField.Text,
        title: getLocalizedText("payment_detail.form.card_holder_name.label"),
        placeholder: getLocalizedText("payment_detail.form.card_holder_name.placeholder"),
    }, _c)));
};
