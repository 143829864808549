var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled, { borderRadius, css, FontWeights, iconSize, spacing, } from "@rentiohq/web-theme";
import { border, rem, stripUnit, transitions } from "polished";
import React from "react";
import { Link } from "react-router-dom";
import { StyledIcon } from "../Icon/Icon.styled";
var largeButton = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " ", ";\n"], ["\n  padding: ", " ", ";\n"])), spacing(), spacing("loose"));
// 40px height
var smallButton = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: calc((", " / 2) - 1px) ", "; // -1px for the border\n  /* border-radius: ", "; */\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"], ["\n  padding: calc((", " / 2) - 1px) ", "; // -1px for the border\n  /* border-radius: ", "; */\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"])), spacing("loose"), spacing(), borderRadius("small"), StyledIcon, spacing("tight"), spacing("tight"));
var extraSmallButton = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", " ", ";\n  /* border-radius: ", "; */\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"], ["\n  padding: ", " ", ";\n  /* border-radius: ", "; */\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"])), spacing("tight"), spacing(), borderRadius("small"), StyledIcon, spacing("tight"), spacing("tight"));
var slimButton = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: ", " ", ";\n  border-radius: ", ";\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"], ["\n  padding: ", " ", ";\n  border-radius: ", ";\n  &&& ", " {\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n"])), spacing("extraTight"), spacing("tight"), borderRadius("small"), StyledIcon, spacing("extraTight"), spacing("extraTight"));
var defaultButton = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "gray" : _b;
    return theme.getColor(color, 0);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "gray" : _b;
    return theme.getColor(color, 800);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "gray" : _b;
    return theme.getColor(color, 200);
});
var primaryButton = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 1000);
});
var repairButton = css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "blue" : _b;
    return theme.getColor(color);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "blue" : _b;
    return theme.getColor(color, 400);
});
var warningButton = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("yellow");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("yellow", 1000);
});
var dangerButton = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("red");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("red", 1000);
});
var outlineButton = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: transparent;\n  border-color: ", ";\n  color: ", ";\n  &:hover,\n  &:focus,\n  &:active {\n    color: ", ";\n    border-color: ", ";\n  }\n"], ["\n  background: transparent;\n  border-color: ", ";\n  color: ", ";\n  &:hover,\n  &:focus,\n  &:active {\n    color: ", ";\n    border-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 400);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 600);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 800);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 800);
});
var ghostButton = css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  border-radius: 0;\n  &:hover,\n  &:focus,\n  &:active {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  border-radius: 0;\n  &:hover,\n  &:focus,\n  &:active {\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 0);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color);
}, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 200);
});
var plainButton = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  background: transparent;\n  border: 0;\n  color: ", ";\n  font-weight: ", ";\n  cursor: pointer;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: transparent;\n    color: ", ";\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  background: transparent;\n  border: 0;\n  color: ", ";\n  font-weight: ", ";\n  cursor: pointer;\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: transparent;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 800);
}, FontWeights.Regular, function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "primary" : _b;
    return theme.getColor(color, 1000);
});
var selectButton = css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  margin: 1px 0;\n  padding: ", ";\n  color: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  letter-spacing: initial;\n  text-transform: none;\n  &:focus {\n    outline: none;\n  }\n  &:focus {\n    border-color: ", ";\n  }\n  &:hover {\n    border-color: ", ";\n  }\n  &&& ", " {\n    box-sizing: content-box;\n    height: ", ";\n    width: ", ";\n    margin-left: 0;\n    margin-right: ", ";\n    padding: ", ";\n    color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  margin: 1px 0;\n  padding: ", ";\n  color: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  letter-spacing: initial;\n  text-transform: none;\n  &:focus {\n    outline: none;\n  }\n  &:focus {\n    border-color: ", ";\n  }\n  &:hover {\n    border-color: ", ";\n  }\n  &&& ", " {\n    box-sizing: content-box;\n    height: ", ";\n    width: ", ";\n    margin-left: 0;\n    margin-right: ", ";\n    padding: ", ";\n    color: ", ";\n  }\n"])), transitions("border-color 250ms ease-in"), function (_a) {
    var theme = _a.theme;
    return "border-radius: ".concat(theme.inputStyles.borderRadius, ";");
}, function (_a) {
    var theme = _a.theme;
    return "background: ".concat(theme.inputStyles.backgroundColor.base, ";");
}, function (_a) {
    var theme = _a.theme;
    return border("1px", "solid", theme.inputStyles.borderColor.base);
}, function (_a) {
    var theme = _a.theme;
    return "".concat(theme.inputStyles.spacing.vertical, " ").concat(theme.inputStyles.spacing.horizontal);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral100;
}, function (_a) {
    var theme = _a.theme;
    return rem(40 - 2 - Number(stripUnit(spacing("tight"))) * 2, theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.borderColor.focus;
}, function (_a) {
    var theme = _a.theme;
    return theme.inputStyles.borderColor.hover;
}, StyledIcon, iconSize("medium"), iconSize("medium"), function (_a) {
    var theme = _a.theme;
    return "-".concat(theme.inputStyles.spacing.horizontal);
}, function (_a) {
    var theme = _a.theme;
    return "0 calc(".concat(theme.spacing("tight"), " - 1px)");
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral60;
});
var resetButton = css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  justify-content: flex-start;\n  padding: 0;\n  border: none;\n  background: transparent;\n  color: inherit;\n  font-weight: inherit;\n  line-height: inherit;\n  letter-spacing: inherit;\n  text-decoration: inherit;\n  text-transform: inherit;\n"], ["\n  justify-content: flex-start;\n  padding: 0;\n  border: none;\n  background: transparent;\n  color: inherit;\n  font-weight: inherit;\n  line-height: inherit;\n  letter-spacing: inherit;\n  text-decoration: inherit;\n  text-transform: inherit;\n"])));
var linkButton = css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", "\n  letter-spacing: 0;\n  text-transform: none;\n  text-decoration: none;\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: underline;\n  }\n"], ["\n  ", "\n  letter-spacing: 0;\n  text-transform: none;\n  text-decoration: none;\n  &:hover,\n  &:focus,\n  &:active {\n    text-decoration: underline;\n  }\n"])), plainButton);
var fullWidthButton = css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var roundButton = css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: calc(", " - 1px) ", ";\n  background: ", ";\n  color: white;\n  border-radius: 50%;\n"], ["\n  padding: calc(", " - 1px) ", ";\n  background: ", ";\n  color: white;\n  border-radius: 50%;\n"])), spacing(), spacing(), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "gray" : _b;
    return theme.getColor(color, 800);
});
var addButton = css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: calc(", " - 1px);\n  background: ", ";\n  color: white;\n  border-radius: 50%;\n"], ["\n  padding: calc(", " - 1px);\n  background: ", ";\n  color: white;\n  border-radius: 50%;\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme, _b = _a.color, color = _b === void 0 ? "gray" : _b;
    return theme.getColor(color, 800);
});
var baseButtonStyle = css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  ", ";\n  display: inline-flex;\n  padding: calc(", " - 1px) ", ";\n  align-items: center;\n  justify-content: center;\n  font-size: inherit;\n  border: 1px solid transparent;\n  line-height: ", ";\n  text-transform: uppercase;\n  font-family: inherit;\n  font-weight: ", ";\n  font-size: ", ";\n  border-radius: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  letter-spacing: 0.1rem;\n  ", ";\n  ", ";\n  ", ";\n  ", " {\n    display: inline-block;\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      display: block;\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n  position: relative;\n  .hollow-dots-spinner {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  ", ";\n  display: inline-flex;\n  padding: calc(", " - 1px) ", ";\n  align-items: center;\n  justify-content: center;\n  font-size: inherit;\n  border: 1px solid transparent;\n  line-height: ", ";\n  text-transform: uppercase;\n  font-family: inherit;\n  font-weight: ", ";\n  font-size: ", ";\n  border-radius: ", ";\n  text-decoration: none;\n  cursor: pointer;\n  letter-spacing: 0.1rem;\n  ", ";\n  ", ";\n  ", ";\n  ", " {\n    display: inline-block;\n    &:first-child {\n      margin-right: ", ";\n    }\n    &:last-child {\n      margin-left: ", ";\n    }\n    &:only-child {\n      display: block;\n      margin-right: 0;\n      margin-left: 0;\n    }\n  }\n  position: relative;\n  .hollow-dots-spinner {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])), transitions("all 250ms ease-in"), spacing(), spacing("loose"), function (_a) {
    var theme = _a.theme;
    return stripUnit(rem(16, theme.bodyFontSize));
}, FontWeights.SemiBold, function (_a) {
    var theme = _a.theme;
    return rem("14px", theme.baseFontSize);
}, borderRadius(), function (_a) {
    var size = _a.size;
    switch (size) {
        case "large":
            return largeButton;
        case "slim":
            return slimButton;
        case "small":
            return smallButton;
        case "extraSmall":
            return extraSmallButton;
        default:
            return null;
    }
}, function (_a) {
    var appearance = _a.appearance;
    switch (appearance) {
        case "primary":
            return primaryButton;
        case "outline":
            return outlineButton;
        case "ghost":
            return ghostButton;
        case "plain":
            return plainButton;
        case "link":
            return linkButton;
        case "warning":
            return warningButton;
        case "danger":
            return dangerButton;
        case "reset":
            return resetButton;
        case "select":
            return selectButton;
        case "round":
            return roundButton;
        case "add":
            return addButton;
        case "repair":
            return repairButton;
        default:
            return defaultButton;
    }
}, function (_a) {
    var isFullWidth = _a.isFullWidth;
    return (isFullWidth ? fullWidthButton : null);
}, StyledIcon, spacing("tight"), spacing("tight"));
export var StyledButton = styled.button(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), baseButtonStyle, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    opacity: .5; \n    cursor: not-allowed;\n  ";
});
// TODO:
export var StyledButtonLink = styled(function (_a) {
    var size = _a.size, isSecondary = _a.isSecondary, isOutline = _a.isOutline, isPlain = _a.isPlain, isFullWidth = _a.isFullWidth, rest = __rest(_a, ["size", "isSecondary", "isOutline", "isPlain", "isFullWidth"]);
    return React.createElement(Link, __assign({}, rest));
})(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), baseButtonStyle);
export var StyledButtonContent = styled.span(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  ", ";\n"], ["\n  display: inline-flex;\n  align-items: center;\n  ", ";\n"])), function (_a) {
    var isSubmitting = _a.isSubmitting;
    return (isSubmitting ? "opacity: 0" : "");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
