var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import addIbanSchemas from "@rentiohq/shared-frontend/dist/forms/addIban";
import { isPerformingSubmitActionSelector as isPerformingSubmitActionSelectorIban, performingSubmitActionErrorSelector as performingSubmitActionErrorSelectorIban, performingSubmitActionResultSelector as performingSubmitActionResultSelectorIban, submitActionCreator as submitActionCreatorIban, } from "@rentiohq/shared-frontend/dist/forms/addIban/schema.addIban.actions";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { REDUCER_KEY_CONTACT, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ESpacings } from "../../../components/Grid";
import { ts } from "../../../services";
import Button from "../../Button";
import DisplayText from "../../DisplayText";
import { ResourceList, ResourceListItem } from "../../ResourceList";
import { MultiStepForm } from "../../SharedForm/MultiStepForm";
import TextStyle from "../../TextStyle";
var formId = generateFormId();
var ModalTabFinancial = React.forwardRef(function (_a, formRefFinancial) {
    var _b;
    var contact = _a.contact, onSubmit = _a.onSubmit, onChange = _a.onChange;
    var _c = React.useState(contact.toBeAddedBankAccount !== undefined), showForm = _c[0], setShowForm = _c[1];
    var dispatch = useDispatch();
    var bankAccounts = useSelector(function (state) {
        return contactSelectors.getIbans(state, contact.id) || [];
    });
    React.useEffect(function () {
        if (contact) {
            dispatch(contactActions.getIbans.actions.start({ contactId: contact.id }));
        }
    }, []);
    var handleToggleForm = function () {
        setShowForm(function (prevValue) { return !prevValue; });
    };
    var renderBankAccount = function (bankAccount) {
        return (_jsxs(ResourceListItem, __assign({ boxProps: { py: ESpacings.base }, item: bankAccount, actions: [] }, { children: [_jsx(DisplayText, __assign({ size: "small", space: "none" }, { children: bankAccount.iban })), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: bankAccount.ownerName }))] })));
    };
    return (_jsxs("div", { children: [bankAccounts.length > 0 && (_jsx(Box, __assign({ mb: ESpacings.base }, { children: _jsx(ResourceList, { items: bankAccounts, renderItem: renderBankAccount }) }))), showForm && (_jsx(Box, __assign({ mb: ESpacings.base }, { children: _jsx(MultiStepForm, { onChange: function (accountData) {
                        if (accountData.iban !== "BE")
                            onChange({ toBeAddedBankAccount: accountData });
                    }, formId: "add-iban-".concat(formId), schemas: addIbanSchemas({
                        ownerName: contact.firstname,
                        iban: (_b = contact.toBeAddedBankAccount) === null || _b === void 0 ? void 0 : _b.iban,
                    }), withSummary: false, withAside: false, showErrorList: false, showControls: false, isPerformingSubmitActionSelector: isPerformingSubmitActionSelectorIban(), performingSubmitActionResultSelector: performingSubmitActionResultSelectorIban(), performingSubmitActionErrorSelector: performingSubmitActionErrorSelectorIban(), submitActionCreator: submitActionCreatorIban(contact.accountId), ref: formRefFinancial, onSuccess: onSubmit }) }))), _jsx(Button, __assign({ appearance: "link", onClick: handleToggleForm }, { children: showForm
                    ? ts.contactDetailsHideIbanFormAction()
                    : ts.contactDetailsShowIbanFormAction() }))] }));
});
export default ModalTabFinancial;
