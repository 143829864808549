export var ERegistrationContractStatus;
(function (ERegistrationContractStatus) {
    ERegistrationContractStatus["None"] = "none";
    ERegistrationContractStatus["Registering"] = "registering";
    ERegistrationContractStatus["Registered"] = "registered";
    ERegistrationContractStatus["RegisteredManually"] = "registered-manually";
    ERegistrationContractStatus["Incomplete"] = "incomplete";
    ERegistrationContractStatus["NeedPayment"] = "need-payment";
    ERegistrationContractStatus["NotApplicable"] = "not-applicable";
    ERegistrationContractStatus["Rejected"] = "rejected";
    ERegistrationContractStatus["Failed"] = "failed";
})(ERegistrationContractStatus || (ERegistrationContractStatus = {}));
export var ERegistrationFailedReason;
(function (ERegistrationFailedReason) {
    ERegistrationFailedReason["AddressNotFound"] = "ADDRESS_NOT_FOUND";
})(ERegistrationFailedReason || (ERegistrationFailedReason = {}));
export var ERegistrationContractDestination;
(function (ERegistrationContractDestination) {
    ERegistrationContractDestination["Housing"] = "housing";
    ERegistrationContractDestination["Commercial"] = "commercial";
    ERegistrationContractDestination["Mixed"] = "mixed";
    ERegistrationContractDestination["Farm"] = "farm";
    ERegistrationContractDestination["HuntingAndFishing"] = "hunting and fishing";
    ERegistrationContractDestination["Special"] = "special";
    ERegistrationContractDestination["Common"] = "common";
})(ERegistrationContractDestination || (ERegistrationContractDestination = {}));
export var ERegistrationContractType;
(function (ERegistrationContractType) {
    ERegistrationContractType["Lease"] = "lease";
    ERegistrationContractType["Amendment"] = "amendment";
    ERegistrationContractType["Sublease"] = "sublease";
    ERegistrationContractType["Cession"] = "cession";
    ERegistrationContractType["Termination"] = "termination";
})(ERegistrationContractType || (ERegistrationContractType = {}));
export var ERegistrationMemberType;
(function (ERegistrationMemberType) {
    ERegistrationMemberType["Owner"] = "OWNER";
    ERegistrationMemberType["Broker"] = "BROKER";
    ERegistrationMemberType["Tenant"] = "TENANT";
})(ERegistrationMemberType || (ERegistrationMemberType = {}));
export var ERegistrationDocumentType;
(function (ERegistrationDocumentType) {
    ERegistrationDocumentType["Contract"] = "Contract";
    ERegistrationDocumentType["PropertyInspectionReport"] = "PropertyInspectionReport";
    ERegistrationDocumentType["Addendum"] = "Addendum";
})(ERegistrationDocumentType || (ERegistrationDocumentType = {}));
