export var EField;
(function (EField) {
    // TODO: Use addContact schema fields. There might be issues updating fields if the values change in the addContact schema
    EField["Company"] = "company";
    EField["VATNumber"] = "VATNumber";
    EField["Firstname"] = "firstname";
    EField["Lastname"] = "lastname";
    EField["Email"] = "email";
    EField["Phone"] = "phone";
    EField["Address"] = "address";
    EField["Language"] = "locale";
    EField["DateOfBirth"] = "dateOfBirth";
    EField["PlaceOfBirth"] = "placeOfBirth";
    EField["Nationality"] = "nationality";
    EField["IdType"] = "idType";
    EField["NationalRegisterNumber"] = "nationalRegisterNumber";
    EField["ForeignIdentityNumber"] = "foreignIdentityNumber";
    EField["BankAccounts"] = "bankAccounts";
    EField["Iban"] = "iban";
    EField["OwnerName"] = "ownerName";
    EField["EfApiKey"] = "efApiKey";
})(EField || (EField = {}));
