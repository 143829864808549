var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import ReactTooltip from "react-tooltip";
export var Trigger = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: help;\n"], ["\n  cursor: help;\n"])));
export var Tooltip = styled(ReactTooltip)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " ", ";\n  max-width: 480px;\n  background-color: ", ";\n"], ["\n  padding: ", " ", ";\n  max-width: 480px;\n  background-color: ", ";\n"])), spacing("extraTight"), spacing("tight"), function (props) {
    switch (props.type) {
        case "success":
            return "".concat(props.theme.getColor("primary", 600), " !important");
        case "warning":
            return "".concat(props.theme.getColor("yellow", 600), " !important");
        case "error":
            return "".concat(props.theme.getColor("red", 600), " !important");
        case "light":
            return "".concat(props.theme.colors.neutral30, " !important");
        default:
            return "";
    }
});
var templateObject_1, templateObject_2;
