import * as t from "../services/translationService";
export var userToString = function (_a) {
    var firstname = _a.firstname, lastname = _a.lastname, email = _a.email, unverifiedEmail = _a.unverifiedEmail, phone = _a.phone, unverifiedPhone = _a.unverifiedPhone;
    var hasNameInfo = Object.values({ firstname: firstname, lastname: lastname }).some(Boolean);
    var hasEmail = Object.values({ email: email, unverifiedEmail: unverifiedEmail }).some(Boolean);
    var hasPhone = Object.values({ phone: phone, unverifiedPhone: unverifiedPhone }).some(Boolean);
    if (hasNameInfo) {
        return Object.values({ lastname: lastname, firstname: firstname }).filter(Boolean).join(" ");
    }
    if (hasEmail) {
        return Object.values({ email: email, unverifiedEmail: unverifiedEmail }).find(Boolean) || "";
    }
    if (hasPhone) {
        return Object.values({ phone: phone, unverifiedPhone: unverifiedPhone }).find(Boolean) || "";
    }
    return t.unknownUser();
};
export var isActiveUser = function (_a) {
    var firstname = _a.firstname, lastname = _a.lastname, email = _a.email, unverifiedEmail = _a.unverifiedEmail, phone = _a.phone, unverifiedPhone = _a.unverifiedPhone;
    return Object.values({
        firstname: firstname,
        lastname: lastname,
        email: email,
        unverifiedEmail: unverifiedEmail,
        phone: phone,
        unverifiedPhone: unverifiedPhone,
    }).find(Boolean);
};
export var filterActiveUsers = function (users) {
    return users.filter(function (user) { return isActiveUser(user); });
};
