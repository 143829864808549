import { REDUCER_KEY_STATS, } from "../../redux/stats/stats.reducer";
import { REDUCER_KEY_SYSTEM_LOCAL, } from "../../redux/system/system.reducer";
export var EChartSize;
(function (EChartSize) {
    EChartSize[EChartSize["LARGE"] = 1] = "LARGE";
    EChartSize[EChartSize["MEDIUM"] = 0.5] = "MEDIUM";
})(EChartSize || (EChartSize = {}));
export var ELineAxis;
(function (ELineAxis) {
    ELineAxis["LEFT"] = "left";
    ELineAxis["RIGHT"] = "right";
})(ELineAxis || (ELineAxis = {}));
export var EColor;
(function (EColor) {
    EColor["DEFAULT"] = "#1ab394";
    EColor["AMOUNT"] = "#37abe5";
    EColor["AVERAGE"] = "#82ca9d";
    EColor["ORANGE"] = "orange";
    EColor["RED"] = "#F4635A";
    EColor["HIDDEN"] = "";
})(EColor || (EColor = {}));
export var EStatInterval;
(function (EStatInterval) {
    EStatInterval["Hourly"] = "hourly";
    EStatInterval["Daily"] = "daily";
    EStatInterval["Weekly"] = "weekly";
    EStatInterval["Monthly"] = "monthly";
    EStatInterval["Yearly"] = "yearly";
})(EStatInterval || (EStatInterval = {}));
export var EStatDuration;
(function (EStatDuration) {
    EStatDuration["Custom"] = "custom";
    EStatDuration["Today"] = "today";
    EStatDuration["ThisWeek"] = "this_week";
    EStatDuration["Last7Days"] = "last_7_days";
    EStatDuration["ThisMonth"] = "this_month";
    EStatDuration["Last30Days"] = "last_30_days";
    EStatDuration["ThisYear"] = "this_year";
    EStatDuration["Last365Days"] = "last_365_days";
    EStatDuration["All"] = "all";
})(EStatDuration || (EStatDuration = {}));
