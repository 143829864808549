var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { addDays, addMonths, addYears, setHours, subDays, subMonths, subYears, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import React from "react";
import DatePicker from "react-datepicker";
import { format } from "../../../../utils/date";
import Icon from "../../../Icon";
import { getIdPath } from "../../../JsonForm/utils";
import { defineStartYear, defineYearAmount, } from "../../../SharedForm/fields/DateField/DateField.util";
import * as S from "../../styled/DateWidget.styled";
import { getUiOptions } from "../../utils";
import { StyledBackdrop, StyledBaseInput, StyledSuffix, } from "../BaseInput/BaseInput.styled";
var parseDateString = function (date) {
    var now = new Date();
    if (date === "today")
        return now;
    if (date.match(/[a-z]*[:]-?[\d]{1,3}/g)) {
        var dateArray = date.split(":");
        var leapAmount = dateArray[0];
        var leapCount = Number(dateArray[1]);
        var shouldAdd = leapCount > 0;
        var absLeapCount = Math.abs(leapCount);
        switch (leapAmount) {
            case "years":
                return (shouldAdd ? addYears : subYears)(now, absLeapCount);
            case "months":
                return (shouldAdd ? addMonths : subMonths)(now, absLeapCount);
            case "days":
                return (shouldAdd ? addDays : subDays)(now, absLeapCount);
            default:
                return null;
        }
    }
};
export var DateWidget = function (_a) {
    var id = _a.id, value = _a.value, onChange = _a.onChange, required = _a.required, props = __rest(_a, ["id", "value", "onChange", "required"]);
    var MONTH_NAMES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
        return format(new Date().setMonth(month), "MMMM");
    });
    var uiOptions = props.schema.ui ? getUiOptions(props.schema.ui) : {};
    var baseDateField = uiOptions.baseDate;
    var allData = props.registry.formContext.formData;
    var baseDate = allData[baseDateField];
    var defaultDate = baseDate ? new Date(baseDate) : undefined;
    var defaultValue = required ? "" : null;
    var rawErrors = props.rawErrors;
    React.useEffect(function () {
        if (!baseDate)
            return;
        var path = getIdPath(id);
        var fieldName = path[path.length - 1];
        allData[fieldName] = format(setHours(new Date(baseDate), 12), "yyyy-MM-dd");
        props.formContext.setFormData(allData);
    }, [allData, baseDate, id, props.formContext]);
    var handleChange = function (date) {
        onChange(date ? format(setHours(date, 12), "yyyy-MM-dd") : defaultValue);
    };
    var _b = props.options, uiMinDate = _b.minDate, uiMaxDate = _b.maxDate;
    var minDate = uiMinDate ? parseDateString(uiMinDate) : null;
    var maxDate = uiMaxDate ? parseDateString(uiMaxDate) : null;
    var renderHeader = function (_a) {
        var date = _a.date, changeYear = _a.changeYear, changeMonth = _a.changeMonth, decreaseMonth = _a.decreaseMonth, increaseMonth = _a.increaseMonth, prevMonthButtonDisabled = _a.prevMonthButtonDisabled, nextMonthButtonDisabled = _a.nextMonthButtonDisabled;
        var yearAmount = defineYearAmount({ minDate: minDate, maxDate: maxDate });
        var yearStart = defineStartYear({ minDate: minDate, maxDate: maxDate });
        var handleMonthChange = function (_a) {
            var value = _a.target.value;
            changeMonth(value);
        };
        var handleYearChange = function (_a) {
            var value = _a.target.value;
            changeYear(value);
        };
        return (_jsxs("div", { children: [!prevMonthButtonDisabled && (_jsx(S.PreviousMonth, __assign({ appearance: "plain", onClick: decreaseMonth }, { children: _jsx(Icon, { source: "chevronLeft" }) }))), !nextMonthButtonDisabled && (_jsx(S.NextMonth, __assign({ appearance: "plain", onClick: increaseMonth }, { children: _jsx(Icon, { source: "chevronRight" }) }))), _jsx(S.CurrentMonth, { children: format(date, "MMMM yyyy") }), _jsxs(S.SelectMonthYear, { children: [_jsx("select", __assign({ value: date.getMonth(), onChange: handleMonthChange }, { children: MONTH_NAMES.map(function (label, value) { return (_jsx("option", __assign({ value: value }, { children: label }), value)); }) })), _jsx("select", __assign({ value: date.getFullYear(), onChange: handleYearChange }, { children: Array(yearAmount)
                                .fill(0)
                                .map(function (_, idx) { return yearStart + idx; })
                                .map(function (year, index) { return (_jsx("option", __assign({ value: year }, { children: year }), index)); }) }))] })] }));
    };
    return (_jsx(S.DatePickerContainer, { children: _jsxs(StyledBaseInput, __assign({ hasError: rawErrors && rawErrors.length > 0 }, { children: [_jsx(StyledBackdrop, {}), _jsx(DatePicker, { id: id, locale: getLocale(), selected: value ? new Date(value) : defaultDate, minDate: minDate, maxDate: maxDate, onChange: handleChange, showMonthDropdown: true, showYearDropdown: true, dateFormat: "dd/MM/yyyy", dropdownMode: "select", renderCustomHeader: renderHeader, popperPlacement: "top-end", popperModifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [5, 10],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                            },
                        },
                    ] }), _jsx(StyledSuffix, { children: _jsx(Icon, { source: "calendar" }) })] })) }));
};
