import { getQueryHash } from "../../reduxV2/utils/hash.utils";
export var EActionGeneric;
(function (EActionGeneric) {
    EActionGeneric["Detail"] = "DETAIL";
    EActionGeneric["Paged"] = "PAGED";
    EActionGeneric["InfiniteLoad"] = "INFINITE_LOAD";
    EActionGeneric["GetAll"] = "GET_ALL";
    EActionGeneric["Create"] = "CREATE";
    EActionGeneric["Count"] = "COUNT";
    EActionGeneric["Update"] = "UPDATE";
    EActionGeneric["UpdateMembers"] = "UPDATE_MEMBERS";
    EActionGeneric["UpdateEmployees"] = "UPDATE_EMPLOYEES";
    EActionGeneric["Remove"] = "REMOVE";
})(EActionGeneric || (EActionGeneric = {}));
export var EActionState;
(function (EActionState) {
    EActionState["Start"] = "START";
    EActionState["StartNoThrottle"] = "START_NO_THROTTLE";
    EActionState["Success"] = "SUCCESS";
    EActionState["Unchanged"] = "UNCHANGED";
    EActionState["Fail"] = "FAIL";
})(EActionState || (EActionState = {}));
export var createAction = function (params) {
    var entity = params.entity, generic = params.generic, state = params.state;
    var type = "".concat(entity, "/").concat(generic, "/").concat(state).toUpperCase();
    return {
        type: type,
        payloadType: {},
        actionType: {},
        getAction: function (payload) { return ({
            type: type,
            payload: payload,
        }); },
    };
};
export var createCustomAction = function (params) {
    var entity = params.entity, custom = params.custom, state = params.state;
    var type = "".concat(entity, "/").concat(custom, "/").concat(state).toUpperCase();
    return {
        type: type,
        payloadType: {},
        actionType: {},
        getAction: function (payload) { return ({
            type: type,
            payload: payload,
        }); },
    };
};
export var doesSuccessActionMatchStartAction = function (params) {
    var startAction = params.startAction, successAction = params.successAction;
    var startActionType = startAction.type;
    var successActionTypeToExpect = startActionType.replace("/".concat(EActionState.Start), "/".concat(EActionState.Success));
    var successActionType = successAction.type;
    // Match type
    if (successActionType !== successActionTypeToExpect) {
        return false;
    }
    // Match payload
    var originalSuccessActionQueryHash = getQueryHash(startAction.payload.query || {}, startAction.payload.customPath);
    var randomSuccessActionQueryHash = getQueryHash(successAction.payload.query || {}, successAction.payload.customPath);
    return originalSuccessActionQueryHash === randomSuccessActionQueryHash;
};
