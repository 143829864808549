export var getCreateSelectors = function (getBase) {
    return {
        isCreating: function (rootState) { return getBase(rootState).isCreating; },
        createError: function (rootState) { return getBase(rootState).createError; },
        lastCreatedId: function (rootState) { return getBase(rootState).lastCreatedId; },
        lastCreatedDetail: function (rootState) {
            var _a;
            var state = getBase(rootState);
            var lastCreatedId = state.lastCreatedId;
            if (!lastCreatedId) {
                return undefined;
            }
            return (_a = state.data) === null || _a === void 0 ? void 0 : _a[lastCreatedId];
        },
    };
};
