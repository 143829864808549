var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as statsActions from "./stats.actions";
export var REDUCER_KEY_STATS = "stats";
var getDefaultState = function () { return ({
    statsByIdentifier: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(statsActions.getStats.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingBrokers: true, fetchBrokersError: undefined })); });
reducer.on(statsActions.getStats.types.START, function (state, _a) {
    var _b;
    var identifier = _a.identifier;
    return (__assign(__assign({}, state), { statsByIdentifier: __assign(__assign({}, state.statsByIdentifier), (_b = {}, _b[identifier] = __assign(__assign({}, state.statsByIdentifier[identifier]), { isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(statsActions.getStats.types.SUCCESS, function (state, _a) {
    var _b;
    var identifier = _a.identifier, stats = _a.stats;
    return (__assign(__assign({}, state), { statsByIdentifier: __assign(__assign({}, state.statsByIdentifier), (_b = {}, _b[identifier] = __assign(__assign({}, state.statsByIdentifier[identifier]), { isFetching: false, fetchError: undefined, stats: stats }), _b)) }));
});
reducer.on(statsActions.getStats.types.FAILURE, function (state, _a) {
    var _b;
    var identifier = _a.identifier, error = _a.error;
    return (__assign(__assign({}, state), { statsByIdentifier: __assign(__assign({}, state.statsByIdentifier), (_b = {}, _b[identifier] = __assign(__assign({}, state.statsByIdentifier[identifier]), { isFetching: false, fetchError: error }), _b)) }));
});
export default reducer;
