var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { borderRadius } from "@rentiohq/web-theme";
import { useState } from "react";
import * as S from "./AccountIndicator.styled";
export var AccountIndicator = function (_a) {
    var size = _a.size, _b = _a.selected, selected = _b === void 0 ? false : _b, hoverText = _a.hoverText, children = _a.children, onClick = _a.onClick;
    var _c = useState(false), hovered = _c[0], setIsHovered = _c[1];
    var toggleHover = function () {
        setIsHovered(!hovered);
    };
    return (_jsxs(S.CircleContainer, __assign({ style: { position: "relative" }, onMouseEnter: toggleHover, onMouseLeave: toggleHover, size: size, onClick: onClick }, { children: [hoverText && hovered && (_jsx("div", __assign({ style: {
                    position: "absolute",
                    zIndex: 100,
                    top: -15,
                    left: 35,
                    whiteSpace: "nowrap",
                    backgroundColor: "#C2E6DC",
                    padding: "3px 5px",
                    borderRadius: borderRadius("base"),
                    boxShadow: "1px 1px lightgray",
                } }, { children: hoverText }))), _jsx(S.IndicatorCircle, __assign({ size: size, selected: selected }, { children: children }))] })));
};
