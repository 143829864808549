var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { hideVisually, rem } from "polished";
import { StyledIcon } from "../../../../Icon/Icon.styled";
export var Checkbox = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  vertical-align: middle;\n  position: relative;\n  border: none;\n  color: inherit;\n  background-color: transparent;\n  cursor: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"], ["\n  display: inline-block;\n  vertical-align: middle;\n  position: relative;\n  border: none;\n  color: inherit;\n  background-color: transparent;\n  cursor: ", ";\n  ", "\n  input {\n    ", "\n  }\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"])), function (props) { return (props.onClick ? "pointer" : "auto"); }, function (_a) {
    var theme = _a.theme, checked = _a.checked, error = _a.error;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), ";\n    height: ").concat(rem(24, theme.baseFontSize), ";\n    ").concat(checked ? "color: ".concat(theme.getColor("primary", 1000)) : "", ";\n    ").concat(error ? "color: ".concat(theme.getColor("red")) : "", ";\n  ");
}, hideVisually(), StyledIcon);
export var InputWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: ", ";\n"], ["\n  margin-right: ", ";\n"])), spacing("tight"));
var templateObject_1, templateObject_2;
