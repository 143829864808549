var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { useMemo } from "react";
import { StyledError } from "../Form";
import { ESpacings } from "../Grid";
import Icon from "../Icon";
import Labelled from "../Labelled";
import * as S from "./OptionList.styled";
var OptionInput = function (props) {
    var value = props.value, type = props.type, _a = props.variant, variant = _a === void 0 ? "default" : _a, size = props.size, label = props.label, description = props.description, icon = props.icon, _b = props.iconSize, iconSize = _b === void 0 ? "extraLarge" : _b, image = props.image, checked = props.checked, onChange = props.onChange, _c = props.showLabel, showLabel = _c === void 0 ? true : _c, _d = props.hasError, hasError = _d === void 0 ? false : _d, disabled = props.disabled, readOnly = props.readOnly, appearance = props.appearance;
    var iconPrefix = type === "checkbox" ? "checkbox" : "radioButton";
    if (variant === "card") {
        return (_jsx(S.CardOptionWrapper, { children: _jsxs(S.CardOption, __assign({ size: size, isChecked: checked, hasError: hasError, disabled: disabled, readOnly: readOnly, appearance: appearance, as: readOnly ? "div" : "label", onClick: function () {
                    if (readOnly || disabled) {
                        return;
                    }
                    onChange(value);
                } }, { children: [_jsx(S.OptionInput, __assign({ isChecked: checked, hasError: hasError, readOnly: readOnly }, { children: checked && (_jsx(S.OptionChecked, { children: _jsx(Icon, { source: "checkboxMark" }) })) })), _jsx(S.OptionContent, { children: icon || image ? (_jsxs(_Fragment, { children: [_jsxs(S.OptionBody, { children: [icon && _jsx(Icon, { size: iconSize, source: icon }), image && _jsx("img", { src: image, alt: "" })] }), (showLabel || description) && (_jsxs(S.OptionFooter, { children: [showLabel && _jsx(S.OptionLabel, { children: label }), description && (_jsx(S.OptionDescription, { children: description }))] }))] })) : (_jsx(_Fragment, { children: showLabel && (_jsxs(_Fragment, { children: [_jsx(S.OptionBody, __assign({ align: "middle" }, { children: _jsx(S.OptionLabel, { children: label }) })), description && (_jsx(S.OptionFooter, { children: _jsx(S.OptionDescription, { children: description }) }))] })) })) })] })) }));
    }
    return (_jsx(S.OptionWrapper, { children: _jsxs(S.Option, __assign({ isChecked: checked, hasError: hasError, disabled: disabled, readOnly: readOnly, variant: variant, as: readOnly ? "div" : "label", appearance: appearance, onClick: function () {
                if (readOnly || disabled) {
                    return;
                }
                onChange(value);
            } }, { children: [_jsxs(S.OptionInput, { children: [_jsx(Icon, { source: "".concat(iconPrefix, "Outline") }), checked && _jsx(Icon, { source: "".concat(iconPrefix, "Mark") })] }), _jsxs(Flex, __assign({ width: 1, alignItems: "center" }, { children: [_jsxs(S.OptionContent, { children: [label, description && (_jsx(S.OptionDescription, { children: description }))] }), appearance === "warning" && (_jsx(Box, __assign({ ml: ESpacings.base }, { children: _jsx(Icon, { source: "alertDiamond" }) })))] }))] })) }));
};
export var OptionList = function (props) {
    var id = props.id, options = props.options, _a = props.value, value = _a === void 0 ? [] : _a, variant = props.variant, size = props.size, iconSize = props.iconSize, title = props.title, _b = props.sortByLabel, sortByLabel = _b === void 0 ? false : _b, onPressExtraInfo = props.onPressExtraInfo, _c = props.multiple, multiple = _c === void 0 ? false : _c, _d = props.disabled, disabled = _d === void 0 ? false : _d, _e = props.readOnly, readOnly = _e === void 0 ? false : _e, optional = props.optional, showLabel = props.showLabel, error = props.error, onChange = props.onChange;
    var isGrouped = options.some(function (o) { return o.groupKey !== undefined; });
    var grouped = useMemo(function () {
        if (isGrouped) {
            return options.reduce(function (map, current) {
                var _a;
                var _b;
                if (!current.groupKey)
                    return map;
                return __assign(__assign({}, map), (_a = {}, _a[current.groupKey] = __spreadArray(__spreadArray([], ((_b = map[current.groupKey]) !== null && _b !== void 0 ? _b : []), true), [current], false), _a));
            }, {});
        }
    }, [isGrouped, options]);
    var hasError = !!error;
    var inputType = multiple ? "checkbox" : "radio";
    var handleChange = function (newValue) {
        var selectedIndex = value.findIndex(function (randomValue) { return randomValue === newValue; });
        var wasSelected = selectedIndex !== -1;
        var newValues = __spreadArray([], value, true);
        if (wasSelected) {
            if (multiple) {
                newValues.splice(selectedIndex, 1);
            }
            else {
                newValues = [];
            }
        }
        else {
            if (multiple) {
                newValues.push(newValue);
            }
            else {
                newValues = [newValue];
            }
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(newValues);
    };
    var getOptionsInput = function (options) {
        var allOptions = sortByLabel
            ? options.sort(function (a, b) {
                if (!a.label || !b.label)
                    return 0;
                else if (a.label > b.label)
                    return 1;
                else if (a.label < b.label)
                    return -1;
                else
                    return 0;
            })
            : options;
        return allOptions.map(function (option, index) { return (_createElement(OptionInput, __assign({}, option, { checked: !!value.find(function (randomOption) { return randomOption === option.id; }), readOnly: readOnly, disabled: disabled || option.disabled, type: inputType, variant: variant, size: size, key: index, onChange: function (values) { return handleChange(values); }, showLabel: showLabel, hasError: hasError, iconSize: iconSize }))); });
    };
    var fieldMarkup = (_jsxs(S.Options, __assign({ variant: variant }, { children: [isGrouped &&
                grouped &&
                Object.values(grouped).map(function (options) { return getOptionsInput(options); }), !isGrouped && getOptionsInput(options)] })));
    var errorMarkup = error && (_jsx(StyledError, __assign({ id: "".concat(id, "Error") }, { children: error })));
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, __assign({ label: title, error: error, optional: optional, onPressExtraInfo: onPressExtraInfo }, { children: fieldMarkup }))) : (_jsxs(_Fragment, { children: [fieldMarkup, errorMarkup] })) }));
};
