var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { ESpacings } from "../../../Grid";
import Icon from "../../../Icon";
import * as S from "../../OptionList.styled";
export var OptionListItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, selected = _a.selected, value = _a.value, error = _a.error, disabled = _a.disabled, readOnly = _a.readOnly, appearance = _a.appearance, type = _a.type, onChange = _a.onChange;
    var iconPrefix = type === "checkbox" ? "checkbox" : "radioButton";
    return (_jsx(S.OptionWrapper, { children: _jsxs(S.Option, __assign({ isChecked: selected, hasError: !!error, disabled: disabled, readOnly: readOnly, as: readOnly ? "div" : "label", appearance: appearance, onClick: function () {
                if (readOnly || disabled) {
                    return;
                }
                // @ts-ignore
                onChange(value);
            } }, { children: [_jsxs(S.OptionInput, __assign({ isChecked: selected, hasError: !!error, readOnly: readOnly }, { children: [_jsx(Icon, { source: "".concat(iconPrefix, "Outline") }), selected && _jsx(Icon, { source: "".concat(iconPrefix, "Mark") })] })), _jsxs(Flex, __assign({ width: 1, alignItems: "center" }, { children: [_jsxs(S.OptionContent, { children: [title, subtitle && _jsx(S.OptionDescription, { children: subtitle })] }), appearance === "warning" && (_jsx(Box, __assign({ ml: ESpacings.base }, { children: _jsx(Icon, { source: "alertDiamond" }) })))] }))] })) }));
};
