var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactPaginate from "react-paginate";
import { ts } from "../../services";
import * as S from "./Pagination.styled";
var Pagination = function (_a) {
    var pageCount = _a.pageCount, rest = __rest(_a, ["pageCount"]);
    return (_jsx(S.Wrapper, { children: _jsx(ReactPaginate, __assign({ previousLabel: ts.system("previous"), nextLabel: ts.system("next"), breakLabel: "...", marginPagesDisplayed: 1, pageRangeDisplayed: 5, breakClassName: "break-me", containerClassName: "pagination", activeClassName: "is-active", disabledClassName: "is-disabled", pageCount: pageCount, disableInitialCallback: true, forcePage: rest.initialPage }, rest)) }));
};
export default Pagination;
