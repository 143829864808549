export var getIndexLastModified = function (state) {
    return state.systemLocal.lastModified;
};
export var searchResult = function (state, identifier) { var _a; return (_a = state.systemLocal.search[identifier]) === null || _a === void 0 ? void 0 : _a.result; };
export var isFetchingSearch = function (state, identifier) { var _a; return ((_a = state.systemLocal.search[identifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchErrorSearch = function (state, identifier) { var _a; return ((_a = state.systemLocal.search[identifier]) === null || _a === void 0 ? void 0 : _a.fetchError) || false; };
export var getPreference = function (state, key, defaultValue) {
    var notPersistedResult = state.systemLocal.preferences[key];
    if (notPersistedResult !== undefined) {
        return notPersistedResult;
    }
    var persistedResult = state.systemLocal.preferencesLocalStorage[key];
    if (persistedResult !== undefined) {
        return persistedResult;
    }
    return defaultValue;
};
export var getPreferences = function (state, preferences) {
    return preferences.map(function (preference) { return ({
        key: preference.key,
        value: getPreference(state, preference.key, preference.defaultValue),
    }); });
};
