var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as t from "../services/translationService";
export var parseAddressObject = function (address, lookupAddress) {
    var number = address.number, street = address.street;
    if (!number || !street || !lookupAddress) {
        return address;
    }
    // Look for addresses with house numbers that include alphabetic characters
    // cspell:disable-next-line
    // i.e. Zilverberklaan 2b
    var regex = new RegExp("".concat(street, " ").concat(number, "[a-zA-Z]{1}"));
    var streetAndNumberMatch = lookupAddress.match(regex);
    if (!streetAndNumberMatch || streetAndNumberMatch.length === 0)
        return address;
    var actualNumber = "".concat(number).concat(streetAndNumberMatch[0].replace("".concat(street, " ").concat(number), ""));
    return __assign(__assign({}, address), { number: actualNumber });
};
export var addressObjToString = function (address, asArray) {
    if (asArray === void 0) { asArray = false; }
    if (!address) {
        return "";
    }
    var street = [
        address.street && address.street,
        address.number && address.number,
        address.box && "".concat(t.box().toLowerCase(), " ").concat(address.box),
    ];
    var city = [address.zip && address.zip, address.city && address.city];
    var addressParts = [
        street.filter(Boolean).join(" "),
        city.filter(Boolean).join(" "),
    ];
    if (!asArray) {
        return addressParts.filter(Boolean).join(", ");
    }
    return addressParts.filter(Boolean);
};
