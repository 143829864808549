var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getManualPaymentsKey } from "@rentiohq/shared-frontend/dist/forms/addLegalCase/schema.addLegalCase.utils";
import { getFieldTitle } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { Fragment, useState } from "react";
import ButtonBase from "../../../Button";
import TextField from "../../../TextField/TextField";
import { FileField } from "../FileField";
import * as S from "./LatePayments.styled";
var getNewState = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = {}; }
    if (action.type === "change-info") {
        var _c = action.payload, info = _c.info, name_1 = _c.name;
        return __assign(__assign({}, state), (_a = {}, _a[name_1] = __assign(__assign({}, state[name_1]), { info: info }), _a));
    }
    if (action.type === "add-document") {
        var _d = action.payload, documents = _d.documents, name_2 = _d.name;
        return __assign(__assign({}, state), (_b = {}, _b[name_2] = __assign(__assign({}, state[name_2]), { documents: documents }), _b));
    }
    return state;
};
var getInitialCount = function (formData, name) {
    return (formData === null || formData === void 0 ? void 0 : formData[name]) ? Object.keys(formData[name]).length : 0;
};
export var LatePaymentsManualSelect = function (_a) {
    var name = _a.name, schema = _a.schema, idSchema = _a.idSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, formContext = _a.formContext, required = _a.required, uiSchema = _a.uiSchema, disabled = _a.disabled, readonly = _a.readonly, otherProps = __rest(_a, ["name", "schema", "idSchema", "rawErrors", "onChange", "formData", "formContext", "required", "uiSchema", "disabled", "readonly"]);
    var title = getFieldTitle(uiSchema, formContext);
    var error = rawErrors[0];
    var _c = useState(getInitialCount(formContext.getAllFormData(), name)), addedManualPaymentsCount = _c[0], setAddedManualPaymentsCount = _c[1];
    var isDisabled = uiSchema.isDisabled, buttonTitle = uiSchema.buttonTitle, infoTitle = uiSchema.infoTitle, documentsTitle = uiSchema.documentsTitle, _d = uiSchema.showAddButton, showAddButton = _d === void 0 ? true : _d, _e = uiSchema.keyIndexOffset, keyIndexOffset = _e === void 0 ? 0 : _e;
    var uiDisabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    return (_jsxs(_Fragment, { children: [Array(addedManualPaymentsCount)
                .fill(0)
                .map(function (_, index) {
                var _a;
                var key = getManualPaymentsKey(index + keyIndexOffset);
                return (_jsxs(Fragment, { children: [_jsx("h1", { children: "".concat(infoTitle, " ").concat(index + 1) }), _jsxs(S.InputWrapper, { children: [_jsx(TextField, { multiline: true, name: "".concat(key, "-info"), error: error, value: formData ? (_a = formData[key]) === null || _a === void 0 ? void 0 : _a.info : "", onChange: function (_a) {
                                        var target = _a.target;
                                        var newState = getNewState(formData, {
                                            type: "change-info",
                                            payload: {
                                                name: key,
                                                info: target.value,
                                            },
                                        });
                                        onChange(newState);
                                    } }), _jsx(S.FileInputWrapper, { children: _jsx(FileField, __assign({ schema: schema, idSchema: idSchema, formData: (formData === null || formData === void 0 ? void 0 : formData[key]) ? formData === null || formData === void 0 ? void 0 : formData[key].documents : undefined, error: error, uiSchema: uiSchema, title: documentsTitle, onChange: function (files) {
                                            var newState = getNewState(formData, {
                                                type: "add-document",
                                                payload: {
                                                    name: key,
                                                    documents: files,
                                                },
                                            });
                                            onChange(newState);
                                        }, disabled: false, readonly: false, formContext: formContext, required: false, optional: true, name: "".concat(key, "-docs") }, otherProps)) })] })] }, key));
            }), showAddButton && (_jsx(ButtonBase, __assign({ title: title, isDisabled: uiDisabled, appearance: "outline", onClick: function () {
                    return setAddedManualPaymentsCount(addedManualPaymentsCount + 1);
                } }, { children: buttonTitle })))] }));
};
