import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGetDetailAction } from "./detail.actions";
import { getDetailSelectors } from "./detail.selectors";
export var detailGenerator = function (paramsGenerator) {
    var getBaseState = paramsGenerator.getBaseState, entityName = paramsGenerator.entityName;
    var getDetail = getGetDetailAction({
        entityName: entityName,
    });
    return function (params) {
        var id = params.id, filter = params.filter, _a = params.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a;
        // Redux
        var dispatch = useDispatch();
        var selectors = getDetailSelectors(getBaseState);
        var detail = useSelector(function (state) { return selectors.detail(state, id); });
        var isFetching = useSelector(function (state) {
            return selectors.isFetching(state, id);
        });
        var fetchError = useSelector(function (state) {
            return selectors.fetchError(state, id);
        });
        // Data
        var refetchDetail = function () {
            if (!id || isFetching) {
                return;
            }
            dispatch(getDetail.actions.start({
                id: id,
                filter: filter,
            }));
        };
        var fetchDetail = function () {
            if (!id || isFetching) {
                return;
            }
            if (!shouldRefetch && !!detail) {
                return;
            }
            dispatch(getDetail.actions.start({
                id: id,
                filter: filter,
            }));
        };
        // Lifecycle
        React.useEffect(function () {
            fetchDetail();
        }, [id]);
        return {
            detail: detail,
            isFetching: isFetching,
            fetchError: fetchError,
            fetchDetail: fetchDetail,
            refetchDetail: refetchDetail,
        };
    };
};
