var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, css, FontWeights, spacing, } from "@rentiohq/web-theme";
import { hideVisually, rem, stripUnit } from "polished";
import { StyledIcon } from "../Icon/Icon.styled";
export var OptionAppearanceToColor = {
    default: "primary",
    info: "blue",
    warning: "yellow",
    error: "red",
    success: "green",
};
var OptionSimpleStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  display: flex;\n  &:hover {\n    color: ", ";\n  }\n  ", "\n  ", "\n  ", "\n"], ["\n  cursor: pointer;\n  position: relative;\n  display: flex;\n  &:hover {\n    color: ", ";\n  }\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 1000);
}, function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return hasError &&
        "\n      color: ".concat(theme.getColor("red", 0), ";\n    ");
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled &&
        "\n    &&& {\n      color: ".concat(theme.getColor("gray", 400), ";\n      opacity: .5;\n      cursor: not-allowed;\n      pointer-events: none;\n    }\n  ");
}, function (_a) {
    var readOnly = _a.readOnly;
    return readOnly &&
        "\n    &&& {\n      cursor: default;\n      pointer-events: none;\n    }\n  ";
});
var OptionStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  display: flex;\n  padding: ", ";\n  border-radius: ", ";\n  ", "\n  &:hover {\n    color: ", ";\n    background-color: inherit;\n  }\n  opacity: 0.7;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  &:focus-within {\n    box-shadow: 0 0 4px ", ";\n  }\n"], ["\n  cursor: pointer;\n  position: relative;\n  display: flex;\n  padding: ", ";\n  border-radius: ", ";\n  ", "\n  &:hover {\n    color: ", ";\n    background-color: inherit;\n  }\n  opacity: 0.7;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  &:focus-within {\n    box-shadow: 0 0 4px ", ";\n  }\n"])), spacing("tight"), borderRadius(), function (_a) {
    var theme = _a.theme;
    return "border: 1px solid ".concat(theme.getColor("gray", 200), ";");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 1000);
}, function (_a) {
    var isChecked = _a.isChecked, theme = _a.theme;
    return isChecked &&
        "\n      &&& {\n        color: ".concat(theme.getColor("primary"), ";\n      }\n      opacity: 1;\n      border: 2px solid ").concat(theme.getColor("primary"), ";\n      &, &:hover {\n        background-color: ").concat(theme.getColor("primary", 0), ";\n      };\n    ");
}, function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return hasError &&
        "\n      border: 1px solid ".concat(theme.getColor("red"), ";\n      background-color: ").concat(theme.getColor("red", 0), ";\n    ");
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled &&
        "\n    &&& {\n      color: ".concat(theme.getColor("gray", 400), ";\n      opacity: .5;\n      cursor: not-allowed;\n      pointer-events: none;\n    }\n  ");
}, function (_a) {
    var readOnly = _a.readOnly;
    return readOnly &&
        "\n    &&& {\n      cursor: default;\n      pointer-events: none;\n    }\n  ";
}, function (_a) {
    var appearance = _a.appearance, theme = _a.theme;
    if (!appearance) {
        return;
    }
    return "\n      &&& {\n        color: ".concat(theme.getColor(OptionAppearanceToColor[appearance]), ";\n      }\n      border: 2px solid ").concat(theme.getColor(OptionAppearanceToColor[appearance]), ";\n      &, &:hover { background-color: ").concat(theme.getColor(OptionAppearanceToColor[appearance], 0), "; }\n    ");
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 1000);
});
var OptionCardLarge = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(220, theme.baseFontSize), ";\n    height: ").concat(rem(150, theme.baseFontSize), ";\n  ");
});
var OptionCard = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(180, theme.baseFontSize), ";\n    height: ").concat(rem(140, theme.baseFontSize), ";\n  ");
});
var OptionCardMedium = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(170, theme.baseFontSize), ";\n    height: ").concat(rem(130, theme.baseFontSize), ";\n  ");
});
var OptionCardSmall = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(140, theme.baseFontSize), ";\n    height: ").concat(rem(110, theme.baseFontSize), ";\n  ");
});
export var OptionWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  & + & {\n    margin-top: ", ";\n  }\n"])), spacing("tight"));
export var OptionInput = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: block;\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n  > input {\n    ", "\n  }\n  ", "\n  ", "\n"], ["\n  display: block;\n  ", " {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n  > input {\n    ", "\n  }\n  ", "\n  ", "\n"])), StyledIcon, hideVisually(), function (_a) {
    var theme = _a.theme, isChecked = _a.isChecked, hasError = _a.hasError;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), ";\n    min-width: ").concat(rem(24, theme.baseFontSize), ";\n    height: ").concat(rem(24, theme.baseFontSize), ";\n    ").concat(isChecked ? "color: ".concat(theme.getColor("primary", 1000)) : "", ";\n    ").concat(hasError ? "color: ".concat(theme.getColor("red")) : "", ";\n  ");
}, function (_a) {
    var readOnly = _a.readOnly;
    return readOnly &&
        "\n    &&& {\n      opacity: .5;\n    }\n  ";
});
export var OptionChecked = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: inline-flex;\n  ", "\n  border-radius: 50%;\n"], ["\n  display: inline-flex;\n  ", "\n  border-radius: 50%;\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    width: ".concat(rem(24, theme.baseFontSize), ";\n    height: ").concat(rem(24, theme.baseFontSize), ";\n    background-color: ").concat(theme.getColor("primary", 600), ";\n    color: ").concat(theme.getColor("white"), ";\n  ");
});
export var OptionContent = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var OptionBody = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var align = _a.align;
    switch (align) {
        case "middle":
            return "\n          margin-top: auto;\n          &:only-child {\n            margin-bottom: auto;\n          }\n        ";
        default:
            return null;
    }
});
export var OptionFooter = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  align-self: stretch;\n  margin-top: auto;\n  &:only-child {\n    margin-bottom: auto;\n  }\n"], ["\n  align-self: stretch;\n  margin-top: auto;\n  &:only-child {\n    margin-bottom: auto;\n  }\n"])));
export var OptionLabel = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), FontWeights.SemiBold);
export var OptionDescription = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", "\n  &:only-child {\n    text-align: left;\n  }\n"], ["\n  ", "\n  &:only-child {\n    text-align: left;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 400), ";\n    font-size: ").concat(rem(12, theme.baseFontSize), ";\n    letter-spacing: 0;\n  ");
});
export var Option = styled.label(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n  align-items: flex-start;\n  text-align: left;\n  ", " {\n    position: relative;\n    margin-right: ", ";\n  }\n  ", " {\n    line-height: ", ";\n    flex: 1 0 auto;\n  }\n  ~ ", " {\n    margin-top: ", ";\n  }\n  ", "\n"], ["\n  ", ";\n  align-items: flex-start;\n  text-align: left;\n  ", " {\n    position: relative;\n    margin-right: ", ";\n  }\n  ", " {\n    line-height: ", ";\n    flex: 1 0 auto;\n  }\n  ~ ", " {\n    margin-top: ", ";\n  }\n  ", "\n"])), function (_a) {
    var variant = _a.variant;
    return (variant === "simple" ? OptionSimpleStyle : OptionStyle);
}, OptionInput, spacing("tight"), OptionContent, function (_a) {
    var theme = _a.theme;
    return stripUnit(rem(24, theme.bodyFontSize));
}, OptionDescription, spacing("extraTight"), function (_a) {
    var isChecked = _a.isChecked, variant = _a.variant;
    return isChecked && variant !== "simple"
        ? " padding: calc(".concat(spacing("tight"), " - 1px); ")
        : "";
});
export var CardOptionWrapper = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding-left: ", ";\n  padding-top: ", ";\n"], ["\n  padding-left: ", ";\n  padding-top: ", ";\n"])), spacing(), spacing());
export var CardOption = styled.label(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  ", "\n  padding: ", ";\n  flex-direction: column;\n  align-items: stretch;\n  text-align: center;\n  ", "\n  ", "\n  ", " {\n    position: absolute;\n    top: ", ";\n    right: ", ";\n  }\n  ", " {\n    flex: 0 0 100%;\n  }\n  ", " {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 0 0 100%;\n  }\n  ", " {\n    ", "\n  }\n  ", " {\n    text-transform: uppercase;\n  }\n  ", ";\n"], ["\n  ", "\n  padding: ", ";\n  flex-direction: column;\n  align-items: stretch;\n  text-align: center;\n  ", "\n  ", "\n  ", " {\n    position: absolute;\n    top: ", ";\n    right: ", ";\n  }\n  ", " {\n    flex: 0 0 100%;\n  }\n  ", " {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 0 0 100%;\n  }\n  ", " {\n    ", "\n  }\n  ", " {\n    text-transform: uppercase;\n  }\n  ", ";\n"])), OptionStyle, spacing(), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 400), "\n    letter-spacing: ").concat(rem(0.5, theme.baseFontSize), ";\n  ");
}, function (_a) {
    var isChecked = _a.isChecked;
    return (isChecked ? " padding: calc(".concat(spacing(), " - 1px);") : "");
}, OptionInput, spacing("tight"), spacing("tight"), OptionInput, OptionContent, OptionFooter, function (_a) {
    var theme = _a.theme;
    return "\n      font-size:  ".concat(rem(12, theme.baseFontSize), ";\n    ");
}, OptionLabel, function (_a) {
    var size = _a.size;
    switch (size) {
        case "large":
            return OptionCardLarge;
        case "medium":
            return OptionCardMedium;
        case "small":
            return OptionCardSmall;
        default:
            return OptionCard;
    }
});
export var Options = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var variant = _a.variant;
    return variant === "card"
        ? "\n        display: flex;\n        flex-wrap: wrap;\n        margin-left: -".concat(spacing(), ";\n        margin-top: -").concat(spacing(), ";\n      ")
        : "";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
