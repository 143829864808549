var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box } from "@rebass/grid";
import styled, { FontWeights, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var StyledLabelWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: ", ";\n"])), spacing("tight"));
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n"], ["\n  font-weight: ", ";\n  color: ", ";\n  font-size: ", ";\n"])), FontWeights.SemiBold, function (props) { return props.theme.getColor("gray", 600); }, function (props) { return rem("14px", props.theme.baseFontSize); });
export var StyledLabelOptional = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-flex;\n"], ["\n  display: inline-flex;\n"])));
export var ExtraInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing("");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
