import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import Loading from "../Loading";
var PropertyFetchListItem = function (_a) {
    var property = _a.property, propertyId = _a.propertyId, renderProperty = _a.renderProperty, shouldRefetch = _a.shouldRefetch;
    var _b = propertyHooks.useDetail({
        id: propertyId,
        shouldRefetch: shouldRefetch,
    }), fetchedProperty = _b.detail, isFetching = _b.isFetching;
    if (property) {
        return _jsx(_Fragment, { children: renderProperty(property) });
    }
    if (isFetching) {
        return _jsx(Loading, { asDots: true });
    }
    if (fetchedProperty) {
        return _jsx(_Fragment, { children: renderProperty(fetchedProperty) });
    }
    return null;
};
export default PropertyFetchListItem;
