export var ACTIVITY_TYPES = {
    CHAT: 1,
    CONTACT: 2,
    CONTRACT: 3,
    PAYMENT: 4,
    PROPERTY: 5,
    TASK: 6,
    USER: 7,
    DOCUMENT_PACKAGE: 8,
    REGISTERED_MAIL: 9,
    DOCUMENT: 10,
    BILLING: 11,
    TEMPLATE_DOCUMENT: 14,
    RENT_DEPOSIT: 13,
    RENT_REGISTRATION: 15,
    INSURANCE_DASHBOARD: 16,
    EXPORT_FILE: 17,
    LEGAL_CASE: 18,
};
export var ACTIVITY_SUBTYPES = {
    CHAT: {
    // CREATED: 1000, // Activity not in use (keep for reference)
    // ARCHIVED: 1001, // Activity not in use (keep for reference)
    // REMOVED: 1002, // Activity not in use (keep for reference)
    // PARTICIPANT_ADDED: 1003, // Activity not in use (keep for reference)
    // PARTICIPANT_REMOVED: 1004, // Activity not in use (keep for reference)
    // PARTICIPANT_LEFT: 1005, // Activity not in use (keep for reference)
    },
    CONTACT: {
        CREATED: 2000,
        UPDATED: 2001,
        REMOVED: 2002,
        // LINKED: 2003, // Activity not in use (keep for reference)
        // UNLINKED: 2004, // Activity not in use (keep for reference)
        CALLED: 2005,
        INVITED: 2006,
        MAILED: 2007,
        // ASSET_SENT: 2008, // Activity not in use (keep for reference)
        INVITED_MANUALLY: 2009,
        INVITE_ACCEPTED: 2010,
        INVITE_DECLINED: 2011,
    },
    CONTRACT: {
        CREATED: 3000,
        // ARCHIVED: 3002, // Activity not in use (keep for reference)
        // UNARCHIVED: 3003, // Activity not in use (keep for reference)
        SIGNED: 3004,
        // CANCELLATION_SUBMITTED: 3005, // Activity not in use (keep for reference)
        QUALIFIED_FOR_INDEXATION: 3006,
        // PROPOSAL_FOR_INDEXATION_RECEIVED: 3007, // Activity not in use (keep for reference)
        INDEXATION_APPLIED: 3008,
        INDEXATION_APPLIED_NOTIFICATION_SENT: 3009,
        INDEXATION_SKIPPED: 3010,
        ENDED: 3011,
        INDEXATION_ASKED_OWNER: 3012,
        PROPOSAL_FOR_INDEXATION_SENT: 3013,
        REMOVED: 3014,
        MEMBER_ADDED: 3015,
        MEMBER_REMOVED: 3016,
        ATTACHMENT_ADDED: 3017,
        ATTACHMENT_REMOVED: 3018,
        INDEXATION_SKIPPED_NOTIFICATION_SENT: 3019,
        MANUALLY_RENEWED: 3020,
        TACIT_RENEWED: 3021,
        UPDATED: 3022,
        INDEXATION_APPLIED_FORCE_FULLY: 3023,
        INDEXATION_APPLIED_A_C: 3024,
        INDEXATION_APPLIED_D: 3025,
        INDEXATION_APPLIED_CORRECTION: 3026,
        CANCELLED: 3027,
        DELETE_CANCELLATION: 3028,
    },
    PAYMENT: {
        ORDER_CREATED: 4000,
        ORDER_REMOVED: 4001,
        REQUEST_CREATED: 4002,
        REQUEST_REMINDER_SENT: 4003,
        PAYIN_CREATED: 4004,
        PAYIN_SUCCEEDED: 4005,
        PAYIN_FAILED: 4006,
        TRANSFER_CREATED: 4007,
        TRANSFER_SUCCEEDED: 4008,
        TRANSFER_FAILED: 4009,
        PAYOUT_CREATED: 4010,
        PAYOUT_SUCCEEDED: 4011,
        PAYOUT_FAILED: 4012,
        KYC_CREATED: 4013,
        KYC_UPDATED: 4014,
        KYC_VALIDATION_ASKED: 4015,
        KYC_VALIDATION_SUCCEEDED: 4016,
        KYC_VALIDATION_FAILED: 4017,
        KYC_NOTIFICATION_SENT: 4018,
        KYC_REMINDER_SENT: 4019,
        MANDATE_CREATED: 4020,
        MANDATE_SUBMITTED: 4021,
        MANDATE_FAILED: 4022,
        MANDATE_ACTIVATED: 4023,
        REQUEST_NOTIFICATION_SENT: 4024,
        ORDER_RECURRING_MANDATE_REMOVED: 4025,
        REQUEST_MANUALLY_PAID: 4026,
        ORDER_CREATED_INTERNAL: 4027,
        ORDER_REMOVED_INTERNAL: 4028,
        ORDER_UPDATED: 4029,
        REQUEST_REMOVED: 4030,
        // MOLLIE_PAYMENT_CREATED: 4031, // Activity not in use (keep for reference)
        // MOLLIE_PAYMENT_PAID: 4032, // Activity not in use (keep for reference)
        // MOLLIE_PAYMENT_CANCELLED: 4033, // Activity not in use (keep for reference)
        // MOLLIE_PAYMENT_EXPIRED: 4034, // Activity not in use (keep for reference)
        // MOLLIE_PAYMENT_FAILED: 4035, // Activity not in use (keep for reference)
        PAYIN_DISPUTED: 4036,
        // THIRD_PARTY_PAID_OUT: 4037, // Activity not in use (keep for reference)
        THIRD_PARTY_CHARGED: 4038,
        THIRD_PARTY_PAID: 4039,
        // PAYOUT_SKIPPED: 4040, // Activity not in use (keep for reference)
        PAYIN_CREATED_AUTOMATICALLY: 4041,
        RENT_DISCOUNT_SKIPPED: 4042,
        RENT_DISCOUNT_LATE: 4043,
        PAYIN_PLANNED: 4044,
        IBANISATION_MATCHING_NO_OLDEST_AMOUNT_MATCH: 4045,
        IBANISATION_MATCHING_NO_OPEN_PAYMENT_REQUESTS: 4046,
        IBANISATION_MATCHING_NO_REFERENCE_MATCH_MULTIPLE_PAYMENT_REQUESTS_WITH_AMOUNT: 4047,
        IBANISATION_MATCHING_NO_REFERENCE_MATCH_NO_AMOUNT_MATCH: 4048,
        IBANISATION_MATCHING_NO_REFERENCE_MATCH_TOOK_CORRESPONDING_AMOUNT: 4049,
        IBANISATION_MATCHING_NO_REFERENCE_MATCH_TOOK_OLDEST: 4050,
        IBANISATION_MATCHING_NO_AMOUNT_MATCH: 4051,
        IBANISATION_MATCHING_TOOK_OLDEST: 4052,
        IBANISATION_MATCHING_MULTIPLE_PAYMENT_REQUESTS: 4053,
        IBANISATION_MATCHING_MANUAL: 4054,
        IBANISATION_MATCHING_MANUAL_PARTIAL_MATCH: 4055,
        IBANISATION_MATCHING_MANUAL_BALANCE_AVAILABLE: 4056,
        MANDATE_EXPIRED: 4057,
    },
    PROPERTY: {
        ADDED: 5000,
        // REMOVED: 5001, // Activity not in use (keep for reference)
        ARCHIVED: 5002,
        UNARCHIVED: 5003,
        OWNER_ADDED: 5004,
        OWNER_REMOVED: 5005,
        // TENANT_ADDED: 5006, // Activity not in use (keep for reference)
        // TENANT_REMOVED: 5007, // Activity not in use (keep for reference)
        TECHNICAL_MANAGER_ADDED: 5008,
        TECHNICAL_MANAGER_REMOVED: 5009,
        FINANCIAL_MANAGER_ADDED: 5010,
        FINANCIAL_MANAGER_REMOVED: 5011,
        // ATTACHMENT_ADDED: 5012, // Activity not in use (keep for reference)
        // ATTACHMENT_REMOVED: 5013, // Activity not in use (keep for reference)
        // ATTACHMENT_SHARED: 5014, // Activity not in use (keep for reference)
        // ATTACHMENT_UNSHARED: 5015, // Activity not in use (keep for reference)
        APPLICATION_COLLECTED: 5016,
        APPLICATION_NOTICED: 5017,
        APPLICATION_MOVED_TO_STEP: 5018,
        INFO_PUBLISHED: 5019,
        // APPLICATION_CREATED: 5020, // Activity not in use (keep for reference)
        INTAKE_INVITATION_SENT: 5021,
        APPLICATION_SEND_EMAIL: 5022,
        APPLICATION_SEND_BROKER: 5023,
        UPDATED: 5024,
    },
    TASK: {
        CREATED: 6000,
        UPDATED: 6001,
        COMPLETED: 6002,
        // REMOVED: 6003, // Activity not in use (keep for reference)
        ATTACHMENT_ADDED: 6004,
        ATTACHMENT_REMOVED: 6005,
        PROPERTY_ADDED: 6006,
        PROPERTY_REMOVED: 6007,
        EXECUTOR_ADDED: 6008,
        EXECUTOR_REMOVED: 6009,
        FOLLOWER_ADDED: 6010,
        FOLLOWER_REMOVED: 6011,
        UNCOMPLETED: 6014,
        REMINDER_SENT_INTERNAL: 6016,
        CREATED_RECURRING_INTERNAL: 6017,
    },
    USER: {
        REGISTERED: 7000,
        LOGGED_IN: 7001,
        LOGGED_OUT: 7002,
        // UPDATED: 7003, // Activity not in use (keep for reference)
        // BANK_ACCOUNT_ADDED: 7004, // Activity not in use (keep for reference)
        // BANK_ACCOUNT_REMOVED: 7005, // Activity not in use (keep for reference)
        TAC_ACCEPTED: 7006,
        LOGGED_IN_BY_SUPPORT: 7007,
        SUSPENDED: 7008,
        UNSUSPENDED: 7009,
        ENABLED_ACCOUNT_ADDED: 7010,
        ENABLED_ACCOUNT_REMOVED: 7011,
        ENABLED_ACCOUNT_SWITCHED: 7012,
    },
    DOCUMENT_PACKAGE: {
        CREATED: 8000,
        PDF_GENERATED: 8001,
        CREATED_IN_CONNECTIVE: 8002,
        FINISHED: 8003,
        REJECTED: 8004,
        REVOKED: 8005,
        // EXPIRED: 8006, // Activity not in use (keep for reference)
        FAILED: 8007,
        SIGNER_FINISHED: 8008,
        SIGNER_REJECTED: 8009,
        SIGNER_FAILED: 8010,
        SIGNING_URL_NOTIFICATION_SENT: 8011,
        WORKER_FAILED: 8012,
    },
    REGISTERED_MAIL: {
        CREATED: 9000,
        // EMAIL_SENT: 9001, // Activity not in use (keep for reference)
        EXTERNAL_INVITATION: 9002,
        EXTERNAL_SENT: 9003,
        EXTERNAL_CONFIRMED: 9004,
        // EXTERNAL_EXPIRED: 9005, // Activity not in use (keep for reference)
        EXTERNAL_BPOSTTOSUBMIT: 9006,
        // EXTERNAL_BPOSTSUBMITTED: 9007, // Activity not in use (keep for reference)
        DOCUMENT_AVAILABLE: 9008,
    },
    DOCUMENT: {
        SHARED: 10000,
        UNSHARED: 10001,
        // SHARED_AUTOMATICALLY: 10002, // Activity not in use (keep for reference)
        // UNSHARED_AUTOMATICALLY: 10003, // Activity not in use (keep for reference)
        BENEFICIARY_REPORT_CREATED: 1004,
        BENEFICIARY_REPORT_SENT: 1005,
        BENEFICIARY_REPORT_SHARED: 1006,
    },
    BILLING: {
        PREPAID_INVOICE_CREATED: 11000,
        // PREPAID_INVOICE_PAID: 11001, // Activity not in use (keep for reference)
        BILLED_LICENSE: 11002,
        // BILLED_PROPERTIES: 11003, // Activity not in use (keep for reference)
        // PREPAID_DEPT: 11004, // Activity not in use (keep for reference)
        POSTPAID_INVOICE_CREATED: 11005,
        // POSTPAID_INVOICE_PAID: 11006, // Activity not in use (keep for reference)
        SUBSCRIPTION_SUBMITTED: 11007,
        // SUBSCRIPTION_ACTIVATED: 11008, // Activity not in use (keep for reference)
        DASHBOARD_PRO_LICENSE_BILLED: 11009,
        SUBSCRIPTION_RENEWAL_REQUESTED: 11010,
        // SUBSCRIPTION_UPGRADE_REQUESTED: 11011, // Activity not in use (keep for reference)
        SUBSCRIPTION_RENEWED: 11012,
        // SUBSCRIPTION_UPGRADED: 11013, // Activity not in use (keep for reference)
        // POSTPAID_INVOICE_PAID_FROM_RENT: 11014, // Activity not in use (keep for reference)
        PREPAID_UNUSED_MANAGEMENT_LICENSES: 11015,
        // PREPAID_UNUSED_RENT_LICENSES: 11016, // Activity not in use (keep for reference)
    },
    INSURANCE: {
    // CREATED: 12000, // Activity not in use (keep for reference)
    },
    RENT_DEPOSIT: {
        CREATED: 13000,
        CANCELLED: 13001,
        // ALL_SIGNED_IN: 13002, // Activity not in use (keep for reference)
        // PARTIALLY_SIGNED_IN: 13003, // Activity not in use (keep for reference)
        ESTABLISHED: 13004,
        IBAN_SEND: 13005,
        IBAN_CONFIRMED: 13006,
        VERIFICATION_SENT: 13007,
        VERIFICATION_CONFIRMED: 13008,
        // ALL_SIGNED_OUT: 13009, // Activity not in use (keep for reference)
        VALIDATED: 13010,
        RELEASED: 13011,
        RELEASE_CREATED: 13012,
        RELEASE_CREATED_JUDICIAL: 13013,
        RESET_TO_ESTABLISHED: 13014,
    },
    TEMPLATE_DOCUMENT: {
        CREATED: 14000,
        EXPORTED: 14001,
        PDF_GENERATED: 14002,
        PDF_GENERATE_FAILED: 14003,
    },
    RENT_REGISTRATION: {
        // CREATED: 15000, // Activity not in use (keep for reference)
        UPDATED: 15001,
        // REVOKED: 15002, // Activity not in use (keep for reference)
        RENT_CONTRACT_REGISTERED: 15003,
        LOCATION_DESCRIPTION_REGISTERED: 15004,
        RENT_CONTRACT_REGISTERED_MANUALLY: 15005,
        LOCATION_DESCRIPTION_REGISTERED_MANUALLY: 15006,
        // ERROR: 15007, // Activity not in use (keep for reference)
        RENT_CONTRACT_WITH_LOCATION_DESCRIPTION_CREATED: 15008,
        RENT_CONTRACT_CREATED: 15009,
        LOCATION_DESCRIPTION_CREATED: 15010,
        RENT_CONTRACT_SENT: 15011,
        LOCATION_DESCRIPTION_SENT: 15012,
        RENT_CONTRACT_INCOMPLETE: 15013,
        LOCATION_CONTRACT_INCOMPLETE: 15014,
        RENT_CONTRACT_PAYMENT_REQUIRED: 15015,
        LOCATION_DESCRIPTION_PAYMENT_REQUIRED: 15016,
        RENT_CONTRACT_REFUSED: 15017,
        LOCATION_DESCRIPTION_REFUSED: 15018,
        REMOVED: 15019,
        ADDRESS_NOT_FOUND: 15020,
        ADDENDUM_CREATED: 15021,
        ADDENDUM_SENT: 15022,
        ADDENDUM_REGISTERED: 15023,
        ADDENDUM_REFUSED: 15024,
        ADDENDUM_INCOMPLETE: 15025,
    },
    INSURANCE_DASHBOARD: {
        CREATED: 16001,
        FINALIZED: 16002,
        RESENT: 16003,
    },
    EXPORT_FILE: {
        CSV_FILE_EXPORTED: 17000,
    },
    LEGAL_CASE: {
        ACTIVATED: 18000,
        CREATED: 18001,
        UPDATED: 18002,
        REOPENED: 18003,
        CLOSED: 18004,
        DELETED: 18005,
        PDF_GENERATED: 18006,
        SEND_TO_LAWYER: 18007,
    },
};
export var ACTIVITY_TYPES_INSURANCE = {
    LEAD: 1,
    QUOTE: 2,
    BALOISE_API: 3,
    BALOISE_COMMUNICATION: 4,
    ROCKESTATE_API: 5,
    BACKEND: 6,
};
export var ACTIVITY_SUBTYPES_INSURANCE = {
    LEAD: {
        INVITED: 1000,
        STEP1_LANDING: 1001,
        STEP2_PROPERTY: 1003,
        STEP3_OPTIONS_INIT: 1004,
        STEP4_INSURER: 1005,
        STEP5_SUMMARY: 1006,
        STEP6_QUOTE_CREATE: 1007,
        STEP7_EXIT_AFTER_MAIL: 1008,
        PREREQUISITES_FAIL_INSURED_VALUE_TOO_LOW: 1009,
        PREREQUISITES_FAIL_INSURED_VALUE_TOO_HIGH: 1010,
        PREREQUISITES_FAIL_RISK_TOO_HIGH: 1011,
        PROPERTY_PRICE_TWENTY_YEARS_RENT: 1012,
        PROPERTY_PRICE_OUTSIDER: 1013,
    },
    QUOTE: {
        STEP1_SIGN_ENTRY: 2001,
        STEP1_QUOTE_INVALID: 2002,
        STEP2_SIGN_START: 2003,
        STEP3_SIGN_FINISH: 2004,
        STEP3_SIGN_FAIL: 2005,
        STEP4_PAYMENT_START: 2006,
        STEP4_PAYMENT_FAIL: 2007,
        STEP5_PAYMENT_SUCCESSFUL: 2008,
    },
    BALOISE_API: {
        SERVICE_STREET_OK: 3000,
        SERVICE_STREET_NOTFOUND: 3001,
        SERVICE_STREET_ERROR: 3002,
        SERVICE_RISK_OK: 3003,
        SERVICE_RISK_ERROR: 3004,
        SERVICE_TARIFF_OK: 3005,
        SERVICE_TARIFF_ERROR: 3006,
        SERVICE_QUICKQUOTE_OK: 3007,
        SERVICE_QUICKQUOTE_ERROR: 3009,
    },
    BALOISE_COMMUNICATION: {
        BALOISE_SENT: 4000,
    },
    ROCKESTATE_API: {
        SERVICE_AUTOCOMPLETE_NO_ADDRESS: 5000,
        SERVICE_AUTOCOMPLETE_MULTIPLE_ADDRESSES: 5001,
        SERVICE_AUTOCOMPLETE_NO_BUILDING: 5002,
        SERVICE_AUTOCOMPLETE_MULTIPLE_BUILDINGS: 5003,
        SERVICE_AUTOCOMPLETE_SUCCESS: 5004,
        SERVICE_FEATURES_ERROR: 5005,
        SERVICE_FEATURES_SUCCESS: 5006,
        SERVICE_LEVEL_SMALLER_THEN_THREE: 5007,
        SERVICE_DEVIATION_GREATER: 5008,
    },
    BACKEND: {
    // TODO: ?
    },
};
export var ALL_ACTIVITY_TYPES = Object.values(ACTIVITY_TYPES);
export var ALL_CHAT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.CHAT);
export var ALL_CONTACT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.CONTACT);
export var ALL_CONTRACT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.CONTRACT);
export var ALL_PAYMENT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.PAYMENT);
export var ALL_PROPERTY_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.PROPERTY);
export var ALL_TASK_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.TASK);
export var ALL_USER_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.USER);
export var ALL_DOCUMENT_PACKAGE_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.DOCUMENT_PACKAGE);
export var ALL_REGISTERED_MAIL_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.REGISTERED_MAIL);
export var ALL_DOCUMENT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.DOCUMENT);
export var ALL_BILLING_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.BILLING);
export var ALL_RENT_DEPOSIT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.RENT_DEPOSIT);
export var ALL_TEMPLATE_DOCUMENT_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.TEMPLATE_DOCUMENT);
export var ALL_RENT_REGISTRATION_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.RENT_REGISTRATION);
export var ALL_INSURANCE_DASHBOARD_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.INSURANCE_DASHBOARD);
export var ALL_EXPORT_FILE_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.EXPORT_FILE);
export var ALL_LEGAL_CASE_ACTIVITY_SUBTYPES = Object.values(ACTIVITY_SUBTYPES.LEGAL_CASE);
export var ENotificationType;
(function (ENotificationType) {
    ENotificationType["Chat"] = "chat";
    ENotificationType["Email"] = "email";
    ENotificationType["PushNotification"] = "push_notification";
    ENotificationType["Card"] = "card";
    ENotificationType["Phone"] = "phone";
    ENotificationType["Sms"] = "sms";
})(ENotificationType || (ENotificationType = {}));
export var EPlatformSource;
(function (EPlatformSource) {
    EPlatformSource["App"] = "app";
    EPlatformSource["Dashboard"] = "dashboard";
    EPlatformSource["Website"] = "website";
    EPlatformSource["Intake"] = "intake";
    EPlatformSource["Backside"] = "backside";
    EPlatformSource["Backoffice"] = "backoffice";
})(EPlatformSource || (EPlatformSource = {}));
export var EUsedSigningType;
(function (EUsedSigningType) {
    EUsedSigningType["Manual"] = "Manual";
    EUsedSigningType["BeId"] = "BeId";
    EUsedSigningType["ManualBeId"] = "ManualBeId";
    EUsedSigningType["SmsOtp"] = "SmsOtp";
    EUsedSigningType["Server"] = "Server";
    EUsedSigningType["MailOtp"] = "MailOtp";
    EUsedSigningType["Idin"] = "Idin";
    EUsedSigningType["BeLawyer"] = "BeLawyer";
    EUsedSigningType["Biometric"] = "Biometric";
    EUsedSigningType["Itsme"] = "Itsme";
    EUsedSigningType["OpenIdConnect"] = "OpenIdConnect";
    EUsedSigningType["Unknown"] = "unknown";
    EUsedSigningType["NotSigned"] = "NotSigned";
})(EUsedSigningType || (EUsedSigningType = {}));
