var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
var TruncateMiddle = function (props) {
    var input = props.input, _a = props.skipInitialChars, skipInitialChars = _a === void 0 ? 0 : _a, maxWidthAfterTruncation = props.maxWidthAfterTruncation;
    if (!input) {
        return null;
    }
    var inputLength = input.length;
    var initialString = input.substring(0, skipInitialChars);
    var truncatedString = inputLength > skipInitialChars
        ? input.substring(skipInitialChars)
        : undefined;
    var appendSpaceToPrefix = (truncatedString === null || truncatedString === void 0 ? void 0 : truncatedString[0]) === " ";
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        } }, { children: [_jsx("span", __assign({ style: { whiteSpace: "pre" } }, { children: initialString })), !!truncatedString && (_jsxs(_Fragment, { children: [appendSpaceToPrefix && _jsx(_Fragment, { children: "\u00A0" }), _jsx("div", __assign({ style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            direction: "rtl",
                            textAlign: "left",
                            maxWidth: maxWidthAfterTruncation !== null && maxWidthAfterTruncation !== void 0 ? maxWidthAfterTruncation : undefined,
                        } }, { children: _jsxs("bdi", { children: ["\u200E", truncatedString] }) }))] }))] })));
};
export default TruncateMiddle;
