import logger from "@rentiohq/shared/dist/logger";
import HttpStatus from "http-status-codes";
import flatten from "lodash/flatten";
import { getLocalizedText } from "../utils/i18n/i18n.utils";
import { capitalizeString, join, stringToPascalCaseWithSpaces, stringToSnakeCase, } from "./string.utils";
var getReadableFieldName = function (input) {
    var fieldNameSnakeCase = stringToSnakeCase(input);
    var fieldNameKey = "field.".concat(fieldNameSnakeCase);
    var fieldNameTranslated = getLocalizedText(fieldNameKey);
    // If field name not translated: return field name formatted as pascal case with spaces
    if (fieldNameTranslated === fieldNameKey) {
        var fieldNamePascalCaseWithSpaces = stringToPascalCaseWithSpaces(input);
        return fieldNamePascalCaseWithSpaces;
    }
    return fieldNameTranslated;
};
var getErrorForUnprocessableEntity = function (axiosError) {
    var _a, _b, _c, _d;
    try {
        var codes_1 = ((_d = (_c = (_b = (_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.codes) || {};
        var results = Object.keys(codes_1).map(function (fieldName) {
            var readableFieldName = getReadableFieldName(fieldName);
            return codes_1[fieldName].map(function (fieldError) {
                var fieldErrorSakeCase = stringToSnakeCase(fieldError);
                return getLocalizedText("error.".concat(fieldErrorSakeCase), {
                    field: readableFieldName,
                });
            });
        });
        var flattenedResults = flatten(results);
        if (flattenedResults.length === 0) {
            return;
        }
        return join(flattenedResults);
    }
    catch (unknownError) {
        var error = unknownError;
        logger.logError({ error: error });
        return;
    }
};
export var getMessageForError = function (error) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var axiosError = error;
    if (!axiosError.isAxiosError) {
        return getLocalizedText("general.error");
    }
    var result = getLocalizedText("general.error");
    if (axiosError.code === "ECONNABORTED") {
        result = getLocalizedText("system.error.timeout");
    }
    var messageFromResponse = 
    // V2
    ((_d = (_c = (_b = (_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.exception) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.message) ||
        ((_g = (_f = (_e = axiosError.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.exception) === null || _g === void 0 ? void 0 : _g.message) ||
        (
        // Legacy
        (_k = (_j = (_h = axiosError.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.error) === null || _k === void 0 ? void 0 : _k.message) ||
        ((_m = (_l = axiosError.response) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.message);
    if (messageFromResponse) {
        result = getLocalizedText(messageFromResponse);
    }
    switch ((_o = axiosError.response) === null || _o === void 0 ? void 0 : _o.status) {
        case HttpStatus.UNPROCESSABLE_ENTITY:
            result = getErrorForUnprocessableEntity(axiosError) || result;
            break;
        case HttpStatus.FORBIDDEN:
            result = getLocalizedText("system.error.access_denied");
            break;
        case HttpStatus.NOT_FOUND:
            result = getLocalizedText("system.error.404");
            break;
        // TODO: Add other HTTP statuses
        default:
            break;
    }
    return capitalizeString("".concat(result, "."));
};
