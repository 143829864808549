export var ERentDepositStatus;
(function (ERentDepositStatus) {
    ERentDepositStatus["New"] = "NEW";
    ERentDepositStatus["OpenToSign"] = "OPEN_TO_SIGN";
    ERentDepositStatus["OpenSigned"] = "OPEN_SIGNED";
    ERentDepositStatus["PayingIn"] = "PAYING_IN";
    ERentDepositStatus["PayingInPartiallySigned"] = "PAYING_IN_PARTIALLY_SIGNED";
    ERentDepositStatus["PaidInPartiallySigned"] = "PAID_IN_PARTIALLY_SIGNED";
    ERentDepositStatus["Established"] = "ESTABLISHED";
    ERentDepositStatus["ReleaseNew"] = "RELEASE_NEW";
    ERentDepositStatus["WaitingForIBAN"] = "WAITING_FOR_IBAN";
    ERentDepositStatus["WaitingForValidations"] = "WAITING_FOR_VALIDATIONS";
    ERentDepositStatus["ReleaseToSign"] = "RELEASE_TO_SIGN";
    ERentDepositStatus["ReleaseSigned"] = "RELEASE_SIGNED";
    ERentDepositStatus["ManualVerification"] = "MANUAL_VERIFICATION";
    ERentDepositStatus["ManualVerificationJudicial"] = "MANUAL_VERIFICATION_JUDICIAL";
    ERentDepositStatus["PayingOut"] = "PAYING_OUT";
    ERentDepositStatus["Released"] = "RELEASED";
    ERentDepositStatus["Cancelled"] = "CANCELLED";
})(ERentDepositStatus || (ERentDepositStatus = {}));
