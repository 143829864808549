import { connect } from "react-redux";
import * as contactActions from "../../../redux/contact/contact.actions";
import * as contactSelectors from "../../../redux/contact/contact.selectors";
import ContactFetchListItem from "./ContactFetchListItem";
import * as utils from "./ContactFetchListItem.utils";
var mapStateToProps = function (state, props) {
    var contactId = utils.getContactId(props);
    var accountId = utils.getAccountId(props);
    return {
        isFetching: contactSelectors.getContactIsFetching(state, contactId, accountId),
        didFetchAccount: accountId
            ? contactSelectors.getContactDidFetch(state, accountId)
            : false,
        fetchError: contactSelectors.getContactFetchError(state, contactId, accountId),
        fetchedContact: contactSelectors.getContact(state, contactId, accountId),
    };
};
var mapDispatchToProps = {
    getContact: contactActions.getContact.actions.start,
    getContactsByAccountIds: contactActions.getContactsByAccountIds.actions.start,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactFetchListItem);
