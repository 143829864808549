var animationSpeedData = {
    extraFast: "200ms",
    fast: "300ms",
    base: "400ms",
    slow: "500px",
    extraSlow: "600px",
};
var animationEasingData = {
    easeInOut: "ease-in-out",
    easeOutSine: "cubic-bezier(0.39, 0.575, 0.565, 1)",
    easeOutCubic: "cubic-bezier(0.215, 0.61, 0.355, 1)",
};
export var animationSpeed = function (value) {
    if (value === void 0) { value = "base"; }
    return animationSpeedData[value];
};
export var animationEasing = function (value) {
    if (value === void 0) { value = "easeOutSine"; }
    return animationEasingData[value];
};
