// cspell:disable
var _a;
import { EChartSize, EColor, ELineAxis, EStatDuration, EStatInterval, } from "./DashboardStats.types";
var ETab;
(function (ETab) {
    ETab["GENERAL"] = "general";
    ETab["PROPERTIES"] = "properties";
    ETab["FINANCIAL"] = "financial";
    ETab["PROFILES"] = "mytenantprofile";
    ETab["SERVICES"] = "services";
    ETab["SUPPORT"] = "support";
})(ETab || (ETab = {}));
export var EStatEntity;
(function (EStatEntity) {
    // Dashboard
    EStatEntity["Applications"] = "applications";
    EStatEntity["ApplicationProfiles"] = "application-profiles";
    EStatEntity["Broker"] = "brokers";
    EStatEntity["BankAccounts"] = "bankaccounts";
    EStatEntity["Chats"] = "chats";
    EStatEntity["Contracts"] = "contracts";
    EStatEntity["Disputes"] = "disputes";
    EStatEntity["MoveServices"] = "move-services";
    EStatEntity["PayIns"] = "payins";
    EStatEntity["PaymentOrders"] = "payment-orders";
    EStatEntity["PaymentRequests"] = "payment-requests";
    EStatEntity["PhoneCalls"] = "phonecalls";
    EStatEntity["PhoneCallsIncoming"] = "phonecalls/incoming";
    EStatEntity["PhoneCallsOutgoing"] = "phonecalls/outgoing";
    EStatEntity["Properties"] = "properties";
    EStatEntity["PropertiesCreatedByBroker"] = "properties-created-by-broker";
    EStatEntity["PropertiesManagedByBroker"] = "properties-managed-by-broker";
    EStatEntity["PropertyInspections"] = "property-inspections";
    EStatEntity["Registrations"] = "registrations";
    EStatEntity["RegisteredMail"] = "registered-mail";
    EStatEntity["RentDeposits"] = "rent-deposits";
    EStatEntity["Signatures"] = "signatures";
    EStatEntity["Tasks"] = "tasks";
    EStatEntity["Users"] = "users";
    // Insurance
    EStatEntity["InsuranceQuotes"] = "quotes";
    EStatEntity["InsuranceTriggerLandingPageOpened"] = "trigger-landing-page-opened";
    EStatEntity["InsuranceTriggerQuoteCreated"] = "trigger-quote-created";
    EStatEntity["InsuranceTriggerDocumentSigned"] = "trigger-document-signed";
    EStatEntity["InsuranceTriggerPaymentSuccessful"] = "trigger-payment-successful";
})(EStatEntity || (EStatEntity = {}));
/**
 * RENTIO Charts
 */
var CHARTS_BACKOFFICE_GENERAL = [
    {
        entity: EStatEntity.Broker,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
            {
                key: "numActive",
                label: "Actief",
                color: EColor.AMOUNT,
            },
            {
                key: "numStopped",
                label: "Gestopt",
                color: EColor.RED,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Users,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Contracts,
        average: {
            dividend: "totalAmount",
            divisor: "count",
            label: "Gemiddelde huur",
            color: EColor.AMOUNT,
        },
        lines: [
            {
                key: "count",
                label: "Aangemaakt",
            },
            {
                key: "avgPrice",
                label: "Gemiddelde huur",
                color: EColor.AMOUNT,
                axis: ELineAxis.RIGHT,
                hideInSummary: true,
            },
            {
                key: "totalAmount",
                label: "Totaal huur",
                hideInSummary: true,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Chats,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.MEDIUM,
    },
    {
        entity: EStatEntity.Tasks,
        lines: [
            {
                key: "count",
                label: "Aangemaakt",
            },
            {
                key: "numFinished",
                label: "Afgewerkt",
                color: EColor.AMOUNT,
            },
        ],
        size: EChartSize.MEDIUM,
    },
];
var CHART_BACKOFFICE_PROPERTIES = [
    {
        entity: EStatEntity.PropertiesCreatedByBroker,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Properties,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
    {
        entity: EStatEntity.PropertiesManagedByBroker,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
];
var CHARTS_BACKOFFICE_FINANCIAL = [
    {
        entity: EStatEntity.RentDeposits,
        average: {
            dividend: "totalAmount",
            divisor: "count",
            label: "Gemiddelde bedrag huurwaarborg",
        },
        lines: [
            {
                key: "count",
                label: "Nog gevestigd",
            },
            {
                key: "totalAmount",
                label: "Nog gevestigd bedrag",
                color: EColor.AMOUNT,
                axis: ELineAxis.RIGHT,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.PaymentOrders,
        lines: [
            {
                key: "count",
                label: "Aangemaakt",
            },
            {
                key: "totalAmount",
                label: "Aangemaakt bedrag",
                color: EColor.AMOUNT,
                axis: ELineAxis.RIGHT,
            },
        ],
    },
    {
        entity: EStatEntity.PaymentRequests,
        lines: [
            {
                key: "count",
                label: "Uitgestuurd",
            },
            {
                key: "totalAmount",
                label: "Bedrag uitgestuurd",
                color: EColor.AMOUNT,
                axis: ELineAxis.RIGHT,
            },
            {
                key: "totalAmountPaid",
                label: "Bedrag geind",
                color: EColor.ORANGE,
                axis: ELineAxis.RIGHT,
            },
        ],
    },
    {
        entity: EStatEntity.PayIns,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
            {
                key: "totalAmount",
                label: "Bedrag",
                color: EColor.AMOUNT,
                axis: ELineAxis.RIGHT,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Disputes,
        average: { dividend: "totalAmount", divisor: "count" },
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
            {
                key: "totalAmount",
                label: "Bedrag",
                color: EColor.RED,
                axis: ELineAxis.RIGHT,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.BankAccounts,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
];
var CHARTS_BACKOFFICE_PROFILES = [
    {
        entity: EStatEntity.ApplicationProfiles,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Applications,
        average: { dividend: "count", divisor: "numUsers" },
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
            {
                key: "numUsers",
                label: "Aantal kandidaten",
                color: EColor.ORANGE,
            },
        ],
        size: EChartSize.LARGE,
    },
];
var CHARTS_BACKOFFICE_SERVICES = [
    {
        entity: EStatEntity.Signatures,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.Registrations,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
    {
        entity: EStatEntity.PropertyInspections,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
    {
        entity: EStatEntity.MoveServices,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
    {
        entity: EStatEntity.RegisteredMail,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
    },
];
var CHARTS_BACKOFFICE_SUPPORT = [
    {
        entity: EStatEntity.PhoneCalls,
        average: {
            dividend: "totalDuration",
            divisor: "count",
            label: "Gem. duur",
        },
        lines: [
            {
                key: "count",
                label: "Gesprekken",
            },
            {
                key: "totalDuration",
                label: "Minuten",
                color: EColor.ORANGE,
                axis: ELineAxis.RIGHT,
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.PhoneCallsIncoming,
        average: {
            dividend: "totalDuration",
            divisor: "count",
            label: "Gem. duur",
        },
        lines: [
            {
                key: "count",
                label: "Gesprekken",
            },
            {
                key: "totalDuration",
                label: "Minuten",
                color: EColor.ORANGE,
                axis: ELineAxis.RIGHT,
            },
        ],
    },
    {
        entity: EStatEntity.PhoneCallsOutgoing,
        average: {
            dividend: "totalDuration",
            divisor: "count",
            label: "Gem. duur",
        },
        lines: [
            {
                key: "count",
                label: "Gesprekken",
            },
            {
                key: "totalDuration",
                label: "Minuten",
                color: EColor.ORANGE,
                axis: ELineAxis.RIGHT,
            },
        ],
    },
];
export var TABS_BACKOFFICE_RENTIO = [
    { name: ETab.GENERAL, charts: CHARTS_BACKOFFICE_GENERAL },
    { name: ETab.PROPERTIES, charts: CHART_BACKOFFICE_PROPERTIES },
    { name: ETab.FINANCIAL, charts: CHARTS_BACKOFFICE_FINANCIAL },
    { name: ETab.PROFILES, charts: CHARTS_BACKOFFICE_PROFILES },
    { name: ETab.SERVICES, charts: CHARTS_BACKOFFICE_SERVICES },
    { name: ETab.SUPPORT, charts: CHARTS_BACKOFFICE_SUPPORT },
];
/**
 * RENTIO INSURANCE Charts
 */
var CHARTS_INSURANCE_GENERAL = [
    {
        entity: EStatEntity.InsuranceQuotes,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.InsuranceTriggerLandingPageOpened,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.InsuranceTriggerQuoteCreated,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.InsuranceTriggerDocumentSigned,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
    {
        entity: EStatEntity.InsuranceTriggerPaymentSuccessful,
        lines: [
            {
                key: "count",
                label: "Aantal",
            },
        ],
        size: EChartSize.LARGE,
    },
];
export var TABS_BACKOFFICE_RENTIO_INSURANCE = [
    { name: ETab.GENERAL, charts: CHARTS_INSURANCE_GENERAL },
];
export var STAT_DURATIONS = [
    EStatDuration.Custom,
    EStatDuration.Today,
    EStatDuration.ThisWeek,
    EStatDuration.Last7Days,
    EStatDuration.ThisMonth,
    EStatDuration.Last30Days,
    EStatDuration.ThisYear,
    EStatDuration.Last365Days,
    EStatDuration.All,
];
export var STAT_INTERVALS_PER_DURATION = (_a = {},
    _a[EStatDuration.Custom] = [
        EStatInterval.Hourly,
        EStatInterval.Daily,
        EStatInterval.Weekly,
        EStatInterval.Monthly,
        EStatInterval.Yearly,
    ],
    _a[EStatDuration.Today] = [EStatInterval.Hourly],
    _a[EStatDuration.ThisWeek] = [EStatInterval.Daily],
    _a[EStatDuration.Last7Days] = [EStatInterval.Daily],
    _a[EStatDuration.ThisMonth] = [EStatInterval.Weekly, EStatInterval.Daily],
    _a[EStatDuration.Last30Days] = [EStatInterval.Weekly, EStatInterval.Daily],
    _a[EStatDuration.ThisYear] = [EStatInterval.Monthly, EStatInterval.Weekly],
    _a[EStatDuration.Last365Days] = [EStatInterval.Monthly, EStatInterval.Weekly],
    _a[EStatDuration.All] = [EStatInterval.Monthly, EStatInterval.Yearly],
    _a);
