var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled, { FontWeights, css } from "@rentiohq/web-theme";
import { rem, transitions } from "polished";
var DropzoneHover = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    border-color: ".concat(theme.colors.neutral90, ";\n  ");
});
export var Dropzone = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  position: relative;\n  min-height: 96px;\n  border-radius: ", ";\n  cursor: pointer;\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  &:hover {\n    ", "\n  }\n  &:focus {\n    outline: none;\n  }\n"], ["\n  ", ";\n  position: relative;\n  min-height: 96px;\n  border-radius: ", ";\n  cursor: pointer;\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  &:hover {\n    ", "\n  }\n  &:focus {\n    outline: none;\n  }\n"])), transitions("background 250ms ease-in, border 250ms ease-in, opacity 250ms ease-in"), function (_a) {
    var theme = _a.theme;
    return theme.borderRadius();
}, function (_a) {
    var theme = _a.theme, props = __rest(_a, ["theme"]);
    return props.isEmpty ? "border: 1px dashed ".concat(theme.colors.neutral50) : "";
}, function (_a) {
    var theme = _a.theme, props = __rest(_a, ["theme"]);
    return props.isDraggingOver &&
        props.isEmpty &&
        "\n      ".concat(DropzoneHover, "\n    ");
}, function (_a) {
    var theme = _a.theme, props = __rest(_a, ["theme"]);
    return props.isDisabled &&
        props.isEmpty &&
        "\n      background: ".concat(theme.colors.neutral20, ";\n      opacity: 0.4;\n    ");
}, function (props) {
    return props.hasError
        ? "background: ".concat(props.theme.getColor("red", 0), "; border-color:  ").concat(props.theme.getColor("red"), ";")
        : "";
}, DropzoneHover);
export var DropzonePlaceholder = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: ", ";\n  right: ", ";\n  transform: translate(0, -50%);\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.02em;\n  text-align: center;\n"], ["\n  position: absolute;\n  top: 50%;\n  left: ", ";\n  right: ", ";\n  transform: translate(0, -50%);\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.02em;\n  text-align: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.spacing();
}, function (_a) {
    var theme = _a.theme;
    return theme.spacing();
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral50;
}, function (_a) {
    var theme = _a.theme;
    return rem("14px", theme.baseFontSize);
}, FontWeights.SemiBold);
Dropzone.defaultProps = {
    state: "",
    isEmpty: true,
};
var templateObject_1, templateObject_2, templateObject_3;
