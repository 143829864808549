/* eslint-disable @typescript-eslint/no-var-requires */
const ICO_CAR = require("./images/ico_car.svg");
const ICO_CHECK = require("./images/ico_check.svg");
const ICO_DROPDOWN = require("./images/ico_dropdown.svg");
const ICO_HOUSEHOLD_EFFECTS = require("./images/ico_household_effects.svg");
const ICO_LAW = require("./images/ico_law.svg");
const ICO_PROFILE = require("./images/ico_profile.svg");
const ICO_PROPERTY_APARTMENT = require("./images/ico_property_apartment.svg");
const ICO_PROPERTY_HOUSE = require("./images/ico_property_house.svg");
const ICO_THEFT = require("./images/ico_theft.svg");

export default {
  ICO_CAR,
  ICO_CHECK,
  ICO_DROPDOWN,
  ICO_HOUSEHOLD_EFFECTS,
  ICO_LAW,
  ICO_PROFILE,
  ICO_PROPERTY_APARTMENT,
  ICO_PROPERTY_HOUSE,
  ICO_THEFT,
};
