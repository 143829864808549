var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import * as t from "../../services/translationService";
import { StyledSearchMoreLink, StyledSearchResultLink, StyledSearchResultMeta, } from "./SearchList.styled";
export var SearchItemProperty = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler, searchValue = _a.searchValue;
    return (_jsxs(_Fragment, { children: [data.slice(0, 10).map(function (item, index) {
                var link = "/properties/".concat(item.id);
                var archivedAt = item.archivedAt;
                var isArchived = !!archivedAt;
                return (_jsxs(StyledSearchResultLink, __assign({ to: link, onClick: linkHandler, subdued: isArchived }, { children: [_jsxs("div", { children: [formatAddress(item), isArchived && _jsxs(_Fragment, { children: [" (", t.system("archived"), ")"] })] }), _jsx(StyledSearchResultMeta, { children: item.name })] }), "property".concat(index)));
            }), data.length > 10 && (_jsx(StyledSearchMoreLink, __assign({ to: "/properties?query=".concat(encodeURIComponent(searchValue)), onClick: linkHandler }, { children: t.searchMore() })))] }));
};
