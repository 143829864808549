var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { transparentize } from "polished";
import { StyledIcon as Icon } from "../Icon/Icon.styled";
export var StyledCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  ", ";\n  ", ";\n\n  & + & {\n    margin-top: ", ";\n  }\n\n  /* ", " {\n    margin-left: auto;\n    margin-right: auto;\n  } */\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  ", ";\n  ", ";\n\n  & + & {\n    margin-top: ", ";\n  }\n\n  /* ", " {\n    margin-left: auto;\n    margin-right: auto;\n  } */\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, borderRadius(), function (_a) {
    var align = _a.align, hasBorder = _a.hasBorder;
    if (!hasBorder) {
        return align ? "text-align: ".concat(align, ";") : "";
    }
}, function (_a) {
    var theme = _a.theme, appearance = _a.appearance, hasBorder = _a.hasBorder;
    switch (appearance) {
        case "highlighted":
            return "\n          background-color: ".concat(theme.getColor("primary", 0), ";\n          // color: ").concat(theme.getColor("primary", 0), ";\n          ").concat(hasBorder
                ? "\n          box-shadow: 0 0 0 1px ".concat(transparentize(0.9, theme.colors.neutral80), ", 0 1px 3px 0  ").concat(transparentize(0.85, theme.colors.neutral80), ";")
                : "", "\n            \n        ");
        case "subdued":
            return "\n          background-color: ".concat(theme.getColor("gray", 0), ";\n        ");
        default:
            return hasBorder
                ? "\n          box-shadow: 0 0 0 1px ".concat(transparentize(0.9, theme.colors.neutral80), ", 0 1px 3px 0  ").concat(transparentize(0.85, theme.colors.neutral80), ";\n            \n            // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);\n            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.06);\n        ")
                : "";
    }
}, spacing("extraLoose"), Icon);
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var space = _a.space;
    return "padding: ".concat(spacing(space), " ").concat(spacing(space), " 0;");
});
export var Section = styled(function (props) { return _jsx(Box, __assign({}, props)); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n  & + &:not(.no-border) {\n    border-top: 1px solid ".concat(theme.colors.neutral30, ";\n  }");
});
export var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var space = _a.space;
    return "padding: 0 ".concat(spacing(space), " ").concat(spacing(space), ";");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
