import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { renderActions } from "../../Button";
import * as S from "../Modal.styled";
export var ModalFooter = function (props) {
    var _a = props.actions, actions = _a === void 0 ? [] : _a, component = props.component, onClose = props.onClose;
    if (!component && actions.length === 0) {
        return null;
    }
    if (component && actions.length > 0) {
        return null;
    }
    if (component) {
        // @ts-ignore
        return React.createElement(component, {
            onClose: onClose,
        });
    }
    return (_jsxs(S.Footer, { children: [_jsx("span", {}), actions.length > 0 &&
                renderActions(actions, {
                    alignment: "center",
                })] }));
};
