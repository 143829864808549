var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var Icon = styled(function (props) { return _jsx(Icon, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  margin-right: ", ";\n"], ["\n  display: inline-block;\n  margin-right: ", ";\n"])), spacing("extraTight"));
export var Dot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  margin-right: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  ", ";\n"], ["\n  display: inline-block;\n  margin-right: ", ";\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  ", ";\n"])), spacing("extraTight"), function (props) { return rem(8, props.theme.baseFontSize); }, function (props) { return rem(8, props.theme.baseFontSize); }, function (props) {
    switch (props.variation) {
        case "success":
            return "background-color: ".concat(props.theme.getColor("primary", 600), ";");
        case "error":
            return "background-color: ".concat(props.theme.getColor("red", 600), ";");
        case "service":
            return "background-color: ".concat(props.theme.getColor("blue", 1000), ";");
    }
});
var templateObject_1, templateObject_2;
