var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import keyBy from "lodash/keyBy";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { createReducer } from "redux-act";
import * as chatActions from "./chat.actions";
import { EChatFetchType } from "./chat.types";
export var REDUCER_KEY_CHAT = "chat";
var getDefaultState = function () { return ({
    chats: {},
    isFetchingChats: false,
    fetchChatsError: undefined,
    isExhausted: false,
    // Chats by identifier
    chatsByIdentifier: {},
    createdChat: undefined,
    isCreatingChat: false,
    createChatError: undefined,
    isUpdating: [],
    updateError: {},
    searchQuery: undefined,
    isSearchingChats: false,
    searchChatsError: undefined,
    searchedChats: [],
    messages: {},
    messagesRequests: {},
    typing: [],
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(chatActions.getChats.types.START, function (state, _a) {
    var _b;
    var _c = _a.chatsIdentifier, chatsIdentifier = _c === void 0 ? EChatFetchType.All : _c;
    var prevStateForIdentifier = state.chatsByIdentifier[chatsIdentifier] || {};
    return __assign(__assign({}, state), { isFetchingChats: true, fetchChatsError: undefined, chatsByIdentifier: __assign(__assign({}, state.chatsByIdentifier), (_b = {}, _b[chatsIdentifier] = __assign(__assign({ chatIds: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(chatActions.getChats.types.SUCCESS, function (state, _a) {
    var _b;
    var _c = _a.chatsIdentifier, chatsIdentifier = _c === void 0 ? EChatFetchType.All : _c, isExhausted = _a.isExhausted, refetch = _a.refetch, chats = _a.chats;
    var prevStateForIdentifier = state.chatsByIdentifier[chatsIdentifier] || {};
    var chatIds = chats.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { 
        // chats: refetch ? chats : [...(state.chats || []), ...chats],
        chats: __assign(__assign({}, state.chats), keyBy(chats, "id")), isFetchingChats: false, fetchChatsError: undefined, isExhausted: isExhausted, chatsByIdentifier: __assign(__assign({}, state.chatsByIdentifier), (_b = {}, _b[chatsIdentifier] = __assign(__assign({}, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, chatIds: refetch
                ? chatIds
                : __spreadArray(__spreadArray([], (prevStateForIdentifier.chatIds || []), true), chatIds, true) }), _b)) });
});
reducer.on(chatActions.getChats.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingChats: false, fetchChatsError: error }));
});
reducer.on(chatActions.searchChats.types.START, function (state, _a) {
    var query = _a.query;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingChats: true, searchChatsError: undefined }));
});
reducer.on(chatActions.searchChats.types.SUCCESS, function (state, _a) {
    var query = _a.query, chats = _a.chats;
    return (__assign(__assign({}, state), { searchQuery: query, isFetchingChats: false, searchChatsError: undefined, searchedChats: chats }));
});
reducer.on(chatActions.searchChats.types.FAILURE, function (state, _a) {
    var query = _a.query, error = _a.error;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingChats: false, searchChatsError: error }));
});
reducer.on(chatActions.createChat.types.START, function (state) { return (__assign(__assign({}, state), { createdChat: undefined, isCreatingChat: true, createChatError: undefined })); });
reducer.on(chatActions.createChat.types.SUCCESS, function (state, chat) {
    var _a, _b;
    var prevStateForIdentifier = state.chatsByIdentifier[EChatFetchType.All] || {};
    return __assign(__assign({}, state), { chats: __assign((_a = {}, _a[chat.id] = chat, _a), state.chats), createdChat: chat, isCreatingChat: false, createChatError: undefined, chatsByIdentifier: __assign(__assign({}, state.chatsByIdentifier), (_b = {}, _b[EChatFetchType.All] = __assign(__assign({}, prevStateForIdentifier), { chatIds: __spreadArray([chat.id], (prevStateForIdentifier.chatIds || []), true) }), _b)) });
});
reducer.on(chatActions.createChat.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingChat: false, createChatError: error }));
});
reducer.on(chatActions.requestForMessages, function (state, _a) {
    var _b;
    var ref = _a.ref, amountRequested = _a.amountRequested, chatId = _a.chatId;
    return __assign(__assign({}, state), { messagesRequests: __assign(__assign({}, state.messagesRequests), (_b = {}, _b[ref] = {
            amountRequested: amountRequested,
            chatId: chatId,
        }, _b)) });
});
reducer.on(chatActions.requestForMessagesFulfilled, function (state, _a) {
    var ref = _a.ref;
    var messagesRequests = __assign({}, state.messagesRequests);
    delete messagesRequests[ref];
    return __assign(__assign({}, state), { messagesRequests: messagesRequests });
});
reducer.on(chatActions.setChatIsExhausted, function (state, _a) {
    var _b;
    var chatId = _a.chatId;
    return __assign(__assign({}, state), { chats: __assign(__assign({}, state.chats), (_b = {}, _b[chatId] = __assign(__assign({}, state.chats[chatId]), { isExhausted: true }), _b)) });
});
reducer.on(chatActions.messagesReceived, function (state, messages) {
    var _a;
    if (messages.length === 0) {
        return state;
    }
    var chatId = messages[0].chatId;
    // Merge new messages with old messages
    var allMessages = __spreadArray(__spreadArray([], (state.messages[chatId] || []), true), messages, true);
    var filteredMessagesObject = allMessages.reduce(function (accumulator, message) {
        accumulator[message.id] = message;
        return accumulator;
    }, {});
    // Get array from unique messages object
    var filteredMessages = Object.values(filteredMessagesObject);
    return __assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_a = {}, _a[chatId] = reverse(sortBy(filteredMessages, ["sendAt"])), _a)), typing: state.typing.filter(function (randomChatId) { return randomChatId !== chatId; }) });
});
reducer.on(chatActions.isTypingReceived, function (state, typingInfo) { return (__assign(__assign({}, state), { typing: uniq(__spreadArray([typingInfo.chatId], state.typing, true)) })); });
reducer.on(chatActions.counterInfoReceived, function (state, counterInfo) {
    var _a;
    return (__assign(__assign({}, state), { chats: __assign(__assign({}, state.chats), (_a = {}, _a[counterInfo.chatId] = __assign(__assign({}, state.chats[counterInfo.chatId]), { unreadCounter: counterInfo.unread }), _a)) }));
});
reducer.on(chatActions.stopTyping, function (state, typingInfo) { return (__assign(__assign({}, state), { typing: state.typing.filter(function (chatId) { return chatId !== typingInfo.chatId; }) })); });
// TODO: Spaghetti
reducer.on(chatActions.statusInfoReceived, function (state, _a) {
    var _b;
    var chatId = _a.chatId, chatMessageId = _a.chatMessageId, accountId = _a.accountId, readAt = _a.readAt, deliveredAt = _a.deliveredAt;
    return (__assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_b = {}, _b[chatId] = reverse(sortBy(__spreadArray([], (state.messages[chatId] || []).map(function (message) {
            if (message.id === chatMessageId) {
                return __assign(__assign({}, message), { status: message.status.map(function (status) {
                        if (status.accountId === accountId) {
                            return {
                                accountId: accountId,
                                readAt: readAt,
                                deliveredAt: deliveredAt,
                            };
                        }
                        return status;
                    }) });
            }
            return message;
        }), true), ["sendAt"])), _b)) }));
});
// TODO: Spaghetti
reducer.on(chatActions.sendReadReceipt.types.START, function (state, _a) {
    var _b;
    var chatId = _a.chatId, messageId = _a.messageId, accountId = _a.accountId;
    return (__assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_b = {}, _b[chatId] = reverse(sortBy(__spreadArray([], (state.messages[chatId] || []).map(function (message) {
            if (message.id === messageId) {
                return __assign(__assign({}, message), { status: message.status.map(function (status) {
                        if (status.accountId === accountId) {
                            return {
                                accountId: accountId,
                                readAt: new Date(),
                                deliveredAt: new Date(),
                            };
                        }
                        return status;
                    }) });
            }
            return message;
        }), true), ["sendAt"])), _b)) }));
});
reducer.on(chatActions.getChatDetail.types.SUCCESS, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { chats: __assign(__assign({}, state.chats), (_a = {}, _a[payload.chat.id] = payload.chat, _a)) }));
});
reducer.on(chatActions.updateChatMembers.types.START, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([payload.chatId], state.isUpdating, true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = undefined, _a)) }));
});
reducer.on(chatActions.updateChatMembers.types.SUCCESS, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([], state.isUpdating.filter(function (id) { return id !== payload.chatId; }), true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = undefined, _a)) }));
});
reducer.on(chatActions.updateChatMembers.types.FAILURE, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([], state.isUpdating.filter(function (id) { return id !== payload.chatId; }), true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = payload.error, _a)) }));
});
reducer.on(chatActions.updateChat.types.START, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([payload.chatId], state.isUpdating, true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = undefined, _a)) }));
});
reducer.on(chatActions.updateChat.types.SUCCESS, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([], state.isUpdating.filter(function (id) { return id !== payload.chatId; }), true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = undefined, _a)) }));
});
reducer.on(chatActions.updateChat.types.FAILURE, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { isUpdating: __spreadArray([], state.isUpdating.filter(function (id) { return id !== payload.chatId; }), true), updateError: __assign(__assign({}, state.updateError), (_a = {}, _a[payload.chatId] = payload.error, _a)) }));
});
reducer.on(chatActions.getChatsByContactId, function (state, _a) {
    var _b;
    var chatsIdentifier = _a.chatsIdentifier;
    var prevStateForIdentifier = state.chatsByIdentifier[chatsIdentifier] || {};
    return __assign(__assign({}, state), { isFetchingChats: true, fetchChatsError: undefined, chatsByIdentifier: __assign(__assign({}, state.chatsByIdentifier), (_b = {}, _b[chatsIdentifier] = __assign(__assign({ chatIds: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: true, fetchError: undefined }), _b)) });
});
export default reducer;
