// TODO: Remove in shared redux types
export var ETemplateDocumentStatus;
(function (ETemplateDocumentStatus) {
    ETemplateDocumentStatus["New"] = "NEW";
    ETemplateDocumentStatus["Generating"] = "GENERATING";
    ETemplateDocumentStatus["Generated"] = "GENERATED";
    ETemplateDocumentStatus["Failed"] = "FAILED";
})(ETemplateDocumentStatus || (ETemplateDocumentStatus = {}));
export var EContractPaydate;
(function (EContractPaydate) {
    EContractPaydate["Calendar"] = "calendar";
    EContractPaydate["Contract"] = "contract";
})(EContractPaydate || (EContractPaydate = {}));
export var EContractMemberTypes;
(function (EContractMemberTypes) {
    EContractMemberTypes["Owner"] = "OWNER";
    EContractMemberTypes["Admin"] = "ADMIN";
    EContractMemberTypes["Tenant"] = "TENANT";
    EContractMemberTypes["Parent"] = "PARENT";
    EContractMemberTypes["Broker"] = "BROKER";
    EContractMemberTypes["Signer"] = "SIGNER";
    EContractMemberTypes["Payer"] = "PAYER";
    EContractMemberTypes["Receiver"] = "RECEIVER";
    // TODO: Partial enum
})(EContractMemberTypes || (EContractMemberTypes = {}));
export var EContractPeriodTypes;
(function (EContractPeriodTypes) {
    EContractPeriodTypes["Future"] = "future";
    EContractPeriodTypes["Current"] = "current";
    EContractPeriodTypes["Past"] = "finished";
})(EContractPeriodTypes || (EContractPeriodTypes = {}));
export var EContractType;
(function (EContractType) {
    EContractType["Basic"] = "basic";
    EContractType["RentDeposit"] = "rent_deposit";
    EContractType["RentDepositRelease"] = "rent_deposit_release";
})(EContractType || (EContractType = {}));
export var EMandateContractBrokerageFeeType;
(function (EMandateContractBrokerageFeeType) {
    EMandateContractBrokerageFeeType["Rent"] = "rent";
    EMandateContractBrokerageFeeType["Percentage"] = "percentage";
})(EMandateContractBrokerageFeeType || (EMandateContractBrokerageFeeType = {}));
export var EContractCostsPriceType;
(function (EContractCostsPriceType) {
    EContractCostsPriceType["Fixed"] = "FIXED";
    EContractCostsPriceType["Provision"] = "PROVISION";
})(EContractCostsPriceType || (EContractCostsPriceType = {}));
export var EContractIndexationStatus;
(function (EContractIndexationStatus) {
    EContractIndexationStatus["Ready"] = "READY";
    EContractIndexationStatus["NotReady"] = "NOT_READY";
    EContractIndexationStatus["OwnerAsked"] = "OWNER_ASKED";
    EContractIndexationStatus["Muted"] = "MUTED";
})(EContractIndexationStatus || (EContractIndexationStatus = {}));
export var EContractPaymentRepetitionType;
(function (EContractPaymentRepetitionType) {
    EContractPaymentRepetitionType["Monthly"] = "MONTHLY";
    EContractPaymentRepetitionType["Yearly"] = "YEARLY";
    EContractPaymentRepetitionType["Quarterly"] = "QUARTERLY";
})(EContractPaymentRepetitionType || (EContractPaymentRepetitionType = {}));
export var EInsuranceExternalStatus;
(function (EInsuranceExternalStatus) {
    EInsuranceExternalStatus["None"] = "NONE";
    EInsuranceExternalStatus["Created"] = "CREATED";
    EInsuranceExternalStatus["Pending"] = "PENDING";
    EInsuranceExternalStatus["Completed"] = "COMPLETED";
    EInsuranceExternalStatus["Failed"] = "FAILED";
})(EInsuranceExternalStatus || (EInsuranceExternalStatus = {}));
export var EMoveServiceStatus;
(function (EMoveServiceStatus) {
    EMoveServiceStatus["None"] = "NONE";
    EMoveServiceStatus["Sent"] = "SENT";
    EMoveServiceStatus["PendingByBroker"] = "PENDING_BY_BROKER";
    EMoveServiceStatus["Pending"] = "PENDING";
    EMoveServiceStatus["Completed"] = "COMPLETED";
})(EMoveServiceStatus || (EMoveServiceStatus = {}));
export var ELeaseType;
(function (ELeaseType) {
    ELeaseType["MainResidence"] = "main_residence";
    ELeaseType["StudentHousing"] = "student_housing";
    ELeaseType["CommercialLease"] = "commercial_lease";
    ELeaseType["Other"] = "other";
})(ELeaseType || (ELeaseType = {}));
export var EContractLengthType;
(function (EContractLengthType) {
    EContractLengthType["Short"] = "short";
    EContractLengthType["Long"] = "long";
})(EContractLengthType || (EContractLengthType = {}));
export var EIndexInfoErrorType;
(function (EIndexInfoErrorType) {
    EIndexInfoErrorType["Error"] = "ERROR";
    EIndexInfoErrorType["Warning"] = "WARNING";
})(EIndexInfoErrorType || (EIndexInfoErrorType = {}));
export var EIndexErrorCode;
(function (EIndexErrorCode) {
    EIndexErrorCode["TooEarly"] = "TOO_EARLY";
    EIndexErrorCode["MissingStartIndex"] = "MISSING_START_INDEX";
    EIndexErrorCode["MissingIndex"] = "MISSING_INDEX";
    EIndexErrorCode["MissingIndexableAt"] = "MISSING_INDEXABLE_AT";
})(EIndexErrorCode || (EIndexErrorCode = {}));
export var EEpcBasedIndexationType;
(function (EEpcBasedIndexationType) {
    EEpcBasedIndexationType["Regular"] = "regular";
    EEpcBasedIndexationType["Restricted"] = "restricted";
    EEpcBasedIndexationType["Corrected"] = "corrected";
})(EEpcBasedIndexationType || (EEpcBasedIndexationType = {}));
export var EContractIbanisationStatus;
(function (EContractIbanisationStatus) {
    EContractIbanisationStatus["None"] = "NONE";
    EContractIbanisationStatus["Disabled"] = "DISABLED";
    EContractIbanisationStatus["Enabling"] = "ENABLING";
    EContractIbanisationStatus["EnablingFailed"] = "ENABLING_FAILED";
    EContractIbanisationStatus["Enabled"] = "ENABLED";
})(EContractIbanisationStatus || (EContractIbanisationStatus = {}));
export var VAT_PERCENTAGES = [0, 21, 12, 6];
export var ELegalCaseContractStatus;
(function (ELegalCaseContractStatus) {
    ELegalCaseContractStatus["NotActivated"] = "not_activated";
    ELegalCaseContractStatus["Activated"] = "activated";
    ELegalCaseContractStatus["Created"] = "created";
    ELegalCaseContractStatus["Closed"] = "closed";
})(ELegalCaseContractStatus || (ELegalCaseContractStatus = {}));
export var ERegion;
(function (ERegion) {
    ERegion["Flanders"] = "V";
    ERegion["Wallonia"] = "W";
    ERegion["Brussels"] = "B";
})(ERegion || (ERegion = {}));
export var EContractTemplateMimeType;
(function (EContractTemplateMimeType) {
    EContractTemplateMimeType["pdf"] = "application/pdf";
    EContractTemplateMimeType["word"] = "application/word";
})(EContractTemplateMimeType || (EContractTemplateMimeType = {}));
