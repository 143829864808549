var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
import { Link } from "react-router-dom";
export var StyledLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("blue"), ";\n    &:hover,\n    &:focus {\n      color: ").concat(theme.getColor("blue", 1000), ";\n    }\n  ");
});
var templateObject_1;
