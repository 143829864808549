var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import uniq from "lodash/uniq";
import { createReducer } from "redux-act";
import { createForm, showErrors, storePartialFormData, updatePartialFormData, updatePartialFormDataBulk, } from "./form.actions";
export var REDUCER_KEY_FORM = "form";
var getDefaultState = function () { return ({}); };
var reducer = createReducer({}, getDefaultState());
// reducer.on("LOG_OUT", () => getDefaultState());
reducer.on(createForm, function (state, _a) {
    var _b;
    var formId = _a.formId, schemas = _a.schemas, withSummary = _a.withSummary;
    var partialFormData = {};
    schemas.forEach(function (schemaPair, index) {
        var schema = schemaPair.schema({});
        var partialFormDataStep = {};
        Object.keys(schema.properties || {}).forEach(function (property) {
            // @ts-ignore
            partialFormDataStep[property] = schema.properties[property].default;
        });
        partialFormData[index] = partialFormDataStep;
    });
    return __assign(__assign({}, state), (_b = {}, _b[formId] = {
        schemas: schemas,
        partialFormData: partialFormData,
        withSummary: withSummary,
        showErrors: [],
        schemaChangeCount: 0,
    }, _b));
});
reducer.on(showErrors, function (state, _a) {
    var _b;
    var formId = _a.formId, stepIndex = _a.stepIndex;
    return (__assign(__assign({}, state), (_b = {}, _b[formId] = __assign(__assign({}, state[formId]), { showErrors: uniq(__spreadArray(__spreadArray([], state[formId].showErrors, true), [stepIndex], false)) }), _b)));
});
reducer.on(storePartialFormData, function (state, _a) {
    var _b, _c;
    var formId = _a.formId, stepIndex = _a.stepIndex, formData = _a.formData, _d = _a.stepsToClearOnSubmitFromSummary, stepsToClearOnSubmitFromSummary = _d === void 0 ? [] : _d;
    var stepsToClearOnSubmitFromSummaryMapped = {};
    stepsToClearOnSubmitFromSummary.forEach(function (id) {
        stepsToClearOnSubmitFromSummaryMapped[id] = {};
    });
    return __assign(__assign({}, state), (_b = {}, _b[formId] = __assign(__assign({}, state[formId]), { partialFormData: __assign(__assign(__assign({}, state[formId].partialFormData), stepsToClearOnSubmitFromSummaryMapped), (_c = {}, _c[stepIndex] = formData, _c)), schemaChangeCount: state[formId].schemaChangeCount + 1 }), _b));
});
reducer.on(updatePartialFormData, function (state, _a) {
    var _b, _c, _d;
    var formId = _a.formId, stepIndex = _a.stepIndex, fieldName = _a.fieldName, fieldData = _a.fieldData;
    return (__assign(__assign({}, state), (_b = {}, _b[formId] = __assign(__assign({}, state[formId]), { partialFormData: __assign(__assign({}, state[formId].partialFormData), (_c = {}, _c[stepIndex] = __assign(__assign({}, state[formId].partialFormData[stepIndex]), (_d = {}, _d[fieldName] = fieldData, _d)), _c)) }), _b)));
});
reducer.on(updatePartialFormDataBulk, function (state, _a) {
    var _b, _c;
    var formId = _a.formId, stepIndex = _a.stepIndex, data = _a.data;
    return (__assign(__assign({}, state), (_b = {}, _b[formId] = __assign(__assign({}, state[formId]), { partialFormData: __assign(__assign({}, state[formId].partialFormData), (_c = {}, _c[stepIndex] = __assign(__assign({}, state[formId].partialFormData[stepIndex]), data), _c)) }), _b)));
});
export default reducer;
