var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { stringToPascalCaseWithSpaces } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { capitalize } from "lodash";
import { useInternalMode } from "../../redux/system/system.hooks";
var RentioInternalRenderer = function (props) {
    var items = props.items, _a = props.mapKeys, mapKeys = _a === void 0 ? true : _a;
    // Hooks
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    // Render
    if (!internalModeEnabled) {
        return null;
    }
    return (_jsxs("div", __assign({ style: {
            border: "2px solid cyan",
            backgroundColor: "rgba(0, 255, 255, 0.1)",
            padding: 4,
            margin: 4,
        } }, { children: [props.children, items
                ? Object.keys(items).map(function (key) {
                    var keyMapped = mapKeys
                        ? capitalize(stringToPascalCaseWithSpaces(key))
                        : key;
                    var value = items[key];
                    var valueMapped = value;
                    if (value instanceof Error) {
                        valueMapped = "".concat(value);
                    }
                    else if (value === true) {
                        valueMapped = "yes";
                    }
                    else if (value === false) {
                        valueMapped = "no";
                    }
                    return (_jsxs("div", { children: [_jsx("b", { children: keyMapped }), ": ", valueMapped ? "".concat(valueMapped) : "-"] }, key));
                })
                : null] })));
};
export default RentioInternalRenderer;
