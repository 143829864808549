var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights, borderRadius, css, resetList, spacing, } from "@rentiohq/web-theme";
import { rem } from "polished";
// Form
export var FormContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: 5;\n"], ["\n  position: relative;\n  z-index: 5;\n"])));
export var Field = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & ~ & {\n    margin-top: ", ";\n  }\n"], ["\n  & ~ & {\n    margin-top: ", ";\n  }\n"])), spacing());
export var Actions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), spacing("loose"));
// Summary
export var Step = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", "px;\n  }\n"], ["\n  & + & {\n    margin-top: ", "px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.baseUnit * 10;
});
// Aside
var currentColorLine = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-repeat: repeat-y;\n  background-image: linear-gradient(\n    to right,\n    currentColor 100%,\n    currentColor 0\n  );\n  background-size: 2px 2px;\n  background-position: 11px 0;\n"], ["\n  background-repeat: repeat-y;\n  background-image: linear-gradient(\n    to right,\n    currentColor 100%,\n    currentColor 0\n  );\n  background-size: 2px 2px;\n  background-position: 11px 0;\n"])));
export var List = styled.ol(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  counter-reset: section;\n  li {\n    &:last-child {\n      ol {\n        li:last-child {\n          padding-bottom: 0;\n        }\n      }\n    }\n  }\n"], ["\n  ", ";\n  counter-reset: section;\n  li {\n    &:last-child {\n      ol {\n        li:last-child {\n          padding-bottom: 0;\n        }\n      }\n    }\n  }\n"])), resetList);
export var AsideStep = styled.li(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  display: flex;\n  font-weight: ", ";\n  ", "\n  &::before {\n    width: ", ";\n    text-align: center;\n    display: inline-block;\n    margin-right: ", ";\n    font-family: ", ";\n    counter-increment: section;\n    content: counters(section, \".\") \" \";\n    background-color: ", ";\n    vertical-align: top;\n    align-self: flex-start;\n    flex: 0 0 auto;\n  }\n  & + & {\n    padding-top: ", ";\n  }\n  ", ";\n"], ["\n  position: relative;\n  width: 100%;\n  display: flex;\n  font-weight: ", ";\n  ", "\n  &::before {\n    width: ", ";\n    text-align: center;\n    display: inline-block;\n    margin-right: ", ";\n    font-family: ", ";\n    counter-increment: section;\n    content: counters(section, \".\") \" \";\n    background-color: ", ";\n    vertical-align: top;\n    align-self: flex-start;\n    flex: 0 0 auto;\n  }\n  & + & {\n    padding-top: ", ";\n  }\n  ", ";\n"])), FontWeights.Bold, currentColorLine, function (_a) {
    var theme = _a.theme;
    return rem(24, theme.baseFontSize);
}, spacing(), function (props) { return props.theme.fonts.financial; }, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, spacing("extraLoose"), function (_a) {
    var theme = _a.theme, isPassed = _a.isPassed, isCurrent = _a.isCurrent;
    if (isPassed && !isCurrent) {
        return "\n        color: ".concat(theme.getColor("gray", 1000), ";\n        cursor: pointer;\n      ");
    }
    else if (isPassed && isCurrent) {
        return "\n        background-image: linear-gradient(to right, ".concat(theme.getColor("gray", 1000), " 100%, ").concat(theme.getColor("gray", 1000), " 0);\n        color: ").concat(theme.getColor("primary"), ";\n      ");
    }
    else {
        return "color: ".concat(theme.getColor("gray", 400), ";");
    }
});
export var ArrayFieldWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  & + & {\n    margin-top: ", ";\n  }\n"])), spacing("loose"));
export var ArrayField = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  & + & {\n    margin-top: ", ";\n  }\n  ", ";\n  ", "\n"], ["\n  display: flex;\n  & + & {\n    margin-top: ", ";\n  }\n  ", ";\n  ", "\n"])), spacing("loose"), function (_a) {
    var hasAdd = _a.hasAdd, theme = _a.theme;
    return hasAdd ? "margin-bottom: ".concat(theme.spacing("loose"), ";") : "";
}, function (_a) {
    var isContact = _a.isContact, isValid = _a.isValid, theme = _a.theme;
    return isContact
        ? "\n    padding: ".concat(spacing("loose"), " ").concat(spacing(), ";\n    background-color: ").concat(isValid ? theme.getColor("gray", 0) : theme.getColor("red", 0), ";\n    border-radius: ").concat(borderRadius(), ";\n  ")
        : "";
});
export var ArrayFieldRemove = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: ", ";\n  margin-right: ", ";\n"], ["\n  width: ", ";\n  margin-right: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("24px", theme.baseFontSize);
}, spacing());
export var ArrayFieldContent = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
