var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as S from "./Spacer.styles";
export var ESpacerWeight;
(function (ESpacerWeight) {
    ESpacerWeight[ESpacerWeight["W02"] = 2] = "W02";
    ESpacerWeight[ESpacerWeight["W04"] = 4] = "W04";
    ESpacerWeight[ESpacerWeight["W08"] = 8] = "W08";
    ESpacerWeight[ESpacerWeight["W10"] = 10] = "W10";
    ESpacerWeight[ESpacerWeight["W12"] = 12] = "W12";
    ESpacerWeight[ESpacerWeight["W16"] = 16] = "W16";
    ESpacerWeight[ESpacerWeight["W20"] = 20] = "W20";
    ESpacerWeight[ESpacerWeight["W24"] = 24] = "W24";
    ESpacerWeight[ESpacerWeight["W32"] = 32] = "W32";
})(ESpacerWeight || (ESpacerWeight = {}));
export var ESpacerDirection;
(function (ESpacerDirection) {
    ESpacerDirection[ESpacerDirection["Horizontal"] = 0] = "Horizontal";
    ESpacerDirection[ESpacerDirection["Vertical"] = 1] = "Vertical";
})(ESpacerDirection || (ESpacerDirection = {}));
var Spacer = /** @class */ (function (_super) {
    __extends(Spacer, _super);
    function Spacer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Lifecycle methods
    Spacer.prototype.render = function () {
        var _a = this.props, weight = _a.weight, direction = _a.direction, rest = __rest(_a, ["weight", "direction"]);
        // TODO:
        // @ts-ignore
        return _jsx(S.Wrap, __assign({ style: Spacer.getStyle(weight, direction) }, rest));
    };
    // Helpers
    Spacer.getStyle = function (weight, direction) {
        if (direction === void 0) { direction = ESpacerDirection.Vertical; }
        switch (direction) {
            case ESpacerDirection.Horizontal:
                return {
                    width: weight,
                    height: "100%",
                };
            case ESpacerDirection.Vertical:
            default:
                return {
                    width: "100%",
                    height: weight,
                };
        }
    };
    return Spacer;
}(React.PureComponent));
export default Spacer;
