var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./Fieldset.styled";
export var Fieldset = function (_a) {
    var children = _a.children, legend = _a.legend, _b = _a.legendSize, legendSize = _b === void 0 ? "medium" : _b, rest = __rest(_a, ["children", "legend", "legendSize"]);
    return (_jsxs(S.Fieldset, __assign({ as: "fieldset" }, rest, { children: [legend && _jsx(S.Legend, __assign({ size: legendSize }, { children: legend })), children] })));
};
