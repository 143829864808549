var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var Overlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  pointer-events: none;\n  user-select: none;\n  opacity: ", ";\n  ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  pointer-events: none;\n  user-select: none;\n  opacity: ", ";\n  ", ";\n"])), function (_a) {
    var opacity = _a.opacity;
    return (opacity ? opacity : "0.5");
}, function (_a) {
    var theme = _a.theme, variation = _a.variation;
    switch (variation) {
        case "error":
            return "background-color: ".concat(theme.getColor("red", 0), ";");
        default:
            return "background-color: ".concat(theme.colors.neutral10, ";");
    }
});
var templateObject_1;
