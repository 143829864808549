import { formatAddress as formatAddressBase } from "../../utils/address.utils";
export var formatAddress = function (lead) {
    var address = {
        street: lead.propertyStreet,
        number: lead.propertyNumber,
        box: lead.propertyBox,
        zip: lead.propertyZipCode,
        city: lead.propertyCity,
    };
    return formatAddressBase(address);
};
