import styled, { css } from "@rentiohq/web-theme";

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SortImage = styled.img<{ isAscending: boolean }>`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  transform: ${props =>
    props.isAscending ? "rotate(180deg)" : "rotate(0deg)"};
  transition: ${props =>
    props.isAscending ? "0.15s ease-in" : "0.05s ease-out"};
`;

const HeaderButton = css`
  display: flex;
  align-items: center;
`;

export const DateWrap = styled.button`
  width: 110px;
  margin-right: 4px;
  ${HeaderButton}
`;

export const WIDTH_ID = 80;
export const IdWrap = styled.button`
  width: ${WIDTH_ID + 4}px;
  white-space: nowrap;
  margin-right: 12px;
  ${HeaderButton}
`;

export const WIDTH_ADDRESS = 220;
export const AddressWrap = styled.button`
  width: ${WIDTH_ADDRESS + 12}px;
  margin-right: 12px;
  ${HeaderButton}
`;

export const ContactWrap = styled.button`
  flex: 1.5;
  ${HeaderButton}
`;

export const ReferrerWrap = styled.button`
  flex: 1;
  ${HeaderButton}
`;

export const QuoteOptionsWrap = styled.button`
  width: 100px;
`;

export const PriceWrap = styled.button`
  width: 100px;
  margin-left: 12px;
  text-align: right;
  ${HeaderButton}
`;

export const StatusWrap = styled.button`
  width: 180px;
  margin-left: 12px;
  text-align: right;
  ${HeaderButton}
`;

export const OptionIcon = styled.img<{ isActive: boolean }>`
  width: 25px;
  height: 25px;
  margin: 1px;
  ${props =>
    props.isActive
      ? css``
      : css`
          filter: grayscale(100%);
          opacity: 0.7;
        `}
`;
