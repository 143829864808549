var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
export var Wrapper = styled(function (props) { return _jsx(Box, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var Body = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  height: 100%;\n"])));
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1 1 auto;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"], ["\n  flex: 1 1 auto;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
export var Image = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  width: ", ";\n  margin-left: ", ";\n"], ["\n  flex: 0 0 auto;\n  width: ", ";\n  margin-left: ", ";\n"])), rem("100px"), spacing("loose"));
export var Heading = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), spacing("loose"));
export var Buttons = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), spacing("loose"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
