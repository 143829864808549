var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Flex } from "@rebass/grid";
import styled from "@rentiohq/web-theme";
export var Grid = styled(function (props) { return _jsx(Flex, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & + & {\n    margin-top: 0;\n  }\n"], ["\n  & + & {\n    margin-top: 0;\n  }\n"])));
var templateObject_1;
