import { getCreateAction } from "./create.actions";
import { getDeleteAction } from "./delete.actions";
import { getGetDetailAction } from "./detail.actions";
import { getGetPagedAction } from "./paged.actions";
import { getUpdateAction } from "./update.actions";
export var getAllActions = function (params) { return ({
    create: getCreateAction(params),
    deleteObject: getDeleteAction(params),
    getDetail: getGetDetailAction(params),
    getPaged: getGetPagedAction(params),
    update: getUpdateAction(params),
}); };
