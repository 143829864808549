var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import * as loggerTypes from "@rentiohq/shared/dist/logger/logger.types";
import HttpStatus from "http-status-codes";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as socketActions from "../../redux/socket/socket.actions";
import { showAlert } from "../../utils/alert/alert.utils";
import { cancelPendingCalls } from "../../utils/api/api.utils";
import { getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import * as contactActions from "../contact/contact.actions";
import { updateContact } from "../contact/contact.api";
import { EContactCustomId } from "../contact/contact.types";
import * as authActions from "./auth.actions";
import * as authApi from "./auth.api";
import { getUser, updateUser } from "./auth.api";
import * as authSelectors from "./auth.selectors";
import * as authUtils from "./auth.utils";
function signOutFlow() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(authUtils.setAccessToken, undefined)];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(authUtils.setRefreshToken, undefined)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function signInStartFlow(_a) {
    var phoneOrEmail, password, data, accessToken, refreshToken, unknownError_1, error, errorMessage, content, axiosError;
    var _b;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                phoneOrEmail = payload.phoneOrEmail, password = payload.password;
                return [4 /*yield*/, call(authApi.signIn, {
                        emailOrPhone: phoneOrEmail,
                        password: password,
                        // captcha,
                        // captchaVersion,
                    })];
            case 1:
                data = (_c.sent()).data;
                accessToken = data.accessToken, refreshToken = data.refreshToken;
                return [4 /*yield*/, put(authActions.signIn.actions.success({
                        accessToken: accessToken,
                        refreshToken: refreshToken,
                    }))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                unknownError_1 = _c.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.signIn.actions.failure({ error: error }))];
            case 4:
                _c.sent();
                errorMessage = getLocalizedText("general.error");
                content = undefined;
                axiosError = error;
                if (axiosError.isAxiosError) {
                    switch ((_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.status) {
                        case HttpStatus.BAD_REQUEST:
                            errorMessage = getLocalizedText("system.wrong.password");
                            break;
                        case HttpStatus.PAYMENT_REQUIRED:
                            errorMessage = getLocalizedText("auth.sign_in.error.payment_required.title");
                            content = getLocalizedText("auth.sign_in.error.payment_required.content");
                            break;
                        default:
                            break;
                    }
                }
                showAlert({
                    type: "error",
                    message: errorMessage,
                    content: content,
                });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function signInAutomaticallyStartFlow(_a) {
    var token, data, accessToken, refreshToken, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                token = payload.token;
                return [4 /*yield*/, call(authApi.signInAutomatically, token)];
            case 1:
                data = (_b.sent()).data;
                accessToken = data.accessToken, refreshToken = data.refreshToken;
                return [4 /*yield*/, put(authActions.signInAutomatically.actions.success({
                        accessToken: accessToken,
                        refreshToken: refreshToken,
                    }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.signInAutomatically.actions.failure({ error: error }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function setAccessTokensWithoutSideEffectsFlow(_a) {
    var accessToken, refreshToken, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                accessToken = payload.accessToken, refreshToken = payload.refreshToken;
                cancelPendingCalls();
                return [4 /*yield*/, call(authUtils.setAccessToken, accessToken)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(authUtils.setRefreshToken, refreshToken)];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function signUpStartFlow(_a) {
    var locale, platform, firstname, lastname, email, phone, password, captcha, captchaVersion, tac, user, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                locale = payload.locale, platform = payload.platform, firstname = payload.firstname, lastname = payload.lastname, email = payload.email, phone = payload.phone, password = payload.password, captcha = payload.captcha, captchaVersion = payload.captchaVersion;
                return [4 /*yield*/, call(authApi.getTac, locale, platform)];
            case 1:
                tac = (_b.sent()).data;
                return [4 /*yield*/, call(authApi.signUp, {
                        locale: locale,
                        tacId: tac.id,
                        firstname: firstname,
                        lastname: lastname,
                        unverifiedPhone: phone,
                        unverifiedEmail: email,
                        password: password,
                        captcha: captcha,
                        captchaVersion: captchaVersion,
                    })];
            case 2:
                user = (_b.sent()).data;
                return [4 /*yield*/, put(authActions.signUp.actions.success({ user: user }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.signUp.actions.failure({ error: error }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function signUpVerifyPhoneStartFlow(_a) {
    var code, user, _b, _c, _d, status_1, data, accessToken, refreshToken, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 6, , 8]);
                code = payload.code;
                _c = (_b = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 1:
                user = _c.apply(_b, [_e.sent()]);
                if (!user) {
                    throw new Error("No user");
                }
                return [4 /*yield*/, call(authApi.verifyPhone, user.id, code)];
            case 2:
                _d = _e.sent(), status_1 = _d.status, data = _d.data;
                if (!(status_1 === HttpStatus.NO_CONTENT)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(authActions.signUpVerifyPhone.actions.failure({
                        error: new Error("Wrong code"),
                    }))];
            case 3:
                _e.sent();
                showAlert({
                    type: "error",
                    message: getLocalizedText("intake.verification.pincode.wrong"),
                });
                return [2 /*return*/];
            case 4:
                accessToken = data.accessToken, refreshToken = data.refreshToken;
                return [4 /*yield*/, put(authActions.signUpVerifyPhone.actions.success({
                        accessToken: accessToken,
                        refreshToken: refreshToken,
                    }))];
            case 5:
                _e.sent();
                return [3 /*break*/, 8];
            case 6:
                unknownError_5 = _e.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.signUpVerifyPhone.actions.failure({ error: error }))];
            case 7:
                _e.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function signUpVerifiedEmailStartFlow() {
    var user, unknownError_6, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getUser)];
            case 1:
                user = (_a.sent()).data;
                return [4 /*yield*/, put(authActions.getUser.actions.success(user))];
            case 2:
                _a.sent();
                logger.setValue({
                    type: loggerTypes.EValueType.UserEmail,
                    value: user.email || "",
                });
                if (user.unverifiedEmail) {
                    showAlert({
                        type: "error",
                        message: getLocalizedText("profile.email.unverified"),
                    });
                }
                return [3 /*break*/, 4];
            case 3:
                unknownError_6 = _a.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function signUpResendVerificationEmailStartFlow() {
    var user, _a, _b, unknownError_7, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 4]);
                _b = (_a = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 1:
                user = _b.apply(_a, [_c.sent()]);
                if (!user) {
                    throw new Error("No user");
                }
                if (!user.unverifiedEmail) {
                    throw new Error("No unverified email");
                }
                return [4 /*yield*/, call(authApi.resendVerificationEmail, user.unverifiedEmail)];
            case 2:
                _c.sent();
                showAlert({
                    type: "info",
                    message: getLocalizedText("verify_email.info.description", {
                        email: user.unverifiedEmail,
                    }),
                });
                return [3 /*break*/, 4];
            case 3:
                unknownError_7 = _c.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function getUserStartFlow(_) {
    var user, unknownError_8, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(getUser)];
            case 1:
                user = (_a.sent()).data;
                return [4 /*yield*/, put(authActions.getUser.actions.success(user))];
            case 2:
                _a.sent();
                logger.setValue({
                    type: loggerTypes.EValueType.UserEmail,
                    value: user.email || "",
                });
                return [3 /*break*/, 5];
            case 3:
                unknownError_8 = _a.sent();
                error = unknownError_8;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.getUser.actions.failure({ error: error }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function genericSignInSuccessFlow(_a) {
    var accessToken, refreshToken, unknownError_9, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 6]);
                accessToken = payload.accessToken, refreshToken = payload.refreshToken;
                cancelPendingCalls();
                return [4 /*yield*/, call(authUtils.setAccessToken, accessToken)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(authUtils.setRefreshToken, refreshToken)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(socketActions.openSocket.actions.start({}))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(authActions.getUser.actions.start({}))];
            case 4:
                _b.sent();
                return [3 /*break*/, 6];
            case 5:
                unknownError_9 = _b.sent();
                error = unknownError_9;
                logger.logError({ error: error });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function rehydrateFlow(_a) {
    var user, _b, _c, hasAccessToken, _d, _e, unknownError_10, error;
    var key = _a.key;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _f.trys.push([0, 5, , 6]);
                if (key !== "auth") {
                    return [2 /*return*/];
                }
                _c = (_b = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 1:
                user = _c.apply(_b, [_f.sent()]);
                if (user) {
                    logger.setValue({
                        type: loggerTypes.EValueType.UserEmail,
                        value: user.email || "",
                    });
                }
                _e = (_d = authSelectors).hasAccessToken;
                return [4 /*yield*/, select()];
            case 2:
                hasAccessToken = _e.apply(_d, [_f.sent()]);
                if (!hasAccessToken) return [3 /*break*/, 4];
                return [4 /*yield*/, put(socketActions.openSocket.actions.start({}))];
            case 3:
                _f.sent();
                _f.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                unknownError_10 = _f.sent();
                error = unknownError_10;
                logger.logError({ error: error });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateUserStartStartFlow(_a) {
    var user, contact, onSuccess, updatedContact, updatedUser, unknownError_11, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                user = payload.user, contact = payload.contact, onSuccess = payload.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 9, , 11]);
                if (!(contact === null || contact === void 0 ? void 0 : contact.id)) return [3 /*break*/, 4];
                return [4 /*yield*/, call(updateContact, contact.id, contact)];
            case 2:
                updatedContact = (_b.sent()).data;
                return [4 /*yield*/, put(contactActions.updateContact.actions.success({
                        contactId: updatedContact.id,
                        contact: updatedContact,
                    }))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4 /*yield*/, call(updateUser, user)];
            case 5:
                updatedUser = (_b.sent()).data;
                return [4 /*yield*/, put(authActions.updateUser.actions.success({
                        user: updatedUser,
                    }))];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(contactActions.getContact.actions.start({
                        contactId: EContactCustomId.MeMaster,
                    }))];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(contactActions.getContact.actions.start({
                        contactId: EContactCustomId.Me,
                    }))];
            case 8:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(updatedUser);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("user"),
                });
                return [3 /*break*/, 11];
            case 9:
                unknownError_11 = _b.sent();
                error = unknownError_11;
                logger.logError({ error: error });
                return [4 /*yield*/, put(authActions.updateUser.actions.failure({ error: error }))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery("LOG_OUT", signOutFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signIn.types.START, signInStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signInAutomatically.types.START, signInAutomaticallyStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.setAccessTokensWithoutSideEffects.getType(), setAccessTokensWithoutSideEffectsFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signUp.types.START, signUpStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signUpVerifyPhone.types.START, signUpVerifyPhoneStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signUpVerifiedEmail.getType(), signUpVerifiedEmailStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.signUpResendVerificationEmail.getType(), signUpResendVerificationEmailStartFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.getUser.types.START, getUserStartFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLatest([
                        authActions.signIn.types.SUCCESS,
                        authActions.signInAutomatically.types.SUCCESS,
                        authActions.signUpVerifyPhone.types.SUCCESS,
                    ], genericSignInSuccessFlow)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatest("persist/REHYDRATE", rehydrateFlow)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(authActions.updateUser.types.START, updateUserStartStartFlow)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
