export var isDateRange = function (toBeDetermined) {
    if (toBeDetermined && (toBeDetermined.end || toBeDetermined.start))
        return true;
    return false;
};
export var EFilterType;
(function (EFilterType) {
    EFilterType[EFilterType["MultiSelect"] = 0] = "MultiSelect";
    EFilterType[EFilterType["SingleSelect"] = 1] = "SingleSelect";
    EFilterType[EFilterType["MonthRange"] = 2] = "MonthRange";
    EFilterType[EFilterType["EmployeeSelect"] = 3] = "EmployeeSelect";
})(EFilterType || (EFilterType = {}));
export var EDateQuickFilters;
(function (EDateQuickFilters) {
    EDateQuickFilters["Today"] = "today";
    EDateQuickFilters["ThisWeek"] = "this_week";
    EDateQuickFilters["ThisMonth"] = "this_month";
    EDateQuickFilters["PreviousMonth"] = "previous_month";
    EDateQuickFilters["LastYear"] = "last_year";
    EDateQuickFilters["DateRange"] = "date_range";
})(EDateQuickFilters || (EDateQuickFilters = {}));
