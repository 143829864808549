import { REDUCER_KEY_COUNT } from "./count.reducer";
export var getCount = function (state, countIdentifier) {
    if (!countIdentifier) {
        return;
    }
    var countState = state.count[countIdentifier];
    if (!countState) {
        return;
    }
    return countState.count;
};
export var getIsFetchingCount = function (state, countIdentifier) {
    if (!countIdentifier) {
        return false;
    }
    var countState = state.count[countIdentifier];
    if (!countState) {
        return false;
    }
    return countState.isFetching;
};
export var getFetchCountError = function (state, countIdentifier) {
    if (!countIdentifier) {
        return;
    }
    var countState = state.count[countIdentifier];
    if (!countState) {
        return;
    }
    return countState.fetchError;
};
