var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { StyledSearchResultLink } from "./SearchList.styled";
export var SearchItemTask = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler;
    return (_jsx(_Fragment, { children: data.map(function (item, index) {
            var link = "/tasks/".concat(item.id);
            return (_jsx(StyledSearchResultLink, __assign({ to: link, onClick: linkHandler }, { children: _jsx("div", { children: item.title }) }), "contact".concat(index)));
        }) }));
};
