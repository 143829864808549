var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { animationEasing, animationSpeed, FontWeights, keyframes, spacing, } from "@rentiohq/web-theme";
import { rem } from "polished";
import { ToastContainer as Container } from "react-toastify";
import media from "styled-media-query";
import { VariationToColor } from "../../components/Banner/Banner.styled";
import { StyledIcon } from "../../components/Icon/Icon.styled";
export var VariationToBackgroundValue = {
    default: 200,
    info: 200,
    warning: 400,
    error: 200,
    success: 200,
};
export var VariationToBorderValue = {
    default: 400,
    info: 400,
    warning: 600,
    error: 400,
    success: 400,
};
var slideIn = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n  to {\n    opacity: 1;\n    transform: translateX(0);\n  }\n"], ["\n  from {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n  to {\n    opacity: 1;\n    transform: translateX(0);\n  }\n"])));
var slideOut = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  from {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  to {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n"], ["\n  from {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  to {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n"])));
export var ToastContainer = styled(function (props) { return _jsx(Container, __assign({}, props)); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: ", ";\n  right: 0;\n  padding: 16px;\n  z-index: 103;\n  width: 400px;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  ", "\n  .Toastify__toast.slideIn {\n    animation: ", " ", "\n      ", " forwards;\n  }\n  .Toastify__toast.slideOut {\n    animation: ", " ", "\n      ", " forwards;\n  }\n"], ["\n  position: fixed;\n  top: ", ";\n  right: 0;\n  padding: 16px;\n  z-index: 103;\n  width: 400px;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  ", "\n  .Toastify__toast.slideIn {\n    animation: ", " ", "\n      ", " forwards;\n  }\n  .Toastify__toast.slideOut {\n    animation: ", " ", "\n      ", " forwards;\n  }\n"])), function (props) { return rem(props.theme.header.height, props.theme.baseFontSize); }, media.greaterThan("medium")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: ", "\n  "], ["\n    padding: ", "\n  "])), spacing("loose")), slideIn, animationSpeed("fast"), animationEasing("easeOutCubic"), slideOut, animationSpeed("fast"), animationEasing("easeOutCubic"));
export var Toast = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  /* margin-top: ", "; */\n  margin-bottom: ", ";\n  padding: ", ";\n  box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;\n  ", "\n  ", "\n  \n  &.toast-enter {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n  &.toast-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: ", ";\n  }\n  &.toast-exit {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  &.toast-exit-active {\n    opacity: 0;\n    transform: translateX(50%);\n    transition: ", ";\n  }\n  &&& ", " {\n    ", "\n  }\n"], ["\n  /* margin-top: ", "; */\n  margin-bottom: ", ";\n  padding: ", ";\n  box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;\n  ", "\n  ", "\n  \n  &.toast-enter {\n    opacity: 0;\n    transform: translateX(50%);\n  }\n  &.toast-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: ", ";\n  }\n  &.toast-exit {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  &.toast-exit-active {\n    opacity: 0;\n    transform: translateX(50%);\n    transition: ", ";\n  }\n  &&& ", " {\n    ", "\n  }\n"])), spacing(), spacing(), spacing(), function (_a) {
    var hasDismiss = _a.hasDismiss, theme = _a.theme;
    return hasDismiss && "padding-right: ".concat(rem("40px", theme.baseFontSize), ";");
}, function (_a) {
    var variation = _a.variation, theme = _a.theme;
    return "\n    background-color: ".concat(theme.getColor(VariationToColor[variation], VariationToBackgroundValue[variation]), ";\n    border-left: 3px solid ").concat(theme.getColor(VariationToColor[variation], VariationToBorderValue[variation]), ";\n  ");
}, "\n      opacity ".concat(animationSpeed("fast"), " ").concat(animationEasing("easeOutCubic"), ",\n      transform ").concat(animationSpeed("fast"), " ").concat(animationEasing(), ";\n    "), "\n      opacity ".concat(animationSpeed("fast"), " ").concat(animationEasing("easeOutCubic"), ",\n      transform ").concat(animationSpeed("fast"), " ").concat(animationEasing(), ";\n    "), StyledIcon, function (_a) {
    var variation = _a.variation, theme = _a.theme;
    return "\n      flex: 0 0 auto;\n      color: ".concat(theme.getColor(VariationToColor[variation], 1000), ";\n    ");
});
export var ToastContentWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 2px;\n  padding: 0 ", ";\n  flex: 1;\n  > *:not(:last-child) {\n    margin-bottom: ", ";\n  }\n"], ["\n  margin-top: 2px;\n  padding: 0 ", ";\n  flex: 1;\n  > *:not(:last-child) {\n    margin-bottom: ", ";\n  }\n"])), spacing(), function (_a) {
    var theme = _a.theme;
    return theme.spacing("");
});
export var ToastContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-left: ", ";\n"], ["\n  padding-left: ", ";\n"])), function (props) { return rem("40px", props.theme.baseFontSize); });
export var Icon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: ", ";\n  flex: 0 0 auto;\n"], ["\n  width: ", ";\n  flex: 0 0 auto;\n"])), function (props) { return rem("40px", props.theme.baseFontSize); });
export var Close = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: ", ";\n  margin-left: ", ";\n  flex: 0 0 auto;\n"], ["\n  margin-top: ", ";\n  margin-left: ", ";\n  flex: 0 0 auto;\n"])), spacing("extraTight"), spacing("tight"));
export var ToastHeading = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n"])));
export var Heading = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-weight: ", ";\n  flex: 1 1 0%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  font-weight: ", ";\n  flex: 1 1 0%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])), FontWeights.SemiBold);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
