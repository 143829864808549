var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import Link from "../Link/Link";
export var StyledSearchList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: calc(100vh - 84px);\n  overflow: auto;\n"], ["\n  max-height: calc(100vh - 84px);\n  overflow: auto;\n"])));
export var StyledSearchListTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " ", ";\n  ", "\n  font-weight: ", ";\n"], ["\n  padding: ", " ", ";\n  ", "\n  font-weight: ", ";\n"])), spacing("extraTight"), spacing(), function (_a) {
    var theme = _a.theme;
    return "\n    font-size: ".concat(rem("14px", theme.baseFontSize), ";\n    text-transform: uppercase;\n  ");
}, FontWeights.Bold);
export var StyledSearchListItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", " 0;\n  & + & {\n    border-top: 1px solid ", ";\n  }\n"], ["\n  padding: ", " 0;\n  & + & {\n    border-top: 1px solid ", ";\n  }\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
});
export var StyledSearchResultLink = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  padding: ", " ", ";\n  color: ", ";\n  text-decoration: none;\n  &:hover {\n    ", "\n  }\n  ", "\n"], ["\n  display: block;\n  padding: ", " ", ";\n  color: ", ";\n  text-decoration: none;\n  &:hover {\n    ", "\n  }\n  ", "\n"])), spacing("tight"), spacing(), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray");
}, function (_a) {
    var theme = _a.theme;
    return "\n    background: ".concat(theme.getColor("gray", 0), ";\n    color: ").concat(theme.getColor("gray", 1000), ";\n  ");
}, function (_a) {
    var subdued = _a.subdued;
    return subdued && "opacity: 0.5;";
});
export var StyledSearchMoreLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  padding: ", " ", ";\n"], ["\n  display: inline-block;\n  padding: ", " ", ";\n"])), spacing("tight"), spacing());
export var StyledSearchResultMeta = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 600), ";\n    font-size: ").concat(rem("14px", theme.baseFontSize), ";  \n  ");
});
export var StyledSearchResultMembers = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: ", ";\n  ", "\n"], ["\n  margin-top: ", ";\n  ", "\n"])), spacing("extraTight"), function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("gray", 400), ";\n    font-size: ").concat(rem("14px", theme.baseFontSize), ";  \n  ");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
