var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { customValidateErrorsForSchema, FORM_FIELDS_WITH_DATA, renderSummary, } from "@rentiohq/shared-frontend/dist/components/components/FormSummary/summary.utils";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { areContactFieldsValid, hasValue, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { compact, flatten } from "lodash";
import React from "react";
import * as t from "../../../services/translationService";
import Button from "../../Button";
import DisplayText from "../../DisplayText";
import Grid, { ESpacings } from "../../Grid";
import Icon from "../../Icon";
import FormSummaryItem from "./components/FormSummaryItem.container";
// Refs cannot be attached to functional components
// https://medium.com/@rossbulat/how-to-use-react-refs-4541a7501663
var FormSummary = /** @class */ (function (_super) {
    __extends(FormSummary, _super);
    function FormSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderHeader = function (stepIndex, title) {
            var _a, _b;
            var _c = _this.props, onPressEditSchema = _c.onPressEditSchema, _d = _c.schemas, schemas = _d === void 0 ? [] : _d;
            var disabledOnSummary = ((_b = (_a = schemas[stepIndex]) === null || _a === void 0 ? void 0 : _a.uiSchema) === null || _b === void 0 ? void 0 : _b.disabledOnSummary) || false;
            var handleEditClick = function () {
                onPressEditSchema === null || onPressEditSchema === void 0 ? void 0 : onPressEditSchema(stepIndex);
            };
            return (_jsxs(Grid, __assign({ justifyContent: "space-between", alignItems: "center", mb: ESpacings.tight, mt: stepIndex > 0 ? ESpacings.base : 0 }, { children: [_jsx(Grid.Item, __assign({ flexGrow: 0 }, { children: _jsx(DisplayText, __assign({ size: "medium" }, { children: title })) })), !disabledOnSummary && (_jsx(Grid.Item, __assign({ flexGrow: 1 }, { children: _jsx(Button, __assign({ onClick: handleEditClick, appearance: "plain" }, { children: _jsx(Icon, { source: "edit", size: "small" }) })) })))] })));
        };
        _this.renderField = function (_, __, title, rawValue, ___, valueSelector, error) {
            var mergedPartialFormData = _this.props.mergedPartialFormData;
            var value = rawValue ? rawValue : t.system("empty_value");
            return (_jsx(FormSummaryItem, { title: title, value: value, rawValue: rawValue, valueSelector: valueSelector, error: error, formData: mergedPartialFormData }));
        };
        _this.attemptSubmit = function () {
            if (!_this.isValid()) {
                return;
            }
            _this.props.onPressSubmit();
        };
        _this.isValid = function () {
            var result = true;
            var _a = _this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, mergedPartialFormData = _a.mergedPartialFormData;
            schemas.forEach(function (_a, index) {
                var uiSchema = _a.uiSchema, schemaGenerator = _a.schema, showIf = _a.showIf, validate = _a.validate;
                var schema = schemaGenerator(mergedPartialFormData);
                var requiredFields = schema.required || [];
                var fieldKeys = Object.keys(uiSchema);
                if (showIf && !showIf(mergedPartialFormData)) {
                    return;
                }
                if (validate) {
                    var errorsFromValidate = flatten(Object.values(customValidateErrorsForSchema({
                        uiSchema: uiSchema,
                        validate: validate,
                        formData: mergedPartialFormData,
                    })).map(function (field) { return (field === null || field === void 0 ? void 0 : field.errors) || []; }));
                    if (errorsFromValidate.length > 0) {
                        result = false;
                    }
                }
                __spreadArray([], fieldKeys, true).filter(function (fieldKey) {
                    var uiField = uiSchema[fieldKey];
                    // Check if field has possibility to display data
                    if (!FORM_FIELDS_WITH_DATA.includes(uiField["ui:field"])) {
                        return false;
                    }
                    if (
                    // @ts-ignore
                    schema.properties[fieldKey].showIf &&
                        // @ts-ignore
                        !schema.properties[fieldKey].showIf(mergedPartialFormData)) {
                        return false;
                    }
                    return true;
                })
                    .forEach(function (fieldKey) {
                    var _a, _b, _c;
                    var uiField = uiSchema[fieldKey];
                    var startValue = mergedPartialFormData[fieldKey];
                    var value = uiField.formatSummary
                        ? uiField.formatSummary(startValue, mergedPartialFormData)
                        : startValue;
                    var isRequired = requiredFields.includes(fieldKey) ||
                        (
                        // @ts-ignore
                        (_b = (_a = schema.properties[fieldKey]) === null || _a === void 0 ? void 0 : _a.requiredIf) === null || _b === void 0 ? void 0 : _b.call(_a, mergedPartialFormData));
                    var fieldType = uiField["ui:field"];
                    if (fieldType === ERentioFormField.ContactSelectInlineV2) {
                        var _d = uiField.withSummaryValidation, withSummaryValidation = _d === void 0 ? true : _d, getRequiredContactFieldsSchema_1 = uiField.getRequiredContactFieldsSchema, getRequiredCompanyFieldsSchema_1 = uiField.getRequiredCompanyFieldsSchema, requiredContactFieldsSchema_1 = uiField.requiredContactFieldsSchema, requiredCompanyFieldsSchema_1 = uiField.requiredCompanyFieldsSchema;
                        if (withSummaryValidation &&
                            value &&
                            (requiredContactFieldsSchema_1 ||
                                getRequiredContactFieldsSchema_1 ||
                                requiredCompanyFieldsSchema_1 ||
                                getRequiredCompanyFieldsSchema_1)) {
                            var state_1 = (_c = getStore()) === null || _c === void 0 ? void 0 : _c.getState();
                            if (state_1) {
                                var accountIds = value;
                                var contacts = compact(accountIds.map(function (accountId) {
                                    return contactSelectors.getContact(state_1, undefined, accountId);
                                }));
                                var contactsAreValid_1 = true;
                                contacts.forEach(function (contact) {
                                    var requiredContactFieldsSchemaParsed = getRequiredContactFieldsSchema_1 && contact
                                        ? getRequiredContactFieldsSchema_1(contact)
                                        : requiredContactFieldsSchema_1;
                                    var requiredCompanyFieldsSchemaParsed = getRequiredCompanyFieldsSchema_1 && contact
                                        ? getRequiredCompanyFieldsSchema_1(contact)
                                        : requiredCompanyFieldsSchema_1;
                                    if (!areContactFieldsValid({
                                        contact: contact,
                                        requiredContactFieldsSchema: requiredContactFieldsSchemaParsed,
                                        requiredCompanyFieldsSchema: requiredCompanyFieldsSchemaParsed,
                                    })) {
                                        contactsAreValid_1 = false;
                                    }
                                });
                                if (!contactsAreValid_1) {
                                    result = false;
                                    return;
                                }
                            }
                        }
                    }
                    if (isRequired && !hasValue(value)) {
                        result = false;
                        return;
                    }
                });
            });
            return result;
        };
        return _this;
    }
    FormSummary.prototype.render = function () {
        var _a = this.props, _b = _a.title, title = _b === void 0 ? t.system("summary") : _b, schemas = _a.schemas, mergedPartialFormData = _a.mergedPartialFormData;
        return (_jsxs(_Fragment, { children: [_jsx(DisplayText, { children: title }), renderSummary({ schemas: schemas, mergedPartialFormData: mergedPartialFormData }, this.renderHeader, this.renderField)] }));
    };
    return FormSummary;
}(React.PureComponent));
export { FormSummary };
