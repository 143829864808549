var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { BaseInput } from "../BaseInput";
export var CurrencyWidget = function (_a) {
    var onChange = _a.onChange, value = _a.value, props = __rest(_a, ["onChange", "value"]);
    var formatCurrency = function (value) {
        var _a;
        if (value === void 0) { value = ""; }
        if (value && String(value).charAt(value.length - 1) === ".") {
            return value.replace(".", ",");
        }
        if (Number(value) === 0) {
            return value;
        }
        var numberOfDigits = (_a = String(value).split(".")[1]) === null || _a === void 0 ? void 0 : _a.length;
        var formatter = new global.Intl.NumberFormat("fr", {
            maximumFractionDigits: 2,
            minimumFractionDigits: numberOfDigits <= 2 && numberOfDigits ? numberOfDigits : 0,
        });
        // @ts-ignore
        return formatter.format(value);
    };
    var handleChange = function (value) {
        var rawValue = 0;
        if (value || value === 0) {
            rawValue = value.replace(/\s/g, "").replace(",", ".");
        }
        if (isNaN(rawValue)) {
            return;
        }
        return onChange(value === "" ? value : rawValue);
    };
    return (_jsx(BaseInput, __assign({ suffix: "EUR", value: formatCurrency(value) || "" }, props, { onChange: handleChange })));
};
