var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Loading from "../Loading";
import * as utils from "./ContactFetchListItem.utils";
var ContactFetchListItem = /** @class */ (function (_super) {
    __extends(ContactFetchListItem, _super);
    function ContactFetchListItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderLoading = function () {
            return _jsx(Loading, { asDots: true });
        };
        // Data
        _this.fetchData = function () {
            var contact = _this.getContact();
            if (contact) {
                return;
            }
            var contactId = utils.getContactId(_this.props);
            if (contactId) {
                _this.props.getContact({ contactId: contactId });
                return;
            }
            var accountId = utils.getAccountId(_this.props);
            if (accountId) {
                _this.props.getContactsByAccountIds({ accountIds: [accountId] });
                return;
            }
        };
        // Helpers
        _this.getContact = function () {
            return _this.props.fetchedContact || _this.props.contact;
        };
        _this.getAccount = function () {
            return _this.props.accountFromStore || _this.props.account;
        };
        return _this;
    }
    // Lifecycle methods
    ContactFetchListItem.prototype.componentDidMount = function () {
        this.fetchData();
    };
    // Render
    ContactFetchListItem.prototype.render = function () {
        var _a = this.props, isFetching = _a.isFetching, fetchError = _a.fetchError, renderLoading = _a.renderLoading, renderError = _a.renderError, renderContact = _a.renderContact, renderAccount = _a.renderAccount;
        var contact = this.getContact();
        if (contact) {
            return (renderContact === null || renderContact === void 0 ? void 0 : renderContact(contact)) || null;
        }
        var account = this.getAccount();
        if (account) {
            return (renderAccount === null || renderAccount === void 0 ? void 0 : renderAccount(account)) || null;
        }
        if (isFetching) {
            return (renderLoading === null || renderLoading === void 0 ? void 0 : renderLoading()) || this.renderLoading();
        }
        // TODO: Render default error
        if (fetchError) {
            return (renderError === null || renderError === void 0 ? void 0 : renderError(fetchError)) || null;
        }
        return null;
    };
    return ContactFetchListItem;
}(React.PureComponent));
export default ContactFetchListItem;
