import { BaseInput } from "../BaseInput";
import { CheckboxesWidget } from "./CheckboxesWidget";
import { CheckboxWidget } from "./CheckboxWidget";
import { CurrencyWidget } from "./CurrencyWidget";
import { DateWidget } from "./DateWidget";
import { FileWidget } from "./FileWidget";
import { IBANWidget } from "./IBANWidget";
import { PhoneWidget } from "./PhoneWidget";
import { SelectWidget } from "./SelectWidget";
import { TextareaWidget } from "./TextareaWidget";
import { TextWidget } from "./TextWidget";
export var widgets = {
    BaseInput: BaseInput,
    text: TextWidget,
    currency: CurrencyWidget,
    checkbox: CheckboxWidget,
    checkboxes: CheckboxesWidget,
    date: DateWidget,
    file: FileWidget,
    phone: PhoneWidget,
    iban: IBANWidget,
    textarea: TextareaWidget,
    select: SelectWidget,
    // hidden: 'HiddenWidget',
    // string: {
    //   select: SelectWidget,
    // },
    // number: {
    //   select: SelectWidget,
    // },
    // integer: {
    //   select: SelectWidget,
    // },
    // array: {
    //   select: SelectWidget,
    // },
};
