var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EExtraIbanSelectValues, } from "@rentiohq/shared/dist/types/contact.types";
import Banner from "../../../Banner";
import IbanSelect from "./components/IbanSelect.container";
export var IbanSelectField = function (_a) {
    var name = _a.name, rawUiSchema = _a.uiSchema, idSchema = _a.idSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
    var uiSchema = rawUiSchema;
    var title = getFieldTitle(uiSchema, formContext);
    var info = getFieldInfo(uiSchema, formContext);
    var error = rawErrors[0];
    var id = idSchema.$id;
    var addIbanCtaTitle = uiSchema.addIbanCtaTitle, customIsFetchingSelector = uiSchema.customIsFetchingSelector, customIbansSelector = uiSchema.customIbansSelector, customGetIbansActions = uiSchema.customGetIbansActions, isDisabled = uiSchema.isDisabled, accountIdToAddIban = uiSchema.accountIdToAddIban, showUnknownIbanOption = uiSchema.showUnknownIbanOption, customUnknownIbanNotification = uiSchema.customUnknownIbanNotification;
    var disabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    var allData = formContext.getFormData();
    var accountId = accountIdToAddIban(allData);
    var handleSelectIban = function (value) {
        onChange(value);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, value);
            }, 0);
        }
    };
    var customIsFetchingSelectorEnhanced = function (state) {
        return customIsFetchingSelector(state, 
        // @ts-ignore
        formContext.getFormData());
    };
    var customIbansSelectorEnhanced = function (state) {
        return customIbansSelector(state, 
        // @ts-ignore
        formContext.getFormData());
    };
    // @ts-ignore
    var customGetIbansActionsEnhanced = customGetIbansActions(formContext.getFormData());
    var handlePressAddIban = function (id) {
        handleSelectIban(id);
        if (formContext.onSelectMore) {
            formContext.onSelectMore(name, id);
        }
    };
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsxs(_Fragment, { children: [formData === EExtraIbanSelectValues.Unknown &&
                customUnknownIbanNotification &&
                showUnknownIbanOption && (_jsx(Banner, { title: customUnknownIbanNotification, variation: "warning", hasDismiss: false, icon: "alertDiamond" })), _jsx(IbanSelect, __assign({}, uiSchema, { id: id, title: title, info: info, accountId: accountId, onSelectIban: handleSelectIban, selectedIbanId: formData, addIbanCtaTitle: addIbanCtaTitle, onPressAddIban: handlePressAddIban, error: error, disabled: disabled, customIsFetchingSelector: customIsFetchingSelectorEnhanced, customIbansSelector: customIbansSelectorEnhanced, customGetIbansActions: customGetIbansActionsEnhanced, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo }))] }));
};
