var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, FontWeights, spacing } from "@rentiohq/web-theme";
var cellStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  padding: ", " ", ";\n  &:first-child {\n    padding-left: 0;\n  }\n  &:last-child {\n    padding-right: 0;\n  }\n"], ["\n  text-align: left;\n  padding: ", " ", ";\n  &:first-child {\n    padding-left: 0;\n  }\n  &:last-child {\n    padding-right: 0;\n  }\n"])), spacing(), spacing());
export var Table = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  table-layout: fixed;\n  border-collapse: collapse;\n  width: 100%;\n"], ["\n  table-layout: fixed;\n  border-collapse: collapse;\n  width: 100%;\n"])));
export var Head = styled.thead(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 2px solid ", ";\n"], ["\n  border-bottom: 2px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
});
export var HeadCell = styled.th(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  font-weight: ", ";\n  text-align: left;\n  vertical-align: top;\n  ", "\n"], ["\n  ", "\n  font-weight: ", ";\n  text-align: left;\n  vertical-align: top;\n  ", "\n"])), cellStyle, FontWeights.Bold, function (props) { return "width: ".concat(props.width, ";"); });
export var Body = styled.tbody(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var Row = styled.tr(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var Cell = styled.td(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  border-bottom: 1px solid ", ";\n"], ["\n  ", "\n  border-bottom: 1px solid ", ";\n"])), cellStyle, function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 200);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
