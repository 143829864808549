var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "../../../../utils";
import Grid from "../../../Grid";
import Icon from "../../../Icon";
import Labelled from "../../../Labelled";
import TextStyle from "../../../TextStyle";
var PropertyInfoField = function (props) {
    var passedProperty = props.property, propertyId = props.propertyId;
    var propertyFromStore = propertyHooks.useDetail({
        id: propertyId,
    }).detail;
    // Render
    var property = propertyFromStore || passedProperty;
    if (!property) {
        return null;
    }
    return (_jsx(Labelled, __assign({ label: getLocalizedText("system.model.property") }, { children: _jsxs(Grid, __assign({ alignItems: "center" }, { children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: utils.properties.getIcon(property.typeId || 0), size: "large" }) }), _jsxs(Grid.Item, { children: [_jsx("div", { children: _jsx(TextStyle, { children: formatAddress(property) }) }), _jsx("div", { children: _jsx(TextStyle, __assign({ variation: "subdued" }, { children: property.name })) })] })] })) })));
};
export default PropertyInfoField;
