var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMappedCountries, getMappedLanguages, getMappedNationalities, } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import React from "react";
export var GET_ALL_INTAKE_TYPES = "GET_ALL_INTAKE_TYPES";
export var GET_ALL_LOCALES = "GET_ALL_LOCALES";
export var GET_ALL_COUNTRY_ISO = "GET_ALL_COUNTRY_ISO";
export var GET_ALL_NATIONALITY_ISO = "GET_ALL_NATIONALITY_ISO";
export var WHISE_CLIENTS = "WHISE_CLIENTS";
export var useJsonFormAction = function (action) {
    var _a = React.useState(), data = _a[0], setData = _a[1];
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    React.useEffect(function () {
        if (!action) {
            return;
        }
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var actionProps, response, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLoading(true);
                        actionProps = getActionProps(action);
                        if (!actionProps.call && !actionProps.data) {
                            return [2 /*return*/];
                        }
                        if (!actionProps.call) return [3 /*break*/, 2];
                        return [4 /*yield*/, actionProps.call];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        _a = actionProps;
                        _b.label = 3;
                    case 3:
                        response = (_a).data;
                        setIsLoading(false);
                        if (actionProps.transform) {
                            setData(response.map(actionProps.transform));
                            return [2 /*return*/];
                        }
                        setData(response);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [action]);
    return { data: data, isLoading: isLoading };
};
export var getActionProps = function (action) {
    switch (action) {
        case GET_ALL_INTAKE_TYPES:
            var INTAKE_TYPE_OPTIONS = [
                { label: "intake_type_1", id: 1 },
                { label: "intake_type_2", id: 2 },
                { label: "intake_type_3", id: 3 },
            ];
            return {
                data: INTAKE_TYPE_OPTIONS.map(function (type) { return ({
                    id: type.id,
                    value: type.id,
                    label: getLocalizedText("broker.intakeType.".concat(type.id)),
                }); }),
            };
        case GET_ALL_NATIONALITY_ISO:
            return {
                data: getMappedNationalities().map(function (nationality) { return ({
                    id: nationality.id,
                    value: nationality.id,
                    label: nationality.value,
                }); }),
            };
        case GET_ALL_COUNTRY_ISO:
            return {
                data: getMappedCountries().map(function (country) { return ({
                    id: country.id,
                    value: country.id,
                    label: country.value,
                }); }),
            };
        case GET_ALL_LOCALES:
            return {
                data: getMappedLanguages().map(function (locale) { return ({
                    id: locale.id,
                    value: locale.id,
                    label: locale.value,
                }); }),
            };
        case WHISE_CLIENTS:
            return {
                call: api.get("/system/whise-clients"),
                transform: function (client, index) {
                    var id = client.id, name = client.name;
                    return {
                        id: index,
                        value: id,
                        label: name,
                    };
                },
            };
        default:
            return {};
    }
};
