var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledDisplayText } from "./DisplayText.styled";
var DisplayText = function (_a) {
    var _b = _a.element, element = _b === void 0 ? "h2" : _b, children = _a.children, _c = _a.size, size = _c === void 0 ? "large" : _c, preventUppercase = _a.preventUppercase, _d = _a.space, space = _d === void 0 ? "base" : _d, _e = _a.subdued, subdued = _e === void 0 ? false : _e, className = _a.className;
    return (_jsx(StyledDisplayText, __assign({ as: element, size: size, preventUppercase: preventUppercase, space: space, subdued: subdued, className: className }, { children: children })));
};
export default DisplayText;
