var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from "../../../../Icon";
import * as S from "./Checkbox.styled";
export var Checkbox = function (props) {
    var checked = props.checked, error = props.error, onChangeValue = props.onChangeValue, appearance = props.appearance, disabled = props.disabled, fieldProps = __rest(props, ["checked", "error", "onChangeValue", "appearance", "disabled"]);
    var colorForAppearance = "neutral60";
    switch (appearance) {
        case "info":
            colorForAppearance = "blue";
            break;
        case "success":
            colorForAppearance = "green";
            break;
        case "error":
            colorForAppearance = "red";
            break;
        case "warning":
            colorForAppearance = "yellow";
            break;
        default:
            break;
    }
    return (_jsxs(S.Checkbox, __assign({ checked: checked, error: error, onClick: function (e) {
            if (disabled) {
                e.stopPropagation();
            }
            if (!disabled && onChangeValue)
                onChangeValue(e);
        } }, { children: [_jsx(Icon, { source: "checkboxOutline", color: disabled ? "neutral40" : colorForAppearance }), checked && _jsx(Icon, { source: "checkboxMark", color: colorForAppearance }), Object.keys(fieldProps).length > 0 && (_jsx(S.InputWrap, { children: _jsx("input", __assign({ disabled: disabled, onChange: function () { }, checked: checked }, fieldProps)) }))] })));
};
