var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { borderRadius, FontWeights, spacing, } from "@rentiohq/web-theme";
import ButtonBase from "../Button";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: ", ";\n  background: ", ";\n  border-radius: ", ";\n  font-weight: ", ";\n  ", "\n"], ["\n  display: flex;\n  padding: ", ";\n  background: ", ";\n  border-radius: ", ";\n  font-weight: ", ";\n  ", "\n"])), spacing("extraTight"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 0);
}, borderRadius(), FontWeights.SemiBold, function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return hasError &&
        "\n      background: ".concat(theme.getColor("red", 0), ";\n    ");
});
export var Button = styled(function (props) { return _jsx(ButtonBase, __assign({}, props)); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  padding: ", ";\n  font-size: ", ";\n  text-align: center;\n  &,\n  &:hover,\n  &:focus {\n    background: none;\n  }\n  &:hover,\n  &:focus {\n    color: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n  &&& {\n    ", "\n  }\n"], ["\n  flex: 1;\n  padding: ", ";\n  font-size: ", ";\n  text-align: center;\n  &,\n  &:hover,\n  &:focus {\n    background: none;\n  }\n  &:hover,\n  &:focus {\n    color: ", ";\n  }\n  &:active {\n    background: ", ";\n  }\n  &&& {\n    ", "\n  }\n"])), function (props) { return (props.size === "small" ? spacing("tight") : "12px"); }, function (props) { return props.size === "small" && "13px"; }, function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary", 800);
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var selected = _a.selected, theme = _a.theme;
    return selected &&
        "\n        background: ".concat(theme.getColor("white"), ";\n        color: ").concat(theme.getColor("primary", 800), ";\n        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);\n      ");
});
var templateObject_1, templateObject_2;
