var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as t from "../../../../services/translationService";
import Labelled from "../../../Labelled/Labelled";
import FakeAsyncSelect, { AsyncSelect as RealAsyncSelect, } from "../../../Select";
import { getActionProps, useJsonFormAction } from "../../actions";
import { getUiOptions } from "../../utils";
// @ts-ignore
export var AsyncSelect = function (_a) {
    var schema = _a.schema, onChange = _a.onChange, rawErrors = _a.rawErrors, formData = _a.formData, name = _a.name, options = _a.options, idSchema = _a.idSchema, rest = __rest(_a, ["schema", "onChange", "rawErrors", "formData", "name", "options", "idSchema"]);
    var data = useJsonFormAction(schema.action).data;
    var actionProps = getActionProps(schema.action);
    var _b = React.useState([]), defaultOptions = _b[0], setDefaultOptions = _b[1];
    var uiOptions = getUiOptions(rest.uiSchema);
    var multiple = uiOptions.multiple;
    var error = rawErrors === null || rawErrors === void 0 ? void 0 : rawErrors[0];
    var optional = !rest.required;
    var disabled = getUiOptions(schema.ui).disabled;
    var isDisabled = disabled === "disabled";
    rest.disabled = isDisabled;
    var isRealAsync = Array.isArray(actionProps);
    React.useEffect(function () {
        if (!data)
            return;
        optional &&
            !multiple &&
            data.unshift({
                id: undefined,
                label: t.formSelectUnselect(),
                value: null,
            });
        setDefaultOptions(data);
    }, [data, optional, multiple]);
    if (!actionProps) {
        return null;
    }
    var reduceValues = function (options) {
        return options.reduce(function (currentValues, option) {
            var value = option.value;
            return __spreadArray(__spreadArray([], currentValues, true), [value], false);
        }, []);
    };
    var handleChange = function (option) {
        var value = multiple ? reduceValues(option) : option.value;
        onChange(value);
    };
    return (_jsx(Labelled, __assign({ label: schema.title, optional: optional, error: error }, { children: isRealAsync ? (_jsx(RealAsyncSelect, __assign({ id: idSchema.$id, value: formData, name: name, onChange: handleChange, error: error, menuPortalTarget: document.body, defaultOptions: defaultOptions, multiple: multiple }, rest))) : (_jsx(FakeAsyncSelect, __assign({ id: idSchema.$id, value: formData, name: name, onChange: handleChange, error: error, options: defaultOptions, multiple: multiple, menuPortalTarget: document.body }, rest))) })));
};
