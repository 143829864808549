var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EContactType } from "@rentiohq/shared/dist/types/contact.types";
import { ts } from "../../services";
import { Tooltip } from "../Tooltip";
import * as S from "./ContactConnectionIndicator.styled";
var ContactConnectionIndicator = function (_a) {
    var contact = _a.contact, _b = _a.showTooltip, showTooltip = _b === void 0 ? true : _b, _c = _a.forceDisplay, forceDisplay = _c === void 0 ? false : _c;
    var hasAccepted = false;
    if (!(contact === null || contact === void 0 ? void 0 : contact.hasOwnProperty("inviteAcceptedAt")) && !forceDisplay) {
        return null;
    }
    if (contact === null || contact === void 0 ? void 0 : contact.inviteAcceptedAt) {
        hasAccepted = true;
    }
    if ((contact === null || contact === void 0 ? void 0 : contact.type) === EContactType.Personal) {
        hasAccepted = true;
    }
    var getVariation = function () {
        return hasAccepted ? "success" : "error";
    };
    return showTooltip ? (_jsx(Tooltip, __assign({ content: hasAccepted
            ? ts.contactConnectionSuccess()
            : ts.contactConnectionPending(), place: "right" }, { children: _jsx(S.Dot, { variation: getVariation() }) }))) : (_jsx(S.Dot, { variation: getVariation() }));
};
export default ContactConnectionIndicator;
