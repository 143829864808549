import * as t from "../services/translationService";
import * as utils from "../utils";
var TEXT_MIME_TYPES = [
    // cspell:disable
    "text/plain",
    "text/richtext",
    "application/rtf",
    "application/x-rtf",
    "application/msword",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/x-iwork-pages-sffpages",
    // cspell:enable
];
export var getTypeData = function (mimeType) {
    var isImage = mimeType.startsWith("image/");
    var isPDF = mimeType === "application/pdf";
    var isText = TEXT_MIME_TYPES.includes(mimeType);
    var data = {
        type: "generic",
        icon: "fileGeneric",
        name: "",
    };
    switch (true) {
        case isImage:
            data.type = "image";
            data.icon = "fileImage";
            break;
        case isPDF:
            data.type = "pdf";
            data.icon = "filePDF";
            break;
        case isText:
            data.type = "text";
            data.icon = "fileText";
            break;
        default:
            break;
    }
    data.name = t.documentTypeLabel(data.type);
    return data;
};
export var isURL = function (url) {
    /* tslint:disable-next-line */
    var urlRegex = /^\s*https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/i;
    return !!url.match(urlRegex);
};
export var viewDocument = function (document) {
    return utils.general.openWindow(document.url);
};
